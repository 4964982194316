import { API_URL } from "./config";

export const signup = async (
  {
    email,
    firstName,
    lastName,
    password,
    phoneNumber,
    fullAddress,
    latitude,
    longitude,
    organization,
    location_id,
    emailVerified,
    facilityVerified
  }
) => {
  // console.log(JSON.stringify({ email, name, username }));
  const res = await fetch(`${API_URL}/auth-facility/signup`, {
    method: "POST",
    headers: {
      "Content-type": "Application/json",
    },
    body: JSON.stringify({
      email,
      firstName,
      lastName,
      password,
      phoneNumber,
      fullAddress,
      latitude,
      longitude,
      organization,
      location_id,
      emailVerified,
      facilityVerified
    }),
  });
  if (res.status === 400) {
    throw new Error("Email should be unique");
  } else {
    return res.json();
  }
};

export const confirmSignUP = async ({ email, emailToken }) => {
  // console.log(JSON.stringify({ email, emailToken }));
  const res = await fetch(`${API_URL}/auth-facility/signup/conformation`, {
    method: "PUT",
    headers: {
      "Content-type": "Application/json",
    },
    body: JSON.stringify({ email, emailToken }),
  });

  if (res.status === 401) {
    throw new Error("Incorrect OTP!");
  } else if (res.status === 402) {
    throw new Error("Expired OTP!");
  } else if (res.status === 403) {
    throw new Error("Email is incorrect!");
  } else if (res.status !== 200) {
    throw new Error("Error during the login process");
  } else {
    return res.json();
  }
};

export const authLogin = async ({ email, password }) => {
  // console.log(JSON.stringify({ email, password }));
  // console.log(`${API_URL}/auth-facility/login-and-authenticate`)

  const res = await fetch(`${API_URL}/auth-facility/login-and-authenticate`, {
    method: "POST",
    headers: {
      "Content-type": "Application/json",
    },
    body: JSON.stringify({ email, password }),
  });
  // console.log(res);
  if (res.status === 400) {
    throw new Error("User is not found");
  } else if (res.status === 401) {
    throw new Error("User is not confirmed!");
  } else if (res.status === 402) {
    throw new Error("Invalid email or password");
  } else if (res.status !== 200) {
    throw new Error("Error during the login process");
  } else {
    return res.json();
  }
};

export const getConfirmCode = async ({ email }) => {
  // console.log(JSON.stringify({ email }));
  const res = await fetch(`${API_URL}/auth-facility/conformation`, {
    method: "POST",
    headers: {
      "Content-type": "Application/json",
    },
    body: JSON.stringify({ email }),
  });
  // console.log(res);
  if (res.status === 400) {
    throw new Error("Something went wrong. Please try later");
  } else if (res.status === 401) {
    throw new Error("User is not found");
  } else {
    return res.json();
  }
};

export const login = async ({ email, password }) => {
  // console.log(JSON.stringify({ email }));
  const res = await fetch(`${API_URL}/auth-facility/login`, {
    method: "POST",
    headers: {
      "Content-type": "Application/json",
    },
    body: JSON.stringify({ email, password }),
  });
  // console.log(res);
  if (res.status === 400) {
    throw new Error("user is not found");
  } else if (res.status === 402) {
    throw new Error("Invalid email or password");
  } else if (res.status !== 200) {
    throw new Error("Error during the login process");
  } else {
    return res.json();
  }
};

export const authenticate = async ({ email, emailToken }) => {
  const res = await fetch(`${API_URL}/auth-facility/authenticate`, {
    method: "POST",
    headers: {
      "Content-type": "Application/json",
    },
    body: JSON.stringify({ email, emailToken }),
  });
  if (res.status !== 200) {
    throw new Error("Error during the login process");
  }
  return res.json();
};

export const forgetPasswordChange = async ({ email, emailToken, password }) => {
  // console.log(JSON.stringify({ email }));
  const res = await fetch(`${API_URL}/auth-facility/forget/password/conformation`, {
    method: "PUT",
    headers: {
      "Content-type": "Application/json",
    },
    body: JSON.stringify({ email, emailToken, password }),
  });
  // console.log(res);
  if (res.status === 401) {
    throw new Error("Incorrect OTP!");
  } else if (res.status === 402) {
    throw new Error("Expired OTP!");
  } else if (res.status === 403) {
    throw new Error("Email is incorrect!");
  } else if (res.status !== 200) {
    throw new Error("Error during the login process");
  } else {
    return res.json();
  }
};

export const authEmail = async (email) => {
  // console.log(JSON.stringify({ email, password }));
  // console.log(`${API_URL}/auth-nurse/login-and-authenticate`)

  const res = await fetch(`${API_URL}/auth-facility/login/${email}`, {
    method: "POST",
    headers: {
      'Content-type': 'application/json'
    }
  });
  // console.log(res);
  if (res.status === 400) {
    throw new Error("User is not found");
  } else if (res.status === 401) {
    throw new Error("User is not confirmed!");
  } else if (res.status === 402) {
    throw new Error("Invalid email or password");
  } else if (res.status !== 200) {
    throw new Error("Error during the login process");
  } else {
    return res.json();
  }
};