import {
  View,
  Text,
  TouchableOpacity,
} from "react-native";
import React, { useEffect, useState, } from "react";
import ShiftScreen from "./OrganizationJobsShift";
import VisitScreen from "./OrganizationJobsVisit";
import { useAuth } from "../../context/AuthContext";
import { getFacilityDetails } from "../../api_url/facilityTable";

const OrganizationJobView = ({props}) => {
  const [jobType, setJobType] = useState("shift");

  const { authToken, userId, userUniqueId } = useAuth();
  const [user, setUser] = useState(undefined);
  const [loadingScreen, setLoadingScreen] = useState(false);

  const checkUser = async (authToken, userId) => {
    try {
      await getFacilityDetails(authToken, userId)
        .then((res) => {
          if (res === undefined) {
            setUser(null);
            setLoadingScreen(false);
          } else {
            setUser(res);
            setLoadingScreen(false);
          }
        })
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (authToken && userId) {
      checkUser(authToken, userId);
    }
  }, [authToken, userId]);

  let appJobType = user?.additionalFields[0]?.appJobType || "Both"

  return (
    <View style={{ flex: 1, backgroundColor: "#fff" }}>
      <View style={{ flex: 1 }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-evenly",
            marginTop: 10,
          }}
        >
          {appJobType === "Shift" || appJobType === "Both" &&
            <TouchableOpacity
              style={{
                backgroundColor: jobType === "shift" ? "#000" : "#fff",
                width: "40%",
                alignItems: "center",
                paddingVertical: 5,
                borderRadius: 5,
                borderWidth: 0.5,
              }}
              activeOpacity={0.5}
              onPress={() => setJobType("shift")}
            >
              <Text style={{ color: jobType === "shift" ? "#fff" : "#000" }}>
                Shift Jobs{" "}
              </Text>
            </TouchableOpacity>}
          {appJobType === "Visit" || appJobType === "Both" &&
            <TouchableOpacity
              style={{
                backgroundColor: jobType === "visit" ? "#000" : "#fff",
                width: "40%",
                alignItems: "center",
                paddingVertical: 5,
                borderRadius: 5,
                borderWidth: 0.5,
              }}
              activeOpacity={0.5}
              onPress={() => setJobType("visit")}
            >
              <Text style={{ color: jobType === "visit" ? "#fff" : "#000" }}>
                Visit Jobs{" "}
              </Text>
            </TouchableOpacity>}
        </View>
        {appJobType === "Both" &&
          (jobType === "shift" ? (
            <ShiftScreen props={props} />
          ) : (
            <VisitScreen props={props} />
          ))}
        {appJobType === "Shift" &&
          <ShiftScreen props={props} />}
        {appJobType === "Visit" &&
          <VisitScreen props={props} />}
      </View>

    </View>
  );
};

export default OrganizationJobView;
