import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Alert,
  Platform
} from "react-native";
import React, { useState, useEffect } from "react";
import NotificationCard from "./NotificationCard";
import CustomDropDownNotification from "../../customComponents/DropDown/CustomDropDownNotification";
import {
  deleteFacilityNotification,
  getFacilityNotification,
  updateFacilityNotificationListVisit
} from "../../api_url/facilityNotification";
import { useAuth } from "../../context/AuthContext";
import { ChatState } from "../../context/ChatContext";

const NotificationScreen = (props) => {
  const { authToken, userId, userUniqueId } = useAuth();
  const {
    fetchAgainNotification,
    dashboardNotificationIconFetchAgain,
    setDashboardNotificationIconFetchAgain } = ChatState();

  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const [selectedCards, setSelectedCards] = useState([]);
  const [selected, setSelected] = useState(false);

  const [lastDay, setLastDay] = useState("Last 10 days");

  const optionsDoc = ["Last 10 days", "Last 30 days", "All Notification"];

  useEffect(() => {
    if (userId !== undefined) {
      getFacility(lastDay);
    }
  }, [userId, lastDay, fetchAgainNotification]);

  const getFacility = async (day) => {
    try {
      const dateDay =
        day === "Last 10 days" ? 10 : day === "Last 30 days" ? 30 : 0;
      const fiveDaysAgo = new Date();
      fiveDaysAgo.setDate(fiveDaysAgo.getDate() - dateDay);

      await getFacilityNotification(authToken, userId).then(
        async res => {
          let data = res?.filter((item) =>
            dateDay !== 0 ? new Date(item.createdAt) >= fiveDaysAgo : res
          )
          const nonVisited = [];

          for (const item of data) {
            if (item?.visited === false) {
              nonVisited.push(item);
            }
          }
          if (nonVisited.length > 0) {
            // console.log(nonVisited.length)
            await updateFacilityNotificationListVisit(authToken, nonVisited.map(i => i?.id))
            setDashboardNotificationIconFetchAgain(!dashboardNotificationIconFetchAgain)
          }
          setData(data);
          setLoading(false);
        }
      ).catch(e => console.log(e))

    } catch (error) {
      console.log("data save error:", error);
    }
  };

  const onJobDetailNavigate = (screen, data) => {
    props.navigation.navigate(screen, {
      data: data,
    });
  };

  const handleCardPress = (selectedElement) => {
    setSelectedCards((prevSelectedCards) => {
      const isSelected = prevSelectedCards.some(
        (card) => card.id === selectedElement.id
      );
      if (isSelected) {
        // Remove the card from the selected cards list
        return prevSelectedCards.filter(
          (card) => card.id !== selectedElement.id
        );
      } else {
        // Add the card to the selected cards list
        return [...prevSelectedCards, selectedElement];
      }
    });
  };

  useEffect(() => {
    if (!selected) {
      setSelectedCards([]);
    }
  }, [selected]);

  const deleteNotification = async () => {
    await deleteFacilityNotification(authToken, selectedCards.map(i => i?.id))
      .then(
        res => {
          setSelected(false);
          setSelectedCards([]);
          getFacility(lastDay);
        }
      ).catch(e => console.log(e))
  };

  return (
    <View style={styles.container}>
      {loading ?
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Text>Loading...</Text>
        </View>
        :

        <View style={{ flex: 1 }}>

          <CustomDropDownNotification
            container={{}}
            options={optionsDoc}
            selectedValue={lastDay}
            onValueChange={(type) => setLastDay(type)}
          />

          {data?.length === 0 ? (
            <View
              style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
            >
              <Text>No New Notification</Text>
            </View>
          ) : (
            <ScrollView showsVerticalScrollIndicator={false}>
              <View
                style={{
                  flex: 1,
                  alignItems: "flex-end",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginHorizontal: 20,
                  marginBottom: 10,
                }}
              >
                <TouchableOpacity
                  activeOpacity={0.4}
                  onPress={() => {
                    setSelected(!selected);
                  }}
                  style={{
                    marginHorizontal: selectedCards.length !== 0 ? 15 : 0,
                    paddingVertical: 3,
                    paddingHorizontal: 10,
                    backgroundColor: "#00b359",
                    borderRadius: 5,
                    elevation: 1,
                    shadowOpacity: 0.2,
                    marginTop: 5,
                    shadowRadius: 3.5,
                    shadowOffset: {
                      width: 0,
                      height: 1,
                    },
                  }}
                >
                  <Text style={{ color: "#fff" }}>
                    {selected ? "Cancel" : "Select"}
                  </Text>
                </TouchableOpacity>
                {selectedCards.length !== 0 && (
                  <TouchableOpacity
                    activeOpacity={0.4}
                    onPress={() => {
                      Platform.OS === "web"
                        ? confirm(
                          "This will be permanently deleted, Continue?"
                        ).valueOf(true) && deleteNotification()
                        : Alert.alert(
                          "This will be permanently deleted, Continue?",
                          "",
                          [
                            {
                              text: "Yes",
                              onPress: () => deleteNotification(),
                            },
                            {
                              text: "No",
                              style: "cancel",
                            },
                          ]
                        );
                    }}
                    style={{
                      paddingVertical: 3,
                      paddingHorizontal: 10,
                      backgroundColor: "red",
                      borderRadius: 5,
                      elevation: 1,
                      shadowOpacity: 0.2,
                      shadowRadius: 3.5,
                      shadowOffset: {
                        width: 0,
                        height: 1,
                      },
                    }}
                  >
                    <Text style={{ color: "#fff" }}>Delete</Text>
                  </TouchableOpacity>
                )}
              </View>

              {data === undefined ? (
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text>Loading...</Text>
                </View>
              ) : (
                data?.map((element, index) => {
                  return (
                    <NotificationCard
                      key={element?.id}
                      length={data?.length}
                      index={index}
                      element={element}
                      onPress={() =>
                        onJobDetailNavigate(
                          element?.navigationScreen,
                          element?.navigationData
                        )
                      }
                      onLongPress={() => handleCardPress(element)}
                      selectedCards={selectedCards}
                      selected={selected}
                      authToken={authToken}
                    />
                  );
                })
              )}
            </ScrollView>
          )}
        </View>
      }
    </View>
  );
};

export default NotificationScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
