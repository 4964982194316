import { API_URL } from "./config";

export async function getFullScheduleAllData(authToken, id, licenseTypes, unassigned) {
  if (!authToken) {
    return;
  }

  const queryParams = new URLSearchParams({
    id: String(id),
    licenseTypes: String(licenseTypes),
    unassigned: Boolean(unassigned),
  });

  const url = `${API_URL}/full-schedule?${queryParams.toString()}`;

  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getFacilityUniqueIdDetails(authToken, userId) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/facility/uniqueId/${userId}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}
