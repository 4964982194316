import { API_URL } from "./config";

export async function getFacilityAllTimeOff(authToken, userId) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/time-off-facility/all_time_off/list/${userId}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching");
  }
  return await res.json();
}

export async function getFacilityTimeOffWithStatus(authToken, userId, status) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/time-off-facility/all_time_off/list/with_status/${userId}/${status}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching");
  }
  return await res.json();
}

export async function getFacilityTimeOffDetails(authToken, id) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/time-off-facility/${id}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching");
  }
  return await res.json();
}

export const postFacilityTimeOff = async (
  authToken,
  id,
  timeOffReason,
  startDate,
  startTime,
  endDate,
  endTime,
  alternateContactNumber,
  phoneNumber,
  organization,
  location_id,
  comments,
  status
) => {
  if (!authToken) {
    return;
  }
  const res = await fetch(`${API_URL}/time-off-facility`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "Application/json",
    },
    body: JSON.stringify({
      id,
      timeOffReason,
      startDate,
      startTime,
      endDate,
      endTime,
      alternateContactNumber,
      phoneNumber,
      organization,
      location_id,
      comments,
      status
    }),
  });
  if (res.status === 400) {
    throw new Error("Error during posting.");
  } else {
    return res.json();
  }
};

export const updateFacilityTimeOff = async (
  authToken,
  id,
  timeOffReason,
  startDate,
  startTime,
  endDate,
  endTime,
  alternateContactNumber,
  phoneNumber,
  organization,
  location_id,
  comments,
  status
) => {

  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/time-off-facility/update/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "application/json", // "application/json" should be lowercase
    },
    body: JSON.stringify({
      timeOffReason,
      startDate,
      startTime,
      endDate,
      endTime,
      alternateContactNumber,
      phoneNumber,
      organization,
      location_id,
      comments,
      status
    }),
  });

  if (res.status === 400) {
    throw new Error("Network error");
  } else {
    return res.json();
  }
};