import {
  View,
  Text,
  Platform,
  StyleSheet,
  TouchableOpacity,
  KeyboardAvoidingView,
  Alert,
  Dimensions,
  ScrollView,
  Modal,
  TextInput,
  FlatList
} from "react-native";
import React, { useState, useEffect } from "react";
import * as Haptics from "expo-haptics";
import { AntDesign, Entypo, Ionicons, MaterialIcons } from "@expo/vector-icons";
import CustomInput from "../../customComponents/Input/CustomInput";
import AuthButton from "../../customComponents/Button/AuthButton";
import CalendarPicker from "react-native-calendar-picker";
import CustomDropdown from "../../customComponents/DropDown/CustomDropdown";
import CustomTemplate from "../../customComponents/DropDown/CustomTemplate";
import CustomToggle from "../../screens/AuthFlow/Register/CustomToggle";
// import AutoFillAddress from "./AutoFillAddress";
import AutoFillAddress from "../Address/AutoFillAddress";
// import CustomSliderFilter from "../../customComponents/CustomSliderFilter";
import CustomSliderFilterDropDown from "../../customComponents/CustomSliderFilterDropDown";
import moment from "moment";
import {
  durationCount,
  timingCount,
  experienceLevel,
  optionsEMR,
  options,
  option,
  specialty,
  optionBreak
} from "../../utils/defaultData";
import {
  GetDateMergedTime,
  convertDate,
  timeDifferent,
  timeDifferentFun
} from "../../utils/function";
import {
  sendNewJobNotification,
  sendNewPushNotification
} from "../../utils/notification";
import { sendNotification } from "../../utils/webNotification";
import UserJobCard from "./Card/UserJobCard";
import { useAuth } from "../../context/AuthContext";
import { ChatState } from "../../context/ChatContext";
import { getTemplateType, postJobTemplate } from "../../api_url/jobTemplate";
import { getCustomerList } from "../../api_url/customerTable";
import { getFacilityUniqueIdDetails } from "../../api_url/facilityTable";
import {
  getAllNurseList,
  getJobMatchedAllNurse,
  getNurseWeekAvailabilityForDirectAssignment
} from "../../api_url/nurseTable";
import { postJobTable } from "../../api_url/jobTable";
import { postNurseNotification } from "../../api_url/nurseNotification";
import { socket } from "../../api_url/socket";

const AddJobScreen = (props) => {
  const { authToken, userId, userUniqueId } = useAuth();
  const { fetchAgainJobDashboard, setFetchAgainJobDashboard } = ChatState();
  const currentDate = moment();

  const { width, height } = Dimensions.get("window");
  const [loading, setLoading] = useState(false);

  const [userData, setUserData] = useState(undefined);

  const [showCalendar, setShowCalendar] = useState(false);
  const [showCalendarEnd, setShowCalendarEnd] = useState(false);
  const [showCertification, setShowCertification] = useState(false);
  const [showEmrExperience, setShowEmrExperience] = useState(false);
  const [showLicenseType, setShowLicenseType] = useState(false);
  const [showSpecialty, setShowSpecialty] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [showAddressWeb, setShowAddressWeb] = useState(false);
  const [showBreak, setShowBreak] = useState(false);
  const [licenseTypeArr, setLicenseTypeArr] = useState([]);
  const [certificationArr, setCertificationArr] = useState([]);
  const [emrExperienceArr, setEmrExperienceArr] = useState([]);
  const [showTimeWeb, setShowTimeWeb] = useState(false);
  const [showTimeEndWeb, setShowTimeEndWeb] = useState(false);
  const [showDuration, setShowDuration] = useState(false);
  const [showTiming, setShowTiming] = useState(false);
  const [showExperienceLevel, setShowExperienceLevel] = useState(false);
  const [showTemplate, setShowTemplate] = useState(false);

  const [buttonType, setButtonType] = useState("");

  const [jobTemplateName, setJobTemplateName] = useState("");
  const [jobTemplateArray, setJobTemplateArray] = useState(undefined);
  const [jobTemplate, setJobTemplate] = useState(undefined);
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedWeeks, setSelectedWeeks] = useState("1");
  const [patientData, setPatientData] = useState([]);
  const [patientView, setPatientView] = useState(false);
  const [patientSearch, setPatientSearch] = useState("");
  const [userList, setUserList] = useState([]);
  const [jobCreated, setJobCreated] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const availableArr = [];
  const arr = [];
  const [directAssignment, setDirectAssignment] = useState(false);
  const [slotsEnable, setSlotsEnable] = useState(false);
  const [selectedSlots, seeSelectedSlots] = useState("1");
  const [availableNurse, setAvailableNurse] = useState([]);
  const [availableNurseLoading, setAvailableNurseLoading] = useState(false);
  const [jobFinalSelectionNurseId, setJobFinalSelectionNurseId] = useState("");
  const [error, setError] = useState(false);

  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownMinute, setShowDropdownMinute] = useState(false);
  const [showDropdownAP, setShowDropdownAP] = useState(false);

  const [
    weekNurseDirectAssignmentAvailabilityData,
    setWeekNurseDirectAssignmentAvailabilityData
  ] = useState([]);
  const [
    nurseDirectAssignmentSelectedDates,
    setNurseDirectAssignmentSelectedDates
  ] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(true);

  let appJobType = userData?.additionalFields[0]?.appJobType || "Both";
  const [jobState, setJobState] = useState({
    licenseType: "",
    shiftTitle: "",
    specialty: "",
    yearOfExperience: "",
    expiration: 0,
    startDate: new Date().toString(),
    endDate: new Date().toString(),
    startTime: new Date().toString(),
    endTime: moment().add(1, "hour"),
    break: "",
    customerName: "",
    customerId: "",
    unit: "",
    floor: "",
    fullAddress: "",
    latitude: "",
    longitude: "",
    baseRate: "",
    enableBid: false,
    notes: "",
    jobType:
      appJobType === "Shift"
        ? "Shift"
        : appJobType === "Visit"
        ? "Visit"
        : "Shift",
    jobStatus: "",
    jobFinalSelectionNurseId: "",
    jobBitFinalAmount: "",
    autoAcceptedEnable: false,
    maxBidAmount: "",
    jobDays: "",
    jobDuration: "",
    jobTiming: "",
    specialtyRequired: false,
    certificationRequired: false,
    experienceRequired: false,
    emrehrRequired: false,
    customerVisibility: false,
    baseRateVisibility: false
  });

  const [selectedHours, setSelectedHours] = useState("1");
  const [selectedHoursLoad, setSelectedHoursLoad] = useState(false);

  const now = new Date();
  const currentMinutes = now.getMinutes().toString().padStart(2, "0");
  const currentAmPm = now.getHours() >= 12 ? "PM" : "AM";
  const [selectedHourTime, setSelectedHourTime] = useState(
    now.getHours() === 0
      ? 12
      : now.getHours() > 12
      ? now.getHours() - 12
      : now.getHours()
  );
  const [selectedMinuteTime, setSelectedMinuteTime] = useState(currentMinutes);
  const [selectedAPTime, setSelectedAPTime] = useState(currentAmPm);

  const [showDropdownEnd, setShowDropdownEnd] = useState(false);
  const [showDropdownMinuteEnd, setShowDropdownMinuteEnd] = useState(false);
  const [showDropdownAPEnd, setShowDropdownAPEnd] = useState(false);

  const [selectedHourTimeEnd, setSelectedHourTimeEnd] = useState(
    now.getHours() === 0
      ? 12
      : now.getHours() > 12
      ? now.getHours() - 12
      : now.getHours()
  );

  const [selectedMinuteTimeEnd, setSelectedMinuteTimeEnd] = useState("00");
  const [selectedAPTimeEnd, setSelectedAPTimeEnd] = useState("AM");

  useEffect(() => {
    const nowEnd = new Date(jobState.endTime);
    const currentMinutesEnd = nowEnd.getMinutes().toString().padStart(2, "0");
    const currentAmPmEnd = nowEnd.getHours() >= 12 ? "PM" : "AM";

    setSelectedHourTimeEnd(
      nowEnd.getHours() === 0
        ? 12
        : nowEnd.getHours() > 12
        ? nowEnd.getHours() - 12
        : nowEnd.getHours()
    );
    setSelectedMinuteTimeEnd(currentMinutesEnd);
    setSelectedAPTimeEnd(currentAmPmEnd);
  }, [jobState.endTime]);

  const hour = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const minutes = [0, 15, 30, 45];
  const dayBreaker = ["AM", "PM"];

  const getData = async (id) => {
    try {
      await getFacilityUniqueIdDetails(authToken, userUniqueId)
        .then(async (res) => {
          // console.log("Facility", res)
          setUserData(res[0]);
          setJobState((prevState) => ({
            ...prevState,
            jobType:
              res[0]?.additionalFields[0]?.appJobType === "Shift"
                ? "Shift"
                : res[0]?.additionalFields[0]?.appJobType === "Visit"
                ? "Visit"
                : "Shift"
          }));
          await getTemplateType(
            authToken,
            id,
            res[0]?.additionalFields[0]?.appJobType === "Shift"
              ? "Shift"
              : res[0]?.additionalFields[0]?.appJobType === "Visit"
              ? "Visit"
              : "Shift"
          )
            .then((res) => {
              // console.log("Template", res)
              setJobTemplateArray(res);
            })
            .catch((e) => {
              console.log(e);
            });
          setLoadingScreen(false);
        })
        .catch((e) => {
          console.log(e);
        });

      await getCustomerList(authToken, id)
        .then((res) => {
          // console.log("Customer", res)
          setPatientData(res);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getDataTemplate = async (id, type) => {
    try {
      await getTemplateType(authToken, id, type)
        .then((res) => {
          // console.log("Template", res)
          setJobTemplateArray(res);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.log(error);
    }
  };

  //Get FacilityTable,JobTemplate,CustomerPatient,TimeOffFacility data
  useEffect(() => {
    if (userId !== undefined) {
      getData(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (userId !== undefined) {
      getDataTemplate(userId, jobState.jobType);
    }
  }, [userId, jobState.jobType]);

  //If Template Select
  useEffect(() => {
    if (jobTemplate !== undefined) {
      setSelectedHoursLoad(true);
      setLicenseTypeArr(jobTemplate?.licenseType);
      setCertificationArr(jobTemplate?.certification);
      setEmrExperienceArr(jobTemplate?.EMRorEHRExperience);

      setSelectedHours(
        timeDifferentFun(jobTemplate?.startTime, jobTemplate?.endTime)
      );
      let date = new Date();
      let dateEnd = new Date();

      let nowEnd = new Date(jobTemplate.startTime);
      let selectedMinuteTime = nowEnd.getMinutes().toString().padStart(2, "0");
      let selectedAPTime = nowEnd.getHours() >= 12 ? "PM" : "AM";
      let selectedHourTime =
        nowEnd.getHours() === 0
          ? 12
          : nowEnd.getHours() > 12
          ? nowEnd.getHours() - 12
          : nowEnd.getHours();

      date.setHours(
        selectedAPTime === "AM" && selectedHourTime === 12
          ? 0
          : selectedAPTime === "PM" && selectedHourTime === 12
          ? 12
          : selectedHourTime !== 12 && selectedAPTime === "PM"
          ? parseInt(selectedHourTime) + 12
          : parseInt(selectedHourTime)
      );
      date.setMinutes(selectedMinuteTime);

      // Add 8 hours to dateEnd based on date
      dateEnd.setFullYear(date.getFullYear());
      dateEnd.setMonth(date.getMonth());
      dateEnd.setDate(date.getDate());
      dateEnd.setHours(
        date.getHours() +
          timeDifferentFun(jobTemplate?.startTime, jobTemplate?.endTime)
      );
      dateEnd.setMinutes(date.getMinutes());

      // Determine if it's AM or PM
      let meridiem = dateEnd.getHours() >= 12 ? "PM" : "AM";

      if (selectedAPTime === "PM" && meridiem === "AM") {
        let endDate = moment(jobState.startDate);
        endDate.add(1, "day");
        setJobState({
          ...jobState,
          startTime: date,
          endTime: dateEnd,
          endDate: endDate,
          shiftTitle: jobTemplate?.shiftTitle,
          specialty: jobTemplate?.specialty,
          yearOfExperience: jobTemplate?.yearOfExperience,
          expiration: jobTemplate?.expiration,
          break: jobTemplate?.break,
          customerName: jobTemplate?.customerName,
          customerId: jobTemplate?.customerId,
          unit: jobTemplate?.unit,
          floor: jobTemplate?.floor,
          fullAddress: jobTemplate?.fullAddress,
          latitude: jobTemplate?.latitude,
          longitude: jobTemplate?.longitude,
          baseRate: jobTemplate?.baseRate,
          enableBid: jobTemplate?.enableBid,
          autoAcceptedEnable: jobTemplate?.autoAcceptedEnable,
          notes: jobTemplate?.notes,
          jobType: jobTemplate?.jobType,
          maxBidAmount: jobTemplate?.maxBidAmount,
          jobDays: jobTemplate?.jobDays,
          jobDuration: jobTemplate?.jobDuration,
          jobTiming: jobTemplate?.jobTiming,
          specialtyRequired: jobTemplate?.specialtyRequired,
          certificationRequired: jobTemplate?.certificationRequired,
          experienceRequired: jobTemplate?.experienceRequired,
          emrehrRequired: jobTemplate?.emrehrRequired,
          customerVisibility: jobTemplate?.customerVisibility,
          baseRateVisibility: jobTemplate?.baseRateVisibility
        });
      } else {
        setJobState({
          ...jobState,
          startTime: date,
          endTime: dateEnd,
          endDate: jobState.startDate,
          shiftTitle: jobTemplate?.shiftTitle,
          specialty: jobTemplate?.specialty,
          yearOfExperience: jobTemplate?.yearOfExperience,
          expiration: jobTemplate?.expiration,
          break: jobTemplate?.break,
          customerName: jobTemplate?.customerName,
          customerId: jobTemplate?.customerId,
          unit: jobTemplate?.unit,
          floor: jobTemplate?.floor,
          fullAddress: jobTemplate?.fullAddress,
          latitude: jobTemplate?.latitude,
          longitude: jobTemplate?.longitude,
          baseRate: jobTemplate?.baseRate,
          enableBid: jobTemplate?.enableBid,
          autoAcceptedEnable: jobTemplate?.autoAcceptedEnable,
          notes: jobTemplate?.notes,
          jobType: jobTemplate?.jobType,
          maxBidAmount: jobTemplate?.maxBidAmount,
          jobDays: jobTemplate?.jobDays,
          jobDuration: jobTemplate?.jobDuration,
          jobTiming: jobTemplate?.jobTiming,
          specialtyRequired: jobTemplate?.specialtyRequired,
          certificationRequired: jobTemplate?.certificationRequired,
          experienceRequired: jobTemplate?.experienceRequired,
          emrehrRequired: jobTemplate?.emrehrRequired,
          customerVisibility: jobTemplate?.customerVisibility,
          baseRateVisibility: jobTemplate?.baseRateVisibility
        });
      }
      if (Platform.OS === "web") {
        alert("Please review your dates.");
      } else {
        Alert.alert("Please review your dates.");
      }
      setInterval(() => {
        setSelectedHoursLoad(false);
      }, 2000);
    }
  }, [jobTemplate]);

  //UI
  const generateOneWeekDatesForJobPostUId = () => {
    const dateForUI = [];
    const currentDate = new Date(jobState.startDate);

    const currentDayOfWeek = currentDate.getDay();
    let currentWeek = [];

    const previousSunday = new Date(currentDate);
    previousSunday.setDate(currentDate.getDate() - currentDayOfWeek);

    for (let i = 0; i < 7 * selectedWeeks; i++) {
      // Assuming a maximum of 31 days
      const nextDate = new Date(previousSunday);
      nextDate.setDate(previousSunday.getDate() + i);

      currentWeek.push(moment(nextDate).format("YYYY-MM-DD"));

      if (nextDate.getDay() === 6) {
        while (currentWeek.length < 7) {
          currentWeek.push(""); // Push empty strings to fill the week
        }
        dateForUI.push({
          currentWeek: currentWeek,
          name: `Week-${parseInt(i / 7 + 1).toFixed(1)}`
        });
        currentWeek = [];
      }
    }

    // console.log(dateForUI)
    return dateForUI;
  };

  const postJob = async (startDate, endDate) => {
    setError(false);
    try {
      const item = await postJobTable(
        authToken,
        licenseTypeArr,
        jobState.shiftTitle,
        jobState.specialty,
        certificationArr,
        jobState.yearOfExperience,
        jobState.expiration.toString(),
        startDate,
        endDate,
        GetDateMergedTime(startDate, jobState.startTime.toString()).toString(),
        GetDateMergedTime(endDate, jobState.endTime.toString()).toString(),
        jobState.break,
        jobState.customerName,
        jobState.customerId.toString(),
        jobState.unit,
        jobState.floor,
        jobState.fullAddress,
        jobState.latitude.toString(),
        jobState.longitude.toString(),
        jobState.baseRate,
        jobState.enableBid,
        jobState.notes,
        jobState?.jobType,
        jobFinalSelectionNurseId === "" ? "Open" : "Nurse Assigned",
        jobFinalSelectionNurseId.toString(),
        "",
        jobFinalSelectionNurseId === "" ? [] : [jobFinalSelectionNurseId],
        jobFinalSelectionNurseId === ""
          ? []
          : [
              {
                id: jobFinalSelectionNurseId,
                type: "Internal Pool"
              }
            ],
        jobState.autoAcceptedEnable,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        jobState.maxBidAmount,
        timeDifferent(jobState?.startTime, jobState?.endTime),
        jobState.jobTiming,
        jobState.jobDays,
        emrExperienceArr,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        false,
        jobState.specialtyRequired,
        jobState.experienceRequired,
        jobState.emrehrRequired,
        jobState.certificationRequired,
        jobState.customerVisibility,
        "",
        "",
        {},
        "",
        userId,
        jobState?.baseRateVisibility,
        "",
        jobFinalSelectionNurseId === "" ? "" : userId?.toString(),
        "",
        false,
        false,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        GetDateMergedTime(startDate, jobState.startTime.toString())
          .valueOf()
          .toString(),
        "",
        "",
        "",
        "",
        userData?.organization,
        userData?.location_id
      );
      return item;
    } catch (error) {
      console.log("data save error:", error);
    }
  };

  const [jobIds, setJobIds] = useState([]);
  // console.log("jobIds", jobIds)
  const postTemplate = async () => {
    setError(false);
    let sDate = moment(jobState.startDate).format("YYYY-MM-DD");
    let eDate = moment(jobState.endDate).format("YYYY-MM-DD");
    try {
      await postJobTemplate(
        authToken,
        licenseTypeArr,
        jobState.shiftTitle,
        jobState.specialty,
        certificationArr,
        jobState.yearOfExperience,
        jobState.expiration.toString(),
        sDate.toString(),
        eDate.toString(),
        GetDateMergedTime(
          jobState.startDate,
          jobState.startTime.toString()
        ).toString(),
        GetDateMergedTime(
          jobState.endDate,
          jobState.endTime.toString()
        ).toString(),
        jobState.break,
        jobState.customerName,
        jobState.customerId.toString(),
        jobState.unit,
        jobState.floor,
        jobState.fullAddress,
        jobState.latitude.toString(),
        jobState.longitude.toString(),
        "1",
        jobState.enableBid,
        jobState.notes,
        jobState?.jobType,
        jobState.autoAcceptedEnable,
        "",
        jobState.maxBidAmount,
        "",
        jobState.jobTiming,
        "",
        emrExperienceArr,
        jobState.specialtyRequired,
        jobState.experienceRequired,
        jobState.emrehrRequired,
        jobState.certificationRequired,
        jobState.customerVisibility,
        userId,
        jobState?.baseRateVisibility,
        GetDateMergedTime(jobState.startDate, jobState.startTime.toString())
          .valueOf()
          .toString(),
        userData?.organization,
        userData?.location_id,
        jobTemplateName
      ).then((res) => {
        // console.log(res)
        setLoading(false);
      });
      if (Platform.OS === "web") {
        alert("Success\nTemplate save successfully");
      } else {
        Alert.alert("Success", "Template save successfully");
      }
      Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
      props?.navigation.goBack();
    } catch (error) {
      console.log("data save error:", error);
      setLoading(false);
    }
  };

  const getNurseMatchedListId = async (item) => {
    const userList = await getAllNurseList(authToken, userId);

    const deg2rad = (deg) => {
      return deg * (Math.PI / 180);
    };

    function getDistance(lat1, lon1, lat2, lon2) {
      const R = 6371; // Radius of the earth in km
      const dLat = deg2rad(lat2 - lat1);
      const dLon = deg2rad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) *
          Math.cos(deg2rad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c; // Distance in km
      return d / 1.609344; // Distance in miles
    }

    // userList.map((nurse) => console.log("user list", nurse?.id, nurse?.firstName, nurse?.mobileId));
    const data = userList.filter((nurseData) => {
      // console.log(nurseData)
      // const lowestYear = nurseData?.workExperienceDetails?.reduce(
      //   (lowest, employment) => {
      //     const year = new Date(employment?.startDate).getFullYear();
      //     return year < lowest ? year : lowest;
      //   },
      //   new Date().getFullYear()
      // );
      // const experience = new Date().getFullYear() - lowestYear;
      return (
        item?.licenseType?.some((i) => i === nurseData?.primaryLicenseType) &&
        item?.fullAddress?.includes(
          nurseData?.JobPreferencesNurse[0]?.notLicensedStateHideJobs
            ? nurseData?.primaryState
            : ""
        ) &&
        (nurseData?.JobPreferencesNurse[0]?.timing?.length !== 0
          ? nurseData?.JobPreferencesNurse[0]?.timing?.includes(item?.jobTiming)
          : item) &&
        (nurseData?.JobPreferencesNurse[0]?.days?.length !== 0
          ? nurseData?.JobPreferencesNurse[0]?.days?.includes(
              moment(item?.startDate).format("ddd")
            )
          : item) &&
        // getDistance(
        //   nurseData?.latitude,
        //   nurseData?.longitude,
        //   item?.latitude,
        //   item?.longitude
        // ).toFixed(0) >=
        //   (nurseData?.JobPreferencesNurse[0]?.minDistance === null
        //     ? 1
        //     : parseInt(nurseData?.JobPreferencesNurse[0]?.minDistance)) &&
        getDistance(
          nurseData?.latitude,
          nurseData?.longitude,
          item?.latitude,
          item?.longitude
        ).toFixed(0) <=
          (nurseData?.JobPreferencesNurse[0]?.maxDistance === null
            ? 100
            : parseInt(nurseData?.JobPreferencesNurse[0]?.maxDistance)) &&
        item?.baseRate >=
          (nurseData?.JobPreferencesNurse[0]?.minRate === null
            ? 1
            : parseInt(nurseData?.JobPreferencesNurse[0]?.minRate))
        //  &&
        //      &&
        // item?.baseRate <=
        //   (nurseData?.JobPreferencesNurse[0]?.maxRate === null
        //     ? 100
        //     : parseInt(nurseData?.JobPreferencesNurse[0]?.maxRate))
        // (item?.specialtyRequired === true
        //   ? nurseData?.specialty?.includes(item?.specialty)
        //   : item?.specialtyRequired === null
        //   ? item
        //   : item) &&
        // (item?.certificationRequired === true
        //   ? item?.certification.some((value) =>
        //       nurseData?.certificationDetails?.some(
        //         (va) => va?.certificationType === value
        //       )
        //     )
        //   : item?.certificationRequired === null
        //   ? item
        //   : item) &&
        // (item?.experienceRequired
        //   ? item?.yearOfExperience === "0 - 1 year"
        //     ? experience <= 1
        //     : item?.yearOfExperience === "1 - 2 years"
        //     ? experience >= 1 && experience <= 2
        //     : item?.yearOfExperience === "2+ years"
        //     ? experience >= 2
        //     : true
        //   : item?.experienceRequired === null
        //   ? item
        //   : item) &&
        // (item?.emrehrRequired === true
        //   ? item?.EMRorEHRExperience.some((value) =>
        //       nurseData?.EMRorEHRExperience?.some((va) => va === value)
        //     )
        //   : item?.emrehrRequired === null
        //   ? item
        //   : item)
      );
    });
    // data.map((nurse) => console.log("filter user list",nurse?.id, nurse?.firstName, nurse?.mobileId));
    return data;
  };

  const getNurseNotificationMobileId = async (element) => {
    const nurses = await getNurseMatchedListId(element);
    // nurses.map((nurse) => console.log(nurse?.id, nurse?.mobileId));
    return nurses.map((nurse) => nurse.mobileId);
  };

  const submit = async () => {
    if (buttonType === "") {
      if (Platform.OS === "web") {
        alert("Select type to post");
      } else {
        Alert.alert("Select type to post");
      }
    } else if (buttonType === "Save Template") {
      setLoading(true);
      if (
        licenseTypeArr.length === 0 ||
        // certificationArr.length === 0 ||
        // emrExperienceArr.length === 0 ||
        jobState.shiftTitle === "" ||
        // jobState.specialty === "" ||
        // jobState.yearOfExperience === "" ||
        jobState.startDate === "" ||
        jobState.endDate === "" ||
        jobState.startTime === "" ||
        jobState.endTime === "" ||
        jobState.break === "" ||
        jobState.fullAddress === "" ||
        jobState.latitude === "" ||
        jobState.longitude === "" ||
        jobState.jobTiming === "" ||
        jobTemplateName === ""
      ) {
        if (Platform.OS === "web") {
          alert("Fill all required fields first");
        } else {
          Alert.alert("Fill all required fields first");
        }
        setError(true);
        setLoading(false);
      } else if (convertDate(jobState.startDate) < convertDate(currentDate)) {
        if (Platform.OS === "web") {
          alert("Check your date");
        } else {
          Alert.alert("Check your date");
        }
        setError(true);
        setLoading(false);
      } else if (
        convertDate(jobState.endDate) < convertDate(jobState.startDate)
      ) {
        if (Platform.OS === "web") {
          alert("Check your end date");
        } else {
          Alert.alert("Check your end date");
        }
        setError(true);
        setLoading(false);
      } else if (jobState.enableBid && jobState.maxBidAmount === "") {
        if (Platform.OS === "web") {
          alert("Enter max bid amount");
        } else {
          Alert.alert("Enter max bid amount");
        }
        setError(true);
        setLoading(false);
      } else if (jobState.baseRateVisibility && jobState.baseRate === "") {
        if (Platform.OS === "web") {
          alert("Enter base rate");
        } else {
          Alert.alert("Enter base rate");
        }
        setError(true);
        setLoading(false);
      }
      // else if (getTimeDifference(start, end) > 12) {
      //   Alert.alert(`The ${jobState.jobType}s can’t be more than 12 hours.`);
      //   setLoading(false);
      // }
      else {
        postTemplate();
      }
    } else if (buttonType === "PostJob") {
      // setLoading(true);
      if (
        licenseTypeArr.length === 0 ||
        // certificationArr.length === 0 ||
        // emrExperienceArr.length === 0 ||
        jobState.shiftTitle === "" ||
        // jobState.specialty === "" ||
        // jobState.yearOfExperience === "" ||
        jobState.break === "" ||
        jobState.fullAddress === "" ||
        jobState.jobTiming === ""
      ) {
        if (Platform.OS === "web") {
          alert("Fill all required fields first");
        } else {
          Alert.alert("Fill all required fields first");
        }
        setError(true);
        setLoading(false);
      }
      //  else if (
      //   dates?.includes(convertDate(jobState.startDate)) ||
      //   dates?.includes(convertDate(jobState.endDate))
      // ) {
      //   Alert.alert(
      //     "Alert",
      //     "You'r in time off on selected days. Please change the date"
      //   );
      // }
      else if (convertDate(jobState.startDate) < convertDate(currentDate)) {
        if (Platform.OS === "web") {
          alert("Check your date");
        } else {
          Alert.alert("Check your date");
        }
        setError(true);
        setLoading(false);
      } else if (
        convertDate(jobState.endDate) < convertDate(jobState.startDate)
      ) {
        if (Platform.OS === "web") {
          alert("Check your end date");
        } else {
          Alert.alert("Check your end date");
        }
        setError(true);
        setLoading(false);
      } else if (timeDifferentFun(jobState?.startTime, jobState?.endTime) < 1) {
        if (Platform.OS === "web") {
          alert(`Start and End Time can't be same`);
        } else {
          Alert.alert(`Start and End Time can't be same`);
        }
        setLoading(false);
      } else if (directAssignment === true && jobFinalSelectionNurseId === "") {
        setDirectAssignment(false);
        setButtonType("");
        setLoading(false);
        if (Platform.OS === "web") {
          alert("Select a nurse before publishing a job.");
        } else {
          Alert.alert("Select a nurse before publishing a job.");
        }
      } else if (jobState.baseRateVisibility && jobState.baseRate === "") {
        if (Platform.OS === "web") {
          alert("Enter base rate");
        } else {
          Alert.alert("Enter base rate");
        }
        setError(true);
        setLoading(false);
      } else if (jobState.enableBid && jobState.maxBidAmount === "") {
        if (Platform.OS === "web") {
          alert("Enter max bid amount");
        } else {
          Alert.alert("Enter max bid amount");
        }
        setError(true);
        setLoading(false);
      } else {
        if (directAssignment === false && selectedDays.length === 0) {
          if (Platform.OS === "web") {
            alert("Select the available days");
          } else {
            Alert.alert("Select the available days");
          }
        } else if (
          directAssignment === true &&
          nurseDirectAssignmentSelectedDates.length === 0
        ) {
          if (Platform.OS === "web") {
            alert("Select the available days");
          } else {
            Alert.alert("Select the available days");
          }
          setLoading(false);
        } else {
          if (Platform.OS === "web") {
            if (confirm("Confirm\nReview Date and Time before post?")) {
              setLoading(true);
              try {
                setLoading(true);
                if (jobFinalSelectionNurseId === "") {
                  const dates = selectedDays;
                  const promises = [];
                  for (let i = 0; i < dates.length; i++) {
                    if (
                      convertDate(jobState.endDate) !==
                      convertDate(jobState.startDate)
                    ) {
                      const date = moment(dates[i]);
                      // Add one day
                      var nightEndDate = date.add(1, "day");
                      // Format the new date if needed
                      var formattedNewDate = nightEndDate.format("YYYY-MM-DD");
                      const postJobPromise = await postJob(
                        dates[i].toString(),
                        formattedNewDate.toString()
                      ).then(async (res) => {
                        try {
                          // console.log("res", res)
                          const nurseIds = await getNurseNotificationMobileId(
                            res
                          );
                          const flattenedIds = nurseIds.flat();
                          const uniqueIds = [...new Set(flattenedIds)];
                          // console.log(uniqueIds)
                          setUserList(uniqueIds);
                        } catch (error) {
                          return console.error(error);
                        }
                      });
                      promises.push(postJobPromise);
                    } else {
                      const postJobPromise = await postJob(
                        dates[i].toString(),
                        dates[i].toString()
                      ).then(async (res) => {
                        try {
                          // console.log("res", res)
                          const nurseIds = await getNurseNotificationMobileId(
                            res
                          );
                          const flattenedIds = nurseIds.flat();
                          const uniqueIds = [...new Set(flattenedIds)];
                          // console.log(uniqueIds)
                          setUserList(uniqueIds);
                        } catch (error) {
                          return console.error(error);
                        }
                      });
                      promises.push(postJobPromise);
                    }
                  }
                  Promise.all(promises)
                    .then(() => {
                      setJobCreated(true);
                      // console.log("setJobCreated")
                    })
                    .catch((error) => console.error(error));
                } else {
                  const dates = nurseDirectAssignmentSelectedDates;
                  const promises = [];
                  for (let i = 0; i < dates.length; i++) {
                    if (
                      convertDate(jobState.endDate) !==
                      convertDate(jobState.startDate)
                    ) {
                      const date = moment(dates[i]);
                      // Add one day
                      var nightEndDate = date.add(1, "day");
                      // Format the new date if needed
                      var formattedNewDate = nightEndDate.format("YYYY-MM-DD");
                      const postJobPromise = await postJob(
                        dates[i].toString(),
                        formattedNewDate.toString()
                      ).then(async (res) => {
                        // console.log(res)
                        await getNurseNotificationMobileId(res)
                          .then((nurseIds) => {
                            const flattenedIds = nurseIds.flat();
                            const uniqueIds = [...new Set(flattenedIds)];
                            // console.log(uniqueIds)
                            setUserList(uniqueIds);
                          })
                          .catch((error) => console.error(error));
                      });
                      promises.push(postJobPromise);
                    } else {
                      const postJobPromise = await postJob(
                        dates[i].toString(),
                        dates[i].toString()
                      ).then(async (res) => {
                        // console.log(res)
                        await getNurseNotificationMobileId(res)
                          .then((nurseIds) => {
                            const flattenedIds = nurseIds.flat();
                            const uniqueIds = [...new Set(flattenedIds)];
                            // console.log(uniqueIds)
                            setUserList(uniqueIds);
                          })
                          .catch((error) => console.error(error));
                      });
                      promises.push(postJobPromise);
                    }
                  }
                  Promise.all(promises)
                    .then(() => {
                      setJobCreated(true);
                      // console.log("setJobCreated")
                    })
                    .catch((error) => console.error(error));
                }
              } catch (error) {
                console.error(error);
                setLoading(false);
              }
            } else {
              setLoading(false);
            }
          } else {
            Alert.alert("Confirm", "Review Date and Time before post?", [
              {
                text: "Cancel",
                onPress: () => setLoading(false),
                style: "cancel"
              },
              {
                text: "OK",
                onPress: async () => {
                  try {
                    setLoading(true);
                    if (jobFinalSelectionNurseId === "") {
                      const dates = selectedDays;
                      const promises = [];
                      for (let i = 0; i < dates.length; i++) {
                        if (
                          convertDate(jobState.endDate) !==
                          convertDate(jobState.startDate)
                        ) {
                          const date = moment(dates[i]);
                          // Add one day
                          var nightEndDate = date.add(1, "day");
                          // Format the new date if needed
                          var formattedNewDate =
                            nightEndDate.format("YYYY-MM-DD");
                          const postJobPromise = await postJob(
                            dates[i].toString(),
                            formattedNewDate.toString()
                          ).then(async (res) => {
                            try {
                              // console.log("res", res)
                              const nurseIds =
                                await getNurseNotificationMobileId(res);
                              const flattenedIds = nurseIds.flat();
                              const uniqueIds = [...new Set(flattenedIds)];
                              // console.log(uniqueIds)
                              setUserList(uniqueIds);
                            } catch (error) {
                              return console.error(error);
                            }
                          });
                          promises.push(postJobPromise);
                        } else {
                          const postJobPromise = await postJob(
                            dates[i].toString(),
                            dates[i].toString()
                          ).then(async (res) => {
                            try {
                              // console.log("res", res?.id)
                              // setJobIds(prevJobIds => [...prevJobIds, res?.id]);
                              const nurseIds =
                                await getNurseNotificationMobileId(res);
                              const flattenedIds = nurseIds.flat();
                              const uniqueIds = [...new Set(flattenedIds)];
                              // console.log(uniqueIds)
                              setUserList(uniqueIds);
                            } catch (error) {
                              return console.error(error);
                            }
                          });
                          promises.push(postJobPromise);
                        }
                      }
                      Promise.all(promises)
                        .then(() => {
                          setJobCreated(true);
                        })
                        .catch((error) => console.error(error));
                    } else {
                      const dates = nurseDirectAssignmentSelectedDates;
                      const promises = [];
                      for (let i = 0; i < dates.length; i++) {
                        if (
                          convertDate(jobState.endDate) !==
                          convertDate(jobState.startDate)
                        ) {
                          const date = moment(dates[i]);
                          // Add one day
                          var nightEndDate = date.add(1, "day");
                          // Format the new date if needed
                          var formattedNewDate =
                            nightEndDate.format("YYYY-MM-DD");
                          const postJobPromise = await postJob(
                            dates[i].toString(),
                            formattedNewDate.toString()
                          ).then(async (res) => {
                            // console.log(res)
                            await getNurseNotificationMobileId(res)
                              .then((nurseIds) => {
                                const flattenedIds = nurseIds.flat();
                                const uniqueIds = [...new Set(flattenedIds)];
                                // console.log(uniqueIds)
                                setUserList(uniqueIds);
                              })
                              .catch((error) => console.error(error));
                          });
                          promises.push(postJobPromise);
                        } else {
                          const postJobPromise = await postJob(
                            dates[i].toString(),
                            dates[i].toString()
                          ).then(async (res) => {
                            // console.log(res)
                            await getNurseNotificationMobileId(res)
                              .then((nurseIds) => {
                                const flattenedIds = nurseIds.flat();
                                const uniqueIds = [...new Set(flattenedIds)];
                                // console.log(uniqueIds)
                                setUserList(uniqueIds);
                              })
                              .catch((error) => console.error(error));
                          });
                          promises.push(postJobPromise);
                        }
                      }
                      Promise.all(promises)
                        .then(() => {
                          setJobCreated(true);
                          // console.log("setJobCreated")
                        })
                        .catch((error) => console.error(error));
                    }
                  } catch (error) {
                    console.error(error);
                    setLoading(false);
                  }
                }
              }
            ]);
          }
        }
      }
    }
  };

  const submitSlots = () => {
    if (buttonType === "") {
      if (Platform.OS === "web") {
        alert("Select type to post");
      } else {
        Alert.alert("Select type to post");
      }
    } else if (buttonType === "PostJob") {
      if (
        licenseTypeArr.length === 0 ||
        // certificationArr.length === 0 ||
        // emrExperienceArr.length === 0 ||
        jobState.shiftTitle === "" ||
        // jobState.specialty === "" ||
        // jobState.yearOfExperience === "" ||
        jobState.startDate === "" ||
        jobState.endDate === "" ||
        jobState.startTime === "" ||
        jobState.endTime === "" ||
        jobState.break === "" ||
        jobState.fullAddress === "" ||
        jobState.jobTiming === ""
      ) {
        if (Platform.OS === "web") {
          alert("Fill all required fields first");
        } else {
          Alert.alert("Fill all required fields first");
        }
        setError(true);
        setLoading(false);
      }
      //  else if (
      //   dates?.includes(convertDate(jobState.startDate)) ||
      //   dates?.includes(convertDate(jobState.endDate))
      // ) {
      //   Alert.alert(
      //     "Alert",
      //     "You'r in time off on selected days. Please change the date"
      //   );
      // }
      else if (convertDate(jobState.startDate) < convertDate(currentDate)) {
        if (Platform.OS === "web") {
          alert("Check your date");
        } else {
          Alert.alert("Check your date");
        }
        setError(true);
        setLoading(false);
      } else if (
        convertDate(jobState.endDate) < convertDate(jobState.startDate)
      ) {
        if (Platform.OS === "web") {
          alert("Check your end date");
        } else {
          Alert.alert("Check your end date");
        }
        setError(true);
        setLoading(false);
      } else if (timeDifferentFun(jobState?.startTime, jobState?.endTime) < 1) {
        if (Platform.OS === "web") {
          alert(`Start and End Time can't be same`);
        } else {
          Alert.alert(`Start and End Time can't be same`);
        }
        setLoading(false);
      } else {
        if (Platform.OS === "web") {
          if (confirm("Confirm\nReview Date and Time before post?")) {
            setLoading(true);
            try {
              setLoading(true);
              const slot = parseInt(selectedSlots);
              const promises = [];
              for (let i = 0; i < slot; i++) {
                if (
                  convertDate(jobState.endDate) !==
                  convertDate(jobState.startDate)
                ) {
                  const date = moment(jobState.startDate);
                  const startDate = moment(date).format("YYYY-MM-DD");
                  const nightEndDate = date.add(1, "day").format("YYYY-MM-DD");
                  // console.log(i, startDate, nightEndDate)
                  const postJobPromise = postJob(
                    startDate.toString(),
                    nightEndDate.toString()
                  ).then(async (res) => {
                    try {
                      const nurseIds = await getNurseNotificationMobileId(res);
                      const flattenedIds = nurseIds.flat();
                      const uniqueIds = [...new Set(flattenedIds)];
                      setUserList(uniqueIds);
                    } catch (error) {
                      return console.error(error);
                    }
                  });
                  promises.push(postJobPromise);
                } else {
                  const date = moment(new Date(jobState.startDate)).format(
                    "YYYY-MM-DD"
                  );
                  // console.log(i, date, date)
                  const postJobPromise = postJob(
                    date.toString(),
                    date.toString()
                  ).then(async (res) => {
                    try {
                      const nurseIds = await getNurseNotificationMobileId(res);
                      const flattenedIds = nurseIds.flat();
                      const uniqueIds = [...new Set(flattenedIds)];
                      setUserList(uniqueIds);
                    } catch (error) {
                      return console.error(error);
                    }
                  });
                  promises.push(postJobPromise);
                }
              }
              Promise.all(promises)
                .then(() => {
                  setJobCreated(true);
                })
                .catch((error) => console.error(error));
            } catch (error) {
              console.error(error);
              setLoading(false);
            }
          } else {
            setLoading(false);
          }
        } else {
          Alert.alert("Confirm", "Review Date and Time before post?", [
            {
              text: "Cancel",
              onPress: () => setLoading(false),
              style: "cancel"
            },
            {
              text: "OK",
              onPress: async () => {
                try {
                  setLoading(true);
                  const slot = parseInt(selectedSlots);
                  const promises = [];
                  for (let i = 0; i < slot; i++) {
                    if (
                      convertDate(jobState.endDate) !==
                      convertDate(jobState.startDate)
                    ) {
                      const date = moment(jobState.startDate);
                      const startDate = moment(date).format("YYYY-MM-DD");
                      const nightEndDate = date
                        .add(1, "day")
                        .format("YYYY-MM-DD");
                      // console.log(i, startDate, nightEndDate)
                      const postJobPromise = postJob(
                        startDate.toString(),
                        nightEndDate.toString()
                      ).then(async (res) => {
                        try {
                          const nurseIds = await getNurseNotificationMobileId(
                            res
                          );
                          const flattenedIds = nurseIds.flat();
                          const uniqueIds = [...new Set(flattenedIds)];
                          setUserList(uniqueIds);
                        } catch (error) {
                          return console.error(error);
                        }
                      });
                      promises.push(postJobPromise);
                    } else {
                      const date = moment(new Date(jobState.startDate)).format(
                        "YYYY-MM-DD"
                      );
                      // console.log(i, date, date)
                      const postJobPromise = postJob(
                        date.toString(),
                        date.toString()
                      ).then(async (res) => {
                        try {
                          const nurseIds = await getNurseNotificationMobileId(
                            res
                          );
                          const flattenedIds = nurseIds.flat();
                          const uniqueIds = [...new Set(flattenedIds)];
                          setUserList(uniqueIds);
                        } catch (error) {
                          return console.error(error);
                        }
                      });
                      promises.push(postJobPromise);
                    }
                  }
                  Promise.all(promises)
                    .then(() => {
                      setJobCreated(true);
                    })
                    .catch((error) => console.error(error));
                } catch (error) {
                  console.error(error);
                  setLoading(false);
                }
              }
            }
          ]);
        }
      }
    }
  };

  useEffect(() => {
    const get = async () => {
      if (
        userData !== undefined &&
        licenseTypeArr.length !== 0 &&
        jobState.fullAddress !== "" &&
        directAssignment === true
      ) {
        setAvailableNurseLoading(true);
        await getJobMatchedAllNurse(
          authToken,
          userData?.organization,
          userData?.location_id,
          licenseTypeArr,
          jobState.fullAddress
        )
          .then((res) => {
            // console.log("default", res)
            const data = res?.filter((nurseData) => {
              const lowestYear = nurseData?.workExperienceDetails?.reduce(
                (lowest, employment) => {
                  const year = new Date(employment?.startDate).getFullYear();
                  return year < lowest ? year : lowest;
                },
                new Date().getFullYear()
              );
              const experience = new Date().getFullYear() - lowestYear;
              return (
                (jobState?.specialtyRequired === true
                  ? nurseData?.specialty?.includes(jobState?.specialty)
                  : jobState?.specialtyRequired === false
                  ? nurseData
                  : nurseData) &&
                (jobState?.certificationRequired === true
                  ? certificationArr.some((value) =>
                      nurseData?.certificationDetails?.some(
                        (va) => va?.certificationType === value
                      )
                    )
                  : jobState?.certificationRequired === false
                  ? nurseData
                  : nurseData) &&
                (jobState?.experienceRequired
                  ? jobState?.yearOfExperience === "0 - 1 year"
                    ? experience <= 1
                    : jobState?.yearOfExperience === "1 - 2 years"
                    ? experience >= 1 && experience <= 2
                    : jobState?.yearOfExperience === "2+ years"
                    ? experience >= 2
                    : true
                  : jobState?.experienceRequired === false
                  ? nurseData
                  : nurseData) &&
                (jobState?.emrehrRequired === true
                  ? emrExperienceArr?.some((value) =>
                      nurseData?.EMRorEHRExperience?.some((va) => va === value)
                    )
                  : jobState?.emrehrRequired === false
                  ? nurseData
                  : nurseData)
              );
            });
            // console.log("filter", data)
            setAvailableNurse(data);
            setAvailableNurseLoading(false);
          })
          .catch((e) => console.log("getJobMatchedAllNurse", e));
      }
    };
    get();
  }, [
    userData,
    directAssignment,
    jobState,
    licenseTypeArr,
    certificationArr,
    emrExperienceArr
  ]);

  useEffect(() => {
    if (jobFinalSelectionNurseId === "") {
      if (jobCreated) {
        // console.log("jobCreated", jobCreated)
        // console.log("userList", userList)
        userList?.map(async (item) => {
          if (item) {
            if (Platform.OS !== "web") {
              await sendNewPushNotification({
                expoPushToken: item,
                job: "New Jobs",
                typeMessage: `${jobState.jobType}s available in your area.`,
                screen: "HomeScreen"
              });
            } else {
              sendNotification(authToken, {
                expoPushToken: item,
                title: "New Jobs",
                body: `${jobState.jobType}s available in your area.`,
                data: { screen: "HomeScreen" }
              });
            }
          }
        });
        socket?.emit("tableJobInsert", {
          table: "JobPostingTable",
          message: "Fetch Dashboard Job"
        });
        if (Platform.OS === "web") {
          if (
            confirm("Jobs Post Successful.\nTo Continue to post more? Click Ok")
          ) {
            setJobState({
              licenseType: "",
              shiftTitle: "",
              specialty: "",
              yearOfExperience: "",
              expiration: 0,
              startDate: new Date().toString(),
              endDate: new Date().toString(),
              startTime: new Date().toString(),
              endTime: new Date().toString(),
              break: "",
              customerName: "",
              customerId: "",
              unit: "",
              floor: "",
              fullAddress: "",
              latitude: "",
              longitude: "",
              baseRate: "",
              enableBid: false,
              notes: "",
              jobType:
                appJobType === "Shift"
                  ? "Shift"
                  : appJobType === "Visit"
                  ? "Visit"
                  : "Shift",
              jobStatus: "",
              jobFinalSelectionNurseId: "",
              jobBitFinalAmount: "",
              autoAcceptedEnable: false,
              maxBidAmount: "",
              jobDays: "",
              jobDuration: "",
              jobTiming: "",
              specialtyRequired: false,
              certificationRequired: false,
              experienceRequired: false,
              emrehrRequired: false,
              customerVisibility: false,
              baseRateVisibility: false
            });
            setButtonType("");
            setLicenseTypeArr([]);
            setCertificationArr([]);
            setEmrExperienceArr([]);
            setJobTemplate(undefined);
            setJobFinalSelectionNurseId("");
            setDirectAssignment(false);
            setNurseDirectAssignmentSelectedDates([]);
            setWeekNurseDirectAssignmentAvailabilityData([]);
          } else {
            setFetchAgainJobDashboard(!fetchAgainJobDashboard);
            props?.navigation?.navigate("HomeScreen");
          }
        } else {
          Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
          Alert.alert(
            "Jobs Post Successful.",
            " To Continue to post more? Click Ok",
            [
              {
                text: "Ok",
                onPress: () => {
                  setJobState({
                    licenseType: "",
                    shiftTitle: "",
                    specialty: "",
                    yearOfExperience: "",
                    expiration: 0,
                    startDate: new Date().toString(),
                    endDate: new Date().toString(),
                    startTime: new Date().toString(),
                    endTime: new Date().toString(),
                    break: "",
                    customerName: "",
                    customerId: "",
                    unit: "",
                    floor: "",
                    fullAddress: "",
                    latitude: "",
                    longitude: "",
                    baseRate: "",
                    enableBid: false,
                    notes: "",
                    jobType:
                      appJobType === "Shift"
                        ? "Shift"
                        : appJobType === "Visit"
                        ? "Visit"
                        : "Shift",
                    jobStatus: "",
                    jobFinalSelectionNurseId: "",
                    jobBitFinalAmount: "",
                    autoAcceptedEnable: false,
                    maxBidAmount: "",
                    jobDays: "",
                    jobDuration: "",
                    jobTiming: "",
                    specialtyRequired: false,
                    certificationRequired: false,
                    experienceRequired: false,
                    emrehrRequired: false,
                    customerVisibility: false,
                    baseRateVisibility: false
                  });
                  setButtonType("");
                  setLicenseTypeArr([]);
                  setCertificationArr([]);
                  setEmrExperienceArr([]);
                  setJobTemplate(undefined);
                  setJobFinalSelectionNurseId("");
                  setDirectAssignment(false);
                  setNurseDirectAssignmentSelectedDates([]);
                  setWeekNurseDirectAssignmentAvailabilityData([]);
                }
              },
              {
                text: "Cancel",
                onPress: () => {
                  setFetchAgainJobDashboard(!fetchAgainJobDashboard);
                  props?.navigation?.navigate("HomeScreen");
                }
              }
            ]
          );
        }
        setUserList([]);
        setSelectedHours("1");
        setJobFinalSelectionNurseId("");
        setDirectAssignment(false);
        setNurseDirectAssignmentSelectedDates([]);
        setWeekNurseDirectAssignmentAvailabilityData([]);
        setLoading(false);
        setJobCreated(false);
        // socket?.emit("jobPosting", "NURSD")
      }
    } else {
      if (jobCreated) {
        const nurse = weekNurseDirectAssignmentAvailabilityData
          .flat()
          .filter((item) => {
            return item?.uniqueId === jobFinalSelectionNurseId;
          });
        async function send() {
          if (nurse[0]?.mobileId) {
            if (Platform.OS !== "web") {
              await sendNewPushNotification({
                expoPushToken: nurse[0]?.mobileId,
                job: `“Direct ${jobState.jobType}s Assignment” (Check Upcoming Jobs)`,
                typeMessage: `${jobState.jobType}s assigned.`,
                screen: "HomeScreen"
              });
            } else {
              sendNotification(authToken, {
                expoPushToken: nurse[0]?.mobileId,
                title: `“Direct ${jobState.jobType}s Assignment” (Check Upcoming Jobs)`,
                body: `${jobState.jobType}s assigned.`,
                data: { screen: "HomeScreen" }
              });
            }
          }
          await postNurseNotification(
            authToken,
            jobFinalSelectionNurseId,
            "",
            `“Direct ${jobState.jobType}s Assignment” (Check Upcoming Jobs)`,
            `${jobState.jobType}s assigned.`,
            "HomeScreen",
            {
              screen: "UpComing"
            },
            false,
            false,
            "green",
            ""
          );
          socket?.emit("tableNotificationInsert", {
            table: "NurseNotificationTable",
            id: jobFinalSelectionNurseId
          });
          socket?.emit("tableJobInsert", {
            table: "JobPostingTable",
            message: "Fetch Dashboard Job"
          });
        }
        send();
        Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
        if (Platform.OS === "web") {
          if (
            confirm("Jobs Post Successful.\nTo Continue to post more? Click Ok")
          ) {
            setJobState({
              licenseType: "",
              shiftTitle: "",
              specialty: "",
              yearOfExperience: "",
              expiration: 0,
              startDate: new Date().toString(),
              endDate: new Date().toString(),
              startTime: new Date().toString(),
              endTime: new Date().toString(),
              break: "",
              customerName: "",
              customerId: "",
              unit: "",
              floor: "",
              fullAddress: "",
              latitude: "",
              longitude: "",
              baseRate: "",
              enableBid: false,
              notes: "",
              jobType:
                appJobType === "Shift"
                  ? "Shift"
                  : appJobType === "Visit"
                  ? "Visit"
                  : "Shift",
              jobStatus: "",
              jobFinalSelectionNurseId: "",
              jobBitFinalAmount: "",
              autoAcceptedEnable: false,
              maxBidAmount: "",
              jobDays: "",
              jobDuration: "",
              jobTiming: "",
              specialtyRequired: false,
              certificationRequired: false,
              experienceRequired: false,
              emrehrRequired: false,
              customerVisibility: false,
              baseRateVisibility: false
            });
            setButtonType("");
            setLicenseTypeArr([]);
            setCertificationArr([]);
            setEmrExperienceArr([]);
            setJobTemplate(undefined);
            setJobFinalSelectionNurseId("");
            setDirectAssignment(false);
            setNurseDirectAssignmentSelectedDates([]);
            setWeekNurseDirectAssignmentAvailabilityData([]);
          } else {
            setFetchAgainJobDashboard(!fetchAgainJobDashboard);
            props?.navigation?.navigate("HomeScreen");
          }
        } else {
          Alert.alert(
            "Jobs Post Successful.",
            " To Continue to post more? Click Ok",
            [
              {
                text: "Ok",
                onPress: () => {
                  setJobState({
                    licenseType: "",
                    shiftTitle: "",
                    specialty: "",
                    yearOfExperience: "",
                    expiration: 0,
                    startDate: new Date().toString(),
                    endDate: new Date().toString(),
                    startTime: new Date().toString(),
                    endTime: new Date().toString(),
                    break: "",
                    customerName: "",
                    customerId: "",
                    unit: "",
                    floor: "",
                    fullAddress: "",
                    latitude: "",
                    longitude: "",
                    baseRate: "",
                    enableBid: false,
                    notes: "",
                    jobType:
                      appJobType === "Shift"
                        ? "Shift"
                        : appJobType === "Visit"
                        ? "Visit"
                        : "Shift",
                    jobStatus: "",
                    jobFinalSelectionNurseId: "",
                    jobBitFinalAmount: "",
                    autoAcceptedEnable: false,
                    maxBidAmount: "",
                    jobDays: "",
                    jobDuration: "",
                    jobTiming: "",
                    specialtyRequired: false,
                    certificationRequired: false,
                    experienceRequired: false,
                    emrehrRequired: false,
                    customerVisibility: false,
                    baseRateVisibility: false
                  });
                  setButtonType("");
                  setLicenseTypeArr([]);
                  setCertificationArr([]);
                  setEmrExperienceArr([]);
                  setJobTemplate(undefined);
                  setJobFinalSelectionNurseId("");
                  setDirectAssignment(false);
                  setNurseDirectAssignmentSelectedDates([]);
                  setWeekNurseDirectAssignmentAvailabilityData([]);
                }
              },
              {
                text: "Cancel",
                onPress: () => {
                  setFetchAgainJobDashboard(!fetchAgainJobDashboard);
                  props?.navigation?.navigate("HomeScreen");
                }
              }
            ]
          );
        }
        setUserList([]);
        setSelectedHours("1");
        setJobFinalSelectionNurseId("");
        setDirectAssignment(false);
        setNurseDirectAssignmentSelectedDates([]);
        setWeekNurseDirectAssignmentAvailabilityData([]);
        setLoading(false);
        setJobCreated(false);
      }
    }
  }, [userList, jobCreated]);

  useEffect(() => {
    const getNurseDirectAssignmentDates = async () => {
      if (jobFinalSelectionNurseId !== "") {
        if (
          convertDate(jobState?.startDate) === convertDate(jobState.endDate)
        ) {
          await getNurseWeekAvailabilityForDirectAssignment(
            authToken,
            jobFinalSelectionNurseId,
            convertDate(jobState?.startDate),
            convertDate(jobState?.endDate),
            moment(new Date(jobState?.startTime)).format("hh:mm a"),
            moment(new Date(jobState?.endTime)).format("hh:mm a"),
            selectedWeeks
          )
            .then((res) => {
              // console.log("getNurseWeekAvailabilityForDirectAssignment", res)
              setWeekNurseDirectAssignmentAvailabilityData(res);
            })
            .catch((e) =>
              console.log("getNurseWeekAvailabilityForDirectAssignment", e)
            );
        } else {
          await getNurseWeekAvailabilityForDirectAssignment(
            authToken,
            jobFinalSelectionNurseId,
            convertDate(jobState?.startDate),
            convertDate(jobState?.endDate),
            moment(new Date(jobState?.startTime)).format("hh:mm a"),
            moment(new Date(jobState?.endTime)).format("hh:mm a"),
            selectedWeeks
          )
            .then((res) => {
              // console.log("getNurseWeekAvailabilityForDirectAssignment not same", res)
              setWeekNurseDirectAssignmentAvailabilityData(res);
            })
            .catch((e) =>
              console.log(
                "getNurseWeekAvailabilityForDirectAssignment not same",
                e
              )
            );
        }
      } else {
        setWeekNurseDirectAssignmentAvailabilityData([]);
      }
    };
    getNurseDirectAssignmentDates();
  }, [
    jobState.startDate,
    jobState.startTime,
    jobState.endTime,
    jobFinalSelectionNurseId,
    selectedWeeks
  ]);

  //Nurse Direct Assignment Week Availability Data End
  const disabledDatesTextStyle = { color: "#CCCCCC" };

  const isPastDate = (date) => {
    const currentDate = moment();
    const twoMonthsLater = moment().add(2, "months");
    return (
      date.isBefore(currentDate, "day") || date.isAfter(twoMonthsLater, "day")
    );
  };

  const disabledDates = (date) => {
    return isPastDate(date);
  };

  const isStartPastDate = (date) => {
    const currentDate = moment();
    const twoMonthsLater = moment().add(2, "months");
    return (
      date.isBefore(moment(jobState.startDate), "day") ||
      date.isAfter(twoMonthsLater, "day")
    );
  };

  const disabledStartDates = (date) => {
    return isStartPastDate(date);
  };

  const handleDayClick = (day) => {
    const isSelected = selectedDays.includes(day);
    if (isSelected) {
      setSelectedDays(selectedDays.filter((d) => d !== day));
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };
  // console.log(selectedDays)
  const handleDayDirectAssignmentClick = (day) => {
    const isSelected = nurseDirectAssignmentSelectedDates.includes(day);
    if (isSelected) {
      setNurseDirectAssignmentSelectedDates(
        nurseDirectAssignmentSelectedDates.filter((d) => d !== day)
      );
    } else {
      setNurseDirectAssignmentSelectedDates([
        ...nurseDirectAssignmentSelectedDates,
        day
      ]);
    }
  };

  const handleAddLicenseType = (item) => {
    if (licenseTypeArr.some((existingItem) => existingItem === item)) {
      // Show an alert for duplicates
      if (Platform.OS === "web") {
        alert(`${item} already added.`);
      } else {
        Alert.alert(`${item} already added.`);
      }
    } else {
      // If it's not a duplicate, add the item to the skills array
      setLicenseTypeArr([...licenseTypeArr, item]);
    }
  };

  const handleRemoveLicenseType = (index) => {
    const updatedLicenseType = [...licenseTypeArr];
    updatedLicenseType.splice(index, 1);
    setLicenseTypeArr(updatedLicenseType);
  };

  const handleAddCertification = (item) => {
    if (certificationArr.some((existingItem) => existingItem === item)) {
      // Show an alert for duplicates
      if (Platform.OS === "web") {
        alert(`${item} already added.`);
      } else {
        Alert.alert(`${item} already added.`);
      }
    } else {
      // If it's not a duplicate, add the item to the skills array
      setCertificationArr([...certificationArr, item]);
    }
  };

  const handleRemoveCertification = (index) => {
    const updatedCertification = [...certificationArr];
    updatedCertification.splice(index, 1);
    setCertificationArr(updatedCertification);
  };

  const handleAddEmrExperience = (item) => {
    if (emrExperienceArr.some((existingItem) => existingItem === item)) {
      // Show an alert for duplicates
      if (Platform.OS === "web") {
        alert(`${item} already added.`);
      } else {
        Alert.alert(`${item} already added.`);
      }
    } else {
      // If it's not a duplicate, add the item to the skills array
      setEmrExperienceArr([...emrExperienceArr, item]);
    }
  };

  const handleRemoveEmrExperience = (index) => {
    const updatedEmrExperience = [...emrExperienceArr];
    updatedEmrExperience.splice(index, 1);
    setEmrExperienceArr(updatedEmrExperience);
  };

  const filterCustomerNameSuggestions = (query) => {
    if (query === "") {
      setFilteredSuggestions([]);
    } else {
      const filtered = patientData
        ?.filter(
          (item) =>
            item?.firstName?.toLowerCase().includes(query.toLowerCase()) ||
            item.lastName?.toLowerCase().includes(query.toLowerCase())
        )
        .sort((a, b) =>
          a?.firstName.toLowerCase().localeCompare(b?.firstName.toLowerCase())
        );
      setFilteredSuggestions(filtered);
    }
  };

  const renderCustomerCardSuggestion = ({ item }) => {
    return (
      <TouchableOpacity
        style={{
          paddingVertical: 10,
          borderRadius: 10,
          paddingLeft: 5,
          borderColor: "#dddd",
          borderBottomWidth: 2
        }}
        onPress={async () => {
          // let address =
          //   item?.patient_address_line_1 +
          //   "," +
          //   item?.patient_city +
          //   "," +
          //   item?.patient_state +
          //   "," +
          //   item?.patient_zip_code;

          // const GOOGLE_PLACES_API_KEY =
          //   "AIzaSyASXZSrsvL25WiY7nZc2M4WJhkDMj-jkvs";
          // const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          //   address
          // )}&key=${GOOGLE_PLACES_API_KEY}`;

          try {
            // const response = await fetch(apiUrl);
            // const data = await response.json();
            // // console.log(data);
            // const state = data.results[0].address_components.find((component) =>
            //   component.types.includes("administrative_area_level_1")
            // );

            setJobState({
              ...jobState,
              customerId: item?.id,
              customerName: item?.firstName + " " + item?.lastName,
              fullAddress: item?.fullAddress,
              latitude: item?.latitude,
              longitude: item?.longitude
              // unit: item?.patient_address_line_2
              //   ? item?.patient_address_line_2
              //   : "",
            });
            setPatientSearch("");
            setFilteredSuggestions([]);
            setPatientView(false);
          } catch (error) {
            console.error("Error:", error);
            return null;
          }
        }}
      >
        <Text>{item?.firstName + " " + item?.lastName}</Text>
      </TouchableOpacity>
    );
  };

  const repeatClear = () => {
    setButtonType("");
    setSelectedDays([]);
    setNurseDirectAssignmentSelectedDates([]);
    setWeekNurseDirectAssignmentAvailabilityData([]);
    setSelectedWeeks(1);
  };

  console.log(appJobType);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : null}
      style={styles.container}
    >
      {loadingScreen ? (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Text>Loading...</Text>
        </View>
      ) : (
        <View>
          {!showAddressWeb && (
            <ScrollView bounces={false} showsVerticalScrollIndicator={false}>
              <View
                style={{
                  marginHorizontal: 20,
                  marginTop: 10,
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "flex-end"
                }}
              >
                <TouchableOpacity
                  style={{
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    backgroundColor: "#f2f2f2",
                    borderRadius: 10,
                    elevation: 1,
                    height: 30,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingHorizontal: 10
                  }}
                  onPress={() => setShowTemplate(true)}
                >
                  <Text
                    style={[
                      {
                        fontSize: 12,
                        color: "#737373"
                      }
                    ]}
                  >
                    {jobTemplate === undefined
                      ? "Select Template"
                      : jobTemplate?.jobTemplateName}
                  </Text>
                </TouchableOpacity>
                {jobTemplate !== undefined && (
                  <TouchableOpacity
                    onPress={() => {
                      setSelectedHoursLoad(true);
                      setJobState({
                        licenseType: "",
                        shiftTitle: "",
                        specialty: "",
                        yearOfExperience: "",
                        expiration: "",
                        startDate: new Date().toString(),
                        endDate: new Date().toString(),
                        startTime: new Date().toString(),
                        endTime: new Date().toString(),
                        break: "",
                        customerName: "",
                        customerId: "",
                        unit: "",
                        floor: "",
                        fullAddress: "",
                        latitude: "",
                        longitude: "",
                        baseRate: "",
                        enableBid: false,
                        notes: "",
                        jobType: "Shift",
                        jobStatus: "",
                        jobFinalSelectionNurseId: "",
                        jobBitFinalAmount: "",
                        autoAcceptedEnable: false,
                        maxBidAmount: "",
                        jobDays: "",
                        jobDuration: "",
                        jobTiming: ""
                      });
                      setJobTemplate(undefined);
                      setLicenseTypeArr([]);
                      setCertificationArr([]);
                      setButtonType("");
                      setJobFinalSelectionNurseId("");
                      setDirectAssignment(false);
                      setSelectedDays([]);
                      setSelectedWeeks(1);
                      setSelectedHours("1");

                      setInterval(() => {
                        setSelectedHoursLoad(false);
                      }, 2000);
                    }}
                  >
                    <MaterialIcons
                      name={"delete-outline"}
                      size={24}
                      color="#999"
                    />
                  </TouchableOpacity>
                )}
              </View>

              <View
                style={{
                  marginHorizontal: 20,
                  marginTop: 10
                }}
              >
                <Text
                  style={[
                    {
                      fontSize: 12,
                      color: "#737373"
                    }
                  ]}
                >
                  Job Type
                  <Text style={{ color: "red" }}>*</Text>
                </Text>
                <View
                  style={{
                    flex: 1,
                    height: 40,
                    borderWidth: 1,
                    borderColor: "#e6e6e6",
                    backgroundColor: "#f2f2f2",
                    borderRadius: 10,
                    flexDirection: "row",
                    marginTop: 5,
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingHorizontal: 10
                  }}
                >
                  <Text style={{ fontSize: 12, color: "#737373" }}>
                    {jobState.jobType}
                  </Text>

                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center"
                    }}
                  >
                    {(appJobType === "Shift" || appJobType === "Both") && (
                      <TouchableOpacity
                        style={{
                          backgroundColor:
                            jobState.jobType === "Shift"
                              ? "#00b359"
                              : "#00000000",
                          paddingVertical: 7,
                          paddingHorizontal: 15,
                          borderRadius: 3,
                          alignItems: "center"
                        }}
                        activeOpacity={0.5}
                        onPress={() => {
                          setJobState((prevState) => ({
                            ...prevState,
                            jobType: "Shift"
                          }));
                        }}
                      >
                        <Text
                          style={{
                            fontWeight: "500",
                            fontSize: 12,
                            color:
                              jobState.jobType === "Shift" ? "#fff" : "#000"
                          }}
                        >
                          Shift
                        </Text>
                      </TouchableOpacity>
                    )}
                    {(appJobType === "Visit" || appJobType === "Both") && (
                      <TouchableOpacity
                        style={{
                          backgroundColor:
                            jobState.jobType === "Visit"
                              ? "#00b359"
                              : "#00000000",
                          paddingVertical: 7,
                          paddingHorizontal: 15,
                          borderRadius: 3,
                          alignItems: "center"
                        }}
                        activeOpacity={0.5}
                        onPress={() => {
                          setJobState((prevState) => ({
                            ...prevState,
                            jobType: "Visit"
                          }));
                        }}
                      >
                        <Text
                          style={{
                            fontWeight: "500",
                            fontSize: 12,
                            color:
                              jobState.jobType === "Visit" ? "#fff" : "#000"
                          }}
                        >
                          Visit
                        </Text>
                      </TouchableOpacity>
                    )}
                  </View>
                </View>
              </View>

              <View
                style={{
                  marginHorizontal: 20,
                  marginTop: 10
                }}
              >
                <Text
                  style={[
                    {
                      fontSize: 12,
                      color: "#737373"
                    }
                  ]}
                >
                  License Type
                  <Text style={{ color: "red" }}>*</Text>
                </Text>
                <TouchableOpacity
                  style={{
                    flex: 1,
                    height: 40,
                    borderWidth: 1,
                    borderColor:
                      error && licenseTypeArr.length === 0 ? "red" : "#e6e6e6",
                    backgroundColor: "#f2f2f2",
                    borderRadius: 10,
                    flexDirection: "row",
                    marginTop: 5,
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingHorizontal: 10
                  }}
                  onPress={() => setShowLicenseType(true)}
                >
                  <Text style={{ fontSize: 12, color: "#737373" }}>
                    Add License Type
                  </Text>
                  <MaterialIcons
                    name={"keyboard-arrow-down"}
                    size={24}
                    color="#b3b3b3"
                  />
                </TouchableOpacity>
                <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                  {licenseTypeArr?.map((item, index) => {
                    return (
                      <View
                        key={index}
                        style={{
                          borderWidth: 1,
                          borderColor: "#e6e6e6",
                          backgroundColor: "#f2f2f2",
                          borderRadius: 10,
                          flexDirection: "row",
                          alignItems: "center",
                          paddingHorizontal: 10,
                          paddingVertical: 5,
                          marginRight: 10,
                          marginTop: 10
                        }}
                      >
                        <Text style={{ fontSize: 12, color: "#737373" }}>
                          {item}
                        </Text>
                        <TouchableOpacity
                          onPress={() => handleRemoveLicenseType(index)}
                        >
                          <MaterialIcons
                            name={"delete-outline"}
                            size={24}
                            color="#999"
                          />
                        </TouchableOpacity>
                      </View>
                    );
                  })}
                </View>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: 10,
                  marginHorizontal: 20
                }}
              >
                <CustomInput
                  required={true}
                  label={`${jobState.jobType} Title`}
                  onChangeText={(text) =>
                    setJobState({ ...jobState, shiftTitle: text })
                  }
                  keyboardType={"default"}
                  placeholder={`${jobState.jobType} Title`}
                  value={jobState.shiftTitle}
                  clearValue={() =>
                    setJobState({ ...jobState, shiftTitle: "" })
                  }
                  viewStyle={{
                    height: 40,
                    backgroundColor: "#f2f2f2",
                    borderRadius: 10,
                    elevation: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor:
                      error && jobState.shiftTitle === "" ? "red" : "#e6e6e6",
                    paddingRight: 10,
                    justifyContent: "space-between",
                    marginTop: 0
                  }}
                  textInputStyle={{
                    flex: 1,
                    height: 40,
                    borderRadius: 10,
                    paddingLeft: 10,
                    paddingRight: 5,
                    fontSize: 12
                  }}
                  iconStyle={{
                    height: 40,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                  }}
                />
              </View>

              <View
                style={{
                  marginHorizontal: 20,
                  marginTop: 10
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <Text
                    style={[
                      {
                        fontSize: 12,
                        color: "#737373"
                      }
                    ]}
                  >
                    Specialty
                  </Text>
                  <CustomToggle
                    state={jobState.specialtyRequired}
                    onValueChange={(value) =>
                      setJobState({ ...jobState, specialtyRequired: value })
                    }
                  />
                </View>
                <View
                  style={{
                    marginTop: 10,
                    height: 40
                  }}
                >
                  <TouchableOpacity
                    style={{
                      borderWidth: 0.5,
                      borderColor:
                        error && jobState.specialty === "" ? "red" : "#e6e6e6",
                      backgroundColor: "#f2f2f2",
                      borderRadius: 10,
                      elevation: 1,
                      height: 40,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingHorizontal: 10
                    }}
                    onPress={() => setShowSpecialty(true)}
                  >
                    <Text
                      style={[
                        {
                          fontSize: 12,
                          color: "#737373"
                        }
                      ]}
                    >
                      {jobState.specialty === ""
                        ? "Select Specialty"
                        : jobState.specialty}
                    </Text>
                    <MaterialIcons
                      name={"keyboard-arrow-down"}
                      size={24}
                      color="#808080"
                    />
                  </TouchableOpacity>
                </View>
              </View>

              <View
                style={{
                  marginHorizontal: 20,
                  marginTop: 10
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <Text
                    style={[
                      {
                        fontSize: 12,
                        color: "#737373"
                      }
                    ]}
                  >
                    Certification
                  </Text>
                  <CustomToggle
                    state={jobState.certificationRequired}
                    onValueChange={(value) =>
                      setJobState({ ...jobState, certificationRequired: value })
                    }
                  />
                </View>
                <TouchableOpacity
                  style={{
                    flex: 1,
                    height: 40,
                    borderWidth: 1,
                    borderColor:
                      error && certificationArr.length === 0
                        ? "red"
                        : "#e6e6e6",
                    backgroundColor: "#f2f2f2",
                    borderRadius: 10,
                    flexDirection: "row",
                    marginTop: 5,
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingHorizontal: 10
                  }}
                  onPress={() => setShowCertification(true)}
                >
                  <Text style={{ fontSize: 12, color: "#737373" }}>
                    Add Certification
                  </Text>
                  <MaterialIcons
                    name={"keyboard-arrow-down"}
                    size={24}
                    color="#b3b3b3"
                  />
                </TouchableOpacity>
                <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                  {certificationArr?.map((item, index) => {
                    return (
                      <View
                        key={index}
                        style={{
                          borderWidth: 1,
                          borderColor: "#e6e6e6",
                          backgroundColor: "#f2f2f2",
                          borderRadius: 10,
                          flexDirection: "row",
                          alignItems: "center",
                          paddingHorizontal: 10,
                          paddingVertical: 5,
                          marginRight: 10,
                          marginTop: 10
                        }}
                      >
                        <Text style={{ fontSize: 12, color: "#737373" }}>
                          {item}
                        </Text>
                        <TouchableOpacity
                          onPress={() => handleRemoveCertification(index)}
                        >
                          <MaterialIcons
                            name={"delete-outline"}
                            size={24}
                            color="#999"
                          />
                        </TouchableOpacity>
                      </View>
                    );
                  })}
                </View>
              </View>

              <View
                style={{
                  marginHorizontal: 20,
                  marginTop: 10
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <Text
                    style={[
                      {
                        fontSize: 12,
                        color: "#737373"
                      }
                    ]}
                  >
                    Experience Level
                  </Text>
                  <CustomToggle
                    state={jobState.experienceRequired}
                    onValueChange={(value) =>
                      setJobState({ ...jobState, experienceRequired: value })
                    }
                  />
                </View>
                <View
                  style={{
                    marginTop: 10,
                    height: 40
                  }}
                >
                  <TouchableOpacity
                    style={{
                      borderWidth: 0.5,
                      borderColor:
                        error && jobState.yearOfExperience === ""
                          ? "red"
                          : "#e6e6e6",
                      backgroundColor: "#f2f2f2",
                      borderRadius: 10,
                      elevation: 1,
                      height: 40,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingHorizontal: 10
                    }}
                    onPress={() => setShowExperienceLevel(true)}
                  >
                    <Text
                      style={[
                        {
                          fontSize: 12,
                          color: "#737373"
                        }
                      ]}
                    >
                      {jobState.yearOfExperience === ""
                        ? "Select Experience Level"
                        : jobState.yearOfExperience}
                    </Text>
                    <MaterialIcons
                      name={"keyboard-arrow-down"}
                      size={24}
                      color="#808080"
                    />
                  </TouchableOpacity>
                </View>
              </View>

              <View
                style={{
                  marginHorizontal: 20,
                  marginTop: 10
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <Text
                    style={[
                      {
                        fontSize: 12,
                        color: "#737373"
                      }
                    ]}
                  >
                    EMR or EHR Experience
                  </Text>
                  <CustomToggle
                    state={jobState.emrehrRequired}
                    onValueChange={(value) =>
                      setJobState({ ...jobState, emrehrRequired: value })
                    }
                  />
                </View>
                <TouchableOpacity
                  style={{
                    flex: 1,
                    height: 40,
                    borderWidth: 1,
                    borderColor:
                      error && emrExperienceArr.length === 0
                        ? "red"
                        : "#e6e6e6",
                    backgroundColor: "#f2f2f2",
                    borderRadius: 10,
                    flexDirection: "row",
                    marginTop: 5,
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingHorizontal: 10
                  }}
                  onPress={() => setShowEmrExperience(true)}
                >
                  <Text style={{ fontSize: 12, color: "#737373" }}>
                    Add EMR or EHR Experience
                  </Text>
                  <MaterialIcons
                    name={"keyboard-arrow-down"}
                    size={24}
                    color="#b3b3b3"
                  />
                </TouchableOpacity>
                <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                  {emrExperienceArr?.map((item, index) => {
                    return (
                      <View
                        key={index}
                        style={{
                          borderWidth: 1,
                          borderColor: "#e6e6e6",
                          backgroundColor: "#f2f2f2",
                          borderRadius: 10,
                          flexDirection: "row",
                          alignItems: "center",
                          paddingHorizontal: 10,
                          paddingVertical: 5,
                          marginRight: 10,
                          marginTop: 10
                        }}
                      >
                        <Text style={{ fontSize: 12, color: "#737373" }}>
                          {item}
                        </Text>
                        <TouchableOpacity
                          onPress={() => handleRemoveEmrExperience(index)}
                        >
                          <MaterialIcons
                            name={"delete-outline"}
                            size={24}
                            color="#999"
                          />
                        </TouchableOpacity>
                      </View>
                    );
                  })}
                </View>
              </View>

              {/* <View
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: 10,
              marginHorizontal: 20,
            }}
          >
            <Text
              style={[
                {
                  fontSize: 12,
                  color: "#737373",
                  marginBottom: 10,
                },
              ]}
            >
              Job Expiration (
              <Text
                style={[
                  {
                    fontSize: 12,
                    color: "#000",
                    fontWeight: "bold",
                    marginBottom: 10,
                  },
                ]}
              >
                {" "}
                {jobState.expiration !== "" && `${jobState.expiration} hr`}
              </Text>{" "}
              Hours before the Start Time).
            </Text>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                top: 3,
              }}
            >
              <Text style={{ fontSize: 12 }}>1 hr</Text>
              <Text style={{ fontSize: 12 }}>24hr</Text>
            </View>
            <View style={{ marginTop: -10 }}>
              <CustomSliderFilter
                sliderWidth={width / 1.5}
                min={1}
                max={24}
                step={1}
                type={""}
                label={true}
                color={"#00b359"}
                onValueChange={(range) => {
                  setJobState({ ...jobState, expiration: range.max });
                  // setState({
                  //   ...state,
                  //   distancePreferenceMinValue: range.min,
                  //   distancePreferenceMaxValue: range.max,
                  // });
                }}
                valueMin={0}
                valueMax={parseInt(jobState?.expiration)}
                minDrag={false}
              />
            </View>
          </View> */}

              <View
                style={{
                  marginHorizontal: 20,
                  marginTop: 10
                }}
              >
                <Text
                  style={[
                    {
                      fontSize: 12,
                      color: "#737373"
                    }
                  ]}
                >
                  {jobState.jobType} Time
                  <Text style={{ color: "red" }}>*</Text>
                </Text>
                <View
                  style={{
                    marginTop: 10,
                    height: 40
                  }}
                >
                  <TouchableOpacity
                    style={{
                      borderWidth: 0.5,
                      borderColor:
                        error && jobState.jobTiming === "" ? "red" : "#e6e6e6",
                      backgroundColor: "#f2f2f2",
                      borderRadius: 10,
                      elevation: 1,
                      height: 40,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingHorizontal: 10
                    }}
                    onPress={() => setShowTiming(true)}
                  >
                    <Text
                      style={[
                        {
                          fontSize: 12,
                          color: "#737373"
                        }
                      ]}
                    >
                      {jobState.jobTiming === ""
                        ? "Select Time"
                        : jobState.jobTiming}
                    </Text>
                    <MaterialIcons
                      name={"keyboard-arrow-down"}
                      size={24}
                      color="#808080"
                    />
                  </TouchableOpacity>
                </View>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginHorizontal: 18,
                  marginTop: 10
                }}
              >
                <Text
                  style={[
                    {
                      fontSize: 12,
                      color: "#737373"
                    }
                  ]}
                >
                  Date
                </Text>
                <Text style={{ color: "red" }}> * </Text>
                <Text style={{ fontSize: 10, color: "#000" }}>
                  ( Select date and time )
                </Text>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: 10,
                  marginHorizontal: 20
                }}
              >
                <View
                  style={{
                    flex: 1
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#737373"
                    }}
                  >
                    Start Date <Text style={{ color: "red" }}>*</Text>
                  </Text>
                  <TouchableOpacity
                    onPress={() =>
                      jobFinalSelectionNurseId !== ""
                        ? Platform.OS === "web"
                          ? alert("To change date, unselect a nurse.")
                          : Alert.alert("To change date, unselect a nurse.")
                        : setShowCalendar(true)
                    }
                    style={{
                      borderWidth: 1,
                      borderColor:
                        error &&
                        convertDate(jobState.startDate) <
                          convertDate(currentDate)
                          ? "red"
                          : "#e6e6e6",
                      backgroundColor: "#f2f2f2",
                      borderRadius: 10,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: 10,
                      marginTop: 10,
                      marginRight: 5
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        color: "#737373"
                      }}
                    >
                      {moment(jobState.startDate).format("MM-DD-YYYY")}
                    </Text>
                    <AntDesign name="calendar" size={20} color="#808080" />
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    flex: 1
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#737373"
                    }}
                  >
                    Start Time <Text style={{ color: "red" }}>*</Text>
                  </Text>
                  <TouchableOpacity
                    onPress={() => setShowTimeWeb(true)}
                    style={{
                      flex: 1,
                      marginLeft: 5,
                      borderWidth: 1,
                      borderColor: "#e6e6e6",
                      backgroundColor: "#f2f2f2",
                      borderRadius: 10,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: 10,
                      marginTop: 10
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        color: "#737373"
                      }}
                    >
                      {moment(jobState.startTime).format("hh:mm a")}
                    </Text>
                    <MaterialIcons
                      name="access-time"
                      size={20}
                      color="#808080"
                    />
                  </TouchableOpacity>
                </View>
              </View>

              {jobState.jobType === "Shift" &&
                (selectedHoursLoad ? (
                  <Text
                    style={{
                      textAlign: "center",
                      marginTop: 5,
                      fontSize: 10
                    }}
                  >
                    Loading...
                  </Text>
                ) : (
                  <View
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      marginHorizontal: 10
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        marginHorizontal: 20,
                        marginTop: 10
                      }}
                    >
                      <Text
                        style={[
                          {
                            fontSize: 12,
                            color: "#737373"
                          }
                        ]}
                      >
                        Select Hour's (
                        <Text
                          style={[
                            {
                              fontSize: 12,
                              color: "#000",
                              fontWeight: "bold",
                              marginBottom: 10
                            }
                          ]}
                        >
                          {selectedHours !== "" &&
                            `${selectedHours} ${
                              selectedHours >= 2 ? "Hour(s)" : "Hour"
                            }`}
                        </Text>
                        )<Text style={{ color: "red" }}>*</Text>
                      </Text>
                    </View>
                    {/* <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    top: 10,
                  }}
                >
                  <Text style={{ fontSize: 12 }}>1 H</Text>
                  <Text style={{ fontSize: 12 }}>12 H</Text>
                </View> */}
                    <View>
                      {/* <CustomSliderFilter
                    sliderWidth={width / 1.3}
                    min={1}
                    max={12}
                    step={1}
                    type={""}
                    label={true}
                    color={"#00b359"}
                    onValueChange={(range) => {
                      setSelectedHours(range.max);

                      let date = new Date();
                      let dateEnd = new Date();

                      let nowEnd = new Date(jobState.startTime);
                      let selectedMinuteTime = nowEnd.getMinutes().toString().padStart(2, "0");
                      let selectedAPTime = nowEnd.getHours() >= 12 ? "PM" : "AM";
                      let selectedHourTime = nowEnd.getHours() === 0
                        ? 12
                        : nowEnd.getHours() > 12
                          ? nowEnd.getHours() - 12
                          : nowEnd.getHours()

                      date.setHours(
                        selectedAPTime === "AM" && selectedHourTime === 12
                          ? 0
                          : selectedAPTime === "PM" && selectedHourTime === 12
                            ? 12
                            : selectedHourTime !== 12 && selectedAPTime === "PM"
                              ? parseInt(selectedHourTime) + 12
                              : parseInt(selectedHourTime)
                      );
                      date.setMinutes(selectedMinuteTime);

                      // Add 8 hours to dateEnd based on date
                      dateEnd.setFullYear(date.getFullYear());
                      dateEnd.setMonth(date.getMonth());
                      dateEnd.setDate(date.getDate());
                      dateEnd.setHours(date.getHours() + range.max);
                      dateEnd.setMinutes(date.getMinutes());

                      // Determine if it's AM or PM
                      let meridiem = dateEnd.getHours() >= 12 ? "PM" : "AM";

                      if (selectedAPTime === "PM" && meridiem === "AM") {
                        let endDate = moment(jobState.startDate);
                        endDate.add(1, "day")
                        setJobState({
                          ...jobState,
                          startTime: date,
                          endTime: dateEnd,
                          endDate: endDate,
                        });
                      } else {
                        setJobState({
                          ...jobState,
                          startTime: date,
                          endTime: dateEnd,
                          endDate: jobState.startDate,
                        });
                      }
                    }}
                    valueMin={0}
                    valueMax={parseInt(selectedHours)}
                    minDrag={false}
                  /> */}
                      <Text
                        style={{
                          fontSize: 12,
                          color: "#737373",
                          marginLeft: 10,
                          marginBottom: 2
                        }}
                      >
                        Duration
                      </Text>
                      <CustomSliderFilterDropDown
                        sliderWidth={10}
                        min={1}
                        max={12}
                        step={1}
                        type={""}
                        label={true}
                        color={"#737373"}
                        onValueChange={(range) => {
                          setSelectedHours(range.max);

                          let date = new Date();
                          let dateEnd = new Date();

                          let nowEnd = new Date(jobState.startTime);
                          let selectedMinuteTime = nowEnd
                            .getMinutes()
                            .toString()
                            .padStart(2, "0");
                          let selectedAPTime =
                            nowEnd.getHours() >= 12 ? "PM" : "AM";
                          let selectedHourTime =
                            nowEnd.getHours() === 0
                              ? 12
                              : nowEnd.getHours() > 12
                              ? nowEnd.getHours() - 12
                              : nowEnd.getHours();

                          date.setHours(
                            selectedAPTime === "AM" && selectedHourTime === 12
                              ? 0
                              : selectedAPTime === "PM" &&
                                selectedHourTime === 12
                              ? 12
                              : selectedHourTime !== 12 &&
                                selectedAPTime === "PM"
                              ? parseInt(selectedHourTime) + 12
                              : parseInt(selectedHourTime)
                          );
                          date.setMinutes(selectedMinuteTime);

                          // Add 8 hours to dateEnd based on date
                          dateEnd.setFullYear(date.getFullYear());
                          dateEnd.setMonth(date.getMonth());
                          dateEnd.setDate(date.getDate());
                          dateEnd.setHours(date.getHours() + range.max);
                          dateEnd.setMinutes(date.getMinutes());

                          // Determine if it's AM or PM
                          let meridiem = dateEnd.getHours() >= 12 ? "PM" : "AM";

                          if (selectedAPTime === "PM" && meridiem === "AM") {
                            let endDate = moment(jobState.startDate);
                            endDate.add(1, "day");
                            setJobState({
                              ...jobState,
                              startTime: date,
                              endTime: dateEnd,
                              endDate: endDate
                            });
                          } else {
                            setJobState({
                              ...jobState,
                              startTime: date,
                              endTime: dateEnd,
                              endDate: jobState.startDate
                            });
                          }
                        }}
                        valueMin={0}
                        valueMax={parseInt(selectedHours)}
                        minDrag={false}
                        dType={"time"}
                      />
                    </View>
                  </View>
                ))}

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: 10,
                  marginHorizontal: 20
                }}
              >
                <View
                  style={{
                    flex: 1
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#737373"
                    }}
                  >
                    {/* End Date <Text style={{ color: "red" }}>*</Text> */}
                    End Date
                  </Text>
                  <TouchableOpacity
                    // onPress={() => setShowCalendarEnd(true)}
                    style={{
                      borderWidth: 1,
                      borderColor:
                        error &&
                        convertDate(jobState.endDate) <
                          convertDate(jobState.startDate)
                          ? "red"
                          : "#e6e6e6",
                      backgroundColor: "#f2f2f2",
                      borderRadius: 10,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: 10,
                      marginTop: 10,
                      marginRight: 5
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        color: "#737373"
                      }}
                    >
                      {moment(jobState.endDate).format("MM-DD-YYYY")}
                    </Text>
                    <AntDesign name="calendar" size={20} color="#808080" />
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    flex: 1
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#737373"
                    }}
                  >
                    {/* End Time <Text style={{ color: "red" }}>*</Text> */}
                    End Time
                  </Text>
                  <TouchableOpacity
                    // onPress={() => setShowTimeEndWeb(true)}
                    style={{
                      flex: 1,
                      marginLeft: 5,
                      borderWidth: 1,
                      borderColor: "#e6e6e6",
                      backgroundColor: "#f2f2f2",
                      borderRadius: 10,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: 10,
                      marginTop: 10
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        color: "#737373"
                      }}
                    >
                      {moment(jobState.endTime).format("hh:mm a")}
                    </Text>
                    <MaterialIcons
                      name="access-time"
                      size={20}
                      color="#808080"
                    />
                  </TouchableOpacity>
                </View>
              </View>

              <View
                style={{
                  marginHorizontal: 20,
                  marginTop: 10
                }}
              >
                <Text
                  style={[
                    {
                      fontSize: 12,
                      color: "#737373"
                    }
                  ]}
                >
                  Break
                  <Text style={{ color: "red" }}>*</Text>
                </Text>
                <View
                  style={{
                    marginTop: 10,
                    height: 40
                  }}
                >
                  <TouchableOpacity
                    style={{
                      borderWidth: 0.5,
                      borderColor:
                        error && jobState.break === "" ? "red" : "#e6e6e6",
                      backgroundColor: "#f2f2f2",
                      borderRadius: 10,
                      elevation: 1,
                      height: 40,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingHorizontal: 10
                    }}
                    onPress={() => setShowBreak(true)}
                  >
                    <Text
                      style={[
                        {
                          fontSize: 12,
                          color: "#737373"
                        }
                      ]}
                    >
                      {jobState.break === "" ? "Select Break" : jobState.break}
                    </Text>
                    <MaterialIcons
                      name={"keyboard-arrow-down"}
                      size={24}
                      color="#808080"
                    />
                  </TouchableOpacity>
                </View>
              </View>

              {/*  <View
               style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 10,
              marginHorizontal: 20,
            }}
          >
         <CustomInput
              required={false}
              label={"Customer Name"}
              onChangeText={(text) =>
                setJobState({ ...jobState, customerName: text })
              }
              keyboardType={"default"}
              placeholder={"Enter Customer Name"}
              value={jobState.customerName}
              clearValue={() =>
                setJobState({ ...jobState, customerName: "" })
              }
              viewStyle={{
                height: 40,
                backgroundColor: "#f2f2f2",
                borderRadius: 10,
                elevation: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderWidth: 0.5,
                borderColor: "#e6e6e6",
                paddingRight: 10,
                justifyContent: "space-between",
                marginTop: 0,
              }}
              textInputStyle={{
                height: 40,
                borderRadius: 10,
                paddingLeft: 10,
                paddingRight: 5,
                fontSize: 12,
              }}
              iconStyle={{
                height: 40,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            /> 
            </View> */}

              <View
                style={{
                  marginHorizontal: 20,
                  marginTop: 10
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <Text
                    style={{
                      marginBottom: 5,
                      fontSize: 12,
                      color: "#737373"
                    }}
                  >
                    Customer Name
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center"
                    }}
                  >
                    <Text
                      style={{
                        marginBottom: 5,
                        fontSize: 12,
                        color: "#737373"
                      }}
                    >
                      After Assignment
                    </Text>
                    <CustomToggle
                      state={jobState.customerVisibility}
                      onValueChange={(value) =>
                        setJobState({ ...jobState, customerVisibility: value })
                      }
                    />
                  </View>
                </View>

                <TouchableOpacity
                  onPress={() => setPatientView(true)}
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: 10,
                    elevation: 1,
                    height: 40,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    paddingRight: 10,
                    justifyContent: "space-between"
                  }}
                >
                  <Text
                    style={{
                      color: "#737373",
                      paddingLeft: 5,
                      fontSize: 12
                    }}
                  >
                    {jobState.customerName === ""
                      ? "Select Customer"
                      : jobState?.customerName}
                  </Text>
                </TouchableOpacity>
              </View>

              <View style={{ marginHorizontal: 18, marginTop: 10 }}>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center"
                  }}
                >
                  <Text
                    style={[
                      {
                        fontSize: 12,
                        color: "#737373"
                      }
                    ]}
                  >
                    Address
                  </Text>
                  <Text style={{ color: "red" }}>*</Text>
                </View>
                <View
                  style={{
                    marginTop: 10
                    // height: 40,
                  }}
                >
                  <TouchableOpacity
                    style={{
                      borderWidth: 0.5,
                      borderColor:
                        error && jobState.fullAddress === ""
                          ? "red"
                          : "#e6e6e6",
                      backgroundColor: "#f2f2f2",
                      borderRadius: 10,
                      elevation: 1,
                      // height: 40,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingHorizontal: 10,
                      paddingVertical: 10
                    }}
                    onPress={() => {
                      Platform.OS === "web"
                        ? setShowAddressWeb(true)
                        : setShowAddress(true);
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        color: "#737373"
                      }}
                    >
                      {jobState.fullAddress !== ""
                        ? jobState.fullAddress
                        : "Enter Address"}
                    </Text>
                    {jobState.fullAddress === "" && (
                      <MaterialIcons name={"add"} size={24} color="#808080" />
                    )}
                  </TouchableOpacity>
                </View>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: 10,
                  marginHorizontal: 10
                }}
              >
                <CustomInput
                  label={"Unit"}
                  required={false}
                  onChangeText={(text) =>
                    setJobState({ ...jobState, unit: text })
                  }
                  keyboardType={"default"}
                  placeholder={"Unit"}
                  value={jobState.unit}
                  clearValue={() => setJobState({ ...jobState, unit: "" })}
                  labelStyle={{
                    marginHorizontal: 10
                  }}
                  viewStyle={{
                    height: 40,
                    backgroundColor: "#f2f2f2",
                    borderRadius: 10,
                    elevation: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    marginHorizontal: 10,
                    paddingRight: 10,
                    justifyContent: "space-between"
                  }}
                  textInputStyle={{
                    height: 40,
                    borderRadius: 10,
                    paddingLeft: 10,
                    fontSize: 12
                  }}
                  iconStyle={{
                    height: 40,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                  }}
                />
                <CustomInput
                  label={"Floor"}
                  required={false}
                  onChangeText={(text) =>
                    setJobState({ ...jobState, floor: text })
                  }
                  keyboardType={"numbers-and-punctuation"}
                  placeholder={"Floor"}
                  value={jobState.floor}
                  clearValue={() => setJobState({ ...jobState, floor: "" })}
                  labelStyle={{
                    marginHorizontal: 10
                  }}
                  viewStyle={{
                    height: 40,
                    backgroundColor: "#f2f2f2",
                    borderRadius: 10,
                    elevation: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    marginHorizontal: 10,
                    paddingRight: 10,
                    justifyContent: "space-between"
                  }}
                  textInputStyle={{
                    height: 40,
                    borderRadius: 10,
                    paddingLeft: 10,
                    fontSize: 12
                  }}
                  iconStyle={{
                    height: 40,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                  }}
                />
              </View>

              <View
                style={{
                  justifyContent: "space-between",
                  flexDirection: "row",
                  paddingHorizontal: 10,
                  height: 42,
                  elevation: 1,
                  marginHorizontal: 20,
                  alignItems: "center",
                  backgroundColor: "#f2f2f2",
                  borderWidth: 1,
                  borderColor: "#e6e6e6",
                  borderRadius: 10,
                  marginTop: 12
                }}
              >
                <Text
                  style={{
                    fontSize: 12,
                    color: "#737373"
                  }}
                >
                  Base Rate
                </Text>
                <CustomToggle
                  state={jobState.baseRateVisibility}
                  onValueChange={(value) => {
                    value
                      ? setJobState({ ...jobState, baseRateVisibility: value })
                      : setJobState({ ...jobState, baseRate: "" });

                    setJobState({ ...jobState, baseRateVisibility: value });
                  }}
                />
              </View>

              <View
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 12,
                  marginHorizontal: 20
                }}
              >
                {jobState.baseRateVisibility && (
                  <CustomInput
                    required={true}
                    label={"Base Rate"}
                    onChangeText={(text) =>
                      setJobState({ ...jobState, baseRate: text })
                    }
                    keyboardType={"numbers-and-punctuation"}
                    placeholder={"Rate $"}
                    value={jobState.baseRate}
                    clearValue={() =>
                      setJobState({ ...jobState, baseRate: "" })
                    }
                    viewStyle={{
                      height: 40,
                      backgroundColor: "#f2f2f2",
                      borderRadius: 10,
                      elevation: 1,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      borderWidth: 0.5,
                      borderColor:
                        error && jobState.baseRate === "" ? "red" : "#e6e6e6",
                      paddingRight: 10,
                      justifyContent: "space-between",
                      marginTop: 0
                    }}
                    textInputStyle={{
                      height: 40,
                      borderRadius: 10,
                      paddingLeft: 10,
                      paddingRight: 5,
                      fontSize: 12
                    }}
                    iconStyle={{
                      height: 40,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center"
                    }}
                  />
                )}
              </View>

              {/* <View
            style={{
              marginHorizontal: 20,
              marginTop: 10,
            }}
          >
            <Text
              style={[
                {
                  fontSize: 12,
                  color: "#737373",
                },
              ]}
            >
              Duration
              <Text style={{ color: "red" }}>*</Text>
            </Text>
            <View
              style={{
                marginTop: 10,
                height: 40,
              }}
            >
              <TouchableOpacity
                style={{
                  borderWidth: 0.5,
                  borderColor: "#e6e6e6",
                  backgroundColor: "#f2f2f2",
                  borderRadius: 10,
                  elevation: 1,
                  height: 40,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingHorizontal: 10,
                }}
                onPress={() => setShowDuration(true)}
              >
                <Text
                  style={[
                    {
                      fontSize: 12,
                      color: "#737373",
                    },
                  ]}
                >
                  {jobState.jobDuration === ""
                    ? "Select Duration"
                    : jobState.jobDuration}
                </Text>
                <MaterialIcons
                  name={"keyboard-arrow-down"}
                  size={24}
                  color="#808080"
                />
              </TouchableOpacity>
            </View>
          </View> */}

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: jobState.baseRateVisibility ? 10 : 0,
                  marginHorizontal: 20
                }}
              >
                <CustomInput
                  label={"Notes"}
                  onChangeText={(text) =>
                    setJobState({ ...jobState, notes: text })
                  }
                  keyboardType={"default"}
                  placeholder={"Add Notes"}
                  value={jobState.notes}
                  clearValue={() => setJobState({ ...jobState, notes: "" })}
                  viewStyle={{
                    height: 40,
                    backgroundColor: "#f2f2f2",
                    borderRadius: 10,
                    elevation: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    paddingRight: 10,
                    justifyContent: "space-between",
                    marginTop: 0
                  }}
                  textInputStyle={{
                    height: 40,
                    borderRadius: 10,
                    paddingLeft: 10,
                    paddingRight: 5,
                    fontSize: 12
                  }}
                  iconStyle={{
                    height: 40,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                  }}
                />
              </View>

              {/*  Need to update select bid and cancel bid functionality*/}
              {!jobState?.autoAcceptedEnable &&
                !directAssignment &&
                jobState.baseRateVisibility && (
                  <View
                    style={{
                      justifyContent: "space-between",
                      flexDirection: "row",
                      paddingHorizontal: 10,
                      height: 42,
                      elevation: 1,
                      marginHorizontal: 20,
                      alignItems: "center",
                      backgroundColor: "#f2f2f2",
                      borderWidth: 1,
                      borderColor: "#e6e6e6",
                      borderRadius: 10,
                      marginTop: 12
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 12,
                        color: "#737373"
                      }}
                    >
                      Enable Bid
                    </Text>
                    <CustomToggle
                      state={jobState.enableBid}
                      onValueChange={(value) =>
                        setJobState({ ...jobState, enableBid: value })
                      }
                    />
                  </View>
                )}

              {jobState?.enableBid && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: 10,
                    marginHorizontal: 20
                  }}
                >
                  <CustomInput
                    required={true}
                    label={"Bid Max Amount"}
                    onChangeText={(text) =>
                      setJobState({ ...jobState, maxBidAmount: text })
                    }
                    keyboardType={"numbers-and-punctuation"}
                    placeholder={"Rate $"}
                    value={jobState.maxBidAmount}
                    clearValue={() =>
                      setJobState({ ...jobState, maxBidAmount: "" })
                    }
                    viewStyle={{
                      height: 40,
                      backgroundColor: "#f2f2f2",
                      borderRadius: 10,
                      elevation: 1,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      borderWidth: 0.5,
                      borderColor:
                        error && jobState.maxBidAmount === ""
                          ? "red"
                          : "#e6e6e6",
                      paddingRight: 10,
                      justifyContent: "space-between",
                      marginTop: 0
                    }}
                    textInputStyle={{
                      height: 40,
                      borderRadius: 10,
                      paddingLeft: 10,
                      paddingRight: 5,
                      fontSize: 12
                    }}
                    iconStyle={{
                      height: 40,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center"
                    }}
                  />
                </View>
              )}

              {!jobState?.enableBid && !directAssignment && (
                <View
                  style={{
                    justifyContent: "space-between",
                    flexDirection: "row",
                    paddingHorizontal: 10,
                    marginHorizontal: 20,
                    height: 42,
                    elevation: 1,
                    alignItems: "center",
                    backgroundColor: "#f2f2f2",
                    borderWidth: 1,
                    borderColor: "#e6e6e6",
                    borderRadius: 10,
                    marginTop: 12
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#737373"
                    }}
                  >
                    Enable Auto Assigned
                  </Text>
                  <CustomToggle
                    state={jobState.autoAcceptedEnable}
                    onValueChange={(value) =>
                      setJobState({ ...jobState, autoAcceptedEnable: value })
                    }
                  />
                </View>
              )}

              {buttonType === "Save Template" && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: 10,
                    marginHorizontal: 20
                  }}
                >
                  <CustomInput
                    required={true}
                    label={"Template Name"}
                    onChangeText={(text) => setJobTemplateName(text)}
                    keyboardType={"default"}
                    placeholder={"Enter Template Name"}
                    value={jobTemplateName}
                    clearValue={() => setJobTemplateName("")}
                    viewStyle={{
                      height: 40,
                      backgroundColor: "#f2f2f2",
                      borderRadius: 10,
                      elevation: 1,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      borderWidth: 0.5,
                      borderColor:
                        error && jobTemplateName === "" ? "red" : "#e6e6e6",
                      paddingRight: 10,
                      justifyContent: "space-between",
                      marginTop: 0
                    }}
                    textInputStyle={{
                      height: 40,
                      borderRadius: 10,
                      paddingLeft: 10,
                      paddingRight: 5,
                      fontSize: 12
                    }}
                    iconStyle={{
                      height: 40,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center"
                    }}
                  />
                </View>
              )}

              {
                // !jobState?.enableBid &&
                // !jobState?.autoAcceptedEnable &&
                !directAssignment && buttonType !== "Save Template" && (
                  <View
                    style={{
                      justifyContent: "space-between",
                      flexDirection: "row",
                      paddingHorizontal: 10,
                      marginHorizontal: 20,
                      height: 42,
                      elevation: 1,
                      alignItems: "center",
                      backgroundColor: "#f2f2f2",
                      borderWidth: 1,
                      borderColor: "#e6e6e6",
                      borderRadius: 10,
                      marginTop: 12
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 12,
                        color: "#737373"
                      }}
                    >
                      Slots
                    </Text>
                    <CustomToggle
                      state={slotsEnable}
                      onValueChange={(value) => {
                        licenseTypeArr?.length === 0
                          ? Platform.OS === "web"
                            ? alert("Select license type")
                            : Alert.alert("Select license type")
                          : jobState?.fullAddress === ""
                          ? Platform.OS === "web"
                            ? alert("Enter address")
                            : Alert.alert("Enter address")
                          : buttonType === "PostJob"
                          ? Platform.OS === "web"
                            ? alert("Uncheck the Post Job and Try")
                            : Alert.alert("Uncheck the Post Job and Try")
                          : setSlotsEnable(value);
                      }}
                    />
                  </View>
                )
              }

              {!jobState?.enableBid &&
                !jobState?.autoAcceptedEnable &&
                buttonType !== "Save Template" &&
                !slotsEnable && (
                  <View
                    style={{
                      justifyContent: "space-between",
                      flexDirection: "row",
                      paddingHorizontal: 10,
                      marginHorizontal: 20,
                      height: 42,
                      elevation: 1,
                      alignItems: "center",
                      backgroundColor: "#f2f2f2",
                      borderWidth: 1,
                      borderColor: "#e6e6e6",
                      borderRadius: 10,
                      marginTop: 12
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 12,
                        color: "#737373"
                      }}
                    >
                      Direct Assignment
                    </Text>
                    <CustomToggle
                      state={directAssignment}
                      onValueChange={(value) => {
                        licenseTypeArr?.length === 0
                          ? Platform.OS === "web"
                            ? alert("Select license type")
                            : Alert.alert("Select license type")
                          : jobState?.fullAddress === ""
                          ? Platform.OS === "web"
                            ? alert("Enter address")
                            : Alert.alert("Enter address")
                          : buttonType === "PostJob"
                          ? Platform.OS === "web"
                            ? alert("Uncheck the Post Job and Try")
                            : Alert.alert("Uncheck the Post Job and Try")
                          : setDirectAssignment(value);
                        setJobFinalSelectionNurseId("");
                      }}
                    />
                  </View>
                )}

              {
                // buttonType === "PostJob" &&
                !directAssignment && slotsEnable && (
                  <View
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      marginHorizontal: 10,
                      marginTop: 10
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        marginHorizontal: 20
                      }}
                    >
                      <Text
                        style={[
                          {
                            fontSize: 12,
                            color: "#737373"
                          }
                        ]}
                      >
                        Select Slots (
                        <Text
                          style={[
                            {
                              fontSize: 12,
                              color: "#000",
                              fontWeight: "bold",
                              marginBottom: 10
                            }
                          ]}
                        >
                          {selectedSlots !== "" &&
                            `${selectedSlots} ${
                              selectedSlots >= 2 ? "Slots(s)" : "Slots"
                            }`}
                        </Text>
                        )<Text style={{ color: "red" }}>*</Text>
                      </Text>
                    </View>
                    {/* <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    top: 10,
                  }}
                >
                  <Text style={{ fontSize: 12 }}>1 S</Text>
                  <Text style={{ fontSize: 12 }}>8 S</Text>
                </View> */}
                    <View style={{}}>
                      {/* <CustomSliderFilter
                    sliderWidth={width / 1.3}
                    min={1}
                    max={8}
                    step={1}
                    type={""}
                    label={true}
                    color={"#00b359"}
                    onValueChange={(range) => {
                      seeSelectedSlots(range.max);
                    }}
                    valueMin={0}
                    valueMax={parseInt(selectedSlots)}
                    minDrag={false}
                  /> */}
                      <Text
                        style={{
                          fontSize: 12,
                          color: "#737373",
                          marginLeft: 10,
                          marginBottom: 2
                        }}
                      >
                        Time Period
                      </Text>
                      <CustomSliderFilterDropDown
                        sliderWidth={10}
                        min={1}
                        max={8}
                        step={1}
                        type={""}
                        label={true}
                        color={"#737373"}
                        onValueChange={(range) => {
                          seeSelectedSlots(range.max);
                        }}
                        valueMin={0}
                        valueMax={parseInt(selectedSlots)}
                        minDrag={false}
                        dType={"slots"}
                      />
                    </View>
                  </View>
                )
              }

              {directAssignment && (
                <View style={{ marginTop: 10 }}>
                  <Text
                    style={{
                      marginHorizontal: 20,
                      fontSize: 12,
                      color: "#888"
                    }}
                  >
                    Select a nurse to check availability.
                  </Text>
                  <View
                    style={{
                      marginTop: 10
                    }}
                  >
                    {availableNurseLoading ? (
                      <Text
                        style={{
                          fontWeight: "500",
                          fontSize: 12,
                          textAlign: "center"
                        }}
                      >
                        Loading...
                      </Text>
                    ) : availableNurse?.length === 0 ? (
                      <Text
                        style={{
                          fontWeight: "500",
                          fontSize: 12,
                          textAlign: "center"
                        }}
                      >
                        No matches found
                      </Text>
                    ) : (
                      availableNurse.map((item, index) => {
                        return (
                          <UserJobCard
                            key={index}
                            element={item}
                            jobFinalSelectionNurseId={jobFinalSelectionNurseId}
                            setWeekNurseDirectAssignmentAvailabilityData={
                              setWeekNurseDirectAssignmentAvailabilityData
                            }
                            setJobFinalSelectionNurseId={
                              setJobFinalSelectionNurseId
                            }
                            buttonType={buttonType}
                          />
                        );
                      })
                    )}
                  </View>
                  <Text
                    style={{
                      marginHorizontal: 20,
                      fontSize: 12,
                      color: "#888",
                      marginTop: 10
                    }}
                  >
                    Select Post Job before submit.
                  </Text>
                </View>
              )}

              <View
                style={{
                  marginHorizontal: 20,
                  marginTop: 10,
                  flex: 1
                }}
              >
                <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                  {buttonType !== "Publish Job" && (
                    <TouchableOpacity
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        marginVertical: 10
                      }}
                      onPress={() =>
                        buttonType === "PostJob"
                          ? repeatClear()
                          : // : convertDate(jobState.endDate) !==
                          //   convertDate(jobState.startDate)
                          // ? Alert.alert(
                          //     `Repeat Job Post feature doesn’t support overnight ${jobState.jobType}s currently.`
                          //   )
                          directAssignment && jobFinalSelectionNurseId === ""
                          ? Platform.OS === "web"
                            ? alert("Select a nurse")
                            : Alert.alert("Select a nurse")
                          : setButtonType("PostJob")
                      }
                    >
                      <TouchableOpacity
                        onPress={() =>
                          buttonType === "PostJob"
                            ? repeatClear()
                            : // : convertDate(jobState.endDate) !==
                            //   convertDate(jobState.startDate)
                            // ? Alert.alert(
                            //     `Repeat Job Post feature doesn’t support overnight ${jobState.jobType}s currently.`
                            //   )
                            directAssignment && jobFinalSelectionNurseId === ""
                            ? Platform.OS === "web"
                              ? alert("Select a nurse")
                              : Alert.alert("Select a nurse")
                            : setButtonType("PostJob")
                        }
                        style={{
                          height: 20,
                          width: 20,
                          borderRadius: 5,
                          borderWidth: 1,
                          borderColor: "#2775BD",
                          backgroundColor: "#2775BD",
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        {buttonType === "PostJob" ? (
                          <MaterialIcons name="done" size={15} color="#fff" />
                        ) : (
                          <View style={{ height: 0, width: 0 }} />
                        )}
                      </TouchableOpacity>
                      <Text style={{ marginLeft: 5 }}>Post Job</Text>
                    </TouchableOpacity>
                  )}

                  {!directAssignment && !slotsEnable && (
                    <TouchableOpacity
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        marginVertical: 10
                      }}
                      onPress={() =>
                        buttonType === "Save Template"
                          ? setButtonType("")
                          : setButtonType("Save Template")
                      }
                    >
                      <TouchableOpacity
                        onPress={() =>
                          buttonType === "Save Template"
                            ? setButtonType("")
                            : setButtonType("Save Template")
                        }
                        style={{
                          height: 20,
                          width: 20,
                          borderRadius: 5,
                          borderWidth: 1,
                          borderColor: "#2775BD",
                          backgroundColor: "#2775BD",
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        {buttonType === "Save Template" ? (
                          <MaterialIcons name="done" size={15} color="#fff" />
                        ) : (
                          <View style={{ height: 0, width: 0 }} />
                        )}
                      </TouchableOpacity>
                      <Text style={{ marginLeft: 5 }}>Save Template</Text>
                    </TouchableOpacity>
                  )}
                </View>
              </View>

              {buttonType === "PostJob" &&
                !directAssignment &&
                !slotsEnable && (
                  <View>
                    <View
                      style={{
                        flexDirection: "row",
                        flexWrap: "wrap",
                        marginHorizontal: 20
                      }}
                    >
                      {generateOneWeekDatesForJobPostUId().map(
                        (items, index) => {
                          return (
                            <View key={index}>
                              <Text
                                style={{
                                  textDecorationLine: "underline",
                                  fontSize: 14,
                                  fontWeight: "600"
                                }}
                              >
                                Week - {index + 1}
                              </Text>
                              {items?.currentWeek?.map((item, index) => {
                                const formattedDate =
                                  moment(item).format("ddd (MMM-DD)");
                                let d = moment(item).format("MM-DD-YYYY");
                                let t = moment(jobState?.startTime).format(
                                  "hh:mm a"
                                );
                                const combinedDateTime = moment(
                                  `${d} ${t}`,
                                  "MM-DD-YYYY hh:mm a"
                                );

                                // console.log(
                                //   combinedDateTime.format("MM-DD-YYYY hh:mm a"),
                                //   currentDate.format("MM-DD-YYYY hh:mm a"),
                                //   combinedDateTime.isAfter(currentDate));

                                return (
                                  <TouchableOpacity
                                    key={index}
                                    style={{
                                      flexDirection: "row",
                                      marginVertical: 10,
                                      marginHorizontal: 5
                                    }}
                                    onPress={() =>
                                      moment(item).format("YYYY-MM-DD") <
                                      currentDate.format("YYYY-MM-DD")
                                        ? null
                                        : !combinedDateTime.isAfter(currentDate)
                                        ? null
                                        : handleDayClick(
                                            moment(item).format("YYYY-MM-DD")
                                          )
                                    }
                                  >
                                    <View
                                      style={{
                                        height: 20,
                                        width: 20,
                                        borderRadius: 5,
                                        borderWidth: 1,
                                        borderColor: "#b3b3b3",
                                        alignItems: "center",
                                        justifyContent: "center"
                                      }}
                                    >
                                      {selectedDays.includes(
                                        moment(item).format("YYYY-MM-DD")
                                      ) ? (
                                        <MaterialIcons
                                          name="done"
                                          size={15}
                                          color="#2775BD"
                                        />
                                      ) : (
                                        <View style={{ height: 0, width: 0 }} />
                                      )}
                                    </View>
                                    <Text
                                      style={{
                                        marginLeft: 5,
                                        color:
                                          moment(item).format("YYYY-MM-DD") <
                                          currentDate.format("YYYY-MM-DD")
                                            ? "red"
                                            : !combinedDateTime.isAfter(
                                                currentDate
                                              )
                                            ? "red"
                                            : "#000"
                                      }}
                                    >
                                      {formattedDate}
                                    </Text>
                                  </TouchableOpacity>
                                );
                              })}
                            </View>
                          );
                        }
                      )}
                    </View>
                    <View
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginHorizontal: 10
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          marginHorizontal: 20
                        }}
                      >
                        <Text
                          style={[
                            {
                              fontSize: 12,
                              color: "#737373"
                            }
                          ]}
                        >
                          Select Weeks (
                          <Text
                            style={[
                              {
                                fontSize: 12,
                                color: "#000",
                                fontWeight: "bold",
                                marginBottom: 10
                              }
                            ]}
                          >
                            {selectedWeeks !== "" &&
                              `${selectedWeeks} ${
                                selectedWeeks >= 2 ? "week(s)" : "week"
                              }`}
                          </Text>
                          )<Text style={{ color: "red" }}>*</Text>
                        </Text>
                      </View>
                      {/* <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      top: 10,
                    }}
                  >
                    <Text style={{ fontSize: 12 }}>1 w</Text>
                    <Text style={{ fontSize: 12 }}>8 w</Text>
                  </View> */}
                      <View style={{}}>
                        {/* <CustomSliderFilter
                      sliderWidth={width / 1.3}
                      min={1}
                      max={8}
                      step={1}
                      type={""}
                      label={true}
                      color={"#00b359"}
                      onValueChange={(range) => {
                        setSelectedWeeks(range.max);
                        // setState({
                        //   ...state,
                        //   distancePreferenceMinValue: range.min,
                        //   distancePreferenceMaxValue: range.max,
                        // });
                      }}
                      valueMin={0}
                      valueMax={parseInt(selectedWeeks)}
                      minDrag={false}
                    /> */}
                        <Text
                          style={{
                            fontSize: 12,
                            color: "#737373",
                            marginLeft: 10,
                            marginBottom: 2
                          }}
                        >
                          Slots
                        </Text>
                        <CustomSliderFilterDropDown
                          sliderWidth={10}
                          min={1}
                          max={8}
                          step={1}
                          type={""}
                          label={true}
                          color={"#737373"}
                          onValueChange={(range) => {
                            setSelectedWeeks(range.max);
                            // setState({
                            //   ...state,
                            //   distancePreferenceMinValue: range.min,
                            //   distancePreferenceMaxValue: range.max,
                            // });
                          }}
                          valueMin={0}
                          valueMax={parseInt(selectedWeeks)}
                          minDrag={false}
                          dType={"week"}
                        />
                      </View>
                    </View>
                  </View>
                )}

              {selectedDays.length > 0 &&
                !directAssignment &&
                buttonType === "PostJob" &&
                !slotsEnable && (
                  <View
                    style={{
                      marginTop: 10,
                      marginHorizontal: 20
                    }}
                  >
                    <Text>
                      <Text>
                        Upcoming dates for selected days for {selectedWeeks}{" "}
                        {selectedWeeks >= 2 ? "week(s)" : "week"}{" "}
                        {moment(
                          generateOneWeekDatesForJobPostUId()[0]?.currentWeek[0]
                        ).format("dddd, MMMM Do YYYY")}
                        :{" "}
                      </Text>
                    </Text>
                    <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                      {selectedDays?.map((date, index) => {
                        return (
                          <TouchableOpacity
                            key={index}
                            style={{
                              borderWidth: 1,
                              borderColor: "#e6e6e6",
                              backgroundColor: "#f2f2f2",
                              borderRadius: 10,
                              flexDirection: "row",
                              alignItems: "center",
                              paddingHorizontal: 10,
                              paddingVertical: 5,
                              marginRight: 10,
                              marginTop: 10
                            }}
                            onPress={() =>
                              handleDayClick(moment(date).format("YYYY-MM-DD"))
                            }
                          >
                            <Text
                              key={date}
                              style={{
                                color: "green",
                                fontWeight: "600"
                              }}
                            >
                              {moment(date).format("ddd (MMM-DD)")}
                            </Text>
                            <MaterialIcons
                              name={"close"}
                              size={22}
                              color="red"
                            />
                          </TouchableOpacity>
                        );
                      })}
                    </View>
                  </View>
                )}

              {buttonType === "PostJob" &&
                directAssignment &&
                jobFinalSelectionNurseId !== "" && (
                  <View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center"
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          marginHorizontal: 20,
                          marginTop: 10
                        }}
                      >
                        <Entypo name="dot-single" size={24} color={"green"} />
                        <Text
                          style={{
                            fontWeight: "500",
                            fontSize: 12,
                            color: "green"
                          }}
                        >
                          Available
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          marginHorizontal: 20,
                          marginTop: 10
                        }}
                      >
                        <Entypo name="dot-single" size={24} color={"red"} />
                        <Text
                          style={{
                            fontWeight: "500",
                            fontSize: 12,
                            color: "red"
                          }}
                        >
                          Not-Available
                        </Text>
                      </View>
                    </View>
                    <Text
                      style={[
                        {
                          fontSize: 12,
                          color: "#737373",
                          marginVertical: 10,
                          marginHorizontal: 20
                        }
                      ]}
                    >
                      Select the date on Sunday for the week you want to
                      schedule the nurse.
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                        flexWrap: "wrap",
                        marginHorizontal: 20
                      }}
                    >
                      {weekNurseDirectAssignmentAvailabilityData.length < 1 ? (
                        <Text
                          style={{
                            fontWeight: "500",
                            fontSize: 12,
                            textAlign: "center"
                          }}
                        >
                          Loading...
                        </Text>
                      ) : (
                        weekNurseDirectAssignmentAvailabilityData.map(
                          (itemMap, index) => {
                            return (
                              <View key={index}>
                                <Text
                                  style={{
                                    textDecorationLine: "underline",
                                    fontSize: 14,
                                    fontWeight: "600"
                                  }}
                                >
                                  Week - {index + 1}
                                </Text>
                                {itemMap?.map((item, index) => {
                                  let d = moment(item?.date).format(
                                    "MM-DD-YYYY"
                                  );
                                  let t = moment(jobState?.startTime).format(
                                    "hh:mm a"
                                  );
                                  const combinedDateTime = moment(
                                    `${d} ${t}`,
                                    "MM-DD-YYYY hh:mm a"
                                  );
                                  return (
                                    <TouchableOpacity
                                      key={index}
                                      style={{
                                        flexDirection: "row",
                                        // width: width / 4,
                                        flexWrap: "wrap",
                                        alignItems: "center",
                                        marginVertical: 10
                                      }}
                                      onPress={() =>
                                        currentDate.format("YYYY-MM-DD") <=
                                          item?.date && !item?.availableTime
                                          ? Platform.OS === "web"
                                            ? alert(
                                                `Nurse is not available on this ${item?.notAvailableTime} time`
                                              )
                                            : Alert.alert(
                                                `Nurse is not available on this ${item?.notAvailableTime} time`
                                              )
                                          : !combinedDateTime.isAfter(
                                              currentDate
                                            )
                                          ? null
                                          : handleDayDirectAssignmentClick(
                                              item?.date
                                            )
                                      }
                                    >
                                      <View
                                        style={{
                                          height: 20,
                                          width: 20,
                                          borderRadius: 5,
                                          borderWidth: 1,
                                          borderColor:
                                            currentDate.format("YYYY-MM-DD") <=
                                              item?.date && !item?.availableTime
                                              ? "red"
                                              : !combinedDateTime.isAfter(
                                                  currentDate
                                                )
                                              ? "red"
                                              : "green",
                                          backgroundColor:
                                            currentDate.format("YYYY-MM-DD") <=
                                              item?.date && !item?.availableTime
                                              ? "red"
                                              : !combinedDateTime.isAfter(
                                                  currentDate
                                                )
                                              ? "red"
                                              : "green",
                                          alignItems: "center",
                                          justifyContent: "center"
                                        }}
                                      >
                                        {nurseDirectAssignmentSelectedDates.includes(
                                          item?.date
                                        ) ? (
                                          <MaterialIcons
                                            name="done"
                                            size={15}
                                            color="#fff"
                                          />
                                        ) : (
                                          <View
                                            style={{ height: 0, width: 0 }}
                                          />
                                        )}
                                      </View>
                                      <Text
                                        style={{
                                          marginLeft: 5,
                                          color:
                                            currentDate.format("YYYY-MM-DD") <=
                                              item?.date && !item?.availableTime
                                              ? "red"
                                              : !combinedDateTime.isAfter(
                                                  currentDate
                                                )
                                              ? "red"
                                              : "green"
                                        }}
                                      >
                                        {moment(item?.date).format(
                                          "ddd (MMM-DD)"
                                        )}
                                      </Text>
                                      <Entypo
                                        name="dot-single"
                                        size={24}
                                        color={
                                          currentDate.format("YYYY-MM-DD") <=
                                            item?.date && !item?.availableTime
                                            ? "red"
                                            : !combinedDateTime.isAfter(
                                                currentDate
                                              )
                                            ? "red"
                                            : "green"
                                        }
                                      />
                                    </TouchableOpacity>
                                  );
                                })}
                              </View>
                            );
                          }
                        )
                      )}
                    </View>

                    <View
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginHorizontal: 10
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          marginHorizontal: 20
                        }}
                      >
                        <Text
                          style={[
                            {
                              fontSize: 12,
                              color: "#737373",
                              marginBottom: 10
                            }
                          ]}
                        >
                          Select Weeks (
                          <Text
                            style={[
                              {
                                fontSize: 12,
                                color: "#000",
                                fontWeight: "bold"
                              }
                            ]}
                          >
                            {selectedWeeks !== "" &&
                              `${selectedWeeks} ${
                                selectedWeeks >= 2 ? "week(s)" : "week"
                              }`}
                          </Text>
                          )<Text style={{ color: "red" }}>*</Text>
                        </Text>
                      </View>
                      {/* <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      top: 10,
                    }}
                  >
                    <Text style={{ fontSize: 12 }}>1 w</Text>
                    <Text style={{ fontSize: 12 }}>8 w</Text>
                  </View> */}
                      <View style={{}}>
                        {/* <CustomSliderFilter
                      sliderWidth={width / 1.3}
                      min={1}
                      max={8}
                      step={1}
                      type={""}
                      label={true}
                      color={"#00b359"}
                      onValueChange={(range) => {
                        setSelectedWeeks(range.max);
                        // setState({
                        //   ...state,
                        //   distancePreferenceMinValue: range.min,
                        //   distancePreferenceMaxValue: range.max,
                        // });
                      }}
                      valueMin={0}
                      valueMax={parseInt(selectedWeeks)}
                      minDrag={false}
                    /> */}
                        <Text
                          style={{
                            fontSize: 12,
                            color: "#737373",
                            marginLeft: 10,
                            marginBottom: 2
                          }}
                        >
                          Time Period
                        </Text>
                        <CustomSliderFilterDropDown
                          sliderWidth={10}
                          min={1}
                          max={8}
                          step={1}
                          type={""}
                          label={true}
                          color={"#737373"}
                          onValueChange={(range) => {
                            setSelectedWeeks(range.max);
                            // setState({
                            //   ...state,
                            //   distancePreferenceMinValue: range.min,
                            //   distancePreferenceMaxValue: range.max,
                            // });
                          }}
                          valueMin={0}
                          valueMax={parseInt(selectedWeeks)}
                          minDrag={false}
                          dType={"week"}
                        />
                      </View>
                    </View>

                    <View
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginTop: 10,
                        marginHorizontal: 20
                      }}
                    >
                      {/* <Text
                    style={[
                      {
                        fontSize: 12,
                        color: "#737373",
                        marginBottom: 10,
                      },
                    ]}
                  >
                    *Direct Assignment only supports one week of planning.
                  </Text> */}
                      {nurseDirectAssignmentSelectedDates?.length !== 0 && (
                        <Text>
                          <Text>
                            Upcoming dates for selected days for {selectedWeeks}{" "}
                            {selectedWeeks >= 2 ? "week(s)" : "week"}{" "}
                            {moment(
                              weekNurseDirectAssignmentAvailabilityData[0][0]
                                ?.date
                            ).format("dddd, MMMM Do YYYY")}
                            :{" "}
                          </Text>
                        </Text>
                      )}
                      <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                        {nurseDirectAssignmentSelectedDates?.map(
                          (date, index) => (
                            <TouchableOpacity
                              key={index}
                              style={{
                                borderWidth: 1,
                                borderColor: "#e6e6e6",
                                backgroundColor: "#f2f2f2",
                                borderRadius: 10,
                                flexDirection: "row",
                                alignItems: "center",
                                paddingHorizontal: 10,
                                paddingVertical: 5,
                                marginRight: 10,
                                marginTop: 10
                              }}
                              onPress={() =>
                                handleDayDirectAssignmentClick(
                                  moment(date).format("YYYY-MM-DD")
                                )
                              }
                            >
                              {/* {date.toLocaleDateString()}- */}
                              <Text
                                key={date}
                                style={{
                                  color: "green",
                                  fontWeight: "600"
                                }}
                              >
                                {/* {moment(date).format("dddd")} */}
                                {moment(date).format("ddd (MMM-DD)")}
                              </Text>
                              <MaterialIcons
                                name={"close"}
                                size={22}
                                color="red"
                              />
                            </TouchableOpacity>
                          )
                        )}
                      </View>
                    </View>
                  </View>
                )}

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: 20,
                  marginBottom: 40
                }}
              >
                <AuthButton
                  name={
                    loading
                      ? "Loading..."
                      : slotsEnable
                      ? "Submit Slots"
                      : "Submit"
                  }
                  onPress={() =>
                    loading ? null : slotsEnable ? submitSlots() : submit()
                  }
                  color={"#00b359"}
                />
              </View>
            </ScrollView>
          )}

          {showAddressWeb && Platform.OS === "web" && (
            <View
              style={{
                height: height,
                width: width,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                position: "absolute"
              }}
            >
              <AutoFillAddress
                setShowAddress={setShowAddressWeb}
                jobState={jobState}
                setJobState={setJobState}
              />
            </View>
          )}

          <Modal
            animationType="fade"
            transparent={true}
            visible={Platform.OS !== "web" && showAddress}
            onRequestClose={() => setShowAddress(false)}
          >
            <AutoFillAddress
              setShowAddress={setShowAddress}
              jobState={jobState}
              setJobState={setJobState}
            />
          </Modal>

          <Modal
            animationType="fade"
            transparent={true}
            visible={showCalendar}
            onRequestClose={() => setShowCalendar(false)}
          >
            <View
              style={{
                height: height,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)"
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 20,
                  justifyContent: "center",
                  elevation: 5,
                  borderRadius: 15,
                  borderColor: "rgba(0, 0, 0, 0.1)"
                }}
              >
                <CalendarPicker
                  onDateChange={(date) =>
                    setJobState({ ...jobState, startDate: date, endDate: date })
                  }
                  selectedDayColor="#00b359"
                  selectedDayTextColor="#FFFFFF"
                  initialDate={new Date()}
                  disabledDates={disabledDates}
                  disabledDatesTextStyle={disabledDatesTextStyle}
                />
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-end"
                  }}
                >
                  <TouchableOpacity
                    onPress={() => setShowCalendar(false)}
                    style={{
                      backgroundColor: "#00b359",
                      marginHorizontal: 20,
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff"
                      }}
                    >
                      Select
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            animationType="fade"
            transparent={true}
            visible={showCalendarEnd}
            onRequestClose={() => setShowCalendarEnd(false)}
          >
            <View
              style={{
                height: height,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)"
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 20,
                  justifyContent: "center",
                  elevation: 5,
                  borderRadius: 15,
                  borderColor: "rgba(0, 0, 0, 0.1)"
                }}
              >
                <CalendarPicker
                  onDateChange={(date) =>
                    setJobState({ ...jobState, endDate: date })
                  }
                  selectedDayColor="#00b359"
                  selectedDayTextColor="#FFFFFF"
                  initialDate={new Date()}
                  disabledDates={disabledStartDates}
                  disabledDatesTextStyle={disabledDatesTextStyle}
                />
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-end"
                  }}
                >
                  <TouchableOpacity
                    onPress={() => setShowCalendarEnd(false)}
                    style={{
                      backgroundColor: "#00b359",
                      marginHorizontal: 20,
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff"
                      }}
                    >
                      Select
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>

          <Modal
            animationType="fade"
            transparent={true}
            visible={showTimeWeb}
            onRequestClose={() => setShowTimeWeb(false)}
          >
            <View
              style={{
                height: height,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)"
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 10,
                  height: 280,
                  justifyContent: "center",
                  elevation: 5,
                  width: width / 1.5,
                  paddingHorizontal: 10,
                  borderRadius: 15,
                  borderColor: "rgba(0, 0, 0, 0.1)"
                }}
              >
                <Text style={{ fontSize: 18, marginBottom: 0 }}>
                  Select Start Time{" "}
                </Text>
                <Text style={{ fontSize: 10, marginBottom: 10 }}>
                  Select Hour and Minutes
                </Text>
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <TouchableOpacity
                    onPress={() => {
                      setShowDropdown(!showDropdown);
                      setShowDropdownMinute(false);
                      setShowDropdownAP(false);
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 25,
                        marginBottom: 10,
                        color: showDropdown ? "#00b359" : "#000"
                      }}
                    >
                      {selectedHourTime === "" ? "00" : selectedHourTime}
                    </Text>
                  </TouchableOpacity>
                  <Text style={{ fontSize: 25, marginBottom: 10 }}> : </Text>
                  <TouchableOpacity
                    onPress={() => {
                      setShowDropdownMinute(!showDropdownMinute);
                      setShowDropdown(false);
                      setShowDropdownAP(false);
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 25,
                        marginBottom: 10,
                        color: showDropdownMinute ? "#00b359" : "#000"
                      }}
                    >
                      {selectedMinuteTime === "" ? "00" : selectedMinuteTime}
                    </Text>
                  </TouchableOpacity>
                  <Text style={{ fontSize: 25, marginBottom: 10 }}> </Text>
                  <TouchableOpacity
                    onPress={() => {
                      setShowDropdownAP(!showDropdownAP);
                      setShowDropdown(false);
                      setShowDropdownMinute(false);
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 25,
                        marginBottom: 10,
                        color: showDropdownAP ? "#00b359" : "#000"
                      }}
                    >
                      {selectedAPTime === "" ? "AM" : selectedAPTime}
                    </Text>
                  </TouchableOpacity>
                </View>
                {showDropdown && (
                  <ScrollView
                    style={{
                      width: "100%",
                      maxHeight: 150,
                      overflow: "scroll",
                      borderWidth: 0.5,
                      borderRadius: 4,
                      marginBottom: 5,
                      backgroundColor: "#fff"
                    }}
                  >
                    {hour.map((item) => {
                      return (
                        <TouchableOpacity
                          key={item}
                          onPress={() => {
                            setSelectedHourTime(item < 10 ? "0" + item : item);
                            setShowDropdown(false);
                          }}
                          style={styles.dropdownItem}
                        >
                          <Text>{item < 10 ? "0" + item : item}</Text>
                        </TouchableOpacity>
                      );
                    })}
                  </ScrollView>
                )}
                {showDropdownMinute && (
                  <ScrollView
                    style={{
                      width: "100%",
                      maxHeight: 150,
                      overflow: "scroll",
                      borderWidth: 0.5,
                      borderRadius: 4,
                      marginBottom: 5,
                      backgroundColor: "#fff"
                    }}
                  >
                    <TextInput
                      style={{
                        width: "90%",
                        padding: 2,
                        paddingLeft: 5,
                        fontSize: 12,
                        color: "#737373"
                      }}
                      keyboardType={"decimal-pad"}
                      onChangeText={(text) => {
                        setSelectedMinuteTime(text);
                      }}
                      value={
                        selectedMinuteTime === "00" ? "" : selectedMinuteTime
                      }
                      placeholderTextColor="#b3b3b3"
                      placeholder={`Enter custom minutes`}
                    />
                    {minutes.map((item) => {
                      return (
                        <TouchableOpacity
                          key={item}
                          onPress={() => {
                            setSelectedMinuteTime(
                              item < 10 ? "0" + item : item
                            );
                            setShowDropdownMinute(false);
                          }}
                          style={styles.dropdownItem}
                        >
                          <Text>{item < 10 ? "0" + item : item}</Text>
                        </TouchableOpacity>
                      );
                    })}
                  </ScrollView>
                )}
                {showDropdownAP && (
                  <ScrollView
                    style={{
                      width: "100%",
                      maxHeight: 100,
                      overflow: "scroll",
                      borderWidth: 0.5,
                      borderRadius: 4,
                      marginBottom: 5,
                      backgroundColor: "#fff"
                    }}
                  >
                    {dayBreaker.map((item) => {
                      return (
                        <TouchableOpacity
                          key={item}
                          onPress={() => {
                            setSelectedAPTime(item);
                            setShowDropdownAP(false);
                          }}
                          style={styles.dropdownItem}
                        >
                          <Text>{item}</Text>
                        </TouchableOpacity>
                      );
                    })}
                  </ScrollView>
                )}
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "flex-end"
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      setShowTimeWeb(false);
                      setShowDropdown(false);
                      setShowDropdownMinute(false);
                      setShowDropdownAP(false);
                    }}
                    style={{
                      backgroundColor: "#888",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10,
                      marginHorizontal: 10
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff"
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      if (parseInt(selectedHours) === 1) {
                        let date = new Date();
                        let dateEnd = new Date();
                        date.setHours(
                          selectedAPTime === "AM" && selectedHourTime === 12
                            ? 0
                            : selectedAPTime === "PM" && selectedHourTime === 12
                            ? 12
                            : selectedHourTime !== 12 && selectedAPTime === "PM"
                            ? parseInt(selectedHourTime) + 12
                            : parseInt(selectedHourTime)
                        );
                        date.setMinutes(selectedMinuteTime);
                        // Add 8 hours to dateEnd based on date
                        dateEnd.setFullYear(date.getFullYear());
                        dateEnd.setMonth(date.getMonth());
                        dateEnd.setDate(date.getDate());
                        // dateEnd.setHours(date.getHours() + (jobState.jobType === "Shift" ? 8 : 1));
                        dateEnd.setHours(date.getHours() + 1);
                        dateEnd.setMinutes(date.getMinutes());

                        // Determine if it's AM or PM
                        let meridiem = dateEnd.getHours() >= 12 ? "PM" : "AM";
                        if (selectedAPTime === "PM" && meridiem === "AM") {
                          let endDate = moment(jobState.startDate);
                          endDate.add(1, "day");
                          setJobState({
                            ...jobState,
                            startTime: date,
                            endTime: dateEnd,
                            endDate: endDate
                          });
                        } else {
                          setJobState({
                            ...jobState,
                            startTime: date,
                            endTime: dateEnd,
                            endDate: jobState.startDate
                          });
                        }
                      } else {
                        let date = new Date();
                        let dateEnd = new Date();
                        date.setHours(
                          selectedAPTime === "AM" && selectedHourTime === 12
                            ? 0
                            : selectedAPTime === "PM" && selectedHourTime === 12
                            ? 12
                            : selectedHourTime !== 12 && selectedAPTime === "PM"
                            ? parseInt(selectedHourTime) + 12
                            : parseInt(selectedHourTime)
                        );
                        date.setMinutes(selectedMinuteTime);
                        // Add 8 hours to dateEnd based on date
                        dateEnd.setFullYear(date.getFullYear());
                        dateEnd.setMonth(date.getMonth());
                        dateEnd.setDate(date.getDate());
                        dateEnd.setHours(
                          date.getHours() +
                            (jobState.jobType === "Shift"
                              ? parseInt(selectedHours)
                              : 1)
                        );
                        // dateEnd.setHours(date.getHours() + parseInt(selectedHours));
                        dateEnd.setMinutes(date.getMinutes());

                        // Determine if it's AM or PM
                        let meridiem = dateEnd.getHours() >= 12 ? "PM" : "AM";
                        if (selectedAPTime === "PM" && meridiem === "AM") {
                          let endDate = moment(jobState.startDate);
                          endDate.add(1, "day");
                          setJobState({
                            ...jobState,
                            startTime: date,
                            endTime: dateEnd,
                            endDate: endDate
                          });
                        } else {
                          setJobState({
                            ...jobState,
                            startTime: date,
                            endTime: dateEnd,
                            endDate: jobState.startDate
                          });
                        }
                      }
                      setShowTimeWeb(false);
                      setShowDropdown(false);
                      setShowDropdownMinute(false);
                      setShowDropdownAP(false);
                    }}
                    style={{
                      backgroundColor: "#00b359",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff"
                      }}
                    >
                      Select
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            animationType="fade"
            transparent={true}
            visible={showTimeEndWeb}
            onRequestClose={() => setShowTimeEndWeb(false)}
          >
            <View
              style={{
                height: height,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)"
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 10,
                  height: 280,
                  justifyContent: "center",
                  elevation: 5,
                  width: width / 1.5,
                  paddingHorizontal: 10,
                  borderRadius: 15,
                  borderColor: "rgba(0, 0, 0, 0.1)"
                }}
              >
                <Text style={{ fontSize: 18, marginBottom: 0 }}>
                  Select End Time
                </Text>
                <Text style={{ fontSize: 10, marginBottom: 10 }}>
                  Select Hour and Minutes
                </Text>
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <TouchableOpacity
                    onPress={() => {
                      setShowDropdownEnd(!showDropdownEnd);
                      setShowDropdownMinuteEnd(false);
                      setShowDropdownAPEnd(false);
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 25,
                        marginBottom: 10,
                        color: showDropdownEnd ? "#00b359" : "#000"
                      }}
                    >
                      {selectedHourTimeEnd === "" ? "00" : selectedHourTimeEnd}
                    </Text>
                  </TouchableOpacity>
                  <Text style={{ fontSize: 25, marginBottom: 10 }}> : </Text>
                  <TouchableOpacity
                    onPress={() => {
                      setShowDropdownMinuteEnd(!showDropdownMinuteEnd);
                      setShowDropdownEnd(false);
                      setShowDropdownAPEnd(false);
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 25,
                        marginBottom: 10,
                        color: showDropdownMinuteEnd ? "#00b359" : "#000"
                      }}
                    >
                      {selectedMinuteTimeEnd === ""
                        ? "00"
                        : selectedMinuteTimeEnd}
                    </Text>
                  </TouchableOpacity>
                  <Text style={{ fontSize: 25, marginBottom: 10 }}> </Text>
                  <TouchableOpacity
                    onPress={() => {
                      setShowDropdownAPEnd(!showDropdownAPEnd);
                      setShowDropdownEnd(false);
                      setShowDropdownMinuteEnd(false);
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 25,
                        marginBottom: 10,
                        color: showDropdownAPEnd ? "#00b359" : "#000"
                      }}
                    >
                      {selectedAPTimeEnd === "" ? "AM" : selectedAPTimeEnd}
                    </Text>
                  </TouchableOpacity>
                </View>

                {showDropdownEnd && (
                  <ScrollView
                    style={{
                      width: "100%",
                      maxHeight: 150,
                      overflow: "scroll",
                      borderWidth: 0.5,
                      borderRadius: 4,
                      marginBottom: 5,
                      backgroundColor: "#fff"
                    }}
                  >
                    {hour.map((item) => {
                      return (
                        <TouchableOpacity
                          key={item}
                          onPress={() => {
                            setSelectedHourTimeEnd(
                              item < 10 ? "0" + item : item
                            );
                            setShowDropdownEnd(false);
                          }}
                          style={styles.dropdownItem}
                        >
                          <Text>{item < 10 ? "0" + item : item}</Text>
                        </TouchableOpacity>
                      );
                    })}
                  </ScrollView>
                )}
                {showDropdownMinuteEnd && (
                  <ScrollView
                    style={{
                      width: "100%",
                      maxHeight: 150,
                      overflow: "scroll",
                      borderWidth: 0.5,
                      borderRadius: 4,
                      marginBottom: 5,
                      backgroundColor: "#fff"
                    }}
                  >
                    <TextInput
                      style={{
                        width: "90%",
                        padding: 2,
                        paddingLeft: 5,
                        fontSize: 12,
                        color: "#737373"
                      }}
                      keyboardType={"decimal-pad"}
                      onChangeText={(text) => {
                        setSelectedMinuteTimeEnd(text);
                      }}
                      value={
                        selectedMinuteTimeEnd === "00"
                          ? ""
                          : selectedMinuteTimeEnd
                      }
                      placeholderTextColor="#b3b3b3"
                      placeholder={`Enter custom minutes`}
                    />
                    {minutes.map((item) => {
                      return (
                        <TouchableOpacity
                          key={item}
                          onPress={() => {
                            setSelectedMinuteTimeEnd(
                              item < 10 ? "0" + item : item
                            );
                            setShowDropdownMinuteEnd(false);
                          }}
                          style={styles.dropdownItem}
                        >
                          <Text>{item < 10 ? "0" + item : item}</Text>
                        </TouchableOpacity>
                      );
                    })}
                  </ScrollView>
                )}

                {showDropdownAPEnd && (
                  <ScrollView
                    style={{
                      width: "100%",
                      maxHeight: 100,
                      overflow: "scroll",
                      borderWidth: 0.5,
                      borderRadius: 4,
                      marginBottom: 5,
                      backgroundColor: "#fff"
                    }}
                  >
                    {dayBreaker.map((item) => {
                      return (
                        <TouchableOpacity
                          key={item}
                          onPress={() => {
                            setSelectedAPTimeEnd(item);
                            setShowDropdownAPEnd(false);
                          }}
                          style={styles.dropdownItem}
                        >
                          <Text>{item}</Text>
                        </TouchableOpacity>
                      );
                    })}
                  </ScrollView>
                )}

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "flex-end"
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      setShowTimeEndWeb(false);
                      setShowDropdownEnd(false);
                      setShowDropdownMinuteEnd(false);
                      setShowDropdownAPEnd(false);
                    }}
                    style={{
                      backgroundColor: "#888",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10,
                      marginHorizontal: 10
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff"
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      let date = new Date();
                      date.setHours(
                        selectedHourTimeEnd === 12 && selectedAPTimeEnd === "AM"
                          ? 0
                          : selectedAPTimeEnd === "PM" &&
                            selectedHourTimeEnd === 12
                          ? 12
                          : selectedHourTimeEnd !== 12 &&
                            selectedAPTimeEnd === "PM"
                          ? parseInt(selectedHourTimeEnd) + 12
                          : parseInt(selectedHourTimeEnd)
                      );
                      date.setMinutes(selectedMinuteTimeEnd);

                      setJobState({ ...jobState, endTime: date });
                      setShowTimeEndWeb(false);
                      setShowDropdownEnd(false);
                      setShowDropdownMinuteEnd(false);
                      setShowDropdownAPEnd(false);
                    }}
                    style={{
                      backgroundColor: "#00b359",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff"
                      }}
                    >
                      Select
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>

          <Modal
            animationType="fade"
            transparent={true}
            visible={showCertification}
            onRequestClose={() => setShowCertification(false)}
          >
            <View
              style={{
                height: height,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)"
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 10,
                  height: 280,
                  justifyContent: "center",
                  elevation: 5,
                  width: width / 1.5,
                  paddingHorizontal: 10,
                  borderRadius: 15,
                  borderColor: "rgba(0, 0, 0, 0.1)"
                }}
              >
                <Text style={{ fontSize: 12, marginBottom: 10 }}>
                  Select Certification
                </Text>
                <CustomDropdown
                  options={options}
                  selectedValue={"Certification"}
                  onValueChange={(value) => handleAddCertification(value)}
                />

                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-end"
                  }}
                >
                  <TouchableOpacity
                    onPress={() => setShowCertification(false)}
                    style={{
                      backgroundColor: "#00b359",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff"
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>

          <Modal
            animationType="fade"
            transparent={true}
            visible={showEmrExperience}
            onRequestClose={() => setShowEmrExperience(false)}
          >
            <View
              style={{
                height: height,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)"
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 10,
                  height: 280,
                  justifyContent: "center",
                  elevation: 5,
                  width: width / 1.5,
                  paddingHorizontal: 10,
                  borderRadius: 15,
                  borderColor: "rgba(0, 0, 0, 0.1)"
                }}
              >
                <Text style={{ fontSize: 12, marginBottom: 10 }}>
                  Select EMR or EHR Experience
                </Text>
                <CustomDropdown
                  options={optionsEMR}
                  selectedValue={"EMR or EHR Experience"}
                  onValueChange={(value) => handleAddEmrExperience(value)}
                />

                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-end"
                  }}
                >
                  <TouchableOpacity
                    onPress={() => setShowEmrExperience(false)}
                    style={{
                      backgroundColor: "#00b359",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff"
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            animationType="fade"
            transparent={true}
            visible={showSpecialty}
            onRequestClose={() => setShowSpecialty(false)}
          >
            <View
              style={{
                height: height,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)"
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 10,
                  height: 280,
                  justifyContent: "center",
                  elevation: 5,
                  width: width / 1.5,
                  paddingHorizontal: 10,
                  borderRadius: 15,
                  borderColor: "rgba(0, 0, 0, 0.1)"
                }}
              >
                <Text style={{ fontSize: 12, marginBottom: 10 }}>
                  Select Specialty
                </Text>
                <CustomDropdown
                  options={specialty}
                  selectedValue={"Specialty"}
                  onValueChange={(value) => {
                    setJobState({ ...jobState, specialty: value });
                    setShowSpecialty(false);
                  }}
                />

                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-end"
                  }}
                >
                  <TouchableOpacity
                    onPress={() => setShowSpecialty(false)}
                    style={{
                      backgroundColor: "#00b359",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff"
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            animationType="fade"
            transparent={true}
            visible={showLicenseType}
            onRequestClose={() => setShowLicenseType(false)}
          >
            <View
              style={{
                height: height,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)"
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 10,
                  height: 280,
                  justifyContent: "center",
                  elevation: 5,
                  width: width / 1.5,
                  paddingHorizontal: 10,
                  borderRadius: 15,
                  borderColor: "rgba(0, 0, 0, 0.1)"
                }}
              >
                <Text style={{ fontSize: 12, marginBottom: 10 }}>
                  Select License Type
                </Text>
                <CustomDropdown
                  options={option}
                  selectedValue={"LicenseType"}
                  onValueChange={(value) => handleAddLicenseType(value)}
                />

                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-end"
                  }}
                >
                  <TouchableOpacity
                    onPress={() => setShowLicenseType(false)}
                    style={{
                      backgroundColor: "#00b359",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff"
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            animationType="fade"
            transparent={true}
            visible={showBreak}
            onRequestClose={() => setShowBreak(false)}
          >
            <View
              style={{
                height: height,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)"
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 10,
                  height: 280,
                  justifyContent: "center",
                  elevation: 5,
                  width: width / 1.5,
                  paddingHorizontal: 10,
                  borderRadius: 15,
                  borderColor: "rgba(0, 0, 0, 0.1)"
                }}
              >
                <Text style={{ fontSize: 12, marginBottom: 10 }}>
                  Select Break
                </Text>
                <CustomDropdown
                  options={optionBreak}
                  selectedValue={"Break"}
                  onValueChange={(value) => {
                    let date = new Date(jobState.endTime);
                    let dateEnd = new Date(jobState.endTime);
                    dateEnd.setFullYear(date.getFullYear());
                    dateEnd.setMonth(date.getMonth());
                    dateEnd.setDate(date.getDate());
                    dateEnd.setHours(
                      value === "1 Hour" ? date.getHours() + 1 : date.getHours()
                    );
                    dateEnd.setMinutes(
                      value === "15 Minutes"
                        ? date.getMinutes() + 15
                        : value === "30 Minutes"
                        ? date.getMinutes() + 30
                        : value === "45 Minutes"
                        ? date.getMinutes() + 45
                        : date.getMinutes()
                    );

                    setJobState({
                      ...jobState,
                      break: value,
                      endTime: dateEnd
                    });
                    setShowBreak(false);
                  }}
                />

                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-end"
                  }}
                >
                  <TouchableOpacity
                    onPress={() => setShowBreak(false)}
                    style={{
                      backgroundColor: "#00b359",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff"
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>

          <Modal
            animationType="fade"
            transparent={true}
            visible={showDuration}
            onRequestClose={() => setShowDuration(false)}
          >
            <View
              style={{
                height: height,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)"
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 10,
                  height: 280,
                  justifyContent: "center",
                  elevation: 5,
                  width: width / 1.5,
                  paddingHorizontal: 10,
                  borderRadius: 15,
                  borderColor: "rgba(0, 0, 0, 0.1)"
                }}
              >
                <Text style={{ fontSize: 12, marginBottom: 10 }}>
                  Select Duration
                </Text>
                <CustomDropdown
                  options={durationCount}
                  selectedValue={"Duration"}
                  onValueChange={(value) =>
                    setJobState({ ...jobState, jobDuration: value })
                  }
                />

                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-end"
                  }}
                >
                  <TouchableOpacity
                    onPress={() => setShowDuration(false)}
                    style={{
                      backgroundColor: "#00b359",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff"
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>

          <Modal
            animationType="fade"
            transparent={true}
            visible={showTiming}
            onRequestClose={() => setShowTiming(false)}
          >
            <View
              style={{
                height: height,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)"
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 10,
                  height: 280,
                  justifyContent: "center",
                  elevation: 5,
                  width: width / 1.5,
                  paddingHorizontal: 10,
                  borderRadius: 15,
                  borderColor: "rgba(0, 0, 0, 0.1)"
                }}
              >
                <Text style={{ fontSize: 12, marginBottom: 10 }}>
                  Select Time
                </Text>
                <CustomDropdown
                  options={timingCount}
                  selectedValue={"Time"}
                  onValueChange={(value) => {
                    setJobState({ ...jobState, jobTiming: value });
                    setShowTiming(false);
                  }}
                />

                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-end"
                  }}
                >
                  <TouchableOpacity
                    onPress={() => setShowTiming(false)}
                    style={{
                      backgroundColor: "#00b359",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff"
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>

          <Modal
            animationType="fade"
            transparent={true}
            visible={showExperienceLevel}
            onRequestClose={() => setShowExperienceLevel(false)}
          >
            <View
              style={{
                height: height,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)"
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 10,
                  height: 280,
                  justifyContent: "center",
                  elevation: 5,
                  width: width / 1.5,
                  paddingHorizontal: 10,
                  borderRadius: 15,
                  borderColor: "rgba(0, 0, 0, 0.1)"
                }}
              >
                <Text style={{ fontSize: 12, marginBottom: 10 }}>
                  Select Experience Level
                </Text>
                <CustomDropdown
                  options={experienceLevel}
                  selectedValue={"Experience Level"}
                  onValueChange={(value) => {
                    setJobState({ ...jobState, yearOfExperience: value });
                    setShowExperienceLevel(false);
                  }}
                />

                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-end"
                  }}
                >
                  <TouchableOpacity
                    onPress={() => setShowExperienceLevel(false)}
                    style={{
                      backgroundColor: "#00b359",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff"
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>

          <Modal
            animationType="fade"
            transparent={true}
            visible={showTemplate}
            onRequestClose={() => setShowTemplate(false)}
          >
            <View
              style={{
                height: height,
                // justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)"
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 10,
                  height: 280,
                  justifyContent: "center",
                  elevation: 5,
                  width: width / 1.5,
                  paddingHorizontal: 10,
                  borderRadius: 15,
                  marginTop: 100,
                  borderColor: "rgba(0, 0, 0, 0.1)"
                }}
              >
                <Text style={{ fontSize: 12, marginBottom: 10 }}>
                  Select Template
                </Text>
                {jobTemplateArray === undefined ? (
                  <Text>Loading...</Text>
                ) : (
                  <CustomTemplate
                    options={
                      jobTemplateArray === undefined ? [] : jobTemplateArray
                    }
                    selectedValue={"Search Template"}
                    onValueChange={(value) => {
                      setJobTemplate(value);
                      setShowTemplate(false);
                    }}
                  />
                )}

                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-end"
                  }}
                >
                  <TouchableOpacity
                    onPress={() => setShowTemplate(false)}
                    style={{
                      backgroundColor: "#00b359",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff"
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>

          <Modal
            animationType="fade"
            transparent={true}
            visible={patientView}
            onRequestClose={() => setPatientView(false)}
          >
            <View
              style={{
                height: height,
                // justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)"
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 10,
                  height: 280,
                  justifyContent: "center",
                  elevation: 5,
                  width: width / 1.5,
                  paddingHorizontal: 10,
                  borderRadius: 15,
                  marginTop: 100,
                  borderColor: "rgba(0, 0, 0, 0.1)"
                }}
              >
                <Text style={{ fontSize: 12, marginTop: 10, marginBottom: 10 }}>
                  Customer Name
                </Text>
                <View
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: 10,
                    elevation: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    paddingRight: 10,
                    justifyContent: "space-between"
                  }}
                >
                  <TextInput
                    style={{
                      width: "90%",
                      padding: 2,
                      paddingLeft: 5,
                      fontSize: 12,
                      color: "#737373"
                    }}
                    onChangeText={(text) => {
                      setPatientSearch(text);
                      filterCustomerNameSuggestions(text);
                    }}
                    value={patientSearch}
                    autoCapitalize="sentences"
                    keyboardType={"default"}
                    placeholderTextColor="#b3b3b3"
                    placeholder={"Search Customer Name"}
                  />
                  <View
                    style={{
                      height: 40,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center"
                    }}
                  >
                    {patientSearch !== "" ? (
                      <TouchableOpacity
                        onPress={() => setPatientSearch("")}
                        style={{ marginLeft: 3 }}
                      >
                        <Ionicons
                          name="close-sharp"
                          size={22}
                          color="#808080"
                        />
                      </TouchableOpacity>
                    ) : null}
                  </View>
                </View>

                {patientData?.length === 0 ? (
                  <Text style={{ textAlign: "center", marginTop: 10 }}>
                    Empty
                  </Text>
                ) : (
                  <FlatList
                    // style={styles.list}
                    data={
                      patientSearch.length === 0
                        ? patientData
                        : filteredSuggestions
                    }
                    renderItem={renderCustomerCardSuggestion}
                    keyExtractor={(item) => item?.id}
                  />
                )}

                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-end"
                  }}
                >
                  <TouchableOpacity
                    onPress={() => setPatientView(false)}
                    style={{
                      backgroundColor: "#00b359",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10,
                      marginTop: 5
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff"
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
        </View>
      )}
    </KeyboardAvoidingView>
  );
};

export default AddJobScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff"
  },
  input: {
    color: "#000",
    paddingLeft: 15,
    fontWeight: "bold",
    height: 40,
    fontSize: 15,
    marginVertical: 10,
    borderRadius: 5
  },
  homeBtn: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#00b359",
    width: "40%",
    height: 35,
    borderRadius: 5,
    marginVertical: 15,
    marginHorizontal: 5,
    alignSelf: "center"
  },
  dayHeaderText: {
    fontWeight: "bold"
  },
  dayContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: 30,
    height: 30,
    borderRadius: 15,
    marginTop: 6
  },
  pastDateContainer: {
    backgroundColor: "#CCCCCC"
  },
  futureDateText: {
    color: "black"
  },
  pastDateText: {
    color: "white"
  },
  inputContainer: {
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 4
  },
  dropdownItem: {
    padding: 8
  }
});
