import { StyleSheet, View, TextInput, Text } from "react-native";
import React from "react";
import { PanGestureHandler } from "react-native-gesture-handler";
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  useAnimatedGestureHandler,
  useAnimatedProps,
  runOnJS,
} from "react-native-reanimated";
const CustomSliderFilter = ({
  sliderWidth,
  min,
  max,
  step,
  type,
  label,
  color,
  onValueChange,
  valueMin,
  valueMax,
  minDrag,
}) => {
  // console.log(valueMax);
  const position = useSharedValue((valueMin / max) * sliderWidth);
  const position2 = useSharedValue((valueMax / max) * sliderWidth);
  const opacity = useSharedValue(0);
  const opacity2 = useSharedValue(0);
  const zIndex = useSharedValue(0);
  const zIndex2 = useSharedValue(0);

  const gestureHandler = useAnimatedGestureHandler({
    onStart: (_, ctx) => {
      ctx.startX = position.value;
    },
    onActive: (e, ctx) => {
      opacity.value = 1;
      if (ctx.startX + e.translationX < 0) {
        position.value = 0;
      } else if (ctx.startX + e.translationX > position2.value) {
        position.value = position2.value;
        zIndex.value = 1;
        zIndex2.value = 0;
      } else {
        position.value = ctx.startX + e.translationX;
      }
    },
    onEnd: () => {
      opacity.value = 0;
      runOnJS(onValueChange)({
        min:
          min +
          Math.floor(position.value / (sliderWidth / ((max - min) / step))) *
          step,
        max:
          min +
          Math.floor(position2.value / (sliderWidth / ((max - min) / step))) *
          step,
      });
    },
  });

  const gestureHandler2 = useAnimatedGestureHandler({
    onStart: (_, ctx) => {
      ctx.startX = position2.value;
    },
    onActive: (e, ctx) => {
      opacity2.value = 1;
      if (ctx.startX + e.translationX > sliderWidth) {
        position2.value = sliderWidth;
      } else if (ctx.startX + e.translationX < position.value) {
        position2.value = position.value;
        zIndex.value = 0;
        zIndex2.value = 1;
      } else {
        position2.value = ctx.startX + e.translationX;
      }
    },
    onEnd: () => {
      opacity2.value = 0;
      runOnJS(onValueChange)({
        min:
          min +
          Math.floor(position.value / (sliderWidth / ((max - min) / step))) *
          step,
        max:
          min +
          Math.floor(position2.value / (sliderWidth / ((max - min) / step))) *
          step,
      });
    },
  });

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{ translateX: position.value }],
    zIndex: zIndex.value,
  }));

  const animatedStyle2 = useAnimatedStyle(() => ({
    transform: [{ translateX: position2.value }],
    zIndex: zIndex2.value,
  }));

  const opacityStyle = useAnimatedStyle(() => ({
    opacity: opacity.value,
  }));

  const opacityStyle2 = useAnimatedStyle(() => ({
    opacity: opacity2.value,
  }));

  const sliderStyle = useAnimatedStyle(() => ({
    transform: [{ translateX: position.value }],
    width: position2.value - position.value,
  }));

  const AnimatedTextInput = Animated.createAnimatedComponent(TextInput);
  const minLabelText = useAnimatedProps(() => {
    return {
      text:
        type +
        `${min +
        Math.floor(position.value / (sliderWidth / ((max - min) / step))) *
        step
        }`,
    };
  });
  const maxLabelText = useAnimatedProps(() => {
    return {
      text:
        type +
        `${min +
        Math.floor(position2.value / (sliderWidth / ((max - min) / step))) *
        step
        }`,
    };
  });
  return (
    <View style={[styles.sliderContainer]}>
      <View style={[styles.sliderBack, { width: sliderWidth }]} />
      <Animated.View
        style={[sliderStyle, styles.sliderFront, { backgroundColor: color }]}
      />
      <PanGestureHandler onGestureEvent={gestureHandler} enabled={minDrag}>
        <Animated.View
          style={[
            animatedStyle,
            minDrag && styles.thumb,
            {
              borderColor: color,
            },
          ]}
        >
          <Animated.View style={[opacityStyle, label && styles.label]}>
            <AnimatedTextInput
              style={styles.labelText}
              animatedProps={label && minLabelText}
              editable={false}
              defaultValue={valueMin}
            />
          </Animated.View>
        </Animated.View>
      </PanGestureHandler>
      <PanGestureHandler onGestureEvent={gestureHandler2}>
        <Animated.View
          style={[
            animatedStyle2,
            styles.thumb,
            {
              borderColor: color,
            },
          ]}
        >
          <Animated.View style={[opacityStyle2, label && styles.label]}>
            <AnimatedTextInput
              style={styles.labelText}
              animatedProps={label && maxLabelText}
              editable={false}
              defaultValue={valueMax}
            />
          </Animated.View>
        </Animated.View>
      </PanGestureHandler>
    </View>
  );
};

export default CustomSliderFilter;

const styles = StyleSheet.create({
  sliderContainer: {
    justifyContent: "center",
    alignSelf: "center",
    flex: 1,
  },
  sliderBack: {
    height: 10,
    backgroundColor: "#DFEAFB",
    borderRadius: 10,
  },
  sliderFront: {
    height: 10,
    borderRadius: 10,
    position: "absolute",
  },
  thumb: {
    left: -10,
    width: 25,
    height: 25,
    position: "absolute",
    backgroundColor: "white",
    borderWidth: 4,
    borderRadius: 10,
  },
  label: {
    position: "absolute",
    // top: -40,
    bottom: 20,
    backgroundColor: "black",
    borderRadius: 5,
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  labelText: {
    color: "white",
    padding: 2,
    fontWeight: "bold",
    fontSize: 12,
    textAlign: "center",
    width: "100%",
    display: "none"
  },
});
