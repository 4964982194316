import {
  View,
  StyleSheet,
  ScrollView,
  Dimensions,
} from "react-native";
import React from "react";
import { useState } from "react";
import CustomInput from "../../../customComponents/Input/CustomInput";
import AuthButton from "../../../customComponents/Button/AuthButton";
import { useEffect } from "react";
import { Alert } from "react-native";
import { Platform } from "react-native";
import { useAuth } from "../../../context/AuthContext";
import { updateDetails } from "../../../api_url/facilityTable";
import { ChatState } from "../../../context/ChatContext";

const EditProfileForm = (props) => {
  const { width, height } = Dimensions.get("window");
  const { authToken, userId, userUniqueId } = useAuth();
  const { fetchAgainProfile, setFetchAgainProfile } = ChatState();

  const [facilityData, setFacilityData] = useState({
    firstName: "",
    lastName: "",
    organization: "",
    emailId: "",
    phoneNumber: "",
    profileImage: "",
  });

  useEffect(() => {
    if (props.route.params.data) {
      setFacilityData({
        ...facilityData,
        firstName: props.route.params.data.firstName,
        lastName: props.route.params.data.lastName,
        organization: props.route.params.data.organization,
        emailId: props.route.params.data.emailId,
        phoneNumber: props.route.params.data.phoneNumber,
        profileImage: props.route.params.data.profileImage,
      });
    }
  }, [props.route.params.data]);

  const update = async () => {
    await updateDetails(
      authToken, userId,
      {
        firstName: facilityData?.firstName,
        lastName: facilityData?.lastName,
        phoneNumber: facilityData.phoneNumber
      }
    )
    setFetchAgainProfile(!fetchAgainProfile)
    props.navigation.navigate("ProfileScreen");
  };

  return (
    <View style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 10,
            }}
          >
            <CustomInput
              label={"First Name"}
              required={true}
              onChangeText={(text) =>
                setFacilityData({ ...facilityData, firstName: text })
              }
              keyboardType={"default"}
              placeholder={"First Name"}
              value={facilityData.firstName}
              clearValue={() => setFacilityData({ ...facilityData, firstName: "" })}
              labelStyle={{
                marginHorizontal: 10,
              }}
              viewStyle={{
                height: 40,
                backgroundColor: "#f2f2f2",
                borderRadius: 10,
                elevation: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderWidth: 0.5,
                borderColor: "#e6e6e6",
                marginHorizontal: 10,
                paddingRight: 10,
                justifyContent: "space-between",
              }}
              textInputStyle={{
                height: 40,
                borderRadius: 10,
                paddingLeft: 10,
                fontSize: 12,
              }}
              iconStyle={{
                height: 40,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            />

            <CustomInput
              label={"Last Name"}
              required={true}
              onChangeText={(text) =>
                setFacilityData({ ...facilityData, lastName: text })
              }
              keyboardType={"default"}
              placeholder={"Last Name"}
              value={facilityData.lastName}
              clearValue={() => setFacilityData({ ...facilityData, lastName: "" })}
              labelStyle={{
                marginHorizontal: 10,
              }}
              viewStyle={{
                height: 40,
                backgroundColor: "#f2f2f2",
                borderRadius: 10,
                elevation: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderWidth: 0.5,
                borderColor: "#e6e6e6",
                marginHorizontal: 10,
                paddingRight: 10,
                justifyContent: "space-between",
              }}
              textInputStyle={{
                height: 40,
                borderRadius: 10,
                paddingLeft: 10,
                fontSize: 12,
              }}
              iconStyle={{
                height: 40,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            />
            {/* <CustomInput
              editable={false}
              label={"Organization"}
              required={true}
              onChangeText={(text) =>
                setFacilityData({ ...facilityData, organization: text })
              }
              keyboardType={"default"}
              placeholder={"Organization"}
              value={facilityData.organization}
              clearValue={() =>
                // setFacilityData({ ...facilityData, organization: "" })
                Alert.alert("Now you can't change this")
              }
              labelStyle={{
                marginHorizontal: 10,
              }}
              viewStyle={{
                width: width / 2.3,
                height: 40,
                backgroundColor: "#f2f2f2",
                borderRadius: 10,
                elevation: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderWidth: 0.5,
                borderColor: "#e6e6e6",
                marginHorizontal: 10,
                paddingRight: 10,
                justifyContent: "space-between",
              }}
              textInputStyle={{
                width: width / 2.9,
                height: 40,
                borderRadius: 10,
                paddingLeft: 10,
                fontSize: 12,
              }}
              iconStyle={{
                height: 40,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            /> */}
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 10,
            }}
          >
            <CustomInput
              label={"Email ID"}
              required={true}
              onChangeText={(text) =>
                setFacilityData({ ...facilityData, emailId: text })
              }
              keyboardType={"email-address"}
              placeholder={"Email"}
              value={facilityData.emailId}
              clearValue={() =>
                Platform.OS === "web" ?
                  alert("Not Editable!") :
                  Alert.alert("Not Editable!")
              }
              labelStyle={{
                marginHorizontal: 10,
              }}
              viewStyle={{
                height: 40,
                backgroundColor: "#f2f2f2",
                borderRadius: 10,
                elevation: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderWidth: 0.5,
                borderColor: "#e6e6e6",
                marginHorizontal: 10,
                paddingRight: 10,
                justifyContent: "space-between",
              }}
              textInputStyle={{
                height: 40,
                borderRadius: 10,
                paddingLeft: 10,
                fontSize: 12,
              }}
              iconStyle={{
                height: 40,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              editable={false}
            />
            <CustomInput
              label={"Phone Number"}
              required={true}
              onChangeText={(text) =>
                setFacilityData({ ...facilityData, phoneNumber: text })
              }
              keyboardType={"number-pad"}
              placeholder={"Phone Number"}
              value={facilityData.phoneNumber}
              clearValue={() =>
                setFacilityData({ ...facilityData, phoneNumber: "" })
              }
              labelStyle={{
                marginHorizontal: 10,
              }}
              viewStyle={{
                height: 40,
                backgroundColor: "#f2f2f2",
                borderRadius: 10,
                elevation: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderWidth: 0.5,
                borderColor: "#e6e6e6",
                marginHorizontal: 10,
                paddingRight: 10,
                justifyContent: "space-between",
              }}
              textInputStyle={{
                height: 40,
                borderRadius: 10,
                paddingLeft: 10,
                fontSize: 12,
              }}
              iconStyle={{
                height: 40,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            />
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginVertical: 25,
            }}
          >
            <AuthButton
              name={"Save"}
              onPress={() => {
                update(props.route.params.data)
              }}
            />
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

export default EditProfileForm;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    justifyContent: "center",
  },
});
