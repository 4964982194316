import React from "react";
import {
  View,
  Text,
  ScrollView,
  Image,
  StyleSheet,
  TouchableOpacity,
  Alert,
  Platform,
} from "react-native";
import { SimpleLineIcons } from "@expo/vector-icons";
import { useState } from "react";
import { useEffect } from "react";
import AuthButton from "../../customComponents/Button/AuthButton";
import * as ImagePicker from "expo-image-picker";
import { useAuth } from "../../context/AuthContext";
import { getFacilityDetails, updateDetails } from "../../api_url/facilityTable";
import * as mime from 'react-native-mime-types';
import { uploadFile } from "./AWSHelper";
import { ChatState } from "../../context/ChatContext";

const Profile = (props) => {

  const getFileType = (path) => {
    const mimeType = mime.lookup(path);

    let fileExtension = mimeType || 'application/octet-stream'

    let imageType;
    if (fileExtension === 'image/png') {
      imageType = 'png';
    } else if (fileExtension === 'image/jpeg') {
      imageType = 'jpeg';
    } else if (fileExtension === 'image/jpg') {
      imageType = 'jpg';
    } else {
      imageType = 'png';
    }

    return imageType;
  };

  const { authToken, userId, userUniqueId, removeAuthToken } = useAuth();
  const { fetchAgainProfile, setFetchAgainProfile } = ChatState();

  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const checkUser = async (authToken, userId) => {
    try {
      await getFacilityDetails(authToken, userId)
        .then((res) => {
          if (res === undefined) {
            setUser(null);
            setLoading(false);
          } else {
            setUser(res);
            setLoading(false);
          }
        })
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userId !== null) {
      checkUser(authToken, userId);
    }
  }, [userId, fetchAgainProfile]);


  const progressCallback = (progress) => {
    setProgress(progress.loaded / progress.total);
  };

  const getBlob = async (uri) => {
    const response = await fetch(uri);
    const blob = await response.blob();
    return blob;
  };

  const blobToUint8Array = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const uint8Array = new Uint8Array(reader.result);
        resolve(uint8Array);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(blob);
    });
  };

  // Image picker
  const pickImage = async () => {
    const response = await ImagePicker.getMediaLibraryPermissionsAsync();
    if (Platform.OS !== "web") {
      // if (response.status === "granted") {
      //   const libraryResponse =
      //     await ImagePicker.requestMediaLibraryPermissionsAsync();
      //   const photoResponse = await ImagePicker.requestCameraPermissionsAsync();

      //   if (
      //     libraryResponse.status !== "granted" ||
      //     photoResponse.status !== "granted"
      //   ) {
      //     if (Platform.OS === "web") {
      //       alert("Sorry, we need camera roll permissions to make this work!");
      //     } else {
      //       Alert.alert(
      //         "Sorry, we need camera roll permissions to make this work!"
      //       );
      //     }
      //   } else {
      //     const result = await ImagePicker.launchImageLibraryAsync({
      //       mediaTypes: ImagePicker.MediaTypeOptions.Images,
      //       allowsEditing: true,
      //       aspect: [4, 4],
      //       quality: 1,
      //     });

      //     if (!result.canceled) {
      //       const file = result.assets[0].uri;

      //       try {
      //         const blob = await getBlob(file);
      //         const buffer = await blobToUint8Array(blob);
      //         let name = `${userUniqueId}.${getFileType(file)}`
      //         await uploadFile(name, buffer, "Successfully uploaded photo.").then(
      //           async res => {
      //             if (res && res?.Key) {
      //               await sendImage(res?.Key);
      //             }
      //           }
      //         );
      //       } catch (error) {
      //         console.log("error upload", error);
      //       }
      //     }
      //   }
      // } else {
      //   Alert.alert("Important Notice",
      //     "NURSD FLOW needs permission to access your device camera or photo library to upload a new picture.You can choose 'Don’t Allow' if preferred.", [
      //     {
      //       text: "Continue",
      //       onPress: async () => {
      const libraryResponse = await ImagePicker.requestCameraPermissionsAsync();
      const photoResponse = await ImagePicker.requestCameraPermissionsAsync();
      if (
        libraryResponse.status !== "granted" ||
        photoResponse.status !== "granted"
      ) {
        if (Platform.OS === "web") {
          alert("Sorry, we need camera roll permissions to make this work!");
        } else {
          Alert.alert(
            "Sorry, we need camera roll permissions to make this work!"
          );
        }
      } else {
        const result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: true,
          aspect: [4, 4],
          quality: 1,
        });

        if (!result.canceled) {
          const file = result.assets[0].uri;

          try {
            const blob = await getBlob(file);
            const buffer = await blobToUint8Array(blob);
            let name = `${userUniqueId}.${getFileType(file)}`
            await uploadFile(authToken, name, buffer, "Successfully uploaded photo.").then(
              async res => {
                if (res && res?.Key) {
                  await sendImage(res?.Key);
                }
              }
            );
          } catch (error) {
            console.log("error upload", error);
          }
        }
        // }
        //     },
        //   },
        //   {
        //     text: "Cancel",
        //     style: "cancel",
        //   },
        // ])
      }
    }
  };

  const takePhoto = async () => {
    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      aspect: [4, 3],
    });

    if (!result?.cancelled) {
      // setImage(result.uri);
    }
  };

  const pickImageWeb = async () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*'; // Allow only image files

    input.onchange = async (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();

        reader.onload = async (event) => {
          const dataURI = event.target.result;
          // Now you have the data URI, you can do something with it 
          try {
            const blob = await getBlob(dataURI);
            const buffer = await blobToUint8Array(blob);
            let name = `${userUniqueId}.${getFileType(dataURI)}`
            await uploadFile(authToken, name, buffer, "Successfully uploaded photo.").then(
              async res => {
                if (res && res?.Key) {
                  await sendImage(res?.Key);
                }
              }
            );
          } catch (error) {
            console.log("error upload", error);
          }
        }
        reader.readAsDataURL(file);
      }
    };

    input.click();
  };

  const fileToBlob = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const blob = new Blob([reader.result], { type: file.type });
        resolve(blob);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const sendImage = async (key) => {
    await updateDetails(authToken, userId, {
      profileImage: key
    }).then(res => {
      checkUser(authToken, userId);
      setFetchAgainProfile(fetchAgainProfile);
    });
  };

  useEffect(() => {
    if (loadingBtn) {
      // Start the timeout when the component mounts
      const timeout = setTimeout(() => {
        if (Platform.OS === "web") {
          alert("something went wrong. Try again later.");
        } else {
          Alert.alert("something went wrong. Try again later.");
        }
        setLoadingBtn(false);
      }, 5000); // 30 seconds
      // Clear the timeout when the component unmounts or the dependency changes
      return () => clearTimeout(timeout);
    }
  }, [loadingBtn]); // Empty dependency array ensures the effect runs only once

  const upDateNotificationFacilityId = async () => {
    setLoading(true);
    const res = await updateDetails(
      authToken,
      userId,
      { mobileId: "" }
    )

    if (res?.mobileId === "") {
      removeAuthToken();
      setLoading(false);
    }
  };

  const logOut = async () => {
    upDateNotificationFacilityId();
  };

  return (
    <View style={styles.container}>
      {loading ? (
        <View
          style={{ justifyContent: "center", alignItems: "center", flex: 1 }}
        >
          <Text>Loading...</Text>
        </View>
      ) : (
        <ScrollView showsVerticalScrollIndicator={false}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 10,
            }}
          >
            <TouchableOpacity
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: 20,
                marginRight: 10,
                borderWidth: 2,
                borderRadius: 10,
                borderColor: "#2775BD",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
                width: 65,
                height: 65,
                overflow: "hidden",
              }}
              onPress={() => Platform.OS === "web" ? pickImageWeb() : pickImage()}
            >
              {user?.profileImage ? (
                <View>
                  <Image
                    source={{
                      uri: user?.profileImage
                    }}
                    style={{ width: 60, height: 60 }}

                  />
                  <TouchableOpacity
                    activeOpacity={0.5}
                    style={{
                      position: "absolute",
                      right: 0,
                      bottom: 0,
                      backgroundColor: "#fff",
                      padding: 2,
                      borderRadius: 50,
                    }}
                    onPress={() => Platform.OS === "web" ? pickImageWeb() : pickImage()}
                  >
                    <SimpleLineIcons
                      name="pencil"
                      size={12}
                      style={{ padding: 2 }}
                      color="#000"
                    />
                  </TouchableOpacity>
                </View>
              ) : (
                <Image
                  source={require("../../../assets/login/upload-profile.png")}
                  style={{ width: 60, height: 60 }}
                />
              )}
            </TouchableOpacity>
          </View>
          {progress !== 0 && (
            <View
              style={{
                flex: 1,
                marginHorizontal: 20,
                marginTop: 5,
              }}
            >
              <Text style={{ fontSize: 10 }}>Loading...</Text>
              <View
                style={{
                  height: 5,
                  borderRadius: 5,
                  backgroundColor: "#2775BD",
                  width: `${progress * 50}%`,
                }}
              />
            </View>
          )}

          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 15,
              marginHorizontal: 20,
            }}
          >
            <Text style={{ fontWeight: "700", fontSize: 16 }}>
              {user?.firstName} {user?.lastName}
            </Text>
          </View>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-end",
              marginHorizontal: 20,
              marginTop: 10,
            }}
          >
            <View>
              <Text style={{ fontWeight: "500", fontSize: 12 }}>
                Employer Information
              </Text>

              <Text style={{ fontWeight: "500", fontSize: 12, marginTop: 5 }}>
                Organization ={" "}
                <Text
                  style={{
                    fontSize: 12,
                    color: "#595959",
                    marginTop: 5,
                  }}
                >
                  {user?.organization}
                </Text>
              </Text>
              <Text style={{ fontWeight: "500", fontSize: 12, marginTop: 5 }}>
                Office ID ={" "}
                <Text
                  style={{
                    fontSize: 12,
                    color: "#595959",
                    marginTop: 5,
                  }}
                >
                  {user?.location_id}
                </Text>
              </Text>
            </View>
          </View>

          <View
            style={{
              height: 3,
              backgroundColor: "#f2f2f2",
              marginVertical: 10,
            }}
          />
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
              marginHorizontal: 20,
              marginBottom: 10,
            }}
          >
            <TouchableOpacity
              activeOpacity={0.5}
              onPress={() =>
                props.navigation.navigate("EditProfileForm", {
                  data: user,
                })
              }
            >
              <SimpleLineIcons name="pencil" size={15} color="#000" />
            </TouchableOpacity>
          </View>

          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginHorizontal: 20,
              }}
            >
              <View>
                <Text style={{ fontWeight: "500", fontSize: 12 }}>Email</Text>
                <Text
                  style={{
                    fontSize: 12,
                    color: "#595959",
                    marginTop: 5,
                  }}
                >
                  {user?.emailId}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginHorizontal: 20,
              }}
            >
              <View>
                <Text style={{ fontWeight: "500", fontSize: 12 }}>
                  Phone Number
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    color: "#595959",
                    marginTop: 5,
                  }}
                >
                  {user?.phoneNumber}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              height: 40,
              marginVertical: 5,
              justifyContent: "center",
              alignItems: "center",
              paddingHorizontal: 20,
              marginTop: 20
            }}
          >
            <AuthButton name={loadingBtn ? "Loading..." : "Log Out"}
              onPress={() =>
                userId !== undefined && Platform.OS === "web"
                  ? confirm("Are you sure want to Logout?").valueOf(true) &&
                  logOut()
                  : Alert.alert("Are you sure want to Logout?", "", [
                    {
                      text: "Yes",
                      onPress: () => logOut(),
                    },
                    {
                      text: "No",
                      style: "cancel",
                    },
                  ])
              } />
          </View>
        </ScrollView>
      )}
    </View>
  );
};

export default Profile;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
