import { API_URL } from "./config";

export async function getReferenceDetails(authToken, id) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/reference/${id}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching");
  }
  return await res.json();
}

export async function getAllReference(authToken, id) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/reference/all/${id}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching");
  }
  return await res.json();
}

export const postReference = async (
  authToken,
  fullName,
  relationship,
  position,
  email,
  contact_number,
  reference_with_your_work_org,
  referenceFile,
  comments,
  nurseTableID,
) => {
  if (!authToken) {
    return;
  }
  console.log(JSON.stringify({
    fullName,
    relationship,
    position,
    email,
    contact_number,
    reference_with_your_work_org,
    referenceFile,
    comments,
    nurseTableID,
  }))
  const res = await fetch(`${API_URL}/reference`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "Application/json",
    },
    body: JSON.stringify({
      fullName,
      relationship,
      position,
      email,
      contact_number,
      reference_with_your_work_org,
      referenceFile,
      comments,
      nurseTableID,
    }),
  });
  if (res.status === 400) {
    console.log(res)
    throw new Error("Error during posting.");
  } else {
    return res.json();
  }
};

export const updateReference = async (
  authToken, id, data
) => {
  console.log(id, data)
  if (!authToken) {
    return;
  }
  const res = await fetch(`${API_URL}/reference/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "application/json", // "application/json" should be lowercase
    },
    body: JSON.stringify(data),
  });

  if (res.status === 400) {
    throw new Error("Network error");
  } else {
    return res.json();
  }
};

export const deleteReference = async (authToken) => {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/reference/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "application/json",
    },
  });

  console.log("Response status:", res.status);

  if (res.status === 200) {
    // Handle successful non-JSON response
    const textResult = await res.text();
    console.log("Non-JSON response:", textResult);
    return textResult;  // You may choose to return or handle textResult as needed
  } else if (res.status === 400) {
    throw new Error("Network error");
  } else {
    try {
      // Assuming JSON response for other status codes
      const jsonResult = await res.json();
      console.log("JSON response:", jsonResult);
      return jsonResult;
    } catch (error) {
      console.error("Error parsing JSON response:", error);
      throw error;
    }
  }
};