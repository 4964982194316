import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import React from "react";
import CustomToggle from "./CustomToggle";
import { useState } from "react";
import { registerForPushNotificationsAsync } from "../../../function/functionReturn";
import { updateDetails } from "../../../api_url/facilityTable";
import { useAuth } from "../../../context/AuthContext";

const Notification = () => {
  const { authToken, userId, userUniqueId } = useAuth();
  const [notifications, setNotifications] = useState(true);
  const [notificationsApp, setNotificationsApp] = useState(true);
  const [announcements, setAnnouncements] = useState(true);
  const [announcementsApp, setAnnouncementsApp] = useState(true);
  const [loading, setLoading] = useState(false);

  const checkUser = async (expoPushToken) => {
    setLoading(true);
    // console.log(expoPushToken);
    try {
      await updateDetails(
        authToken, userId,
        {
          mobileId: expoPushToken
        }
      )
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const updatedItem = () => {
    registerForPushNotificationsAsync().then((token) => checkUser(token.token));
  };

  return (
    <View style={styles.container}>
      <View style={{ alignItems: "flex-end" }}>
        <TouchableOpacity
          activeOpacity={0.4}
          onPress={() => updatedItem()}
          style={{
            paddingVertical: 3,
            paddingHorizontal: 10,
            marginHorizontal: 20,
            marginTop: 5,
            backgroundColor: "#00b359",
            borderRadius: 5,
            elevation: 1,
            shadowOpacity: 0.2,
            shadowRadius: 3.5,
            shadowOffset: {
              width: 0,
              height: 1,
            },
          }}
        >
          <Text style={{ color: "#fff" }}>
            {loading ? "Loading..." : "Sync Notification"}
          </Text>
        </TouchableOpacity>
      </View>
      <View
        style={{
          justifyContent: "space-between",
          flexDirection: "row",
          paddingHorizontal: 20,
          alignItems: "center",
          paddingVertical: 5,
          marginTop: 5,
        }}
      >
        <Text></Text>
        <View
          style={{
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Text style={{ paddingHorizontal: 15, fontSize: 12 }}>Text</Text>
          <Text style={{ paddingHorizontal: 15, fontSize: 12 }}>App</Text>
        </View>
      </View>
      <View
        style={{
          justifyContent: "space-between",
          flexDirection: "row",
          paddingHorizontal: 20,
          alignItems: "center",
          borderBottomWidth: 1,
          borderBottomColor: "#f3f3f3",
          paddingVertical: 5,
        }}
      >
        <Text>Notifications</Text>
        <View
          style={{
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <CustomToggle
            state={notifications}
            onValueChange={(value) => setNotifications(value)}
          />
          <CustomToggle
            state={notificationsApp}
            onValueChange={(value) => setNotificationsApp(value)}
          />
        </View>
      </View>
      <View
        style={{
          justifyContent: "space-between",
          flexDirection: "row",
          paddingHorizontal: 20,
          alignItems: "center",
          borderBottomWidth: 1,
          borderBottomColor: "#f3f3f3",
          paddingVertical: 5,
        }}
      >
        <Text>Announcements/Alerts</Text>
        <View
          style={{
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <CustomToggle
            state={announcements}
            onValueChange={(value) => setAnnouncements(value)}
          />
          <CustomToggle
            state={announcementsApp}
            onValueChange={(value) => setAnnouncementsApp(value)}
          />
        </View>
      </View>
    </View>
  );
};

export default Notification;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
