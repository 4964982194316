import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import React, { useEffect, useState } from "react";
import {
  BackHandler,
  Dimensions,
  StyleSheet,
  Text,
  View,
  Alert,
  Image,
  TouchableOpacity,
  Platform,
} from "react-native";
import AuthButton from "../../../customComponents/Button/AuthButton";
import TitleText from "../../../customComponents/Text/TitleText";
import OTPInput from "../VerifyOTP/OTPInput";
import { confirmSignUP, getConfirmCode } from "../../../api_url/authFacility";

function ConfirmCodeLogin({ setContainerScreen, state, setState }) {
  const { width, height } = Dimensions.get("window");
  const [otpCode, setOtpCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    if (Platform.OS !== "web") {
      const backAction = () => {
        Alert.alert("Hold on!", "Are you sure you want to go back?", [
          {
            text: "Cancel",
            onPress: () => null,
            style: "cancel",
          },
          { text: "YES", onPress: () => setContainerScreen("") },
        ]);
        return true;
      };

      const backHandler = BackHandler.addEventListener(
        "hardwareBackPress",
        backAction
      );

      return () => backHandler.remove();
    }
  }, []);

  // Confirm SignUP
  const confirmCodeSignUp = async (email, authCode) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      await confirmSignUP({ email: email, emailToken: authCode })
        .then(async res => {
          // console.log("res", res)
          setContainerScreen("")
          setLoading(false);
          setError(undefined)
        }).catch(e => {
          // console.log(e?.message);
          setLoading(false);
          setError(e?.message)
        });
    } catch (error) {
      console.log("error confirm :", error);
    }
    setLoading(false);
  };

  const statusBarHeight = Platform.OS === "android" ? 50 : 40;

  const confirmCode = async (email) => {
    if (loading) {
      return;
    }
    try {
      await getConfirmCode({ email: email })
        .then(async res => {
          // console.log("res", res)
          if (Platform.OS === "web") {
            alert("Success", "New OTP Code sent to your email on record")
          } else {
            Alert.alert("Success", "New OTP Code sent to your email on record")
          }
          setError(undefined)
        }).catch(e => {
          if (Platform.OS === "web") {
            alert("Alert", "Please try some other time!")
          } else {
            Alert.alert("Alert", "Please try some other time!")
          }
          // console.log(e?.message);
          setError(e?.message)
        });
    } catch (error) {
      console.log("error confirm :", error);
    }
    setLoading(false);
  };

  return (
    <View style={{ ...styles.container, marginTop: statusBarHeight }}>
      <View
        style={{
          height: 50,
          justifyContent: "center",
        }}
      >
        <TouchableOpacity
          onPress={() => setContainerScreen("")}
          activeOpacity={0.5}
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginLeft: 10,
          }}
        >
          <MaterialIcons name="keyboard-arrow-left" size={28} color="#808080" />

          <Image
            source={require("../../../../assets/login/NURSD-Flow.png")}
            style={{
              marginLeft: 0,
              marginBottom: 0,
              width: 75,
              height: 25,
            }}
          />
        </TouchableOpacity>
      </View>
      <View style={{ alignItems: "center", marginTop: 40 }}>
        <TitleText text={"Verify the OTP"} />
        <Text
          style={{
            fontSize: 13,
            textAlign: "center",
            marginTop: 20,
            marginBottom: 20,
            width: width / 1.3,
          }}
        >
          Please enter 6 digit verification code we just sent you on your email
        </Text>
        <OTPInput otpCode={otpCode} setOtpCode={setOtpCode} />
      </View>
      <View style={{ alignItems: "center", marginTop: 10 }}>
        <AuthButton
          name={loading ? "Loading..." : "Verify"}
          onPress={() =>
            otpCode.length < 6
              ? Alert.alert("Enter 6 digit code")
              : loading
                ? null
                : confirmCodeSignUp(state?.email, otpCode.toString())
          }
        />
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            color: "red",
            textAlign: "center",
            marginVertical: 10,
          }}
        >
          {error}
        </Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          marginTop: 20,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 13,
            textAlign: "center",
          }}
        >
          Need a New Code?
        </Text>
        <Text
          onPress={() => confirmCode(state?.email)}
          style={{ textAlign: "center", color: "#2775BD", marginLeft: 10 }}
        >
          Request a code
        </Text>
      </View>
      <Text
        style={{
          fontSize: 12,
          textAlign: "center",
          marginTop: 15,
        }}
        onPress={() => setContainerScreen("")}
      >
        Back to Login
      </Text>
    </View>
  );
}

export default ConfirmCodeLogin;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
