import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  Modal,
} from "react-native";
import React from "react";
import { useState } from "react";
import CustomInput from "../../../customComponents/Input/CustomInput";
import AuthButton from "../../../customComponents/Button/AuthButton";
import { KeyboardAvoidingView } from "react-native";
import { Platform } from "react-native";
import AutoFillAddress from "./AutoFillAddress";
import { signup } from "../../../api_url/authFacility";
import TitleText from "../../../customComponents/Text/TitleText";
import CustomToggle from "./CustomToggle";

const RegisterScreen = ({ setContainerScreen }) => {

  const [facilityState, setFacilityState] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNumber: "",
    password: "",
    emailVerified: false,
    facilityVerified: true,
  });
  const [showAddress, setShowAddress] = useState(false);
  const [address, setAddress] = useState({
    fullAddress: "",
    latitude: "",
    longitude: "",
  });

  const register = async (location_id, organization) => {
    await signup({
      email: facilityState.emailId,
      firstName: facilityState.firstName,
      lastName: facilityState.lastName,
      password: facilityState.password,
      phoneNumber: facilityState.phoneNumber,
      fullAddress: address.fullAddress,
      latitude: address.latitude,
      longitude: address.longitude,
      location_id: location_id,
      organization: organization,
      emailVerified: facilityState.emailVerified,
      facilityVerified: facilityState.facilityVerified,
    }).then(res => {
      console.log(res)
      setContainerScreen("")
    }).catch(e => {
      console.log(e)
    })
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : 50}
      style={styles.container}
    >
      <ScrollView showsVerticalScrollIndicator={false}>
        <View>
          <View style={{ alignItems: "center", marginTop: 100 }}>
            <Text style={{ color: "#00b359", fontWeight: "bold", fontSize: 20 }}>
              Welcome to NURSD
            </Text>
            <TitleText text={"Register"} />
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 10,
              marginHorizontal: 10,
            }}
          >
            <CustomInput
              label={"First Name"}
              required={true}
              onChangeText={(text) =>
                setFacilityState({ ...facilityState, firstName: text })
              }
              keyboardType={"default"}
              placeholder={"First Name"}
              value={facilityState.firstName}
              clearValue={() => setFacilityState({ ...facilityState, firstName: "" })}
              labelStyle={{
                marginHorizontal: 10,
              }}
              viewStyle={{
                height: 40,
                backgroundColor: "#f2f2f2",
                borderRadius: 10,
                elevation: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderWidth: 0.5,
                borderColor: "#e6e6e6",
                marginHorizontal: 10,
                paddingRight: 10,
                justifyContent: "space-between",
              }}
              textInputStyle={{
                height: 40,
                borderRadius: 10,
                paddingLeft: 10,
                fontSize: 12,
              }}
              iconStyle={{
                height: 40,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            />
            <CustomInput
              label={"Last Name"}
              required={true}
              onChangeText={(text) =>
                setFacilityState({ ...facilityState, lastName: text })
              }
              keyboardType={"default"}
              placeholder={"Last Name"}
              value={facilityState.lastName}
              clearValue={() => setFacilityState({ ...facilityState, lastName: "" })}
              labelStyle={{
                marginHorizontal: 10,
              }}
              viewStyle={{
                height: 40,
                backgroundColor: "#f2f2f2",
                borderRadius: 10,
                elevation: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderWidth: 0.5,
                borderColor: "#e6e6e6",
                marginHorizontal: 10,
                paddingRight: 10,
                justifyContent: "space-between",
              }}
              textInputStyle={{
                height: 40,
                borderRadius: 10,
                paddingLeft: 10,
                fontSize: 12,
              }}
              iconStyle={{
                height: 40,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            />
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 10,
              marginHorizontal: 10,
            }}
          >
            <CustomInput
              label={"Email ID"}
              required={true}
              onChangeText={(text) =>
                setFacilityState({ ...facilityState, emailId: text })
              }
              keyboardType={"email-address"}
              placeholder={"Email"}
              value={facilityState.emailId}
              clearValue={() => setFacilityState({ ...facilityState, emailId: "" })}
              labelStyle={{
                marginHorizontal: 10,
              }}
              viewStyle={{
                height: 40,
                backgroundColor: "#f2f2f2",
                borderRadius: 10,
                elevation: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderWidth: 0.5,
                borderColor: "#e6e6e6",
                marginHorizontal: 5,
                paddingRight: 20,
                justifyContent: "space-between",
              }}
              textInputStyle={{
                height: 40,
                borderRadius: 10,
                paddingLeft: 10,
                fontSize: 12,
              }}
              iconStyle={{
                height: 40,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            />
            <CustomInput
              label={"Phone Number"}
              required={true}
              onChangeText={(text) =>
                setFacilityState({ ...facilityState, phoneNumber: text })
              }
              keyboardType={"number-pad"}
              placeholder={"Phone Number"}
              value={facilityState.phoneNumber}
              clearValue={() =>
                setFacilityState({ ...facilityState, phoneNumber: "" })
              }
              labelStyle={{
                marginHorizontal: 10,
              }}
              viewStyle={{
                height: 40,
                backgroundColor: "#f2f2f2",
                borderRadius: 10,
                elevation: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderWidth: 0.5,
                borderColor: "#e6e6e6",
                marginHorizontal: 5,
                paddingRight: 10,
                justifyContent: "space-between",
              }}
              textInputStyle={{
                height: 40,
                borderRadius: 10,
                paddingLeft: 10,
                fontSize: 12,
              }}
              iconStyle={{
                height: 40,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            />
          </View>
          {/* <View style={{ marginHorizontal: 18, marginTop: 10 }}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text
                style={[
                  {
                    fontSize: 12,
                    color: "#737373",
                  },
                ]}
              >
                Address
              </Text>
              <Text style={{ color: "red" }}>*</Text>
            </View>
            <View
              style={{
                marginTop: 10,
                // height: 40,
              }}
            >
              <TouchableOpacity
                style={{
                  borderWidth: 0.5,
                  borderColor: "#e6e6e6",
                  backgroundColor: "#f2f2f2",
                  borderRadius: 10,
                  elevation: 1,
                  // height: 40,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingHorizontal: 10,
                  paddingVertical: 10,
                }}
                onPress={() => setShowAddress(true)}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    color: "#737373",
                  }}
                >
                  {address.fullAddress !== ""
                    ? address.fullAddress
                    : "Enter Address"}
                </Text>
                {address.fullAddress === "" && (
                  <MaterialIcons name={"add"} size={24} color="#808080" />
                )}
              </TouchableOpacity>
            </View>
          </View> */}
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10,
            marginHorizontal: 20,
          }}
        >
          <CustomInput
            value={facilityState.password}
            placeholder="Password"
            keyboardType={"default"}
            onChangeText={(text) => setFacilityState({ ...facilityState, password: text })}
            clearValue={() => setFacilityState({ ...facilityState, password: "" })}
            viewStyle={{
              height: 40,
              backgroundColor: "#f2f2f2",
              borderRadius: 10,
              elevation: 1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              borderWidth: 0.5,
              borderColor: "#e6e6e6",
              paddingRight: 10,
              justifyContent: "space-between",
              marginTop: -5,
            }}
            textInputStyle={{
              height: 40,
              borderRadius: 10,
              paddingLeft: 10,
              paddingRight: 5,
              fontSize: 12,
            }}
            iconStyle={{
              height: 40,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          />
        </View>
        {/* <View
          style={{
            justifyContent: "space-between",
            flexDirection: "row",
            paddingHorizontal: 10,
            marginHorizontal: 20,
            height: 42,
            elevation: 1,
            alignItems: "center",
            backgroundColor: "#f2f2f2",
            borderWidth: 1,
            borderColor: "#e6e6e6",
            borderRadius: 10,
            marginTop: 20,
          }}
        >
          <Text
            style={{
              fontSize: 12,
              color: "#737373",
            }}
          >
            Facility Verified
          </Text>
          <CustomToggle
            state={facilityState.facilityVerified}
            onValueChange={(value) =>
              setFacilityState({ ...facilityState, facilityVerified: value })
            }
          />
        </View> */}
        <View
          style={{
            justifyContent: "space-between",
            flexDirection: "row",
            paddingHorizontal: 10,
            marginHorizontal: 20,
            height: 42,
            elevation: 1,
            alignItems: "center",
            backgroundColor: "#f2f2f2",
            borderWidth: 1,
            borderColor: "#e6e6e6",
            borderRadius: 10,
            marginTop: 20,
          }}
        >
          <Text
            style={{
              fontSize: 12,
              color: "#737373",
            }}
          >
            Email Verified
          </Text>

          <CustomToggle
            state={facilityState.emailVerified}
            onValueChange={(value) =>
              setFacilityState({ ...facilityState, emailVerified: value })
            }
          />
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginVertical: 25,
          }}
        >
          <AuthButton
            name={"Register"}
            onPress={() => register("Chicago60540", "NURSD Health")}
          />
        </View>

        <Text
          style={{
            fontSize: 12,
            textAlign: "center",
            marginTop: 5,
          }}
          onPress={() => setContainerScreen("")}
        >
          Back to Login
        </Text>
      </ScrollView>

      <Modal
        animationType="fade"
        transparent={true}
        visible={showAddress}
        onRequestClose={() => setShowAddress(false)}
      >
        <AutoFillAddress
          setShowAddress={setShowAddress}
          address={address.fullAddress}
          setAddress={setAddress}
        />
      </Modal>
    </KeyboardAvoidingView>
  );
};

export default RegisterScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    justifyContent: "center",
  },
});
