import { API_URL } from "./config";

export async function getTemplateDetails(authToken, id) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/job-template/${id}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching template.");
  }
  return await res.json();
}

export async function getTemplateType(authToken, id, type) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/job-template/location/${id}/${type}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching template.");
  }
  return await res.json();
}

export async function getAllTemplate(authToken, id) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/job-template/location/${id}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching template.");
  }
  return await res.json();
}

export const postJobTemplate = async (
  authToken,
  licenseType,
  shiftTitle,
  specialty,
  certification,
  yearOfExperience,
  expiration,
  startDate,
  endDate,
  startTime,
  endTime,
  jobBreak,
  customerName,
  customerId,
  unit,
  floor,
  fullAddress,
  latitude,
  longitude,
  baseRate,
  enableBid,
  notes,
  jobType,
  autoAcceptedEnable,
  jobShiftTimingType,
  maxBidAmount,
  jobDuration,
  jobTiming,
  jobDays,
  EMRorEHRExperience,
  specialtyRequired,
  experienceRequired,
  emrehrRequired,
  certificationRequired,
  customerVisibility,
  jobPostingTableFacilityTableId,
  baseRateVisibility,
  startDateTimeStamp,
  organization,
  location_id,
  jobTemplateName
) => {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/job-template`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "Application/json",
    },
    body: JSON.stringify({
      licenseType,
      shiftTitle,
      specialty,
      certification,
      yearOfExperience,
      expiration,
      startDate,
      endDate,
      startTime,
      endTime,
      jobBreak,
      customerName,
      customerId,
      unit,
      floor,
      fullAddress,
      latitude,
      longitude,
      baseRate,
      enableBid,
      notes,
      jobType,
      autoAcceptedEnable,
      jobShiftTimingType,
      maxBidAmount,
      jobDuration,
      jobTiming,
      jobDays,
      EMRorEHRExperience,
      specialtyRequired,
      experienceRequired,
      emrehrRequired,
      certificationRequired,
      customerVisibility,
      jobPostingTableFacilityTableId,
      baseRateVisibility,
      startDateTimeStamp,
      organization,
      location_id,
      jobTemplateName
    }),
  });
  if (res.status === 400) {
    throw new Error("Error during job template posting.");
  } else {
    return res.json();
  }
};

export async function updateJobTemplate(authToken, id, data) {
  if (!authToken) {
    return;
  }
  // console.log(JSON.stringify(data)) 

  const res = await fetch(`${API_URL}/job-template/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "application/json", // "application/json" should be lowercase
    },
    body: JSON.stringify(data), // Convert data to JSON string
  });

  if (res.status === 400) {
    throw new Error("Failed to update the user. Try again!");
  }

  return await res.json();
}


export const deleteJobTemplate = async (authToken, id) => {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/job-template/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "application/json",
    },
  });

  console.log("Response status:", res.status);

  if (res.status === 200) {
    // Handle successful non-JSON response
    const textResult = await res.text();
    console.log("Non-JSON response:", textResult);
    return textResult;  // You may choose to return or handle textResult as needed
  } else if (res.status === 400) {
    throw new Error("Network error");
  } else {
    try {
      // Assuming JSON response for other status codes
      const jsonResult = await res.json();
      console.log("JSON response:", jsonResult);
      return jsonResult;
    } catch (error) {
      console.error("Error parsing JSON response:", error);
      throw error;
    }
  }
};