import { View, Text, Platform, TouchableOpacity } from "react-native";
import React, { useState, useEffect } from "react";
import TimeOffCardNurse from "./Card/TimeOffCardNurse";
import { ScrollView } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { getNurseAllTimeOff } from "../../../api_url/timeoffNurse";
import { getFacilityDetails } from "../../../api_url/facilityTable";
import { useAuth } from "../../../context/AuthContext";
import { ChatState } from "../../../context/ChatContext";

const TimeOffScreenNurse = (props) => {

  const { authToken, userId, userUniqueId } = useAuth();
  const { fetchAgainTimeOffListNurse, setFetchAgainTimeOffLisNurse } = ChatState();

  const [nurseID, setNurseID] = useState(props.route.params?.data?.userId);
  const [mobileId, setMobileId] = useState(props.route.params?.data?.mobileId);

  const [timeOff, setTimeOff] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const [facilityData, setFacilityData] = useState(undefined);

  //Facility Detail
  const getFacilityDetail = async (id) => {
    try {
      const facilityData = await getFacilityDetails(authToken, id)
      setFacilityData(facilityData[0])
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (nurseID !== undefined) {
      getFacilityTimeOff(nurseID);
    }
  }, [nurseID, fetchAgainTimeOffListNurse]);

  useEffect(() => {
    if (userId !== undefined) {
      getFacilityDetail(userId);
    }
  }, [userId]);

  //Get Jobs List in Selected Nurse
  const getFacilityTimeOff = async (id) => {
    const itemArr = await getNurseAllTimeOff(authToken, id)
    setTimeOff(itemArr.sort((a, b) => new Date(b?.startDate) - new Date(a?.startDate)));
    setLoading(false);
  };

  const onEditJobNavigate = (d) => {
    props?.navigation.navigate("RequestTimeOffScreen", {
      data: d,
    });
  };

  const [dateSort, setDateSort] = useState(null);

  if (dateSort) {
    timeOff?.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
  } else {
    timeOff?.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
  }

  return (
    <ScrollView style={{ flex: 1, backgroundColor: "#fff" }}
      showsVerticalScrollIndicator={false}
    >
      {loading ? (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Text>Loading...</Text>
        </View>
      ) : (
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: Platform.OS === "ios" ? 30 : 50,
          }}
        >
          <View
            style={{
              marginHorizontal: 5,
              marginTop: 10,
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                marginHorizontal: 10,
              }}
              onPress={() => setDateSort(!dateSort)}
              activeOpacity={0.5}
            >
              <Text>Date Sort</Text>

              <MaterialCommunityIcons name="sort" size={22} color="#595959" />
            </TouchableOpacity>
          </View>
          {timeOff?.length !== 0 ? (
            timeOff?.map((element, index) => {
              return (
                <TimeOffCardNurse
                  key={element?.id}
                  authToken={authToken}
                  element={element}
                  onEditJobNavigate={onEditJobNavigate}
                />
              );
            })
          ) : (
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <Text>Empty List</Text>
            </View>
          )}
        </View>
      )}
    </ScrollView>
  );
};

export default TimeOffScreenNurse;
