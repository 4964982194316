import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Image,
  ImageBackground,
  Alert,
  Linking,
  Modal,
  Dimensions,
  TextInput,
  Platform
} from "react-native";
import React, { useState, useEffect } from "react";
import * as DocumentPicker from "expo-document-picker";
import * as MediaLibrary from "expo-media-library";
// import Pdf from "react-native-pdf";
import { Entypo, Feather, Ionicons, MaterialIcons } from "@expo/vector-icons";
import AuthButton from "../../customComponents/Button/AuthButton";
import { sendPushNotification } from "../../utils/notification";
import { sendNotification } from "../../utils/webNotification";
import {
  openMap,
  jobUniqueId,
  timeDifferent,
  totalAmount,
  DateFormat,
  GetDateMergedTime,
  jobUniqueIdDoc
} from "../../utils/function";
import moment from "moment";
import UserJobCard from "./UserJobCard";
import AutoFillAddressModel from "./AutoFillAddressModel";
import CustomDropdownScreen from "../../customComponents/DropDown/CustomDropdownScreen";
import {
  getNurseAvaJobDate,
  getNurseDetails,
  getNurseUniqueIdDetails,
  getSwapNurseAvailableList
} from "../../api_url/nurseTable";
import { useAuth } from "../../context/AuthContext";
import {
  deleteJob,
  getJobDetails,
  updateJobTable
} from "../../api_url/jobTable";
import { postNurseNotification } from "../../api_url/nurseNotification";
import { postFacilityNotification } from "../../api_url/facilityNotification";
import {
  getAllFacilityList,
  getFacilityDetails
} from "../../api_url/facilityTable";
import { ChatState } from "../../context/ChatContext";
import { uploadFile } from "../../screens/More/AWSHelper";
import { socket } from "../../api_url/socket";
import { getReasonAll } from "../../api_url/reason";
import { getAcceptJobDetail } from "../../api_url/nurseDashboard";

const JobDetailsScreen = (props) => {
  const { width, height } = Dimensions.get("window");

  const { authToken, userId, userUniqueId } = useAuth();
  const {
    fetchAgainJobDetails,
    setFetchAgainJobDetails,
    fetchAgainJobDashboard,
    setFetchAgainJobDashboard,
    fetchAgainJobList,
    setFetchAgainJobList
  } = ChatState();
  const jobId = props !== undefined && props?.route?.params?.data?.id;
  console.log("JobDetailsScreen", jobId);

  // const [userId, setUserId] = useState(props?.userId);

  const [job, setJob] = useState(undefined);
  const [acceptedJobs, setAcceptedJobs] = useState(undefined);
  const [nurses, setNurses] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [pendingNeverCheckOutModel, setPendingNeverCheckOutModel] =
    useState(false);
  const [pendingNeverCheckOutModelAction, setPendingNeverCheckOutModelAction] =
    useState(false);
  const [noShowViewModel, setNoShowViewModel] = useState(false);
  const [noShowViewModelAction, setNoShowViewModelAction] = useState(false);
  const [loading, setLoading] = useState(false);
  //Cancel
  const [removeNurse, setRemoveNurse] = useState(false);
  const [removeNurseReason, setRemoveNurseReason] = useState("");
  const [removeNurseObj, setRemoveNurseObj] = useState(undefined);
  //Cancel Bid
  const [removeNurseBid, setRemoveNurseBid] = useState(false);
  const [removeNurseBidReason, setRemoveNurseBidReason] = useState("");
  const [removeNurseBidObj, setRemoveNurseBidObj] = useState(undefined);
  let availableArr = [];
  const [nurseSwap, setNurseSwap] = useState(false);
  const [nurseSwapReason, setNurseSwapReason] = useState("");
  const [jobFinalSelectionNurseId, setJobFinalSelectionNurseId] = useState("");
  const [available, setAvailable] = useState(undefined);

  const [viewPDF, setViewPDF] = useState(false);
  const [viewPDFURI, setViewPDFURI] = useState("");
  const [swapOldNurse, setSwapOldNurse] = useState(undefined);
  const [completeJob, setCompleteJob] = useState(false);
  const [completeJobComment, setCompleteJobComment] = useState("");
  const [jobPostedFacilityDetails, setJobPostedFacilityDetails] =
    useState(undefined);

  const [
    pendingOrNoShowFacilityDecideMessage,
    setPendingOrNoShowFacilityDecideMessage
  ] = useState("");
  const [noShowMessage, setNoShowMessage] = useState("");
  const [neverCheckOutMessage, setNeverCheckOutMessage] = useState("");
  const [reason, setReason] = useState("");

  const [missedReason, setMissedReason] = useState(undefined);

  const [noShowAttachment, setNoShowAttachment] = useState("");
  const [noShowAttachmentURI, setNoShowAttachmentURI] = useState("");
  const [pendingNeverCheckOutAttachment, setPendingNeverCheckOutAttachment] =
    useState("");
  const [
    pendingNeverCheckOutAttachmentURI,
    setPendingNeverCheckOutAttachmentURI
  ] = useState("");
  const [showAddress, setShowAddress] = useState(false);
  const [showAddressEnd, setShowAddressEnd] = useState(false);
  const [jobClockDetails, setJobClockDetails] = useState({
    checkInTimeNurse: moment(),
    checkInFullAddressNurse: "",
    checkInLatitudeNurse: "",
    checkInLongitudeNurse: "",
    checkOutTimeNurse: moment(),
    checkOutFullAddressNurse: "",
    checkOutLatitudeNurse: "",
    checkOutLongitudeNurse: ""
  });

  const [showTimeWeb, setShowTimeWeb] = useState(false);
  const [showTimeEndWeb, setShowTimeEndWeb] = useState(false);

  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownMinute, setShowDropdownMinute] = useState(false);
  const [showDropdownAP, setShowDropdownAP] = useState(false);

  const now = new Date();
  const currentMinutes = now.getMinutes().toString().padStart(2, "0");
  const currentAmPm = now.getHours() >= 12 ? "PM" : "AM";
  const [selectedHourTime, setSelectedHourTime] = useState(
    now.getHours() === 0
      ? 12
      : now.getHours() > 12
      ? now.getHours() - 12
      : now.getHours()
  );
  const [selectedMinuteTime, setSelectedMinuteTime] = useState(currentMinutes);
  const [selectedAPTime, setSelectedAPTime] = useState(currentAmPm);

  const [showDropdownEnd, setShowDropdownEnd] = useState(false);
  const [showDropdownMinuteEnd, setShowDropdownMinuteEnd] = useState(false);
  const [showDropdownAPEnd, setShowDropdownAPEnd] = useState(false);

  const [selectedHourTimeEnd, setSelectedHourTimeEnd] = useState(
    now.getHours() === 0
      ? 12
      : now.getHours() > 12
      ? now.getHours() - 12
      : now.getHours()
  );

  const [selectedMinuteTimeEnd, setSelectedMinuteTimeEnd] =
    useState(currentMinutes);
  const [selectedAPTimeEnd, setSelectedAPTimeEnd] = useState(currentAmPm);

  useEffect(() => {
    if (job !== undefined) {
      setJobClockDetails({
        ...jobClockDetails,
        checkInTimeNurse:
          job?.checkInTime !== "" ? job?.checkInTimeNurse : job?.startTime,
        checkOutTimeNurse:
          job?.checkOutTime !== "" ? job?.checkOutTimeNurse : job?.endTime
      });
    }
  }, [job]);

  const hour = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const minutes = [0, 15, 30, 45];
  const ampm = ["AM", "PM"];

  const onChatRoomNavigate = (d) => {
    props?.navigation.navigate("ChatRoomScreen", {
      data: d
    });
  };

  const getReasons = async (authToken) => {
    try {
      const data = await getReasonAll(authToken);
      setMissedReason(data[0]?.facilityReasons?.map((i) => i?.reason));
    } catch (error) {
      console.log("getReasons error:", error);
    }
  };

  const getNurseDetail = async (id) => {
    if (id == null) {
      return [];
    } else {
      try {
        const nurseData = await getNurseUniqueIdDetails(authToken, id);
        return nurseData;
      } catch (error) {
        console.log("getNurseDetail error:", error);
        return [];
      }
    }
  };

  const getNursesDetails = async (data) => {
    const nursePromises = data
      ?.filter((id) => id != null)
      .map((id) => getNurseDetail(id));
    const nursesData = await Promise.all(nursePromises);
    return nursesData;
  };

  const getNursesMobileIds = async (data) => {
    const nursePromises = data
      ?.filter((id) => id != null)
      .map((id) => getNurseDetail(id));
    const nursesData = await Promise.all(nursePromises);
    return nursesData;
  };

  useEffect(() => {
    if (job?.jobAcceptedNurses.length > 0) {
      getNursesDetails(job?.jobAcceptedNurses)
        .then((nursesData) => setNurses(nursesData))
        .catch((error) => console.error(error));
    }
  }, [job]);

  //Get Jobs List in Particular Facility
  const getUpdatedJob = async (id) => {
    await getJobDetails(authToken, id)
      .then((res) => {
        setJob(res);
      })
      .catch((e) => console.log("getUpdatedJob error" + e));
  };

  useEffect(() => {
    if (jobId !== undefined) {
      getUpdatedJob(jobId);
      getReasons(authToken);
    }
  }, [authToken, jobId, fetchAgainJobDetails]);

  const downloadAndOpenPDF = async (link) => {
    // Download the PDF file to the device's file system

    // const response = await Storage.get(name, {
    //   level: "public/",
    // });
    // const localUri = FileSystem.documentDirectory + "filename.pdf";

    // await FileSystem.writeAsStringAsync(localUri, response.Body, {
    //   encoding: FileSystem.EncodingType.Base64,
    // });
    // return localUri;
    // console.log(link)
    Linking.openURL(link);

    // setViewPDFURI(link);
    // setViewPDF(true);
  };
  // console.log(job)

  const removeValue = (array, value) => {
    const filteredArray = array?.filter((item) => item !== value);
    return filteredArray.length !== array.length ? filteredArray : array;
  };

  const removeDeclinedValue = (array, value) => {
    const filteredArray = array?.filter((item) => item?.id !== value);
    return filteredArray.length !== array.length
      ? filteredArray?.map((i) => ({
          id: i?.id,
          type: i?.type
        }))
      : array?.map((i) => ({
          id: i?.id,
          type: i?.type
        }));
  };

  const removeBidValue = (array, value) => {
    const filteredArray = array?.filter(
      (item) => item?.jobBitTableNurseTableId !== value
    );
    return filteredArray.length !== array.length ? filteredArray : array;
  };

  const getFacilityLocationDetail = async (id) => {
    try {
      const facilityData = await getAllFacilityList(authToken, id, "facility");
      return facilityData;
    } catch (error) {
      console.log("getFacilityLocationDetail error:", error);
    }
  };

  //Normal job approved function
  const selectNurse = async (id, uniqueId, type, mobileId) => {
    if (type === "Agency") {
      await updateJobTable(authToken, job?.id, {
        jobFinalSelectionNurseId: uniqueId?.toString(),
        jobAcceptedNurses: [uniqueId?.toString()],
        approved_manager: job?.jobPostingTableFacilityTableId,
        jobStatus: "Nurse Assigned",
        jobCompletedBy: job?.jobSharedType,
        jobCompletedAgencyId: Number(job?.jobSharedId)
      }).catch((e) => console.log("selectNurse updateJobTable error" + e));
      getUpdatedJob(jobId);
    } else {
      const res = await getNurseAvaJobDate(
        authToken,
        id,
        uniqueId,
        job?.startDate,
        job?.endDate,
        moment(new Date(job?.startTime)).format("hh:mm a"),
        moment(new Date(job?.endTime)).format("hh:mm a")
      );

      if (res?.availableTime) {
        try {
          job?.jobRequestedNurses?.forEach(async (nurseData) => {
            const nurseDataUniqueId = nurseData?.uniqueId;
            const nurseDataId = nurseData?.id;
            const nurseDataMobileId = nurseData?.mobileId;
            const nurseType = nurseData?.nurseType;

            if (nurseDataUniqueId !== uniqueId) {
              if (nurseType === "Internal Pool") {
                await postNurseNotification(
                  authToken,
                  nurseDataId,
                  "",
                  jobUniqueId(job?.id, job?.jobType),
                  "Your Request Expired for this job",
                  "JobDetailsScreen",
                  {
                    id: job?.id
                  },
                  false,
                  false,
                  "red",
                  ""
                );
                if (nurseDataMobileId) {
                  sendNotification(authToken, {
                    expoPushToken: nurseDataMobileId,
                    title: job?.shiftTitle,
                    body: `Your Request Expired for job ${jobUniqueId(
                      job?.id,
                      job?.jobType
                    )}`,
                    data: { jobId: job?.id, screen: "JobDetailScreen" }
                  });
                }
                socket?.emit("tableNotificationInsert", {
                  table: "NurseNotificationTable",
                  id: nurseDataId
                });
              }
            }
          });

          await updateJobTable(authToken, job?.id, {
            jobFinalSelectionNurseId: uniqueId?.toString(),
            jobAcceptedNurses: [uniqueId?.toString()],
            approved_manager: job?.jobPostingTableFacilityTableId,
            jobCompletedBy: type,
            jobStatus: "Nurse Assigned"
          }).catch((e) => console.log("selectNurse updateJobTable error" + e));
          socket?.emit("tableJobInsert", {
            table: "JobPostingTable",
            message: "Fetch Dashboard Job"
          });
          await postNurseNotification(
            authToken,
            id,
            "",
            jobUniqueId(job?.id, job?.jobType),
            `You got approved for this job`,
            "JobDetailsScreen",
            {
              id: job?.id
            },
            false,
            false,
            "green",
            ""
          );
          if (mobileId) {
            sendNotification(authToken, {
              expoPushToken: mobileId,
              title: job?.shiftTitle,
              body: `Approved for job ${jobUniqueId(job?.id, job?.jobType)}`,
              data: { jobId: job?.id, screen: "JobDetailScreen" }
            });
          }
          socket?.emit("tableNotificationInsert", {
            table: "NurseNotificationTable",
            id: id
          });
          if (
            parseInt(job?.jobPostingTableFacilityTableId) !==
            parseInt(job?.jobPostingTableFacilityTableId)
          ) {
            const facilityDetail = await getFacilityDetail(
              job?.jobPostingTableFacilityTableId
            );
            await postFacilityNotification(
              authToken,
              job?.jobPostingTableFacilityTableId,
              "",
              jobUniqueId(job?.id, job?.jobType),
              `Your job Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
              "JobDetailsScreen",
              {
                id: job?.id
              },
              false,
              false,
              "green",
              ""
            );
            socket?.emit("tableNotificationInsert", {
              table: "FacilityNotificationTable",
              id: job?.jobPostingTableFacilityTableId
            });
            if (job?.manager?.mobileId) {
              sendNotification(authToken, {
                expoPushToken: job?.manager?.mobileId,
                title: jobUniqueId(job?.id, job?.jobType),
                body: `Your job Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                data: { jobId: job?.id, screen: "JobDetailScreen" }
              });
            }
          }
          getUpdatedJob(jobId);
        } catch (error) {
          console.log("selectNurse error:", error);
        }
      } else {
        alert(`Date Conflict. Choose a different date.`);
      }
    }
  };

  const cancelNurse = async () => {
    setLoading(true);
    try {
      if (removeNurseObj?.type === "Agency") {
        await updateJobTable(authToken, job?.id, {
          jobFinalSelectionNurseId: "",
          jobRequestedNurses: [],
          jobAcceptedNurses: [],
          approved_manager: "",
          jobStatus: "Open",
          jobCompletedAgencyId: 0,
          jobCompletedBy: "",
          jobSharedAction: "Rejected",
          nurseCancelNotes: !Array.isArray(job?.nurseCancelNotes)
            ? [
                {
                  nurseId: removeNurseObj?.id,
                  nurseName: removeNurseObj?.name,
                  cancelNote: removeNurseReason
                }
              ]
            : [
                ...job?.nurseCancelNotes,
                {
                  nurseId: removeNurseObj?.id,
                  nurseName: removeNurseObj?.name,
                  cancelNote: removeNurseReason
                }
              ]
        }).catch((e) => console.log("cancelNurse updateJobTable error" + e));
      } else {
        await updateJobTable(authToken, job?.id, {
          jobFinalSelectionNurseId: "",
          jobRequestedNurses: [],
          jobAcceptedNurses: [],
          approved_manager: "",
          jobStatus: "Open",
          jobCompletedBy: "",
          nurseCancelNotes: !Array.isArray(job?.nurseCancelNotes)
            ? [
                {
                  nurseId: removeNurseObj?.id,
                  nurseName: removeNurseObj?.name,
                  cancelNote: removeNurseReason
                }
              ]
            : [
                ...job?.nurseCancelNotes,
                {
                  nurseId: removeNurseObj?.id,
                  nurseName: removeNurseObj?.name,
                  cancelNote: removeNurseReason
                }
              ]
        }).catch((e) => console.log("cancelNurse updateJobTable error" + e));
      }
      socket?.emit("tableJobInsert", {
        table: "JobPostingTable",
        message: "Fetch Dashboard Job"
      });

      await postNurseNotification(
        authToken,
        Number(removeNurseObj?.id),
        "",
        jobUniqueId(job?.id, job?.jobType),
        "You have been released from the assignment. For any questions, please reach out to Manager",
        "JobDetailsScreen",
        {
          id: job?.id
        },
        false,
        false,
        "red",
        ""
      );
      if (removeNurseObj?.mobileId) {
        sendNotification(authToken, {
          expoPushToken: removeNurseObj?.mobileId,
          title: jobUniqueId(job?.id, job?.jobType) + " " + job?.shiftTitle,
          body: "You have been released from the assignment. For any questions, please reach out to Manager",
          data: { jobId: job?.id, screen: "JobDetailScreen" }
        });
      }
      socket?.emit("tableNotificationInsert", {
        table: "NurseNotificationTable",
        id: removeNurseObj?.id
      });
      //If other manager approved sending notification to job origin manager
      if (
        parseInt(job?.jobPostingTableFacilityTableId) !==
        parseInt(job?.jobPostingTableFacilityTableId)
      ) {
        const facilityDetail = await getFacilityDetail(
          job?.jobPostingTableFacilityTableId
        );
        await postFacilityNotification(
          authToken,
          job?.jobPostingTableFacilityTableId,
          "",
          jobUniqueId(job?.id, job?.jobType),
          `This ${facilityDetail?.firstName} ${facilityDetail?.lastName} manager removed the nurse.`,
          "JobDetailsScreen",
          {
            id: job?.id
          },
          false,
          false,
          "red",
          ""
        );
        socket?.emit("tableNotificationInsert", {
          table: "FacilityNotificationTable",
          id: job?.jobPostingTableFacilityTableId
        });

        if (job?.manager?.mobileId) {
          sendNotification(authToken, {
            expoPushToken: job?.manager?.mobileId,
            title: jobUniqueId(job?.id, job?.jobType),
            body: `This ${facilityDetail?.firstName} ${facilityDetail?.lastName} manager removed the nurse.`,
            data: { jobId: job?.id, screen: "JobDetailScreen" }
          });
        }
      }
      setRemoveNurse(false);
      setRemoveNurseReason("");
      setRemoveNurseObj(undefined);
      setLoading(false);
      getUpdatedJob(jobId);
    } catch (error) {
      console.log("Cancel Nurse error:", error);
    }
  };

  const selectNurseBid = async (id, uniqueId, type, mobileId, amount) => {
    if (type === "Agency") {
      await updateJobTable(authToken, job?.id, {
        jobFinalSelectionNurseId: uniqueId?.toString(),
        jobAcceptedNurses: [uniqueId?.toString()],
        approved_manager: job?.jobPostingTableFacilityTableId,
        jobStatus: "Nurse Assigned",
        jobCompletedBy: job?.jobSharedType,
        jobCompletedAgencyId: Number(job?.jobSharedId)
      }).catch((e) => console.log("selectNurseBid updateJobTable error" + e));
      getUpdatedJob(jobId);
    } else {
      try {
        const res = await getNurseAvaJobDate(
          authToken,
          id,
          uniqueId,
          job?.startDate,
          job?.endDate,
          moment(new Date(job?.startTime)).format("hh:mm a"),
          moment(new Date(job?.endTime)).format("hh:mm a")
        );
        if (res?.availableTime) {
          await updateJobTable(authToken, job?.id, {
            jobFinalSelectionNurseId: uniqueId?.toString(),
            jobAcceptedNurses: [uniqueId?.toString()],
            approved_manager: job?.jobPostingTableFacilityTableId,
            jobCompletedBy: type,
            jobBitFinalAmount: amount,
            jobStatus: "Nurse Assigned"
          }).catch((e) =>
            console.log("selectNurseBid updateJobTable error" + e)
          );
          socket?.emit("tableJobInsert", {
            table: "JobPostingTable",
            message: "Fetch Dashboard Job"
          });
          await postNurseNotification(
            authToken,
            id,
            "",
            jobUniqueId(job?.id, job?.jobType),
            "Your Bid-Approved for this job",
            "JobDetailsScreen",
            {
              id: job?.id
            },
            false,
            false,
            "green",
            ""
          );
          socket?.emit("tableNotificationInsert", {
            table: "NurseNotificationTable",
            id: id
          });
          if (mobileId) {
            sendNotification(authToken, {
              expoPushToken: mobileId,
              title: job?.shiftTitle,
              body: `Your Bid-Approved for this job ${jobUniqueId(
                job?.id,
                job?.jobType
              )}`,
              data: { jobId: job?.id, screen: "JobDetailScreen" }
            });
          }
          job?.jobRequestedNurses?.forEach(async (nurseData) => {
            const nurseDataUniqueId = nurseData?.uniqueId;
            const nurseDataId = nurseData?.id;
            const nurseDataMobileId = nurseData?.mobileId;
            const nurseType = nurseData?.nurseType;
            if (nurseDataUniqueId !== uniqueId) {
              if (nurseType === "Internal Pool") {
                await postNurseNotification(
                  authToken,
                  nurseDataId,
                  "",
                  jobUniqueId(job?.id, job?.jobType),
                  "Your Bid-Expired for this job",
                  "JobDetailsScreen",
                  {
                    id: job?.id
                  },
                  false,
                  false,
                  "red",
                  ""
                );
                socket?.emit("tableNotificationInsert", {
                  table: "NurseNotificationTable",
                  id: nurseDataId
                });
                if (!nurseDataMobileId) {
                  return;
                }
                sendNotification(authToken, {
                  expoPushToken: nurseDataMobileId,
                  title: job?.shiftTitle,
                  body: `Your Bid-Expired for job ${jobUniqueId(
                    job?.id,
                    job?.jobType
                  )}`,
                  data: { jobId: job?.id, screen: "JobDetailScreen" }
                });
              }
            }
          });
          if (
            parseInt(job?.jobPostingTableFacilityTableId) !==
            parseInt(job?.jobPostingTableFacilityTableId)
          ) {
            const facilityDetail = await getFacilityDetail(
              job?.jobPostingTableFacilityTableId
            );
            await postFacilityNotification(
              authToken,
              job?.jobPostingTableFacilityTableId,
              "",
              jobUniqueId(job?.id, job?.jobType),
              `Your Bid-Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
              "JobDetailsScreen",
              {
                id: job?.id
              },
              false,
              false,
              "green",
              ""
            );
            socket?.emit("tableNotificationInsert", {
              table: "FacilityNotificationTable",
              id: job?.id
            });
            if (job?.manager?.mobileId) {
              sendNotification(authToken, {
                expoPushToken: job?.manager?.mobileId,
                title: jobUniqueId(job?.id, job?.jobType),
                body: `Your Bid-Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                data: { jobId: job?.id, screen: "JobDetailScreen" }
              });
            }
          }
          getUpdatedJob(jobId);
        } else {
          alert(`Date Conflict. Choose a different date.`);
        }
      } catch (error) {
        console.log("selectNurseBid save error:", error);
      }
    }
  };

  const cancelNurseBid = async () => {
    setLoading(true);
    try {
      await updateJobTable(authToken, job?.id, {
        jobFinalSelectionNurseId: "",
        jobRequestedNurses: [],
        jobAcceptedNurses: [],
        approved_manager: "",
        jobStatus: "Open",
        jobCompletedBy: "",
        jobBitFinalAmount: removeNurseBidObj?.amount,
        nurseCancelNotes: !Array.isArray(job?.nurseCancelNotes)
          ? [
              {
                nurseId: removeNurseBidObj?.id,
                nurseName: removeNurseBidObj?.name,
                cancelNote: removeNurseReason
              }
            ]
          : [
              ...job?.nurseCancelNotes,
              {
                nurseId: removeNurseBidObj?.id,
                nurseName: removeNurseBidObj?.name,
                cancelNote: removeNurseReason
              }
            ],
        bidNurseArray: removeBidValue(job?.bidNurseArray, removeNurseBidObj?.id)
      }).catch((e) => console.log("cancelNurseBid updateJobTable error" + e));
      if (removeNurseBidObj?.type === "Internal Pool") {
        socket?.emit("tableJobInsert", {
          table: "JobPostingTable",
          message: "Fetch Dashboard Job"
        });
        await postNurseNotification(
          authToken,
          Number(removeNurseBidObj?.id),
          "",
          jobUniqueId(job?.id, job?.jobType),
          "You have been released from the assignment. For any questions, please reach out to Manager",
          "JobDetailsScreen",
          {
            id: job?.id
          },
          false,
          false,
          "red",
          ""
        );
        socket?.emit("tableNotificationInsert", {
          table: "NurseNotificationTable",
          id: removeNurseBidObj?.id
        });
        if (removeNurseBidObj?.mobileId) {
          sendNotification(authToken, {
            expoPushToken: removeNurseBidObj?.mobileId,
            title: jobUniqueId(job?.id, job?.jobType) + " " + job?.shiftTitle,
            body: "You have been released from the assignment. For any questions, please reach out to Manager",
            data: { jobId: job?.id, screen: "JobDetailScreen" }
          });
        }
      }
      if (
        parseInt(job?.jobPostingTableFacilityTableId) !==
        parseInt(job?.jobPostingTableFacilityTableId)
      ) {
        const facilityDetail = await getFacilityDetail(
          job?.jobPostingTableFacilityTableId
        );
        await postFacilityNotification(
          authToken,
          job?.jobPostingTableFacilityTableId,
          "",
          jobUniqueId(job?.id, job?.jobType),
          `This ${facilityDetail?.firstName} ${facilityDetail?.lastName} manager removed the nurse.`,
          "JobDetailsScreen",
          {
            id: job?.id
          },
          false,
          false,
          "red",
          ""
        );
        socket?.emit("tableNotificationInsert", {
          table: "FacilityNotificationTable",
          id: job?.jobPostingTableFacilityTableId
        });

        if (job?.manager?.mobileId) {
          sendNotification(authToken, {
            expoPushToken: job?.manager?.mobileId,
            title: jobUniqueId(job?.id, job?.jobType),
            body: `This ${facilityDetail?.firstName} ${facilityDetail?.lastName} manager removed the nurse.`,
            data: { jobId: job?.id, screen: "JobDetailScreen" }
          });
        }
      }
      setRemoveNurseBid(false);
      setRemoveNurseReason("");
      setRemoveNurseBidObj(undefined);
      setLoading(false);
      getUpdatedJob(jobId);
    } catch (error) {
      console.log("cancelNurseBid save error:", error);
    }
  };

  const declinedNurseAction = async (id, uniqueId, mobileId, type, name) => {
    let jobDetails = await getAcceptJobDetail(authToken, job?.id);
    let ava = jobDetails[0]?.bidNurseArray?.find(
      (item) => item?.jobBitTableNurseTableId === id
    );
    if (ava === undefined) {
      try {
        if (type === "Agency") {
          await updateJobTable(authToken, jobDetails[0]?.id, {
            jobFinalSelectionNurseId: "",
            jobRequestedNurses: removeDeclinedValue(
              jobDetails[0]?.jobRequestedNurses,
              uniqueId
            ),
            jobStatus:
              jobDetails[0]?.jobRequestedNurses?.length <= 1
                ? "Open"
                : "Pending Assignment",
            jobCompletedBy: "",
            jobSharedAction: "Rejected"
          }).catch((e) =>
            console.log("declinedNurseAction updateJobTable error", e)
          );
        } else {
          await updateJobTable(authToken, jobDetails[0]?.id, {
            jobFinalSelectionNurseId: "",
            jobRequestedNurses: removeDeclinedValue(
              jobDetails[0]?.jobRequestedNurses,
              uniqueId
            ),
            jobStatus:
              jobDetails[0]?.jobRequestedNurses?.length <= 1
                ? "Open"
                : "Pending Assignment"
          }).catch((e) =>
            console.log("declinedNurseAction updateJobTable error", e)
          );
        } 
          socket?.emit("tableJobInsert", {
            table: "JobPostingTable",
            message: "Fetch Dashboard Job"
          });
          await postNurseNotification(
            authToken,
            id,
            "",
            jobUniqueId(jobDetails[0]?.id, jobDetails[0]?.jobType),
            "This job request has been denied. For further queries, please reach out to Manager",
            "JobDetailsScreen",
            {
              id: jobDetails[0]?.id
            },
            false,
            false,
            "red",
            ""
          );
          socket?.emit("tableNotificationInsert", {
            table: "NurseNotificationTable",
            id: id
          });
          if (mobileId) {
            sendNotification(authToken, {
              expoPushToken: mobileId,
              title:
                jobUniqueId(jobDetails[0]?.id, jobDetails[0]?.jobType) +
                " " +
                jobDetails[0]?.shiftTitle,
              body: "This job request has been denied. For further queries, please reach out to Manager",
              data: { jobId: jobDetails[0]?.id, screen: "JobDetailScreen" }
            });
          } 
        //Sending notification to other manager
        getFacilityLocationDetail(jobDetails[0]?.jobPostingTableFacilityTableId)
          .then(async (facilitiesData) => {
            for (const facility of facilitiesData) {
              if (
                parseInt(jobDetails[0]?.jobPostingTableFacilityTableId) !==
                parseInt(facility?.id)
              ) {
                try {
                  const facilityDetail = await getFacilityDetail(
                    jobDetails[0]?.jobPostingTableFacilityTableId
                  );
                  await postFacilityNotification(
                    authToken,
                    facility?.id,
                    "",
                    jobUniqueId(jobDetails[0]?.id, jobDetails[0]?.jobType),
                    `This job has been cancelled to ${name}-${type} by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                    "JobDetailsScreen",
                    {
                      id: jobDetails[0]?.id
                    },
                    false,
                    false,
                    "red",
                    ""
                  );
                  socket?.emit("tableNotificationInsert", {
                    table: "FacilityNotificationTable",
                    id: facility?.id
                  });
                  if (facility?.mobileId) {
                    sendNotification(authToken, {
                      expoPushToken: facility?.mobileId,
                      title: jobUniqueId(
                        jobDetails[0]?.id,
                        jobDetails[0]?.jobType
                      ),
                      body: `This job has been cancelled to ${name} by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                      data: {
                        jobId: jobDetails[0]?.id,
                        screen: "JobDetailScreen"
                      }
                    });
                  }
                } catch (error) {
                  console.error("Error fetching facility detail:", error);
                }
              }
            }
          })
          .catch((error) => console.error(error));
        getUpdatedJob(jobId);
      } catch (error) {
        console.log("declinedNurseAction error", e);
      }
    }
    //Bid Nurse Cancel
    else {
      try {
        await updateJobTable(authToken, jobDetails[0]?.id, {
          jobFinalSelectionNurseId: "",
          jobRequestedNurses: removeDeclinedValue(
            jobDetails[0]?.jobRequestedNurses,
            uniqueId
          ),
          jobStatus:
            jobDetails[0]?.jobRequestedNurses?.length <= 1
              ? "Open"
              : "Pending Assignment",
          bidNurseArray: removeBidValue(jobDetails[0]?.bidNurseArray, id)
        }).catch((e) => console.log(e));
        socket?.emit("tableJobInsert", {
          table: "JobPostingTable",
          message: "Fetch Dashboard Job"
        });
        await postNurseNotification(
          authToken,
          id,
          "",
          jobUniqueId(jobDetails[0]?.id, jobDetails[0]?.jobType),
          "This job request has been denied. For further queries, please reach out to Manager",
          "JobDetailsScreen",
          {
            id: jobDetails[0]?.id
          },
          false,
          false,
          "red",
          ""
        );
        socket?.emit("tableNotificationInsert", {
          table: "NurseNotificationTable",
          id: id
        });
        if (mobileId) {
          sendNotification(authToken, {
            expoPushToken: mobileId,
            title:
              jobUniqueId(jobDetails[0]?.id, jobDetails[0]?.jobType) +
              " " +
              jobDetails[0]?.shiftTitle,
            body: "This job request has been denied. For further queries, please reach out to Manager",
            data: { jobId: jobDetails[0]?.id, screen: "JobDetailScreen" }
          });
        }
        //Sending notification to other manager
        getFacilityLocationDetail(jobDetails[0]?.jobPostingTableFacilityTableId)
          .then(async (facilitiesData) => {
            for (const facility of facilitiesData) {
              if (
                parseInt(jobDetails[0]?.jobPostingTableFacilityTableId) !==
                parseInt(facility?.id)
              ) {
                try {
                  const facilityDetail = await getFacilityDetail(
                    jobDetails[0]?.jobPostingTableFacilityTableId
                  );
                  await postFacilityNotification(
                    authToken,
                    facility?.id,
                    "",
                    jobUniqueId(jobDetails[0]?.id, jobDetails[0]?.jobType),
                    `This job has been cancelled to ${name} by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                    "JobDetailsScreen",
                    {
                      id: jobDetails[0]?.id
                    },
                    false,
                    false,
                    "red",
                    ""
                  );
                  socket?.emit("tableNotificationInsert", {
                    table: "FacilityNotificationTable",
                    id: facility?.id
                  });
                  if (facility?.mobileId) {
                    sendNotification(authToken, {
                      expoPushToken: facility?.mobileId,
                      title: jobUniqueId(
                        jobDetails[0]?.id,
                        jobDetails[0]?.jobType
                      ),
                      body: `This job has been cancelled to ${name} by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                      data: {
                        jobId: jobDetails[0]?.id,
                        screen: "JobDetailScreen"
                      }
                    });
                  }
                } catch (error) {
                  console.error("Error fetching facility detail:", error);
                }
              }
            }
          })
          .catch((error) => console.error(error));
        setFetchAgainJobDashboard(!fetchAgainJobDashboard);
        setFetchAgainJobList(!fetchAgainJobList);
        getUpdatedJob(jobId);
      } catch (error) {
        console.log("data save error:", error);
      }
    }
  };

  const getFacilityDetail = async (id) => {
    // console.log(id);
    try {
      const facilityData = await getFacilityDetails(authToken, id);
      return facilityData;
    } catch (error) {
      console.log("getFacilityDetail error:", error);
    }
  };

  const onEditJobNavigate = (d) => {
    props?.navigation.navigate("EditJobScreen", {
      data: d
    });
  };

  const onAksQuestionChatRoomScreenNavigate = (d) => {
    props?.navigation.navigate("AksQuestionChatRoomScreen", {
      data: d
    });
  };

  const onUserDetailNavigate = (d) => {
    props.navigation.navigate("UserDetailsScreen", {
      data: d
    });
  };

  const completePayment = async (data) => {
    setLoading(true);

    const res = await updateJobTable(authToken, jobId, {
      jobStatus: "Completed",
      jobCompletedBy: "Internal Pool",
      closed_by: userId,
      worked_hours: timeDifferent(data?.checkInTime, data?.checkOutTime),
      total_payment:
        job?.jobType === "Visit"
          ? data?.baseRate
          : totalAmount(data?.checkInTime, data?.checkOutTime, data?.baseRate),
      manager_review_comments: completeJobComment
    }).catch((e) => console.log("completePayment updateJobTable error" + e));
    socket?.emit("tableJobInsert", {
      table: "JobPostingTable",
      message: "Fetch Dashboard Job"
    });
    if (res.jobStatus === "Completed") {
      const data = await getNurseUniqueIdDetails(
        authToken,
        job?.jobFinalSelectionNurseId
      );
      let nurseData = data[0];
      if (nurseData) {
        if (Platform.OS !== "web") {
          sendPushNotification({
            expoPushToken: nurseData.mobileId,
            job: jobUniqueId(job?.id, job?.jobType) + " " + job?.shiftTitle,
            typeMessage: ` – Review Approved and Completed.`,
            jobId: jobId
          });
        } else {
          sendNotification(authToken, {
            expoPushToken: nurseData.mobileId,
            title: jobUniqueId(job?.id, job?.jobType) + " " + job?.shiftTitle,
            body: ` – Review Approved and Completed.`,
            data: { jobId: jobId, screen: "JobDetailScreen" }
          });
        }
        await postNurseNotification(
          authToken,
          nurseData?.id,
          "",
          jobUniqueId(job?.id, job?.jobType),
          "Review Approved and Completed.",
          "JobDetailsScreen",
          {
            id: job?.id
          },
          false,
          false,
          "green",
          ""
        );
        socket?.emit("tableNotificationInsert", {
          table: "NurseNotificationTable",
          id: nurseData?.id
        });
        setLoading(false);
        setCompleteJob(false);
      }

      if (parseInt(job?.jobPostingTableFacilityTableId) !== parseInt(userId)) {
        const facilityDetail = await getFacilityDetail(userId);

        await postFacilityNotification(
          authToken,
          jobPostedFacilityDetails?.id,
          "",
          jobUniqueId(job?.id, job?.jobType),
          `Your job Reviewed and Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
          "JobDetailsScreen",
          {
            id: jobId
          },
          false,
          false,
          "green",
          ""
        );
        socket?.emit("tableNotificationInsert", {
          table: "FacilityNotificationTable",
          id: jobPostedFacilityDetails?.id
        });

        if (jobPostedFacilityDetails?.mobileId) {
          if (Platform.OS !== "web") {
            sendPushNotification({
              expoPushToken: jobPostedFacilityDetails?.mobileId,
              job: jobUniqueId(job?.id, job?.jobType),
              name: "",
              typeMessage: `Your job Reviewed and Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
              jobId: jobId
            });
          } else {
            sendNotification(authToken, {
              expoPushToken: jobPostedFacilityDetails?.mobileId,
              title: jobUniqueId(job?.id, job?.jobType),
              body: `Your job Reviewed and Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
              data: { jobId: jobId, screen: "JobDetailScreen" }
            });
          }
        }
      }
      setFetchAgainJobDashboard(!fetchAgainJobDashboard);
      setFetchAgainJobList(!fetchAgainJobList);
      getUpdatedJob(jobId);
    }
  };

  const getJobFinalSelectionNurseIdDetails = async () => {
    const nurseData = await getNurseUniqueIdDetails(
      authToken,
      job?.jobFinalSelectionNurseId
    );

    setSwapOldNurse({
      mobileId: nurseData[0]?.mobileId,
      id: nurseData[0]?.id
    });
  };

  useEffect(() => {
    if (job !== undefined) {
      if (job?.jobFinalSelectionNurseId) {
        getJobFinalSelectionNurseIdDetails();
      }
      if (job?.jobPostingTableFacilityTableId) {
        getFacilityDetail(job?.jobPostingTableFacilityTableId).then((res) => {
          setJobPostedFacilityDetails(res);
          setPageLoading(false);
        });
      }
    }
  }, [job]);

  const swapNurse = async () => {
    setLoading(true);
    if (jobFinalSelectionNurseId === "") {
      if (Platform.OS === "web") {
        alert("To process the swap select the nurse");
      } else {
        Alert.alert("To process the swap select the nurse");
      }
      setLoading(false);
    } else if (nurseSwapReason === "") {
      if (Platform.OS === "web") {
        alert("Fill the reason.");
      } else {
        Alert.alert("Fill the reason.");
      }
      setLoading(false);
    } else {
      try {
        let date = `${moment(job?.startTime).format("hh:mm a")}-${moment(
          job?.endTime
        ).format("hh:mm a")}`;

        socket?.emit("tableJobInsert", {
          table: "JobPostingTable",
          message: "Fetch Dashboard Job"
        });

        await postNurseNotification(
          authToken,
          swapOldNurse?.id,
          "",
          `"${job?.jobType} Swap Request Processed"`,
          `${job?.jobType} for ${DateFormat(job?.startDate)}  ${date}`,
          "JobDetailsScreen",
          {
            id: job?.id
          },
          false,
          false,
          "green",
          ""
        );
        socket?.emit("tableNotificationInsert", {
          table: "NurseNotificationTable",
          id: swapOldNurse?.id
        });
        if (swapOldNurse?.mobileId) {
          if (Platform.OS !== "web") {
            sendPushNotification({
              expoPushToken: swapOldNurse?.mobileId,
              job: `"${job?.jobType} Swap Request Processed"`,
              typeMessage: `${job?.jobType} for ${DateFormat(
                job?.startDate
              )}  ${date}`,
              jobId: job?.id
            });
          } else {
            sendNotification(authToken, {
              expoPushToken: swapOldNurse?.mobileId,
              title: `"${job?.jobType} Swap Request Processed"`,
              body: `${job?.jobType} for ${DateFormat(
                job?.startDate
              )}  ${date}`,
              data: { jobId: job?.id, screen: "JobDetailScreen" }
            });
          }
        }

        const nurseJobFinalSelectionNurseId = available.filter((item) => {
          return item?.id?.uniqueId === jobFinalSelectionNurseId;
        });
        
        await updateJobTable(authToken, jobId, {
          jobAcceptedNurses: [jobFinalSelectionNurseId],
          jobFinalSelectionNurseId: jobFinalSelectionNurseId?.toString(),
          nurseSwapNotes: nurseSwapReason,
          jobCompletedBy: nurseJobFinalSelectionNurseId[0]?.id?.nurseType,
          jobRequestedNurses: [
            {
              id: jobFinalSelectionNurseId?.toString(),
              type: nurseJobFinalSelectionNurseId[0]?.id?.nurseType
            }
          ]
        }).catch((e) => console.log("swapNurse updateJobTable" + e));

        if (nurseJobFinalSelectionNurseId[0]?.id?.mobileId) {
          if (Platform.OS !== "web") {
            sendPushNotification({
              expoPushToken: nurseJobFinalSelectionNurseId[0]?.id?.mobileId,
              job: `“Direct Swap ${job?.jobType} Assignment” (Check Upcoming Jobs)`,
              typeMessage: `${job?.jobType} for ${DateFormat(
                job?.startDate
              )}  ${date}`,
              jobId: job?.id
            });
          } else {
            sendNotification(authToken, {
              expoPushToken: nurseJobFinalSelectionNurseId[0]?.id?.mobileId,
              title: `“Direct Swap ${job?.jobType} Assignment” (Check Upcoming Jobs)`,
              body: `${job?.jobType} for ${DateFormat(
                job?.startDate
              )}  ${date}`,
              data: { jobId: job?.id, screen: "JobDetailScreen" }
            });
          }
        }

        await postNurseNotification(
          authToken,
          nurseJobFinalSelectionNurseId[0]?.id?.id,
          "",
          `“Direct Swap ${job?.jobType} Assignment” (Check Upcoming Jobs)`,
          `${job?.jobType} for ${DateFormat(job?.startDate)}  ${date}`,
          "JobDetailsScreen",
          {
            id: job?.id
          },
          false,
          false,
          "green",
          ""
        );
        socket?.emit("tableNotificationInsert", {
          table: "NurseNotificationTable",
          id: nurseJobFinalSelectionNurseId[0]?.id?.id
        });

        setNurseSwap(false);
        setNurseSwapReason("");
        setJobFinalSelectionNurseId("");
        setAvailable([]);
        setLoading(false);
        if (Platform.OS === "web") {
          alert("Swapped Successfully.");
          getUpdatedJob(jobId);
        } else {
          Alert.alert("Swapped Successfully.");
          getUpdatedJob(jobId);
        }
      } catch (error) {
        console.log("swapNurse error:", error);
      }
    }
  };

  useEffect(() => {
    if (job !== undefined) {
      if (nurseSwap) {
        const get = async () => {
          await getSwapNurseAvailableList(
            authToken,
            jobPostedFacilityDetails?.organization,
            jobPostedFacilityDetails?.location_id,
            job.startDate,
            job.endDate,
            moment(new Date(job?.startTime)).format("hh:mm a"),
            moment(new Date(job?.endTime)).format("hh:mm a"),
            job?.licenseType,
            job?.fullAddress
          )
            .then((res) => {
              setAvailable(res);
            })
            .catch((e) => console.log("getSwapNurseAvailableList error" + e));
        };
        get();
      }
    }
  }, [job, nurseSwap]);

  const webCancel = (item) => {
    setRemoveNurseObj({
      id: item?.id,
      uniqueId: item?.uniqueId,
      mobileId: item?.mobileId,
      type: item?.nurseType,
      name: item?.firstName + " " + item?.lastName
    });
    setRemoveNurse(true);
  };

  const webCancelBid = (item) => {
    setRemoveNurseBid(true);
    setRemoveNurseBidObj({
      id: item?.id,
      uniqueId: item?.uniqueId,
      mobileId: item?.mobileId,
      type: item?.nurseType,
      amount: "",
      name: item?.firstName + " " + item?.lastName
    });
  };

  const pickDocument = async () => {
    try {
      // Request permission to access the user's media library
      const { status } = await MediaLibrary.requestPermissionsAsync();
      if (status !== "granted") {
        Alert.alert(
          "Permission denied",
          "Sorry, we need media library access permissions to make this work!"
        );
        return;
      }
      // Open the document picker and select a PDF file
      const result = await DocumentPicker.getDocumentAsync({
        type: "application/pdf"
      });
      if (result.type === "cancel") {
        return;
      }

      const uri = result?.assets[0].uri;

      if (job?.jobStatus === "Missed") {
        setNoShowAttachment(uri);
      } else if (job?.jobStatus === "Pending Clock Out") {
        setPendingNeverCheckOutAttachment(uri.toString());
      }
    } catch (error) {
      console.log("Failed to upload document", error);
      // Alert.alert("Error", "Sorry, we failed to upload your document");
    }
  };

  const pickDocumentWeb = async () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "pdf/*"; // All files

    input.onchange = async (e) => {
      const file = e.target.files[0];
      if (file) {
        if (job?.jobStatus === "Missed") {
          const reader = new FileReader();

          setNoShowAttachment(file);

          reader.onload = async (event) => {
            const dataURI = event.target.result;
            // Now you have the data URI, you can do something with it
            setNoShowAttachmentURI(dataURI);
          };
          // console.log(dataURI);

          // const response = await fetch(dataURI);
          // const blob = await response.blob();
          // const file = `noShow/${`noShowManager-${jobUniqueId(
          //   job?.createdAt,
          //   job?.jobType
          // )}`}.pdf`;
          // const { key } = await Storage.put(file, blob, {
          //   contentType: "application/pdf",
          //   ACL: "public-read",
          // });
          reader.readAsDataURL(file);
        } else if (job?.jobStatus === "Pending Clock Out") {
          const reader = new FileReader();

          setPendingNeverCheckOutAttachment(file);

          reader.onload = async (event) => {
            const dataURI = event.target.result;
            // Now you have the data URI, you can do something with it
            setPendingNeverCheckOutAttachmentURI(dataURI);
          };
          // console.log(dataURI);

          // const response = await fetch(dataURI);
          // const blob = await response.blob();
          // const file = `noShow/${`noShowManager-${jobUniqueId(
          //   job?.createdAt,
          //   job?.jobType
          // )}`}.pdf`;
          // const { key } = await Storage.put(file, blob, {
          //   contentType: "application/pdf",
          //   ACL: "public-read",
          // });
          reader.readAsDataURL(file);
        }
      }
    };

    input.click();
  };

  const getBlob = async (uri) => {
    const response = await fetch(uri);
    const blob = await response.blob();
    return blob;
  };

  const blobToUint8Array = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const uint8Array = new Uint8Array(reader.result);
        resolve(uint8Array);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(blob);
    });
  };

  const pendingOrNoShowFacilityDecide = async (data) => {
    setLoading(true);
    if (noShowMessage === "") {
      if (Platform.OS === "web") {
        alert("Please select the reason...");
      } else {
        Alert.alert("Please select the reason...");
      }
      setLoading(false);
    } else if (pendingOrNoShowFacilityDecideMessage === "") {
      if (Platform.OS === "web") {
        alert("Please fill the message...");
      } else {
        Alert.alert("Please fill the message...");
      }
      setLoading(false);
    } else {
      let enterTime =
        moment(new Date(job?.checkInTimeNurse)).format("hh:mm a") ===
        moment(new Date(jobClockDetails?.checkInTimeNurse)).format("hh:mm a")
          ? job?.checkInTimeNurse
          : jobClockDetails?.checkInTimeNurse;

      let exitTime =
        moment(new Date(job?.checkOutTimeNurse)).format("hh:mm a") ===
        moment(new Date(jobClockDetails?.checkOutTimeNurse)).format("hh:mm a")
          ? job?.checkOutTimeNurse
          : jobClockDetails?.checkOutTimeNurse;

      let adjustStart =
        moment(new Date(job?.checkInTimeNurse)).format("hh:mm a") ===
        moment(new Date(jobClockDetails?.checkInTimeNurse)).format("hh:mm a")
          ? false
          : true;
      let adjustEnd =
        moment(new Date(job?.checkOutTimeNurse)).format("hh:mm a") ===
        moment(new Date(jobClockDetails?.checkOutTimeNurse)).format("hh:mm a")
          ? false
          : true;

      let adjust = adjustStart === true || adjustEnd === true ? true : false;

      if (noShowAttachment === "") {
        await updateJobTable(authToken, jobId, {
          jobStatus: "Completed",
          jobCompletedBy: "Internal Pool",
          worked_hours: timeDifferent(enterTime, exitTime),
          total_payment: totalAmount(enterTime, exitTime, data?.baseRate),
          checkInTime: new Date(enterTime)?.toString(),
          checkInFullAddress: job?.fullAddress,
          checkInLatitude: job?.latitude.toString(),
          checkInLongitude: job?.longitude.toString(),
          checkOutTime: new Date(exitTime)?.toString(),
          checkOutFullAddress: job?.fullAddress,
          checkOutLatitude: job?.latitude.toString(),
          checkOutLongitude: job?.longitude.toString(),
          checkOutMessage: "Facility Approved",
          approved_manager: userId,
          closed_by: userId,
          timeAdjustByFacility: adjust,
          pendingOrNoShowFacilityDecideMessage:
            pendingOrNoShowFacilityDecideMessage,
          pendingOrNoShowFacilityDecideStatus: true,
          noShowManagerReason: noShowMessage
        })
          .then(async (res) => {
            socket?.emit("tableJobInsert", {
              table: "JobPostingTable",
              message: "Fetch Dashboard Job"
            });
            const data = await getNurseUniqueIdDetails(
              authToken,
              job?.jobFinalSelectionNurseId
            );
            let nurseData = data[0];
            if (nurseData) {
              if (Platform.OS !== "web") {
                sendPushNotification({
                  expoPushToken: nurseData.mobileId,
                  job: job?.shiftTitle,
                  typeMessage:
                    `Job ${jobUniqueId(job?.id, job?.jobType)}` +
                    "-" +
                    ` Missed ${job?.jobType} Job is Approved`,
                  jobId: job?.id
                });
              } else {
                sendNotification(authToken, {
                  expoPushToken: nurseData.mobileId,
                  title: job?.shiftTitle,
                  body:
                    `Job ${jobUniqueId(job?.id, job?.jobType)}` +
                    "-" +
                    ` Missed ${job?.jobType} Job is Approved`,
                  data: { jobId: job?.id, screen: "JobDetailScreen" }
                });
              }
              await postNurseNotification(
                authToken,
                nurseData?.id,
                "",
                jobUniqueId(job?.id, job?.jobType),
                ` Missed ${job?.jobType} Job is Approved`,
                "JobDetailsScreen",
                {
                  id: job?.id
                },
                false,
                false,
                "green",
                ""
              );
              socket?.emit("tableNotificationInsert", {
                table: "NurseNotificationTable",
                id: nurseData?.id
              });
              setLoading(false);
              setNoShowViewModelAction(false);
              setPendingOrNoShowFacilityDecideMessage("");
              setNoShowMessage("");
              setReason("");
              setNoShowAttachment("");
              setNoShowAttachmentURI("");
              setJobClockDetails({
                checkInTimeNurse: "",
                checkOutTimeNurse: "",
                checkInFullAddressNurse: "",
                checkInLatitudeNurse: "",
                checkInLongitudeNurse: "",
                checkOutFullAddressNurse: "",
                checkOutLatitudeNurse: "",
                checkOutLongitudeNurse: ""
              });
            }

            if (
              parseInt(job?.jobPostingTableFacilityTableId) !== parseInt(userId)
            ) {
              const facilityDetail = await getFacilityDetail(userId);

              await postFacilityNotification(
                authToken,
                jobPostedFacilityDetails?.id,
                "",
                jobUniqueId(job?.id, job?.jobType),
                `Your Missed ${job?.jobType} Job is Reviewed and Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                "JobDetailsScreen",
                {
                  id: jobId
                },
                false,
                false,
                "green",
                ""
              );
              socket?.emit("tableNotificationInsert", {
                table: "FacilityNotificationTable",
                id: jobPostedFacilityDetails?.id
              });

              if (jobPostedFacilityDetails?.mobileId) {
                if (Platform.OS !== "web") {
                  sendPushNotification({
                    expoPushToken: jobPostedFacilityDetails?.mobileId,
                    job: jobUniqueId(job?.id, job?.jobType),
                    name: "",
                    typeMessage: `Your Missed ${job?.jobType} Job is Reviewed and Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                    jobId: jobId
                  });
                } else {
                  sendNotification(authToken, {
                    expoPushToken: jobPostedFacilityDetails?.mobileId,
                    title: jobUniqueId(job?.id, job?.jobType),
                    body: `Your Missed ${job?.jobType} Job is Reviewed and Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                    data: { jobId: jobId, screen: "JobDetailScreen" }
                  });
                }
              }
            }
            setFetchAgainJobDashboard(!fetchAgainJobDashboard);
            setFetchAgainJobList(!fetchAgainJobList);
            if (Platform.OS === "web") {
              alert("Approved successfully.");
            } else {
              Alert.alert("Approved successfully.");
            }
            getUpdatedJob(jobId);
          })
          .catch((e) => console.log("pendingOrNoShowFacilityDecide error" + e));
      } else {
        const blob = await getBlob(
          Platform.OS === "web" ? noShowAttachmentURI : noShowAttachment
        );
        const buffer = await blobToUint8Array(blob);
        const name = `noShow/${`noShowManager-${jobUniqueIdDoc(
          job?.createdAt,
          job?.jobType
        )}`}.pdf`;

        await uploadFile(authToken, name, buffer, "").then(async (res) => {
          // console.log("res", res)
          if (res && res?.Key) {
            await updateJobTable(authToken, jobId, {
              jobStatus: "Completed",
              jobCompletedBy: "Internal Pool",
              worked_hours: timeDifferent(enterTime, exitTime),
              total_payment: totalAmount(enterTime, exitTime, data?.baseRate),
              checkInTime: new Date(enterTime)?.toString(),
              checkInFullAddress: job?.fullAddress,
              checkInLatitude: job?.latitude.toString(),
              checkInLongitude: job?.longitude.toString(),
              checkOutTime: new Date(exitTime)?.toString(),
              checkOutFullAddress: job?.fullAddress,
              checkOutLatitude: job?.latitude.toString(),
              checkOutLongitude: job?.longitude.toString(),
              checkOutMessage: "Facility Approved",
              approved_manager: userId,
              closed_by: userId,
              timeAdjustByFacility: adjust,
              pendingOrNoShowFacilityDecideMessage:
                pendingOrNoShowFacilityDecideMessage,
              pendingOrNoShowFacilityDecideStatus: true,
              noShowManagerReason: noShowMessage,
              noShowManagerReasonAttachment: res?.Key
            })
              .then(async (res) => {
                socket?.emit("tableJobInsert", {
                  table: "JobPostingTable",
                  message: "Fetch Dashboard Job"
                });
                const data = await getNurseUniqueIdDetails(
                  authToken,
                  job?.jobFinalSelectionNurseId
                );
                let nurseData = data[0];
                if (nurseData) {
                  if (Platform.OS !== "web") {
                    sendPushNotification({
                      expoPushToken: nurseData.mobileId,
                      job: job?.shiftTitle,
                      typeMessage:
                        `Job ${jobUniqueId(job?.id, job?.jobType)}` +
                        "-" +
                        ` Missed ${job?.jobType} Job is Approved`,
                      jobId: job?.id
                    });
                  } else {
                    sendNotification(authToken, {
                      expoPushToken: nurseData.mobileId,
                      title: job?.shiftTitle,
                      body:
                        `Job ${jobUniqueId(job?.id, job?.jobType)}` +
                        "-" +
                        ` Missed ${job?.jobType} Job is Approved`,
                      data: { jobId: job?.id, screen: "JobDetailScreen" }
                    });
                  }
                  await postNurseNotification(
                    authToken,
                    nurseData?.id,
                    "",
                    jobUniqueId(job?.id, job?.jobType),
                    ` Missed ${job?.jobType} Job is Approved`,
                    "JobDetailsScreen",
                    {
                      id: job?.id
                    },
                    false,
                    false,
                    "green",
                    ""
                  );
                  socket?.emit("tableNotificationInsert", {
                    table: "NurseNotificationTable",
                    id: nurseData?.id
                  });
                  setLoading(false);
                  setNoShowViewModelAction(false);
                  setPendingOrNoShowFacilityDecideMessage("");
                  setNoShowMessage("");
                  setReason("");
                  setNoShowAttachment("");
                  setNoShowAttachmentURI("");
                  setJobClockDetails({
                    checkInTimeNurse: "",
                    checkOutTimeNurse: "",
                    checkInFullAddressNurse: "",
                    checkInLatitudeNurse: "",
                    checkInLongitudeNurse: "",
                    checkOutFullAddressNurse: "",
                    checkOutLatitudeNurse: "",
                    checkOutLongitudeNurse: ""
                  });
                }

                if (
                  parseInt(job?.jobPostingTableFacilityTableId) !==
                  parseInt(userId)
                ) {
                  const facilityDetail = await getFacilityDetail(userId);

                  await postFacilityNotification(
                    authToken,
                    jobPostedFacilityDetails?.id,
                    "",
                    jobUniqueId(job?.id, job?.jobType),
                    `Your Missed ${job?.jobType} Job is Reviewed and Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                    "JobDetailsScreen",
                    {
                      id: jobId
                    },
                    false,
                    false,
                    "green",
                    ""
                  );
                  socket?.emit("tableNotificationInsert", {
                    table: "FacilityNotificationTable",
                    id: jobPostedFacilityDetails?.id
                  });

                  if (jobPostedFacilityDetails?.mobileId) {
                    if (Platform.OS !== "web") {
                      sendPushNotification({
                        expoPushToken: jobPostedFacilityDetails?.mobileId,
                        job: jobUniqueId(job?.id, job?.jobType),
                        name: "",
                        typeMessage: `Your Missed ${job?.jobType} Job is Reviewed and Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                        jobId: jobId
                      });
                    } else {
                      sendNotification(authToken, {
                        expoPushToken: jobPostedFacilityDetails?.mobileId,
                        title: jobUniqueId(job?.id, job?.jobType),
                        body: `Your Missed ${job?.jobType} Job is Reviewed and Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                        data: { jobId: jobId, screen: "JobDetailScreen" }
                      });
                    }
                  }
                }
                setFetchAgainJobDashboard(!fetchAgainJobDashboard);
                setFetchAgainJobList(!fetchAgainJobList);
                if (Platform.OS === "web") {
                  alert("Approved successfully.");
                } else {
                  Alert.alert("Approved successfully.");
                }
                getUpdatedJob(jobId);
              })
              .catch((e) =>
                console.log("pendingOrNoShowFacilityDecide error" + e)
              );
          }
        });
      }
    }
  };

  const pendingOrNoShowFacilityDecideCancel = async (data) => {
    setLoading(true);
    if (noShowMessage === "") {
      if (Platform.OS === "web") {
        alert("Please select the reason...");
      } else {
        Alert.alert("Please select the reason...");
      }
      setLoading(false);
    } else if (pendingOrNoShowFacilityDecideMessage === "") {
      if (Platform.OS === "web") {
        alert("Please fill the reason...");
      } else {
        Alert.alert("Please fill the reason...");
      }

      setLoading(false);
    } else {
      // console.log(moment(data.checkInTimeNurse).format("hh:mm a"), moment(data.checkOutTimeNurse).format("hh:mm a"))
      if (noShowAttachment === "") {
        await updateJobTable(authToken, jobId, {
          worked_hours: "0",
          total_payment: "0",
          pendingOrNoShowFacilityDecideMessage:
            pendingOrNoShowFacilityDecideMessage,
          pendingOrNoShowFacilityDecideStatus: false,
          noShowManagerReason: noShowMessage,
          approved_manager: userId,
          closed_by: userId
        })
          .then(async (res) => {
            socket?.emit("tableJobInsert", {
              table: "JobPostingTable",
              message: "Fetch Dashboard Job"
            });
            const data = await getNurseUniqueIdDetails(
              authToken,
              job?.jobFinalSelectionNurseId
            );
            let nurseData = data[0];
            if (nurseData) {
              if (Platform.OS !== "web") {
                sendPushNotification({
                  expoPushToken: nurseData.mobileId,
                  job: job?.shiftTitle,
                  typeMessage:
                    `Job ${jobUniqueId(job?.id, job?.jobType)}` +
                    "-" +
                    ` Missed ${job?.jobType} Job is Not Approved`,
                  jobId: job?.id
                });
              } else {
                sendNotification(authToken, {
                  expoPushToken: nurseData.mobileId,
                  title: job?.shiftTitle,
                  body:
                    `Job ${jobUniqueId(job?.id, job?.jobType)}` +
                    "-" +
                    ` Missed ${job?.jobType} Job is Not Approved`,
                  data: { jobId: job?.id, screen: "JobDetailScreen" }
                });
              }
              await postNurseNotification(
                authToken,
                nurseData?.id,
                "",
                jobUniqueId(job?.id, job?.jobType),
                ` Missed ${job?.jobType} Job is Not Approved`,
                "JobDetailsScreen",
                {
                  id: job?.id
                },
                false,
                false,
                "red",
                ""
              );
              socket?.emit("tableNotificationInsert", {
                table: "NurseNotificationTable",
                id: nurseData?.id
              });
              setLoading(false);
              setNoShowViewModelAction(false);
              setPendingOrNoShowFacilityDecideMessage("");
              setNoShowMessage("");
              setReason("");
              setNoShowAttachment("");
              setNoShowAttachmentURI("");
              setJobClockDetails({
                checkInTimeNurse: "",
                checkOutTimeNurse: "",
                checkInFullAddressNurse: "",
                checkInLatitudeNurse: "",
                checkInLongitudeNurse: "",
                checkOutFullAddressNurse: "",
                checkOutLatitudeNurse: "",
                checkOutLongitudeNurse: ""
              });
            }

            if (
              parseInt(job?.jobPostingTableFacilityTableId) !== parseInt(userId)
            ) {
              const facilityDetail = await getFacilityDetail(userId);

              await postFacilityNotification(
                authToken,
                jobPostedFacilityDetails?.id,
                "",
                jobUniqueId(job?.id, job?.jobType),
                `Your Missed ${job?.jobType} Job is Reviewed and Not Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                "JobDetailsScreen",
                {
                  id: jobId
                },
                false,
                false,
                "green",
                ""
              );
              socket?.emit("tableNotificationInsert", {
                table: "FacilityNotificationTable",
                id: jobPostedFacilityDetails?.id
              });

              if (jobPostedFacilityDetails?.mobileId) {
                if (Platform.OS !== "web") {
                  sendPushNotification({
                    expoPushToken: jobPostedFacilityDetails?.mobileId,
                    job: jobUniqueId(job?.id, job?.jobType),
                    name: "",
                    typeMessage: `Your Missed ${job?.jobType} Job is Reviewed and Not Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                    jobId: jobId
                  });
                } else {
                  sendNotification(authToken, {
                    expoPushToken: jobPostedFacilityDetails?.mobileId,
                    title: jobUniqueId(job?.id, job?.jobType),
                    body: `Your Missed ${job?.jobType} Job is Reviewed and Not Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                    data: { jobId: jobId, screen: "JobDetailScreen" }
                  });
                }
              }
            }
            setFetchAgainJobDashboard(!fetchAgainJobDashboard);
            setFetchAgainJobList(!fetchAgainJobList);
            if (Platform.OS === "web") {
              alert("Not-Approved successfully.");
            } else {
              Alert.alert("Not-Approved successfully.");
            }
            getUpdatedJob(jobId);
          })
          .catch((e) =>
            console.log("pendingOrNoShowFacilityDecideCancel error" + e)
          );
      } else {
        const blob = await getBlob(
          Platform.OS === "web" ? noShowAttachmentURI : noShowAttachment
        );
        const buffer = await blobToUint8Array(blob);
        const name = `noShow/${`noShowManager-${jobUniqueIdDoc(
          job?.createdAt,
          job?.jobType
        )}`}.pdf`;
        await uploadFile(
          authToken,
          name,
          buffer,
          "File upload successfully."
        ).then(async (res) => {
          // console.log("res", res)
          if (res && res?.Key) {
            await updateJobTable(authToken, jobId, {
              worked_hours: "0",
              total_payment: "0",
              pendingOrNoShowFacilityDecideMessage:
                pendingOrNoShowFacilityDecideMessage,
              pendingOrNoShowFacilityDecideStatus: false,
              noShowManagerReason: noShowMessage,
              noShowManagerReasonAttachment: res?.Key,
              approved_manager: userId,
              closed_by: userId
            })
              .then(async (res) => {
                socket?.emit("tableJobInsert", {
                  table: "JobPostingTable",
                  message: "Fetch Dashboard Job"
                });
                const data = await getNurseUniqueIdDetails(
                  authToken,
                  job?.jobFinalSelectionNurseId
                );
                let nurseData = data[0];
                if (nurseData) {
                  if (Platform.OS !== "web") {
                    sendPushNotification({
                      expoPushToken: nurseData.mobileId,
                      job: job?.shiftTitle,
                      typeMessage:
                        `Job ${jobUniqueId(job?.id, job?.jobType)}` +
                        "-" +
                        ` Missed ${job?.jobType} Job is Not Approved`,
                      jobId: job?.id
                    });
                  } else {
                    sendNotification(authToken, {
                      expoPushToken: nurseData.mobileId,
                      title: job?.shiftTitle,
                      body:
                        `Job ${jobUniqueId(job?.id, job?.jobType)}` +
                        "-" +
                        ` Missed ${job?.jobType} Job is Not Approved`,
                      data: { jobId: job?.id, screen: "JobDetailScreen" }
                    });
                  }
                  await postNurseNotification(
                    authToken,
                    nurseData?.id,
                    "",
                    jobUniqueId(job?.id, job?.jobType),
                    ` Missed ${job?.jobType} Job is Not Approved`,
                    "JobDetailsScreen",
                    {
                      id: job?.id
                    },
                    false,
                    false,
                    "red",
                    ""
                  );
                  socket?.emit("tableNotificationInsert", {
                    table: "NurseNotificationTable",
                    id: nurseData?.id
                  });
                  setLoading(false);
                  setNoShowViewModelAction(false);
                  setPendingOrNoShowFacilityDecideMessage("");
                  setNoShowMessage("");
                  setReason("");
                  setNoShowAttachment("");
                  setNoShowAttachmentURI("");
                  setJobClockDetails({
                    checkInTimeNurse: "",
                    checkOutTimeNurse: "",
                    checkInFullAddressNurse: "",
                    checkInLatitudeNurse: "",
                    checkInLongitudeNurse: "",
                    checkOutFullAddressNurse: "",
                    checkOutLatitudeNurse: "",
                    checkOutLongitudeNurse: ""
                  });
                }

                if (
                  parseInt(job?.jobPostingTableFacilityTableId) !==
                  parseInt(userId)
                ) {
                  const facilityDetail = await getFacilityDetail(userId);

                  await postFacilityNotification(
                    authToken,
                    jobPostedFacilityDetails?.id,
                    "",
                    jobUniqueId(job?.id, job?.jobType),
                    `Your Missed ${job?.jobType} Job is Reviewed and Not Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                    "JobDetailsScreen",
                    {
                      id: jobId
                    },
                    false,
                    false,
                    "green",
                    ""
                  );
                  socket?.emit("tableNotificationInsert", {
                    table: "FacilityNotificationTable",
                    id: jobPostedFacilityDetails?.id
                  });

                  if (jobPostedFacilityDetails?.mobileId) {
                    if (Platform.OS !== "web") {
                      sendPushNotification({
                        expoPushToken: jobPostedFacilityDetails?.mobileId,
                        job: jobUniqueId(job?.id, job?.jobType),
                        name: "",
                        typeMessage: `Your Missed ${job?.jobType} Job is Reviewed and Not Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                        jobId: jobId
                      });
                    } else {
                      sendNotification(authToken, {
                        expoPushToken: jobPostedFacilityDetails?.mobileId,
                        title: jobUniqueId(job?.id, job?.jobType),
                        body: `Your Missed ${job?.jobType} Job is Reviewed and Not Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                        data: { jobId: jobId, screen: "JobDetailScreen" }
                      });
                    }
                  }
                }
                setFetchAgainJobDashboard(!fetchAgainJobDashboard);
                setFetchAgainJobList(!fetchAgainJobList);
                if (Platform.OS === "web") {
                  alert("Not-Approved successfully.");
                } else {
                  Alert.alert("Not-Approved successfully.");
                }
                getUpdatedJob(jobId);
              })
              .catch((e) =>
                console.log("pendingOrNoShowFacilityDecideCancel error" + e)
              );
          }
        });
      }
    }
  };

  const pendingJobOrNoShowFacilityDecide = async () => {
    setLoading(true);
    if (pendingOrNoShowFacilityDecideMessage === "") {
      if (Platform.OS === "web") {
        alert("Please fill the reason...");
      } else {
        Alert.alert("Please fill the reason...");
      }
      setLoading(false);
    } else {
      if (pendingNeverCheckOutAttachment === "") {
        await updateJobTable(authToken, jobId, {
          jobStatus: "Completed",
          jobCompletedBy: "Internal Pool",
          worked_hours: timeDifferent(job?.checkInTime, job?.checkOutTimeNurse),
          total_payment: totalAmount(
            job?.checkInTime,
            job?.checkOutTimeNurse,
            job?.baseRate
          ),
          checkOutTime: job?.checkOutTimeNurse,
          checkOutFullAddress: job?.fullAddress,
          checkOutLatitude: job?.latitude.toString(),
          checkOutLongitude: job?.longitude.toString(),
          checkOutMessage: "Facility Approved",
          approved_manager: userId,
          closed_by: userId,
          timeAdjustByNurse: true,
          pendingOrNoShowFacilityDecideMessage:
            pendingOrNoShowFacilityDecideMessage,
          pendingOrNoShowFacilityDecideStatus: true,
          neverCheckOutManagerReason: neverCheckOutMessage,
          neverCheckOutManagerReasonAttachment: ""
        })
          .then(async (res) => {
            socket?.emit("tableJobInsert", {
              table: "JobPostingTable",
              message: "Fetch Dashboard Job"
            });
            const data = await getNurseUniqueIdDetails(
              authToken,
              job?.jobFinalSelectionNurseId
            );
            let nurseData = data[0];
            if (nurseData) {
              if (Platform.OS !== "web") {
                sendPushNotification({
                  expoPushToken: nurseData.mobileId,
                  job: job?.shiftTitle,
                  typeMessage:
                    `Job ${jobUniqueId(job?.id, job?.jobType)}` +
                    "-" +
                    ` Pending Clock Out Job is Approved`,
                  jobId: job?.id
                });
              } else {
                sendNotification(authToken, {
                  expoPushToken: nurseData.mobileId,
                  title: job?.shiftTitle,
                  body:
                    `Job ${jobUniqueId(job?.id, job?.jobType)}` +
                    "-" +
                    ` Pending Clock Out Job is Approved`,
                  data: { jobId: job?.id, screen: "JobDetailScreen" }
                });
              }
              await postNurseNotification(
                authToken,
                nurseData?.id,
                "",
                jobUniqueId(job?.id, job?.jobType),
                ` Pending Clock Out Job is Approved`,
                "JobDetailsScreen",
                {
                  id: job?.id
                },
                false,
                false,
                "green",
                ""
              );
              socket?.emit("tableNotificationInsert", {
                table: "NurseNotificationTable",
                id: nurseData?.id
              });
              setLoading(false);
              setPendingNeverCheckOutModelAction(false);
              setPendingOrNoShowFacilityDecideMessage("");
            }

            if (
              parseInt(job?.jobPostingTableFacilityTableId) !== parseInt(userId)
            ) {
              const facilityDetail = await getFacilityDetail(userId);

              await postFacilityNotification(
                authToken,
                jobPostedFacilityDetails?.id,
                "",
                jobUniqueId(job?.id, job?.jobType),
                `Your Pending Clock Out Job Reviewed and Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                "JobDetailsScreen",
                {
                  id: jobId
                },
                false,
                false,
                "green",
                ""
              );
              socket?.emit("tableNotificationInsert", {
                table: "FacilityNotificationTable",
                id: jobPostedFacilityDetails?.id
              });
              if (jobPostedFacilityDetails?.mobileId) {
                if (Platform.OS !== "web") {
                  sendPushNotification({
                    expoPushToken: jobPostedFacilityDetails?.mobileId,
                    job: jobUniqueId(job?.id, job?.jobType),
                    name: "",
                    typeMessage: `Your Pending Clock Out Job Reviewed and Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                    jobId: jobId
                  });
                } else {
                  sendNotification(authToken, {
                    expoPushToken: jobPostedFacilityDetails?.mobileId,
                    title: jobUniqueId(job?.id, job?.jobType),
                    body: `Your Pending Clock Out Job Reviewed and Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                    data: { jobId: jobId, screen: "JobDetailScreen" }
                  });
                }
              }
            }
            setFetchAgainJobDashboard(!fetchAgainJobDashboard);
            setFetchAgainJobList(!fetchAgainJobList);
            if (Platform.OS === "web") {
              alert("Approved successfully.");
            } else {
              Alert.alert("Approved successfully.");
            }
            getUpdatedJob(jobId);
          })
          .catch((e) =>
            console.log("pendingJobOrNoShowFacilityDecide error" + e)
          );
      } else {
        const blob = await getBlob(
          Platform.OS === "web"
            ? pendingNeverCheckOutAttachmentURI
            : pendingNeverCheckOutAttachment
        );
        const buffer = await blobToUint8Array(blob);
        const name = `pendingNeverCheckOut/${`pendingNeverCheckOutManager-${jobUniqueIdDoc(
          job?.createdAt,
          job?.jobType
        )}`}.pdf`;

        await uploadFile(authToken, name, buffer, "").then(async (res) => {
          if (res && res?.Key) {
            await updateJobTable(authToken, jobId, {
              jobStatus: "Completed",
              jobCompletedBy: "Internal Pool",
              worked_hours: timeDifferent(
                job?.checkInTime,
                job?.checkOutTimeNurse
              ),
              total_payment: totalAmount(
                job?.checkInTime,
                job?.checkOutTimeNurse,
                job?.baseRate
              ),
              checkOutTime: job?.checkOutTimeNurse,
              checkOutFullAddress: job?.fullAddress,
              checkOutLatitude: job?.latitude.toString(),
              checkOutLongitude: job?.longitude.toString(),
              checkOutMessage: "Facility Approved",
              approved_manager: userId,
              closed_by: userId,
              timeAdjustByNurse: true,
              pendingOrNoShowFacilityDecideMessage:
                pendingOrNoShowFacilityDecideMessage,
              pendingOrNoShowFacilityDecideStatus: true,
              neverCheckOutManagerReason: neverCheckOutMessage,
              neverCheckOutManagerReasonAttachment: res?.Key
            })
              .then(async (res) => {
                socket?.emit("tableJobInsert", {
                  table: "JobPostingTable",
                  message: "Fetch Dashboard Job"
                });
                const data = await getNurseUniqueIdDetails(
                  authToken,
                  job?.jobFinalSelectionNurseId
                );
                let nurseData = data[0];
                if (nurseData) {
                  if (Platform.OS !== "web") {
                    sendPushNotification({
                      expoPushToken: nurseData.mobileId,
                      job: job?.shiftTitle,
                      typeMessage:
                        `Job ${jobUniqueId(job?.id, job?.jobType)}` +
                        "-" +
                        ` Pending Clock Out Job is Approved`,
                      jobId: job?.id
                    });
                  } else {
                    sendNotification(authToken, {
                      expoPushToken: nurseData.mobileId,
                      title: job?.shiftTitle,
                      body:
                        `Job ${jobUniqueId(job?.id, job?.jobType)}` +
                        "-" +
                        ` Pending Clock Out Job is Approved`,
                      data: { jobId: job?.id, screen: "JobDetailScreen" }
                    });
                  }
                  await postNurseNotification(
                    authToken,
                    nurseData?.id,
                    "",
                    jobUniqueId(job?.id, job?.jobType),
                    ` Pending Clock Out Job is Approved`,
                    "JobDetailsScreen",
                    {
                      id: job?.id
                    },
                    false,
                    false,
                    "green",
                    ""
                  );
                  socket?.emit("tableNotificationInsert", {
                    table: "NurseNotificationTable",
                    id: nurseData?.id
                  });
                  setLoading(false);
                  setPendingNeverCheckOutModelAction(false);
                  setPendingOrNoShowFacilityDecideMessage("");
                }

                if (
                  parseInt(job?.jobPostingTableFacilityTableId) !==
                  parseInt(userId)
                ) {
                  const facilityDetail = await getFacilityDetail(userId);

                  await postFacilityNotification(
                    authToken,
                    jobPostedFacilityDetails?.id,
                    "",
                    jobUniqueId(job?.id, job?.jobType),
                    `Your Pending Clock Out Job Reviewed and Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                    "JobDetailsScreen",
                    {
                      id: jobId
                    },
                    false,
                    false,
                    "green",
                    ""
                  );
                  socket?.emit("tableNotificationInsert", {
                    table: "FacilityNotificationTable",
                    id: jobPostedFacilityDetails?.id
                  });
                  if (jobPostedFacilityDetails?.mobileId) {
                    if (Platform.OS !== "web") {
                      sendPushNotification({
                        expoPushToken: jobPostedFacilityDetails?.mobileId,
                        job: jobUniqueId(job?.id, job?.jobType),
                        name: "",
                        typeMessage: `Your Pending Clock Out Job Reviewed and Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                        jobId: jobId
                      });
                    } else {
                      sendNotification(authToken, {
                        expoPushToken: jobPostedFacilityDetails?.mobileId,
                        title: jobUniqueId(job?.id, job?.jobType),
                        body: `Your Pending Clock Out Job Reviewed and Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                        data: { jobId: jobId, screen: "JobDetailScreen" }
                      });
                    }
                  }
                }
                setFetchAgainJobDashboard(!fetchAgainJobDashboard);
                setFetchAgainJobList(!fetchAgainJobList);
                if (Platform.OS === "web") {
                  alert("Approved successfully.");
                } else {
                  Alert.alert("Approved successfully.");
                }
                getUpdatedJob(jobId);
              })
              .catch((e) =>
                console.log("pendingJobOrNoShowFacilityDecide error" + e)
              );
          }
        });
      }
    }
  };

  const pendingJobOrNoShowFacilityDecideCancel = async () => {
    setLoading(true);
    if (pendingOrNoShowFacilityDecideMessage === "") {
      if (Platform.OS === "web") {
        alert("Please fill the reason...");
      } else {
        Alert.alert("Please fill the reason...");
      }
      setLoading(false);
    } else {
      if (pendingNeverCheckOutAttachment === "") {
        await updateJobTable(authToken, jobId, {
          pendingOrNoShowFacilityDecideMessage:
            pendingOrNoShowFacilityDecideMessage,
          pendingOrNoShowFacilityDecideStatus: false,
          approved_manager: userId,
          closed_by: userId,
          neverCheckOutManagerReason: neverCheckOutMessage,
          neverCheckOutManagerReasonAttachment: ""
        })
          .then(async (res) => {
            socket?.emit("tableJobInsert", {
              table: "JobPostingTable",
              message: "Fetch Dashboard Job"
            });
            const data = await getNurseUniqueIdDetails(
              authToken,
              job?.jobFinalSelectionNurseId
            );
            let nurseData = data[0];
            if (nurseData) {
              if (Platform.OS !== "web") {
                sendPushNotification({
                  expoPushToken: nurseData.mobileId,
                  job: job?.shiftTitle,
                  typeMessage:
                    `Job ${jobUniqueId(job?.id, job?.jobType)}` +
                    "-" +
                    ` Pending Clock Out Job is Not Approved`,
                  jobId: job?.id
                });
              } else {
                sendNotification(authToken, {
                  expoPushToken: nurseData.mobileId,
                  title: job?.shiftTitle,
                  body:
                    `Job ${jobUniqueId(job?.id, job?.jobType)}` +
                    "-" +
                    ` Pending Clock Out Job is Not Approved`,
                  data: { jobId: job?.id, screen: "JobDetailScreen" }
                });
              }
              await postNurseNotification(
                authToken,
                nurseData?.id,
                "",
                jobUniqueId(job?.id, job?.jobType),
                ` Pending Clock Out Job is Not Approved`,
                "JobDetailsScreen",
                {
                  id: job?.id
                },
                false,
                false,
                "red",
                ""
              );
              socket?.emit("tableNotificationInsert", {
                table: "NurseNotificationTable",
                id: nurseData?.id
              });
              setLoading(false);
              setPendingNeverCheckOutModelAction(false);
              setPendingOrNoShowFacilityDecideMessage("");
            }

            if (
              parseInt(job?.jobPostingTableFacilityTableId) !== parseInt(userId)
            ) {
              const facilityDetail = await getFacilityDetail(userId);
              await postFacilityNotification(
                authToken,
                jobPostedFacilityDetails?.id,
                "",
                jobUniqueId(job?.id, job?.jobType),
                `Your Pending Clock Out Job Reviewed and Not Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                "JobDetailsScreen",
                {
                  id: jobId
                },
                false,
                false,
                "green",
                ""
              );
              socket?.emit("tableNotificationInsert", {
                table: "FacilityNotificationTable",
                id: jobPostedFacilityDetails?.id
              });
              if (jobPostedFacilityDetails?.mobileId) {
                if (Platform.OS !== "web") {
                  sendPushNotification({
                    expoPushToken: jobPostedFacilityDetails?.mobileId,
                    job: jobUniqueId(job?.id, job?.jobType),
                    name: "",
                    typeMessage: `Your Pending Clock Out Job Reviewed and Not Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                    jobId: jobId
                  });
                } else {
                  sendNotification(authToken, {
                    expoPushToken: jobPostedFacilityDetails?.mobileId,
                    title: jobUniqueId(job?.id, job?.jobType),
                    body: `Your Pending Clock Out Job Reviewed and Not Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                    data: { jobId: jobId, screen: "JobDetailScreen" }
                  });
                }
              }
            }
            setFetchAgainJobDashboard(!fetchAgainJobDashboard);
            setFetchAgainJobList(!fetchAgainJobList);
            if (Platform.OS === "web") {
              alert("Not-Approved successfully.");
            } else {
              Alert.alert("Not-Approved successfully.");
            }
            getUpdatedJob(jobId);
          })
          .catch((e) =>
            console.log("pendingJobOrNoShowFacilityDecideCancel error" + e)
          );
      } else {
        const blob = await getBlob(
          Platform.OS === "web"
            ? pendingNeverCheckOutAttachmentURI
            : pendingNeverCheckOutAttachment
        );
        const buffer = await blobToUint8Array(blob);
        const name = `pendingNeverCheckOut/${`pendingNeverCheckOutManager-${jobUniqueIdDoc(
          job?.createdAt,
          job?.jobType
        )}`}.pdf`;

        await uploadFile(authToken, name, buffer, "").then(async (res) => {
          if (res && res?.Key) {
            await updateJobTable(authToken, jobId, {
              pendingOrNoShowFacilityDecideMessage:
                pendingOrNoShowFacilityDecideMessage,
              pendingOrNoShowFacilityDecideStatus: false,
              approved_manager: userId,
              closed_by: userId,
              neverCheckOutManagerReason: neverCheckOutMessage,
              neverCheckOutManagerReasonAttachment: res?.Key
            })
              .then(async (res) => {
                socket?.emit("tableJobInsert", {
                  table: "JobPostingTable",
                  message: "Fetch Dashboard Job"
                });
                const data = await getNurseUniqueIdDetails(
                  authToken,
                  job?.jobFinalSelectionNurseId
                );
                let nurseData = data[0];
                if (nurseData) {
                  if (Platform.OS !== "web") {
                    sendPushNotification({
                      expoPushToken: nurseData.mobileId,
                      job: job?.shiftTitle,
                      typeMessage:
                        `Job ${jobUniqueId(job?.id, job?.jobType)}` +
                        "-" +
                        ` Pending Clock Out Job is Not Approved`,
                      jobId: job?.id
                    });
                  } else {
                    sendNotification(authToken, {
                      expoPushToken: nurseData.mobileId,
                      title: job?.shiftTitle,
                      body:
                        `Job ${jobUniqueId(job?.id, job?.jobType)}` +
                        "-" +
                        ` Pending Clock Out Job is Not Approved`,
                      data: { jobId: job?.id, screen: "JobDetailScreen" }
                    });
                  }
                  await postNurseNotification(
                    authToken,
                    nurseData?.id,
                    "",
                    jobUniqueId(job?.id, job?.jobType),
                    ` Pending Clock Out Job is Not Approved`,
                    "JobDetailsScreen",
                    {
                      id: job?.id
                    },
                    false,
                    false,
                    "red",
                    ""
                  );
                  socket?.emit("tableNotificationInsert", {
                    table: "NurseNotificationTable",
                    id: nurseData?.id
                  });
                  setLoading(false);
                  setPendingNeverCheckOutModelAction(false);
                  setPendingOrNoShowFacilityDecideMessage("");
                }

                if (
                  parseInt(job?.jobPostingTableFacilityTableId) !==
                  parseInt(userId)
                ) {
                  const facilityDetail = await getFacilityDetail(userId);
                  await postFacilityNotification(
                    authToken,
                    jobPostedFacilityDetails?.id,
                    "",
                    jobUniqueId(job?.id, job?.jobType),
                    `Your Pending Clock Out Job Reviewed and Not Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                    "JobDetailsScreen",
                    {
                      id: jobId
                    },
                    false,
                    false,
                    "green",
                    ""
                  );
                  socket?.emit("tableNotificationInsert", {
                    table: "FacilityNotificationTable",
                    id: jobPostedFacilityDetails?.id
                  });
                  if (jobPostedFacilityDetails?.mobileId) {
                    if (Platform.OS !== "web") {
                      sendPushNotification({
                        expoPushToken: jobPostedFacilityDetails?.mobileId,
                        job: jobUniqueId(job?.id, job?.jobType),
                        name: "",
                        typeMessage: `Your Pending Clock Out Job Reviewed and Not Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                        jobId: jobId
                      });
                    } else {
                      sendNotification(authToken, {
                        expoPushToken: jobPostedFacilityDetails?.mobileId,
                        title: jobUniqueId(job?.id, job?.jobType),
                        body: `Your Pending Clock Out Job Reviewed and Not Approved by ${facilityDetail?.firstName} ${facilityDetail?.lastName}.`,
                        data: { jobId: jobId, screen: "JobDetailScreen" }
                      });
                    }
                  }
                }
                setFetchAgainJobDashboard(!fetchAgainJobDashboard);
                setFetchAgainJobList(!fetchAgainJobList);
                if (Platform.OS === "web") {
                  alert("Not-Approved successfully.");
                } else {
                  Alert.alert("Not-Approved successfully.");
                }
                getUpdatedJob(jobId);
              })
              .catch((e) =>
                console.log("pendingJobOrNoShowFacilityDecideCancel error" + e)
              );
          }
        });
      }
    }
  };

  const deleteJobAction = async (id) => {
    await deleteJob(authToken, id)
      .then((res) => {
        setFetchAgainJobList(!fetchAgainJobList);
        props?.navigation?.goBack();
      })
      .catch((e) => console.log("deleteJob error" + e));
  };
  // console.log(job);
  return (
    <View style={styles.container}>
      {pageLoading ? (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Text>Loading...</Text>
        </View>
      ) : (
        <View>
          {jobPostedFacilityDetails && (
            <ScrollView showsVerticalScrollIndicator={false}>
              {job?.jobStatus === "Open" && (
                <View
                  style={{
                    marginHorizontal: 20,
                    marginTop: 10,
                    alignItems: "flex-end",
                    flexDirection: "row",
                    justifyContent: "flex-end"
                  }}
                >
                  <TouchableOpacity
                    style={{
                      borderWidth: 0.5,
                      borderColor: "#e6e6e6",
                      backgroundColor: "#f2f2f2",
                      borderRadius: 10,
                      elevation: 1,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingHorizontal: 15,
                      paddingVertical: 5
                    }}
                    onPress={() => {
                      job?.jobAcceptedNurses?.length === 0
                        ? onEditJobNavigate(job)
                        : Platform.OS === "web"
                        ? alert("Release nurse and try edit option!")
                        : Alert.alert("Release nurse and try edit option!");
                    }}
                  >
                    <Text
                      style={[
                        {
                          fontSize: 12,
                          color: "#000"
                        }
                      ]}
                    >
                      Edit
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{ marginLeft: 10 }}
                    onPress={() =>
                      job?.jobAcceptedNurses?.length === 0
                        ? Platform.OS === "web"
                          ? confirm(
                              "This will be permanently deleted, Continue?"
                            ).valueOf(true) && deleteJobAction(job?.id)
                          : Alert.alert(
                              "This will be permanently deleted, Continue?",
                              "",
                              [
                                {
                                  text: "Yes",
                                  onPress: () => deleteJobAction(job?.id)
                                },
                                {
                                  text: "No",
                                  style: "cancel"
                                }
                              ]
                            )
                        : Platform.OS === "web"
                        ? alert("Release nurse and try delete option!")
                        : Alert.alert("Release nurse and try delete option!")
                    }
                  >
                    <MaterialIcons
                      name={"delete-outline"}
                      size={24}
                      color="red"
                    />
                  </TouchableOpacity>
                </View>
              )}
              <View
                style={{
                  marginVertical: 5,
                  justifyContent: "center",
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  borderBottomWidth: 1.5,
                  borderBottomColor: "#f2f2f2"
                }}
              >
                <Text
                  style={{ color: "#1a1a1a", fontWeight: "500", fontSize: 15 }}
                >
                  Job ID
                </Text>
                <Text style={{ color: "#595959", fontSize: 12, marginTop: 5 }}>
                  {jobUniqueId(job?.id, job?.jobType)}
                </Text>
                {/* <Text style={{ color: "#595959", fontSize: 12, marginTop: 5 }}>
              {job?.id}
            </Text> */}
              </View>
              <View
                style={{
                  marginVertical: 5,
                  justifyContent: "center",
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  borderBottomWidth: 1.5,
                  borderBottomColor: "#f2f2f2"
                }}
              >
                <Text
                  style={{ color: "#1a1a1a", fontWeight: "500", fontSize: 15 }}
                >
                  Job Status
                </Text>
                <Text
                  style={{
                    color:
                      job?.jobStatus === "Missed" ||
                      job?.jobStatus === "Pending Clock Out"
                        ? "red"
                        : "#00b359",
                    fontSize: 12,
                    marginTop: 5
                  }}
                >
                  {job?.jobStatus}
                </Text>
              </View>

              {jobPostedFacilityDetails === undefined ? null : (
                <View
                  style={{
                    marginVertical: 5,
                    justifyContent: "center",
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    borderBottomWidth: 1.5,
                    borderBottomColor: "#f2f2f2"
                  }}
                >
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontWeight: "500",
                      fontSize: 15
                    }}
                  >
                    Posted By
                  </Text>
                  <Text
                    style={{ color: "#595959", fontSize: 12, marginTop: 5 }}
                  >
                    {jobPostedFacilityDetails?.firstName}{" "}
                    {jobPostedFacilityDetails?.lastName}
                  </Text>
                </View>
              )}

              {job?.approved_manager && (
                <View
                  style={{
                    marginVertical: 5,
                    justifyContent: "center",
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    borderBottomWidth: 1.5,
                    borderBottomColor: "#f2f2f2"
                  }}
                >
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontWeight: "500",
                      fontSize: 15
                    }}
                  >
                    Approved By
                  </Text>
                  <Text
                    style={{ color: "#595959", fontSize: 12, marginTop: 5 }}
                  >
                    {job?.approved_manager?.name}
                  </Text>
                </View>
              )}

              {job?.jobFinalSelectionNurseId === "" ? (
                job?.jobRequestedNurses.length !== 0 && (
                  <View
                    style={{
                      marginVertical: 5,
                      marginBottom: 20,
                      justifyContent: "center",
                      paddingVertical: 10
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingHorizontal: 20
                      }}
                    >
                      <Text
                        style={{
                          color: "#1a1a1a",
                          fontWeight: "500",
                          fontSize: 15
                        }}
                      >
                        Requested Nurse List{" "}
                        {job?.enableBid && "- With Bid Rate"}
                      </Text>
                      {!job?.enableBid &&
                        job?.checkInTime === "" &&
                        job?.jobFinalSelectionNurseId !== "" &&
                        job?.jobStatus === "Nurse Assigned" && (
                          <TouchableOpacity
                            style={{
                              backgroundColor: "#2775BD",
                              paddingHorizontal: 10,
                              paddingVertical: 5,
                              borderRadius: 8
                            }}
                            activeOpacity={0.5}
                            onPress={() => {
                              setNurseSwap(true);
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 12,
                                fontWeight: "600",
                                color: "#fff"
                              }}
                            >
                              Swap
                            </Text>
                          </TouchableOpacity>
                        )}
                    </View>
                    {job?.jobRequestedNurses
                      ?.filter(
                        (i) => i?.uniqueId !== job?.jobFinalSelectionNurseId
                      )
                      .flat()
                      .map((item, index) => {
                        return (
                          <View
                            key={index}
                            style={{
                              paddingVertical: 10,
                              display: "flex",
                              marginHorizontal: 15,
                              marginTop: 10,
                              padding: 8,
                              backgroundColor:
                                job?.jobFinalSelectionNurseId === item?.uniqueId
                                  ? "#e6ffee"
                                  : "#fff",
                              borderRadius: 10,
                              elevation: 0.5,
                              shadowColor: "#00b359"
                            }}
                          >
                            <View>
                              <View
                                style={{
                                  flexDirection: "row"
                                }}
                              >
                                <View
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    borderWidth: 2,
                                    borderRadius: 10,
                                    borderColor: "#2775BD",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "#fff",
                                    width: 60,
                                    height: 60,
                                    overflow: "hidden"
                                  }}
                                >
                                  {item?.profileImage ? (
                                    <Image
                                      source={{
                                        uri: item?.profileImage
                                      }}
                                      style={{
                                        width: 58,
                                        height: 58,
                                        borderRadius: 10
                                      }}
                                    />
                                  ) : (
                                    <Image
                                      source={require("../../../assets/images/icon.jpg")}
                                      style={{ width: 120, height: 120 }}
                                    />
                                  )}
                                </View>

                                <View style={{ marginLeft: 5, flex: 1 }}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "space-between"
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 16,
                                        fontWeight: "600"
                                      }}
                                    >
                                      {item?.firstName} {item?.lastName}
                                      <Text
                                        style={{
                                          fontSize: 16,
                                          color: "#2775BD"
                                        }}
                                      >
                                        (
                                        {item?.nurseType === "Internal Pool"
                                          ? "Internal Staff"
                                          : item?.nurseType === "Float Pool"
                                          ? "Float"
                                          : item?.nurseType}
                                        )
                                      </Text>
                                    </Text>
                                    {job?.jobFinalSelectionNurseId ===
                                      item?.uniqueId && (
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          alignItems: "center"
                                        }}
                                      >
                                        <Entypo
                                          name="dot-single"
                                          size={24}
                                          color={"#00b359"}
                                        />
                                        <Text
                                          style={{
                                            fontSize: 12,
                                            fontWeight: "600",
                                            color: "#00b359"
                                          }}
                                        >
                                          Selected
                                        </Text>
                                      </View>
                                    )}
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "space-between"
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 14,
                                        fontWeight: "400",
                                        marginTop: 5
                                      }}
                                    >
                                      {item?.primaryLicenseType}
                                    </Text>

                                    {job?.bidNurseArray
                                      ?.filter(
                                        (value) =>
                                          value?.jobBitTableNurseTableId ===
                                          item?.uniqueId
                                      )
                                      .map((item, index) => (
                                        <Text
                                          key={index}
                                          style={{
                                            fontSize: 12,
                                            fontWeight: "600",
                                            color: "#000"
                                          }}
                                        >
                                          Bid:{" "}
                                          <Text
                                            key={item?.id}
                                            style={{
                                              fontSize: 12,
                                              fontWeight: "600",
                                              color: "#ff3333"
                                            }}
                                          >
                                            ${item?.bitAmount}
                                          </Text>
                                        </Text>
                                      ))}
                                    {/* {acceptedJobs?.map((value) =>
                              value?.jobBitTableNurseTableId === item?.id ? (
                                <Text
                                  key={item?.id}
                                  style={{
                                    fontSize: 15,
                                    fontWeight: "600",
                                    color: "#ff3333",
                                  }}
                                >
                                  $ {value?.bitAmount}
                                </Text>
                              ) : null
                            )} */}
                                    {job?.enableBid &&
                                    job?.bidNurseArray?.filter(
                                      (value) =>
                                        value?.jobBitTableNurseTableId ===
                                        item?.uniqueId
                                    )?.length === 0 ? (
                                      <Text
                                        style={{
                                          fontSize: 15,
                                          fontWeight: "600",
                                          color: "#00b359"
                                        }}
                                      >
                                        $ {job?.baseRate}
                                      </Text>
                                    ) : null}
                                  </View>
                                </View>
                              </View>
                            </View>

                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                marginTop: 10
                              }}
                            >
                              <TouchableOpacity
                                style={{
                                  backgroundColor: "#2775BD",
                                  paddingHorizontal: 10,
                                  paddingVertical: 5,
                                  borderRadius: 8,
                                  marginRight: 10
                                }}
                                activeOpacity={0.5}
                                onPress={() =>
                                  onUserDetailNavigate({
                                    id: item?.uniqueId,
                                    facilityId: userUniqueId
                                  })
                                }
                              >
                                <Text
                                  style={{
                                    fontSize: 12,
                                    fontWeight: "600",
                                    color: "#fff"
                                  }}
                                >
                                  View Profile
                                </Text>
                              </TouchableOpacity>
                              <TouchableOpacity
                                style={{
                                  backgroundColor: "#ddd",
                                  paddingHorizontal: 10,
                                  paddingVertical: 5,
                                  borderRadius: 8,
                                  marginRight: 10
                                }}
                                activeOpacity={0.5}
                                onPress={() =>
                                  onChatRoomNavigate({
                                    chatRoomId: undefined,
                                    nurseId: item?.uniqueId,
                                    facilityId: userUniqueId
                                  })
                                }
                              >
                                <Text
                                  style={{
                                    fontSize: 12,
                                    fontWeight: "600",
                                    color: "#000"
                                  }}
                                >
                                  Message
                                </Text>
                              </TouchableOpacity>
                              {job?.checkInTime === "" &&
                                (job?.jobFinalSelectionNurseId === "" ||
                                  job?.jobFinalSelectionNurseId ===
                                    item?.uniqueId) &&
                                // moment(job?.startDate).format("MM-DD-YYYY") >= currentDate &&
                                GetDateMergedTime(
                                  job?.startDate,
                                  job?.startTime
                                ).isSameOrAfter(moment(), "hh:mm a") && (
                                  <TouchableOpacity
                                    style={{
                                      backgroundColor:
                                        job?.jobFinalSelectionNurseId ===
                                        item?.uniqueId
                                          ? "red"
                                          : "#00b359",
                                      paddingHorizontal: 10,
                                      paddingVertical: 5,
                                      borderRadius: 8
                                    }}
                                    activeOpacity={0.5}
                                    onPress={() =>
                                      job?.bidNurseArray?.length === 0
                                        ? job?.jobFinalSelectionNurseId ===
                                          item?.uniqueId
                                          ? Platform.OS === "web"
                                            ? confirm(
                                                "To Release the assigned nurse. Click OK"
                                              ).valueOf(true) && webCancel(item)
                                            : Alert.alert(
                                                "To Release the assigned nurse. Click OK",
                                                "",
                                                [
                                                  {
                                                    text: "Ok",
                                                    onPress: () => {
                                                      setRemoveNurseObj({
                                                        id: item?.id,
                                                        uniqueId:
                                                          item?.uniqueId,
                                                        mobileId:
                                                          item?.mobileId,
                                                        type: item?.nurseType,
                                                        name:
                                                          item?.firstName +
                                                          " " +
                                                          item?.lastName
                                                      });
                                                      setRemoveNurse(true);
                                                    }
                                                    // cancelNurse(
                                                    //   item?.id,
                                                    //   item?.mobileId
                                                    // ),
                                                  },
                                                  {
                                                    text: "Cancel",
                                                    style: "cancel"
                                                  }
                                                ]
                                              )
                                          : !item?.nurseAppAccessControl
                                          ? selectNurse(
                                              item?.id,
                                              item?.uniqueId,
                                              item?.nurseType,
                                              item?.mobileId
                                            )
                                          : Platform.OS === "web"
                                          ? alert(
                                              `${item?.firstName} ${item?.lastName} account is currently Suspended.`
                                            )
                                          : Alert.alert(
                                              `${item?.firstName} ${item?.lastName} account is currently Suspended.`
                                            )
                                        : job?.bidNurseArray
                                            ?.filter(
                                              (v) =>
                                                v?.jobBitTableNurseTableId ===
                                                item?.uniqueId
                                            )
                                            .map(
                                              (value) =>
                                                value?.jobBitTableNurseTableId ===
                                                  item?.uniqueId &&
                                                (job?.jobFinalSelectionNurseId ===
                                                item?.uniqueId
                                                  ? Platform.OS === "web"
                                                    ? confirm(
                                                        "Are you sure want to cancel?"
                                                      ).valueOf(true) &&
                                                      webCancelBid(item)
                                                    : Alert.alert(
                                                        "Are you sure want to cancel?",
                                                        "",
                                                        [
                                                          {
                                                            text: "Yes",
                                                            onPress: () => {
                                                              setRemoveNurseBid(
                                                                true
                                                              );
                                                              setRemoveNurseBidObj(
                                                                {
                                                                  id: item?.id,
                                                                  uniqueId:
                                                                    item?.uniqueId,
                                                                  mobileId:
                                                                    item?.mobileId,
                                                                  type: item?.nurseType,
                                                                  amount: "",
                                                                  name:
                                                                    item?.firstName +
                                                                    " " +
                                                                    item?.lastName
                                                                }
                                                              );
                                                            }
                                                            // cancelNurseBid(
                                                            //   item?.id,
                                                            //   item?.mobileId,
                                                            //   "",
                                                            //   value
                                                            // ),
                                                          },
                                                          {
                                                            text: "No",
                                                            style: "cancel"
                                                          }
                                                        ]
                                                      )
                                                  : selectNurseBid(
                                                      item?.id,
                                                      item?.mobileId,
                                                      value?.bitAmount
                                                    ))
                                            )
                                    }
                                  >
                                    <Text
                                      style={{
                                        fontSize: 12,
                                        fontWeight: "600",
                                        color: "#fff"
                                      }}
                                    >
                                      {job?.jobFinalSelectionNurseId ===
                                      item?.uniqueId
                                        ? "Release"
                                        : "Approve"}
                                    </Text>
                                  </TouchableOpacity>
                                )}
                              {job?.jobFinalSelectionNurseId !==
                                item?.uniqueId &&
                                job?.jobStatus !== "Unfulfilled" && (
                                  <TouchableOpacity
                                    style={{
                                      backgroundColor: "red",
                                      paddingHorizontal: 10,
                                      paddingVertical: 5,
                                      borderRadius: 8,
                                      marginLeft: 10
                                    }}
                                    activeOpacity={0.5}
                                    onPress={() => {
                                      let name =
                                        item?.firstName + " " + item?.lastName;
                                      Platform.OS === "web"
                                        ? confirm(
                                            "Are you sure want to Deny?"
                                          ).valueOf(true) &&
                                          declinedNurseAction(
                                            item?.id,
                                            item?.uniqueId,
                                            item?.mobileId,
                                            item?.nurseType,
                                            name
                                          )
                                        : Alert.alert(
                                            "Are you sure want to Deny?",
                                            "",
                                            [
                                              {
                                                text: "Yes",
                                                onPress: () => {
                                                  declinedNurseAction(
                                                    item?.id,
                                                    item?.uniqueId,
                                                    item?.mobileId,
                                                    item?.nurseType,
                                                    name
                                                  );
                                                }
                                              },
                                              {
                                                text: "No",
                                                style: "cancel"
                                              }
                                            ]
                                          );
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 12,
                                        fontWeight: "600",
                                        color: "#fff"
                                      }}
                                    >
                                      Deny
                                    </Text>
                                  </TouchableOpacity>
                                )}
                            </View>
                          </View>
                        );
                      })}
                  </View>
                )
              ) : (
                <View
                  style={{
                    marginVertical: 5,
                    marginBottom: 20,
                    justifyContent: "center",
                    paddingVertical: 10
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingHorizontal: 20
                    }}
                  >
                    <Text
                      style={{
                        color: "#1a1a1a",
                        fontWeight: "500",
                        fontSize: 15
                      }}
                    >
                      Nurse
                    </Text>
                    {!job?.enableBid &&
                      job?.checkInTime === "" &&
                      job?.jobStatus === "Nurse Assigned" && (
                        <TouchableOpacity
                          style={{
                            backgroundColor: "#2775BD",
                            paddingHorizontal: 10,
                            paddingVertical: 5,
                            borderRadius: 8
                          }}
                          activeOpacity={0.5}
                          onPress={() => {
                            setNurseSwap(true);
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 12,
                              fontWeight: "600",
                              color: "#fff"
                            }}
                          >
                            Swap
                          </Text>
                        </TouchableOpacity>
                      )}
                  </View>

                  <View
                    style={{
                      paddingVertical: 10,
                      display: "flex",
                      marginHorizontal: 15,
                      marginTop: 10,
                      padding: 8,
                      backgroundColor: "#e6ffee",
                      borderRadius: 10,
                      elevation: 0.5,
                      shadowColor: "#00b359"
                    }}
                  >
                    <View>
                      <View
                        style={{
                          flexDirection: "row"
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            borderWidth: 2,
                            borderRadius: 10,
                            borderColor: "#2775BD",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#fff",
                            width: 60,
                            height: 60,
                            overflow: "hidden"
                          }}
                        >
                          {job?.nurse?.profileImage ? (
                            <Image
                              source={{
                                uri: job?.nurse?.profileImage
                              }}
                              style={{
                                width: 58,
                                height: 58,
                                borderRadius: 10
                              }}
                            />
                          ) : (
                            <Image
                              source={require("../../../assets/images/icon.jpg")}
                              style={{ width: 120, height: 120 }}
                            />
                          )}
                        </View>

                        <View style={{ marginLeft: 5, flex: 1 }}>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between"
                            }}
                          >
                            <Text style={{ fontSize: 16, fontWeight: "600" }}>
                              {job?.nurse?.firstName} {job?.nurse?.lastName}
                              <Text
                                style={{
                                  fontSize: 16,
                                  color: "#2775BD"
                                }}
                              >
                                (
                                {job?.nurse?.nurseType === "Internal Pool"
                                  ? "Internal Staff"
                                  : job?.nurse?.nurseType === "Float Pool"
                                  ? "Float"
                                  : job?.nurse?.nurseType}
                                )
                              </Text>
                            </Text>
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center"
                              }}
                            >
                              <Entypo
                                name="dot-single"
                                size={24}
                                color={"#00b359"}
                              />
                              <Text
                                style={{
                                  fontSize: 12,
                                  fontWeight: "600",
                                  color: "#00b359"
                                }}
                              >
                                Selected
                              </Text>
                            </View>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between"
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 14,
                                fontWeight: "400",
                                marginTop: 5
                              }}
                            >
                              {job?.nurse?.primaryLicenseType}
                            </Text>

                            {job?.bidNurseArray
                              ?.filter(
                                (value) =>
                                  value?.jobBitTableNurseTableId ===
                                  job?.nurse?.uniqueId
                              )
                              .map((item, index) => (
                                <Text
                                  key={index}
                                  style={{
                                    fontSize: 12,
                                    fontWeight: "600",
                                    color: "#000"
                                  }}
                                >
                                  Bid:{" "}
                                  <Text
                                    key={item?.id}
                                    style={{
                                      fontSize: 12,
                                      fontWeight: "600",
                                      color: "#ff3333"
                                    }}
                                  >
                                    ${item?.bitAmount}
                                  </Text>
                                </Text>
                              ))}
                            {/* {acceptedJobs?.map((value) =>
                          value?.jobBitTableNurseTableId === item?.id ? (
                            <Text
                              key={item?.id}
                              style={{
                                fontSize: 15,
                                fontWeight: "600",
                                color: "#ff3333",
                              }}
                            >
                              $ {value?.bitAmount}
                            </Text>
                          ) : null
                        )} */}
                            {job?.enableBid &&
                            job?.bidNurseArray?.filter(
                              (value) =>
                                value?.jobBitTableNurseTableId ===
                                job?.nurse?.uniqueId
                            )?.length === 0 ? (
                              <Text
                                style={{
                                  fontSize: 15,
                                  fontWeight: "600",
                                  color: "#00b359"
                                }}
                              >
                                $ {job?.baseRate}
                              </Text>
                            ) : null}
                          </View>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        marginTop: 10
                      }}
                    >
                      <TouchableOpacity
                        style={{
                          backgroundColor: "#2775BD",
                          paddingHorizontal: 10,
                          paddingVertical: 5,
                          borderRadius: 8,
                          marginRight: 10
                        }}
                        activeOpacity={0.5}
                        onPress={() =>
                          onUserDetailNavigate({
                            id: job?.nurse?.uniqueId,
                            facilityId: userUniqueId
                          })
                        }
                      >
                        <Text
                          style={{
                            fontSize: 12,
                            fontWeight: "600",
                            color: "#fff"
                          }}
                        >
                          View Profile
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={{
                          backgroundColor: "#ddd",
                          paddingHorizontal: 10,
                          paddingVertical: 5,
                          borderRadius: 8,
                          marginRight: 10
                        }}
                        activeOpacity={0.5}
                        onPress={() =>
                          onChatRoomNavigate({
                            chatRoomId: undefined,
                            nurseId: job?.nurse?.uniqueId,
                            facilityId: userUniqueId
                          })
                        }
                      >
                        <Text
                          style={{
                            fontSize: 12,
                            fontWeight: "600",
                            color: "#000"
                          }}
                        >
                          Message
                        </Text>
                      </TouchableOpacity>
                      {job?.checkInTime === "" &&
                        (job?.jobFinalSelectionNurseId === "" ||
                          job?.jobFinalSelectionNurseId ===
                            job?.nurse?.uniqueId) &&
                        // moment(job?.startDate).format("MM-DD-YYYY") >= currentDate &&
                        GetDateMergedTime(
                          job?.startDate,
                          job?.startTime
                        ).isSameOrAfter(moment(), "hh:mm a") && (
                          <TouchableOpacity
                            style={{
                              backgroundColor:
                                job?.jobFinalSelectionNurseId ===
                                job?.nurse?.uniqueId
                                  ? "red"
                                  : "#00b359",
                              paddingHorizontal: 10,
                              paddingVertical: 5,
                              borderRadius: 8
                            }}
                            activeOpacity={0.5}
                            onPress={() =>
                              job?.bidNurseArray?.length === 0
                                ? job?.jobFinalSelectionNurseId ===
                                  job?.nurse?.uniqueId
                                  ? Platform.OS === "web"
                                    ? confirm(
                                        "To Release the assigned nurse. Click OK"
                                      ).valueOf(true) && webCancel(job?.nurse)
                                    : Alert.alert(
                                        "To Release the assigned nurse. Click OK",
                                        "",
                                        [
                                          {
                                            text: "Ok",
                                            onPress: () => {
                                              setRemoveNurseObj({
                                                id: job?.nurse?.id,
                                                uniqueId: job?.nurse?.uniqueId,
                                                mobileId: job?.nurse?.mobileId,
                                                type: job?.nurse?.nurseType,
                                                name:
                                                  job?.nurse?.firstName +
                                                  " " +
                                                  job?.nurse?.lastName
                                              });
                                              setRemoveNurse(true);
                                            }
                                            // cancelNurse(
                                            //   item?.id,
                                            //   item?.mobileId
                                            // ),
                                          },
                                          {
                                            text: "Cancel",
                                            style: "cancel"
                                          }
                                        ]
                                      )
                                  : !job?.nurse?.nurseAppAccessControl
                                  ? selectNurse(
                                      job?.nurse?.id,
                                      job?.nurse?.uniqueId,
                                      job?.nurse?.nurseType,
                                      job?.nurse?.mobileId
                                    )
                                  : Platform.OS === "web"
                                  ? alert(
                                      `${job?.nurse?.firstName} ${job?.nurse?.lastName} account is currently Suspended.`
                                    )
                                  : Alert.alert(
                                      `${job?.nurse?.firstName} ${job?.nurse?.lastName} account is currently Suspended.`
                                    )
                                : job?.bidNurseArray
                                    ?.filter(
                                      (v) =>
                                        v?.jobBitTableNurseTableId ===
                                        job?.nurse?.uniqueId
                                    )
                                    .map(
                                      (value) =>
                                        value?.jobBitTableNurseTableId ===
                                          job?.nurse?.uniqueId &&
                                        (job?.jobFinalSelectionNurseId ===
                                        job?.nurse?.uniqueId
                                          ? Platform.OS === "web"
                                            ? confirm(
                                                "Are you sure want to cancel?"
                                              ).valueOf(true) &&
                                              webCancelBid(job?.nurse)
                                            : Alert.alert(
                                                "Are you sure want to cancel?",
                                                "",
                                                [
                                                  {
                                                    text: "Yes",
                                                    onPress: () => {
                                                      setRemoveNurseBid(true);
                                                      setRemoveNurseBidObj({
                                                        id: job?.nurse?.id,
                                                        uniqueId:
                                                          job?.nurse?.uniqueId,
                                                        mobileId:
                                                          job?.nurse?.mobileId,
                                                        type: job?.nurse
                                                          ?.nurseType,
                                                        amount: "",
                                                        name:
                                                          job?.nurse
                                                            ?.firstName +
                                                          " " +
                                                          job?.nurse?.lastName
                                                      });
                                                    }
                                                    // cancelNurseBid(
                                                    //   item?.id,
                                                    //   item?.mobileId,
                                                    //   "",
                                                    //   value
                                                    // ),
                                                  },
                                                  {
                                                    text: "No",
                                                    style: "cancel"
                                                  }
                                                ]
                                              )
                                          : selectNurseBid(
                                              job?.nurse?.uniqueId,
                                              job?.nurse?.mobileId,
                                              value?.bitAmount
                                            ))
                                    )
                            }
                          >
                            <Text
                              style={{
                                fontSize: 12,
                                fontWeight: "600",
                                color: "#fff"
                              }}
                            >
                              {job?.jobFinalSelectionNurseId ===
                              job?.nurse?.uniqueId
                                ? "Release"
                                : "Approve"}
                            </Text>
                          </TouchableOpacity>
                        )}
                      {job?.jobFinalSelectionNurseId !== job?.nurse?.uniqueId &&
                        job?.jobStatus !== "Unfulfilled" && (
                          <TouchableOpacity
                            style={{
                              backgroundColor: "red",
                              paddingHorizontal: 10,
                              paddingVertical: 5,
                              borderRadius: 8,
                              marginLeft: 10
                            }}
                            activeOpacity={0.5}
                            onPress={() => {
                              let name =
                                job?.nurse?.firstName +
                                " " +
                                job?.nurse?.lastName;
                              Platform.OS === "web"
                                ? confirm("Are you sure want to Deny?").valueOf(
                                    true
                                  ) &&
                                  declinedNurseAction(
                                    job?.nurse?.id,
                                    job?.nurse?.uniqueId,
                                    job?.nurse?.mobileId,
                                    job?.nurse?.nurseType,
                                    name
                                  )
                                : Alert.alert(
                                    "Are you sure want to Deny?",
                                    "",
                                    [
                                      {
                                        text: "Yes",
                                        onPress: () => {
                                          declinedNurseAction(
                                            job?.nurse?.id,
                                            job?.nurse?.uniqueId,
                                            job?.nurse?.mobileId,
                                            job?.nurse?.nurseType,
                                            name
                                          );
                                        }
                                      },
                                      {
                                        text: "No",
                                        style: "cancel"
                                      }
                                    ]
                                  );
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 12,
                                fontWeight: "600",
                                color: "#fff"
                              }}
                            >
                              Deny
                            </Text>
                          </TouchableOpacity>
                        )}
                    </View>
                  </View>
                </View>
              )}

              <View
                style={{
                  marginVertical: 5,
                  justifyContent: "center",
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  borderBottomWidth: 1.5,
                  borderBottomColor: "#f2f2f2"
                }}
              >
                <Text
                  style={{ color: "#1a1a1a", fontWeight: "500", fontSize: 15 }}
                >
                  {job?.jobType === "Shift" ? "Shift" : "Visit"} Date
                </Text>
                <Text style={{ color: "#595959", fontSize: 12, marginTop: 5 }}>
                  Start Date : {moment(job?.startDate).format("MM-DD-YYYY")} -
                  End Date : {moment(job?.endDate).format("MM-DD-YYYY")}
                </Text>
              </View>
              <View
                style={{
                  marginVertical: 5,
                  justifyContent: "center",
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  borderBottomWidth: 1.5,
                  borderBottomColor: "#f2f2f2"
                }}
              >
                <Text
                  style={{ color: "#1a1a1a", fontWeight: "500", fontSize: 15 }}
                >
                  {job?.jobType === "Shift" ? "Shift" : "Visit"} Time
                </Text>
                <Text style={{ color: "#595959", fontSize: 12, marginTop: 5 }}>
                  Start Time :{" "}
                  {moment(new Date(job?.startTime)).format("hh:mm a")} - End
                  Time : {moment(new Date(job?.endTime)).format("hh:mm a")}
                </Text>
              </View>
              <View
                style={{
                  marginVertical: 5,
                  justifyContent: "center",
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  borderBottomWidth: 1.5,
                  borderBottomColor: "#f2f2f2"
                }}
              >
                <Text
                  style={{ color: "#1a1a1a", fontWeight: "500", fontSize: 15 }}
                >
                  Duration
                </Text>
                <Text style={{ color: "#595959", fontSize: 12, marginTop: 5 }}>
                  {timeDifferent(job?.startTime, job?.endTime)}
                </Text>
              </View>
              <View
                style={{
                  marginVertical: 5,
                  justifyContent: "center",
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  borderBottomWidth: 1.5,
                  borderBottomColor: "#f2f2f2"
                }}
              >
                <Text
                  style={{ color: "#1a1a1a", fontWeight: "500", fontSize: 15 }}
                >
                  License Type
                </Text>

                <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                  {job?.licenseType?.map((item, index) => {
                    return (
                      <View
                        key={index}
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 5,
                          marginHorizontal: 5
                        }}
                      >
                        <Text style={{ fontSize: 12, color: "#595959" }}>
                          {item}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              </View>
              <View
                style={{
                  marginVertical: 5,
                  justifyContent: "center",
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  borderBottomWidth: 1.5,
                  borderBottomColor: "#f2f2f2"
                }}
              >
                <Text
                  style={{ color: "#1a1a1a", fontWeight: "500", fontSize: 15 }}
                >
                  {job?.jobType === "Shift" ? "Shift" : "Visit"} Title
                </Text>
                <Text style={{ color: "#595959", fontSize: 12, marginTop: 5 }}>
                  {job?.shiftTitle}
                </Text>
              </View>
              {job?.specialty && (
                <View
                  style={{
                    marginVertical: 5,
                    justifyContent: "center",
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    borderBottomWidth: 1.5,
                    borderBottomColor: "#f2f2f2"
                  }}
                >
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontWeight: "500",
                      fontSize: 15
                    }}
                  >
                    Specialty{" "}
                    {job?.specialtyRequired && (
                      <Text style={{ color: "red" }}>*</Text>
                    )}
                  </Text>
                  <Text
                    style={{ color: "#595959", fontSize: 12, marginTop: 5 }}
                  >
                    {job?.specialty}
                  </Text>
                </View>
              )}
              {job?.certification?.length !== 0 && (
                <View
                  style={{
                    marginVertical: 5,
                    justifyContent: "center",
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    borderBottomWidth: 1.5,
                    borderBottomColor: "#f2f2f2"
                  }}
                >
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontWeight: "500",
                      fontSize: 15
                    }}
                  >
                    Certification{" "}
                    {job?.certificationRequired && (
                      <Text style={{ color: "red" }}>*</Text>
                    )}
                  </Text>

                  <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                    {job?.certification?.map((item, index) => {
                      return (
                        <View
                          key={index}
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: 5
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 12,
                              marginHorizontal: 3
                            }}
                          >
                            {item}
                          </Text>
                        </View>
                      );
                    })}
                  </View>
                </View>
              )}
              {job?.yearOfExperience && (
                <View
                  style={{
                    marginVertical: 5,
                    justifyContent: "center",
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    borderBottomWidth: 1.5,
                    borderBottomColor: "#f2f2f2"
                  }}
                >
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontWeight: "500",
                      fontSize: 15
                    }}
                  >
                    Experience Level{" "}
                    {job?.experienceRequired && (
                      <Text style={{ color: "red" }}>*</Text>
                    )}
                  </Text>
                  <Text
                    style={{ color: "#595959", fontSize: 12, marginTop: 5 }}
                  >
                    {job?.yearOfExperience}
                  </Text>
                </View>
              )}
              {job?.EMRorEHRExperience?.length !== 0 && (
                <View
                  style={{
                    marginVertical: 5,
                    justifyContent: "center",
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    borderBottomWidth: 1.5,
                    borderBottomColor: "#f2f2f2"
                  }}
                >
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontWeight: "500",
                      fontSize: 15
                    }}
                  >
                    EMR or EHR Experience
                    {job?.emrehrRequired && (
                      <Text style={{ color: "red" }}>*</Text>
                    )}
                  </Text>

                  <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                    {job?.EMRorEHRExperience?.map((item, index) => {
                      return (
                        <View
                          key={index}
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: 5
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 12,
                              marginHorizontal: 3
                            }}
                          >
                            {item}
                          </Text>
                        </View>
                      );
                    })}
                  </View>
                </View>
              )}
              {/* <View
                style={{
                  marginVertical: 5,
                  justifyContent: "center",
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  borderBottomWidth: 1.5,
                  borderBottomColor: "#f2f2f2"
                }}
              >
                <Text
                  style={{ color: "#1a1a1a", fontWeight: "500", fontSize: 15 }}
                >
                  Job Expiration
                </Text>
                <Text style={{ color: "#595959", fontSize: 12, marginTop: 5 }}>
                  Before{" "}
                  <Text style={{ fontWeight: "500" }}>{job?.expiration}</Text>{" "}
                  hours
                </Text>
              </View> */}
              {job?.jobTiming !== "" ? (
                <View
                  style={{
                    marginVertical: 5,
                    justifyContent: "center",
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    borderBottomWidth: 1.5,
                    borderBottomColor: "#f2f2f2"
                  }}
                >
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontWeight: "500",
                      fontSize: 15
                    }}
                  >
                    {job?.jobType === "Shift" ? "Shift" : "Visit"} Times
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 5
                    }}
                  >
                    <Text
                      style={{ fontSize: 10, color: "#000", marginRight: 5 }}
                    >
                      {job?.jobTiming === "Morning" ? (
                        <Feather name="sun" size={12} color="black" />
                      ) : job?.jobTiming === "Afternoon" ? (
                        <Ionicons name="sunny-sharp" size={12} color="black" />
                      ) : job?.jobTiming === "Evening" ? (
                        <Ionicons
                          name="partly-sunny-outline"
                          size={12}
                          color="black"
                        />
                      ) : (
                        <Feather name="moon" size={12} color="black" />
                      )}
                    </Text>
                    <Text
                      style={{
                        color: "#595959",
                        fontSize: 12
                      }}
                    >
                      {job?.jobTiming}
                    </Text>
                  </View>
                </View>
              ) : null}

              <View
                style={{
                  marginVertical: 5,
                  justifyContent: "center",
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  borderBottomWidth: 1.5,
                  borderBottomColor: "#f2f2f2"
                }}
              >
                <Text
                  style={{ color: "#1a1a1a", fontWeight: "500", fontSize: 15 }}
                >
                  Break
                </Text>
                <Text style={{ color: "#595959", fontSize: 12, marginTop: 5 }}>
                  {job?.break === "NA" ? "No Break" : job?.break}
                </Text>
              </View>

              {job?.customerName && (
                <View
                  style={{
                    marginVertical: 5,
                    justifyContent: "center",
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    borderBottomWidth: 1.5,
                    borderBottomColor: "#f2f2f2"
                  }}
                >
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontWeight: "500",
                      fontSize: 15
                    }}
                  >
                    Customer Name{" "}
                    {job?.customerVisibility && (
                      <Text style={{ color: "red" }}>*</Text>
                    )}
                  </Text>
                  <View
                    style={{
                      alignItems: "center",
                      flexDirection: "row",
                      justifyContent: "space-between"
                    }}
                  >
                    <Text
                      style={{
                        color: "#595959",
                        fontSize: 12,
                        marginTop: 5,
                        flex: 1
                      }}
                    >
                      {job?.customerName}
                    </Text>
                  </View>
                </View>
              )}

              <View
                style={{
                  marginVertical: 5,
                  justifyContent: "center",
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  borderBottomWidth: 1.5,
                  borderBottomColor: "#f2f2f2"
                }}
              >
                <Text
                  style={{ color: "#1a1a1a", fontWeight: "500", fontSize: 15 }}
                >
                  Address
                </Text>
                <View
                  style={{
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-between"
                  }}
                >
                  <Text
                    style={{
                      color: "#595959",
                      fontSize: 12,
                      marginTop: 5,
                      flex: 1
                    }}
                  >
                    {job?.fullAddress}
                  </Text>

                  <TouchableOpacity onPress={() => openMap(job?.fullAddress)}>
                    <ImageBackground
                      source={require("../../../assets/images/maps-icon.png")}
                      style={{
                        width: 50,
                        height: 50,
                        justifyContent: "flex-end"
                      }}
                    >
                      <Text
                        style={{
                          backgroundColor: "#00b359",
                          fontSize: 12,
                          textAlign: "center",
                          fontWeight: "600",
                          paddingVertical: 2,
                          color: "#fff",
                          opacity: 0.7
                        }}
                      >
                        view
                      </Text>
                    </ImageBackground>
                  </TouchableOpacity>
                </View>
              </View>

              {job?.unit && (
                <View
                  style={{
                    marginVertical: 5,
                    justifyContent: "center",
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    borderBottomWidth: 1.5,
                    borderBottomColor: "#f2f2f2"
                  }}
                >
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontWeight: "500",
                      fontSize: 15
                    }}
                  >
                    Unit / Floor
                  </Text>
                  <Text
                    style={{ color: "#595959", fontSize: 12, marginTop: 5 }}
                  >
                    {job?.unit} - {job?.floor}
                  </Text>
                </View>
              )}

              <View
                style={{
                  marginVertical: 5,
                  justifyContent: "center",
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  borderBottomWidth: 1.5,
                  borderBottomColor: "#f2f2f2"
                }}
              >
                <Text
                  style={{ color: "#1a1a1a", fontWeight: "500", fontSize: 15 }}
                >
                  Current Rate
                </Text>
                <Text style={{ color: "#00b359", fontSize: 12, marginTop: 5 }}>
                  $ {job?.baseRate} / hr
                </Text>
              </View>
              <View
                style={{
                  marginVertical: 5,
                  justifyContent: "center",
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  borderBottomWidth: 1.5,
                  borderBottomColor: "#f2f2f2"
                }}
              >
                <Text
                  style={{ color: "#1a1a1a", fontWeight: "500", fontSize: 15 }}
                >
                  Job Type
                </Text>
                <Text style={{ color: "#00b359", fontSize: 12, marginTop: 5 }}>
                  {job?.jobType}
                </Text>
              </View>

              {/* <View
            style={{
              marginVertical: 5,
              justifyContent: "center",
              paddingHorizontal: 20,
              paddingVertical: 10,
              borderBottomWidth: 1.5,
              borderBottomColor: "#f2f2f2",
            }}
          >
            <Text style={{ color: "#1a1a1a", fontWeight: "500", fontSize: 15 }}>
              Duration
            </Text>
            <Text style={{ color: "#595959", fontSize: 12, marginTop: 5 }}>
              {job?.jobDuration}
            </Text>
          </View> */}
              {job?.enableBid && job?.maxBidAmount && (
                <View
                  style={{
                    marginVertical: 5,
                    justifyContent: "center",
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    borderBottomWidth: 1.5,
                    borderBottomColor: "#f2f2f2"
                  }}
                >
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontWeight: "500",
                      fontSize: 15
                    }}
                  >
                    Max Bid Amount
                  </Text>
                  <Text
                    style={{ color: "#00b359", fontSize: 12, marginTop: 5 }}
                  >
                    $ {job?.maxBidAmount} / hr
                  </Text>
                </View>
              )}

              {job?.notes !== "" ? (
                <View
                  style={{
                    marginVertical: 5,
                    justifyContent: "center",
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    borderBottomWidth: 1.5,
                    borderBottomColor: "#f2f2f2"
                  }}
                >
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontWeight: "500",
                      fontSize: 15
                    }}
                  >
                    Notes
                  </Text>
                  <Text
                    style={{
                      color: "#595959",
                      fontSize: 12,
                      marginTop: 5
                    }}
                  >
                    {job?.notes}
                  </Text>
                </View>
              ) : null}

              {Array.isArray(job?.nurseCancelNotes) &&
              job?.nurseCancelNotes?.length !== 0 ? (
                <View
                  style={{
                    marginVertical: 5,
                    justifyContent: "center",
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    borderBottomWidth: 1.5,
                    borderBottomColor: "#f2f2f2"
                  }}
                >
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontWeight: "500",
                      fontSize: 15
                    }}
                  >
                    Nurse cancellation notes
                  </Text>
                  {job?.nurseCancelNotes?.map((item, index) => {
                    return (
                      <View
                        key={index}
                        style={{
                          marginVertical: 5,
                          justifyContent: "center"
                        }}
                      >
                        <Text
                          style={{
                            color: "#595959",
                            fontSize: 12
                          }}
                        >
                          Nurse : {item?.nurseName}
                        </Text>
                        {item?.cancelNote && (
                          <Text
                            style={{
                              color: "#595959",
                              fontSize: 12,
                              marginTop: 5
                            }}
                          >
                            Reason : {item?.cancelNote}
                          </Text>
                        )}
                      </View>
                    );
                  })}
                </View>
              ) : null}

              {job?.nurseSwapNotes && (
                <View
                  style={{
                    marginVertical: 5,
                    justifyContent: "center",
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    borderBottomWidth: 1.5,
                    borderBottomColor: "#f2f2f2"
                  }}
                >
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontWeight: "500",
                      fontSize: 15
                    }}
                  >
                    Nurse Swap Reason
                  </Text>

                  <Text
                    style={{
                      color: "#595959",
                      fontSize: 12,
                      marginTop: 10
                    }}
                  >
                    {job?.nurseSwapNotes}
                  </Text>
                </View>
              )}

              {job?.checkInTime !== "" && (
                <View>
                  <View
                    style={{
                      marginVertical: 5,
                      justifyContent: "center",
                      paddingHorizontal: 20,
                      paddingVertical: 10,
                      borderBottomWidth: 1.5,
                      borderBottomColor: "#f2f2f2"
                    }}
                  >
                    <Text
                      style={{
                        color: "#2775BD",
                        fontWeight: "500",
                        fontSize: 20
                      }}
                    >
                      Clock In Details
                    </Text>
                    <Text
                      style={{ color: "#00b359", fontSize: 15, marginTop: 5 }}
                    >
                      Time ={" "}
                      {moment(new Date(job?.checkInTime)).format("hh:mm a")}
                    </Text>
                    <View
                      style={{
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "space-between"
                      }}
                    >
                      <Text
                        style={{
                          color: "#595959",
                          fontSize: 12,
                          flex: 1
                        }}
                      >
                        {job?.checkInFullAddress}
                      </Text>

                      <TouchableOpacity
                        onPress={() => openMap(job?.checkInFullAddress)}
                      >
                        <ImageBackground
                          source={require("../../../assets/images/maps-icon.png")}
                          style={{
                            width: 50,
                            height: 50,
                            justifyContent: "flex-end"
                          }}
                        >
                          <Text
                            style={{
                              backgroundColor: "#00b359",
                              fontSize: 12,
                              textAlign: "center",
                              fontWeight: "600",
                              paddingVertical: 2,
                              color: "#fff",
                              opacity: 0.7
                            }}
                          >
                            view
                          </Text>
                        </ImageBackground>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              )}

              {job?.checkOutTime !== "" && (
                <View>
                  <View
                    style={{
                      marginVertical: 5,
                      justifyContent: "center",
                      paddingHorizontal: 20,
                      paddingVertical: 10,
                      borderBottomWidth: 1.5,
                      borderBottomColor: "#f2f2f2"
                    }}
                  >
                    <Text
                      style={{
                        color: "#2775BD",
                        fontWeight: "500",
                        fontSize: 20
                      }}
                    >
                      Clock Out Details
                    </Text>
                    <Text
                      style={{ color: "#00b359", fontSize: 15, marginTop: 5 }}
                    >
                      Time ={" "}
                      {moment(new Date(job?.checkOutTime)).format("hh:mm a")}
                    </Text>
                    <View
                      style={{
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "space-between"
                      }}
                    >
                      <Text
                        style={{
                          color: "#595959",
                          fontSize: 12,
                          flex: 1
                        }}
                      >
                        {job?.checkOutFullAddress}
                      </Text>

                      <TouchableOpacity
                        onPress={() => openMap(job?.checkOutFullAddress)}
                      >
                        <ImageBackground
                          source={require("../../../assets/images/maps-icon.png")}
                          style={{
                            width: 50,
                            height: 50,
                            justifyContent: "flex-end"
                          }}
                        >
                          <Text
                            style={{
                              backgroundColor: "#00b359",
                              fontSize: 12,
                              textAlign: "center",
                              fontWeight: "600",
                              paddingVertical: 2,
                              color: "#fff",
                              opacity: 0.7
                            }}
                          >
                            view
                          </Text>
                        </ImageBackground>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              )}

              {job?.pendingOrNoShowFacilityDecideMessage !== "" ? (
                <View
                  style={{
                    marginVertical: 5,
                    justifyContent: "center",
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    borderBottomWidth: 1.5,
                    borderBottomColor: "#f2f2f2"
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Text
                      style={{
                        color: "#1a1a1a",
                        fontWeight: "500",
                        fontSize: 15
                      }}
                    >
                      Reviewer Notes{" "}
                    </Text>
                    {job?.neverCheckOutReason !== "" && (
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-end"
                        }}
                      >
                        <TouchableOpacity
                          style={{
                            backgroundColor:
                              job?.pendingOrNoShowFacilityDecideMessage !==
                                "" && !job?.pendingOrNoShowFacilityDecideStatus
                                ? "red"
                                : "#00b359",
                            paddingHorizontal: 10,
                            paddingVertical: 5,
                            borderRadius: 5
                          }}
                          onPress={() => setPendingNeverCheckOutModel(true)}
                        >
                          <Text
                            style={{
                              textAlign: "right",
                              fontSize: 12,
                              color: "#fff"
                            }}
                          >
                            View Reason
                            {job?.pendingOrNoShowFacilityDecideMessage !== "" &&
                              !job?.pendingOrNoShowFacilityDecideStatus && (
                                <Text
                                  style={{
                                    textAlign: "right",
                                    fontSize: 12,
                                    marginRight: 10
                                  }}
                                >
                                  -Not Approved
                                </Text>
                              )}
                          </Text>
                        </TouchableOpacity>
                      </View>
                    )}
                    {job?.noShowReason !== "" && (
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-end"
                        }}
                      >
                        <TouchableOpacity
                          style={{
                            backgroundColor:
                              job?.pendingOrNoShowFacilityDecideMessage !==
                                "" && !job?.pendingOrNoShowFacilityDecideStatus
                                ? "red"
                                : "#00b359",
                            paddingHorizontal: 10,
                            paddingVertical: 5,
                            borderRadius: 5
                          }}
                          onPress={() => setNoShowViewModel(true)}
                        >
                          <Text
                            style={{
                              textAlign: "right",
                              fontSize: 12,
                              color: "#fff"
                            }}
                          >
                            View Reason
                            {job?.pendingOrNoShowFacilityDecideMessage !== "" &&
                              !job?.pendingOrNoShowFacilityDecideStatus && (
                                <Text
                                  style={{
                                    textAlign: "right",
                                    fontSize: 12,
                                    marginRight: 10
                                  }}
                                >
                                  -Not Approved
                                </Text>
                              )}
                          </Text>
                        </TouchableOpacity>
                      </View>
                    )}
                  </View>
                  <Text
                    style={{
                      color: "#595959",
                      fontSize: 12,
                      marginTop: 5
                    }}
                  >
                    {job?.pendingOrNoShowFacilityDecideMessage}
                  </Text>
                </View>
              ) : null}

              {job?.pendingOrNoShowFacilityDecideMessage !==
              "" ? null : job?.checkOutMessage !== "" ? (
                <View
                  style={{
                    marginVertical: 5,
                    justifyContent: "center",
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    borderBottomWidth: 1.5,
                    borderBottomColor: "#f2f2f2"
                  }}
                >
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontWeight: "500",
                      fontSize: 15
                    }}
                  >
                    Nurse Clock Out Notes
                  </Text>
                  <Text
                    style={{
                      color: "#595959",
                      fontSize: 12,
                      marginTop: 5
                    }}
                  >
                    {job?.checkOutMessage}
                  </Text>
                </View>
              ) : null}

              {job?.enableBid && (
                <View
                  style={{
                    marginTop: 5,
                    justifyContent: "center",
                    paddingHorizontal: 20,
                    paddingVertical: 10
                  }}
                >
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontWeight: "500",
                      fontSize: 15
                    }}
                  >
                    Bid is Enabled
                  </Text>

                  {job?.jobBitFinalAmount !== "" &&
                  job?.jobBitFinalAmount !== "" ? (
                    <Text
                      style={{
                        color: "#595959",
                        fontSize: 12,
                        flex: 1
                      }}
                    >
                      Bid Final Amount = $ {job?.jobBitFinalAmount}
                    </Text>
                  ) : (
                    <Text
                      style={{ color: "#595959", fontSize: 12, marginTop: 5 }}
                    >
                      Waiting...
                    </Text>
                  )}
                </View>
              )}

              {job?.closed_manager && (
                <View
                  style={{
                    marginVertical: 5,
                    justifyContent: "center",
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    borderBottomWidth: 1.5,
                    borderBottomColor: "#f2f2f2"
                  }}
                >
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontWeight: "500",
                      fontSize: 15
                    }}
                  >
                    Closed By
                  </Text>
                  <Text
                    style={{ color: "#595959", fontSize: 12, marginTop: 5 }}
                  >
                    {job?.closed_manager?.name}{" "}
                  </Text>
                </View>
              )}

              {job?.manager_review_comments && (
                <View
                  style={{
                    marginVertical: 5,
                    justifyContent: "center",
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    borderBottomWidth: 1.5,
                    borderBottomColor: "#f2f2f2"
                  }}
                >
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontWeight: "500",
                      fontSize: 15
                    }}
                  >
                    Manager Approval Notes
                  </Text>

                  <Text
                    style={{
                      color: "#595959",
                      fontSize: 12,
                      marginTop: 10
                    }}
                  >
                    {job?.manager_review_comments}
                  </Text>
                </View>
              )}

              <View
                style={{
                  marginVertical: 5,
                  justifyContent: "center",
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  borderBottomWidth: 1.5,
                  borderBottomColor: "#f2f2f2"
                }}
              >
                <Text
                  style={{
                    color: "#1a1a1a",
                    fontWeight: "500",
                    fontSize: 15
                  }}
                >
                  Last Updated
                </Text>

                <Text
                  style={{
                    color: "#595959",
                    fontSize: 12,
                    marginTop: 10
                  }}
                >
                  {moment(job?._lastChangedAt).format("MM-DD-YYYY h:mm a")}
                </Text>
              </View>

              {job?.jobStatus === "Pending Approval" && (
                <View
                  style={{
                    marginVertical: 10,
                    marginBottom: 20,
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <AuthButton
                    name={loading ? "Loading..." : "Close Job"}
                    onPress={() => (loading ? null : setCompleteJob(true))}
                  />
                </View>
              )}
              {job?.jobStatus === "Missed" &&
                job?.noShowReason !== "" &&
                job?.pendingOrNoShowFacilityDecideMessage === "" && (
                  <View
                    style={{
                      marginVertical: 10,
                      marginBottom: 20,
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <AuthButton
                      name={"View Reason"}
                      onPress={() => setNoShowViewModelAction(true)}
                    />
                  </View>
                )}
              {job?.jobStatus === "Pending Clock Out" &&
                job?.neverCheckOutReason !== "" &&
                job?.pendingOrNoShowFacilityDecideMessage === "" && (
                  <View
                    style={{
                      marginVertical: 10,
                      marginBottom: 20,
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <AuthButton
                      name={"View Reason"}
                      onPress={() => setPendingNeverCheckOutModelAction(true)}
                    />
                  </View>
                )}
            </ScrollView>
          )}
        </View>
      )}

      <Modal
        animationType="fade"
        transparent={true}
        visible={noShowViewModelAction}
        onRequestClose={() => setNoShowViewModelAction(false)}
      >
        <View
          style={{
            height: height,
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              paddingVertical: 15,
              width: 300,
              justifyContent: "center",
              elevation: 5,
              paddingHorizontal: 10,
              borderRadius: 15,
              borderColor: "rgba(0, 0, 0, 0.1)",
              marginTop: 45
            }}
          >
            <View>
              <Text
                style={{
                  marginVertical: 10,
                  fontSize: 12,
                  color: "#737373",
                  marginHorizontal: 10
                }}
              >
                Missed {job?.jobType} Reason (Employee)
              </Text>
              <View
                style={{
                  backgroundColor: "#f2f2f2",
                  borderRadius: 5,
                  elevation: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderWidth: 0.5,
                  borderColor: "#e6e6e6",
                  marginHorizontal: 10,
                  paddingRight: 10,
                  justifyContent: "space-between"
                }}
              >
                <Text
                  style={{
                    marginVertical: 10,
                    fontSize: 12,
                    color: "#000",
                    marginHorizontal: 10
                  }}
                >
                  {job?.noShowReason}
                </Text>
              </View>
            </View>

            {job?.noShowComments && (
              <View>
                <Text
                  style={{
                    marginVertical: 10,
                    fontSize: 12,
                    color: "#737373",
                    marginHorizontal: 10
                  }}
                >
                  Notes (Employee)
                </Text>
                <View
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: 5,
                    elevation: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    marginHorizontal: 10,
                    paddingRight: 10,
                    justifyContent: "space-between"
                  }}
                >
                  <Text
                    style={{
                      marginVertical: 10,
                      fontSize: 12,
                      color: "#000",
                      marginHorizontal: 10
                    }}
                  >
                    {job?.noShowComments}
                  </Text>
                </View>
              </View>
            )}

            {/* {job?.timeAdjustByNurse ? (
              <View>
                <Text
                  style={{
                    marginTop: 10,
                    fontSize: 12,
                    color: "#737373",
                    marginHorizontal: 10,
                  }}
                >
                  Requested Time Adjustment
                </Text>
                <View
                  style={{
                    display: "flex",
                    // flexDirection: "row",
                    alignItems: "flex-start",
                    // justifyContent: "space-between",
                    marginTop: 5,
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontSize: 10,
                        color: "#00b359",
                        fontWeight: "500",
                        marginHorizontal: 10,
                        textDecorationLine: "underline",
                      }}
                    >
                      Clock In Time -{" "}
                      {job?.checkInTimeNurse !== "" &&
                        moment(new Date(job?.checkInTimeNurse)).format("hh:mm a")}
                    </Text>
                  </View>

                  <View>
                    <Text
                      style={{
                        fontSize: 10,
                        color: "#00b359",
                        fontWeight: "500",
                        marginHorizontal: 10,
                        textDecorationLine: "underline",
                      }}
                    >
                      Clock Out Time -{" "}
                      {job?.checkOutTimeNurse !== "" &&
                        moment(new Date(job?.checkOutTimeNurse)).format(
                          "hh:mm a"
                        )}
                    </Text>
                  </View>
                </View>
              </View>
            ) : (
              <View>
                <Text
                  style={{
                    marginTop: 10,
                    fontSize: 12,
                    color: "#737373",
                    marginHorizontal: 10,
                  }}
                >
                  Time Details
                </Text>
                <View
                  style={{
                    display: "flex",
                    // flexDirection: "row",
                    alignItems: "flex-start",
                    // justifyContent: "space-between",
                    marginTop: 5,
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontSize: 12,
                        color: "#00b359",
                        fontWeight: "600",
                        marginHorizontal: 10,
                        textDecorationLine: "underline",
                      }}
                    >
                      Clock In Time -{" "}
                      {moment(job?.checkInTimeNurse).format("hh:mm a")}
                    </Text>
                  </View>

                  <View>
                    <Text
                      style={{
                        fontSize: 12,
                        color: "#00b359",
                        fontWeight: "600",
                        marginHorizontal: 10,
                        textDecorationLine: "underline",
                      }}
                    >
                      Clock Out Time -{" "}
                      {moment(job?.checkOutTimeNurse).format("hh:mm a")}
                    </Text>
                  </View>
                </View>
              </View>
            )} */}

            {job?.noShowReasonAttachment && (
              <View>
                <Text
                  style={{
                    marginVertical: 10,
                    fontSize: 12,
                    color: "#737373",
                    marginHorizontal: 10
                  }}
                >
                  Attachment submitted (Employee)
                </Text>
                <TouchableOpacity
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: 5,
                    elevation: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    marginHorizontal: 10,
                    paddingRight: 10,
                    justifyContent: "space-between"
                  }}
                  onPress={() =>
                    downloadAndOpenPDF(job?.noShowReasonAttachment)
                  }
                >
                  <Text
                    style={{
                      marginVertical: 10,
                      fontSize: 12,
                      color: "#8888",
                      marginHorizontal: 10
                    }}
                  >
                    Click to view the attachment
                  </Text>
                </TouchableOpacity>
              </View>
            )}

            {job?.pendingOrNoShowFacilityDecideMessage !== "" &&
            !job?.pendingOrNoShowFacilityDecideStatus ? (
              <View>
                <Text
                  style={{
                    marginVertical: 10,
                    fontSize: 12,
                    color: "#737373",
                    marginHorizontal: 10
                  }}
                >
                  Reviewer Notes
                </Text>
                <View
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: 5,
                    elevation: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    marginHorizontal: 10,
                    paddingRight: 10,
                    justifyContent: "space-between"
                  }}
                >
                  <Text
                    style={{
                      marginVertical: 10,
                      fontSize: 12,
                      color: "#000",
                      marginHorizontal: 10
                    }}
                  >
                    {job?.pendingOrNoShowFacilityDecideMessage}
                  </Text>
                </View>
              </View>
            ) : (
              <View>
                <Text
                  style={{
                    marginVertical: 10,
                    fontSize: 12,
                    color: "#737373",
                    marginHorizontal: 10
                  }}
                >
                  Approved Reason <Text style={{ color: "red" }}>*</Text>
                </Text>

                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginHorizontal: 10
                  }}
                >
                  <CustomDropdownScreen
                    container={{ flex: 1 }}
                    options={missedReason}
                    placeholder={"Select Reason"}
                    selectedValue={noShowMessage}
                    onValueChange={(type) => {
                      setReason(type);
                      setNoShowMessage(type === "Other" ? "" : type);
                    }}
                  />
                </View>

                {reason === "Other" && (
                  <View>
                    <Text
                      style={{
                        marginVertical: 10,
                        fontSize: 12,
                        color: "#737373",
                        marginHorizontal: 10
                      }}
                    >
                      Reason <Text style={{ color: "red" }}>*</Text>
                    </Text>
                    <View
                      style={{
                        backgroundColor: "#f2f2f2",
                        borderRadius: 10,
                        elevation: 1,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        borderWidth: 0.5,
                        borderColor: "#e6e6e6",
                        marginHorizontal: 10,
                        paddingRight: 10,
                        marginBottom: 5,
                        justifyContent: "space-between"
                      }}
                    >
                      <TextInput
                        style={{
                          width: "90%",
                          padding: 2,
                          paddingLeft: 5,
                          fontSize: 12,
                          color: "#737373"
                        }}
                        multiline
                        onChangeText={(text) => setNoShowMessage(text)}
                        value={noShowMessage}
                        keyboardType={"default"}
                        autoCapitalize="sentences"
                        placeholderTextColor="#b3b3b3"
                        placeholder={`Missed ${job?.jobType} Reason`}
                      />
                      <View
                        style={{
                          height: 40,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center"
                        }}
                      >
                        {noShowMessage ? (
                          <TouchableOpacity
                            onPress={() => {
                              setNoShowMessage("");
                              setReason("");
                            }}
                            style={{ marginLeft: 3 }}
                          >
                            <Ionicons
                              name="close-sharp"
                              size={22}
                              color="#808080"
                            />
                          </TouchableOpacity>
                        ) : null}
                      </View>
                    </View>
                  </View>
                )}

                <Text
                  style={{
                    marginBottom: 10,
                    fontSize: 12,
                    color: "#737373",
                    marginHorizontal: 10
                  }}
                >
                  Reviewer Notes <Text style={{ color: "red" }}>*</Text>
                </Text>

                <View
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: 10,
                    elevation: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    marginHorizontal: 10,
                    paddingRight: 10,
                    justifyContent: "space-between"
                  }}
                >
                  <TextInput
                    style={{
                      width: "90%",
                      padding: 2,
                      paddingLeft: 5,
                      fontSize: 12,
                      color: "#737373"
                    }}
                    multiline
                    onChangeText={(text) =>
                      setPendingOrNoShowFacilityDecideMessage(text)
                    }
                    value={pendingOrNoShowFacilityDecideMessage}
                    keyboardType={"default"}
                    autoCapitalize="sentences"
                    placeholderTextColor="#b3b3b3"
                    placeholder={"Message"}
                  />
                  <View
                    style={{
                      height: 40,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center"
                    }}
                  >
                    {pendingOrNoShowFacilityDecideMessage ? (
                      <TouchableOpacity
                        onPress={() =>
                          setPendingOrNoShowFacilityDecideMessage("")
                        }
                        style={{ marginLeft: 3 }}
                      >
                        <Ionicons
                          name="close-sharp"
                          size={22}
                          color="#808080"
                        />
                      </TouchableOpacity>
                    ) : null}
                  </View>
                </View>

                <View>
                  <Text
                    style={{
                      marginVertical: 10,
                      fontSize: 12,
                      color: "#737373",
                      marginHorizontal: 10
                    }}
                  >
                    Attachment <Text style={{ color: "red" }}>(optional)</Text>
                  </Text>
                  <TouchableOpacity
                    style={{
                      backgroundColor: "#f2f2f2",
                      borderRadius: 5,
                      elevation: 1,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      borderWidth: 0.5,
                      borderColor: "#e6e6e6",
                      marginHorizontal: 10,
                      paddingRight: 10,
                      justifyContent: "space-between"
                    }}
                    onPress={() => {
                      Platform.OS === "web"
                        ? pickDocumentWeb()
                        : pickDocument();
                    }}
                  >
                    <Text
                      style={{
                        marginVertical: 10,
                        fontSize: 12,
                        color: "#8888",
                        marginHorizontal: 10
                      }}
                    >
                      {noShowAttachment
                        ? "File is attached"
                        : "Click to upload your attachment"}
                    </Text>
                    <View
                      style={{
                        height: 40,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center"
                      }}
                    >
                      {noShowAttachment ? (
                        <TouchableOpacity
                          onPress={() => {
                            setNoShowAttachment("");
                            setNoShowAttachmentURI("");
                          }}
                          style={{ marginLeft: 3 }}
                        >
                          <Ionicons
                            name="close-sharp"
                            size={22}
                            color="#808080"
                          />
                        </TouchableOpacity>
                      ) : null}
                    </View>
                  </TouchableOpacity>
                </View>

                <View>
                  <Text
                    style={{
                      marginTop: 10,
                      color: "#737373",
                      marginHorizontal: 10
                    }}
                  >
                    Time Details & Adjustments
                  </Text>
                  <View
                    style={{
                      display: "flex",
                      // flexDirection: "row",
                      alignItems: "flex-start",
                      // justifyContent: "space-between",
                      marginTop: 5
                    }}
                  >
                    <View>
                      <Text
                        style={{
                          fontSize: 12,
                          color: "#00b359",
                          fontWeight: "600",
                          marginHorizontal: 10,
                          textDecorationLine: "underline"
                        }}
                      >
                        Clock In Time -{" "}
                        {moment(job?.checkInTimeNurse).format("hh:mm a")}
                      </Text>
                    </View>

                    <View>
                      <Text
                        style={{
                          fontSize: 12,
                          color: "#00b359",
                          fontWeight: "600",
                          marginHorizontal: 10,
                          textDecorationLine: "underline"
                        }}
                      >
                        Clock Out Time -{" "}
                        {moment(job?.checkOutTimeNurse).format("hh:mm a")}
                      </Text>
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    display: "flex",
                    marginTop: 10
                  }}
                >
                  <View>
                    {moment(new Date(job?.startTime)).format("hh:mm a") ===
                    moment(new Date(jobClockDetails?.checkInTimeNurse)).format(
                      "hh:mm a"
                    ) ? (
                      <Text
                        style={{
                          fontSize: 12,
                          color: "#000",
                          fontWeight: "600",
                          marginHorizontal: 10,
                          textDecorationLine: "underline"
                        }}
                      >
                        Start Time -{" "}
                        {moment(new Date(job?.checkInTimeNurse)).format(
                          "hh:mm a"
                        )}
                      </Text>
                    ) : (
                      <Text
                        style={{
                          fontSize: 12,
                          color: "#2775BD",
                          fontWeight: "600",
                          marginHorizontal: 10,
                          textDecorationLine: "underline"
                        }}
                      >
                        Adjusted Time -{" "}
                        {moment(
                          new Date(jobClockDetails?.checkInTimeNurse)
                        ).format("hh:mm a")}
                      </Text>
                    )}
                    <TouchableOpacity
                      style={{
                        backgroundColor: "#2775BD",
                        borderRadius: 5,
                        elevation: 1,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        borderWidth: 0.5,
                        borderColor: "#2775BD",
                        justifyContent: "center",
                        paddingVertical: 2,
                        marginTop: 5
                      }}
                      onPress={() => {
                        setNoShowViewModelAction(false);
                        setShowTimeWeb(true);
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          color: "#fff",
                          marginHorizontal: 10
                        }}
                      >
                        Choose Time
                      </Text>
                    </TouchableOpacity>
                  </View>

                  <View
                    style={{
                      marginTop: 5
                    }}
                  >
                    {moment(new Date(job?.endTime)).format("hh:mm a") ===
                    moment(new Date(jobClockDetails?.checkOutTimeNurse)).format(
                      "hh:mm a"
                    ) ? (
                      <Text
                        style={{
                          fontSize: 12,
                          color: "#000",
                          fontWeight: "600",
                          marginHorizontal: 10,
                          textDecorationLine: "underline"
                        }}
                      >
                        End Time -{" "}
                        {moment(new Date(job?.checkOutTimeNurse)).format(
                          "hh:mm a"
                        )}
                      </Text>
                    ) : (
                      <Text
                        style={{
                          fontSize: 12,
                          color: "#2775BD",
                          fontWeight: "600",
                          marginHorizontal: 10,
                          textDecorationLine: "underline"
                        }}
                      >
                        Adjusted Time -{" "}
                        {moment(
                          new Date(jobClockDetails?.checkOutTimeNurse)
                        ).format("hh:mm a")}
                      </Text>
                    )}

                    <TouchableOpacity
                      style={{
                        backgroundColor: "#2775BD",
                        borderRadius: 5,
                        elevation: 1,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        borderWidth: 0.5,
                        borderColor: "#2775BD",
                        justifyContent: "center",
                        paddingVertical: 2,
                        marginTop: 5
                      }}
                      onPress={() => {
                        setNoShowViewModelAction(false);
                        setShowTimeEndWeb(true);
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          color: "#fff",
                          marginHorizontal: 10
                        }}
                      >
                        Choose Time
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            )}

            <View
              style={{
                justifyContent: "flex-end",
                alignItems: "flex-end",
                flexDirection: "row",
                marginTop: 20
              }}
            >
              {job?.pendingOrNoShowFacilityDecideMessage === "" && (
                <TouchableOpacity
                  onPress={() => {
                    loading
                      ? null
                      : Platform.OS === "web"
                      ? confirm("Are you sure want to Approve?").valueOf(
                          true
                        ) && pendingOrNoShowFacilityDecide(job)
                      : Alert.alert("Are you sure want to Approve?", "", [
                          {
                            text: "Yes",
                            onPress: () => pendingOrNoShowFacilityDecide(job)
                          },
                          {
                            text: "No",
                            style: "cancel"
                          }
                        ]);
                  }}
                  style={{
                    backgroundColor: "#00b359",
                    paddingHorizontal: 10,
                    paddingVertical: 5,
                    borderRadius: 8,
                    marginRight: 10
                  }}
                >
                  <Text
                    style={{
                      textAlign: "right",
                      fontSize: 12,
                      color: "#fff"
                    }}
                  >
                    {loading ? "Loading..." : "Approve"}
                  </Text>
                </TouchableOpacity>
              )}
              {job?.pendingOrNoShowFacilityDecideMessage === "" && (
                <TouchableOpacity
                  onPress={() => {
                    loading
                      ? null
                      : Platform.OS === "web"
                      ? confirm("Proceed with Not-Approved?").valueOf(true) &&
                        pendingOrNoShowFacilityDecideCancel(job)
                      : Alert.alert("Proceed with Not-Approved?", "", [
                          {
                            text: "Yes",
                            onPress: () =>
                              pendingOrNoShowFacilityDecideCancel(job)
                          },
                          {
                            text: "No",
                            style: "cancel"
                          }
                        ]);
                  }}
                  style={{
                    backgroundColor: "red",
                    paddingHorizontal: 10,
                    paddingVertical: 5,
                    borderRadius: 8,
                    marginRight: 10
                  }}
                >
                  <Text
                    style={{
                      textAlign: "right",
                      fontSize: 12,
                      color: "#fff"
                    }}
                  >
                    {loading ? "Loading..." : "Not Approved"}
                  </Text>
                </TouchableOpacity>
              )}
              <TouchableOpacity
                onPress={() => {
                  setNoShowViewModelAction(false);
                  setPendingOrNoShowFacilityDecideMessage("");
                  setNoShowMessage("");
                  setReason("");
                  setNoShowAttachment("");
                  setNoShowAttachmentURI("");
                  setJobClockDetails({
                    checkInTimeNurse:
                      job?.checkInTime !== ""
                        ? job?.checkInTimeNurse
                        : job?.startTime,
                    checkOutTimeNurse:
                      job?.checkOutTime !== ""
                        ? job?.checkOutTimeNurse
                        : job?.endTime,
                    checkInFullAddressNurse: "",
                    checkInLatitudeNurse: "",
                    checkInLongitudeNurse: "",
                    checkOutFullAddressNurse: "",
                    checkOutLatitudeNurse: "",
                    checkOutLongitudeNurse: ""
                  });
                }}
                style={{
                  backgroundColor: "gray",
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 8,
                  marginRight: 10
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff"
                  }}
                >
                  Close
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>

      <Modal
        animationType="fade"
        transparent={true}
        visible={pendingNeverCheckOutModelAction}
        onRequestClose={() => setPendingNeverCheckOutModelAction(false)}
      >
        <View
          style={{
            height: height,
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              paddingVertical: 15,
              width: 300,
              justifyContent: "center",
              elevation: 5,
              paddingHorizontal: 10,
              borderRadius: 15,
              borderColor: "rgba(0, 0, 0, 0.1)",
              marginTop: 45
            }}
          >
            <View>
              <Text
                style={{
                  marginVertical: 10,
                  fontSize: 12,
                  color: "#737373",
                  marginHorizontal: 10
                }}
              >
                Pending Clock Out Reason (Employee)
              </Text>
              <View
                style={{
                  backgroundColor: "#f2f2f2",
                  borderRadius: 5,
                  elevation: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderWidth: 0.5,
                  borderColor: "#e6e6e6",
                  marginHorizontal: 10,
                  paddingRight: 10,
                  justifyContent: "space-between"
                }}
              >
                <Text
                  style={{
                    marginVertical: 10,
                    fontSize: 12,
                    color: "#000",
                    marginHorizontal: 10
                  }}
                >
                  {job?.neverCheckOutReason}
                </Text>
              </View>
            </View>

            {job?.neverCheckOutReasonAttachment && (
              <View>
                <Text
                  style={{
                    marginVertical: 10,
                    fontSize: 12,
                    color: "#737373",
                    marginHorizontal: 10
                  }}
                >
                  Attachment (Employee)
                </Text>
                <TouchableOpacity
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: 5,
                    elevation: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    marginHorizontal: 10,
                    paddingRight: 10,
                    justifyContent: "space-between"
                  }}
                  onPress={() =>
                    downloadAndOpenPDF(job?.neverCheckOutReasonAttachment)
                  }
                >
                  <Text
                    style={{
                      marginVertical: 10,
                      fontSize: 12,
                      color: "#8888",
                      marginHorizontal: 10
                    }}
                  >
                    Click to view the attachment
                  </Text>
                </TouchableOpacity>
              </View>
            )}

            {job?.pendingOrNoShowFacilityDecideMessage !== "" &&
            !job?.pendingOrNoShowFacilityDecideStatus ? (
              <View>
                <Text
                  style={{
                    marginVertical: 10,
                    fontSize: 12,
                    color: "#737373",
                    marginHorizontal: 10
                  }}
                >
                  Reviewer Notes
                </Text>
                <View
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: 5,
                    elevation: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    marginHorizontal: 10,
                    paddingRight: 10,
                    justifyContent: "space-between"
                  }}
                >
                  <Text
                    style={{
                      marginVertical: 10,
                      fontSize: 12,
                      color: "#000",
                      marginHorizontal: 10
                    }}
                  >
                    {job?.pendingOrNoShowFacilityDecideMessage}
                  </Text>
                </View>
              </View>
            ) : (
              <View>
                <Text
                  style={{
                    marginVertical: 10,
                    fontSize: 12,
                    color: "#737373",
                    marginHorizontal: 10
                  }}
                >
                  Approved Reason <Text style={{ color: "red" }}>*</Text>
                </Text>

                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginHorizontal: 10
                  }}
                >
                  <CustomDropdownScreen
                    container={{ flex: 1 }}
                    options={missedReason}
                    placeholder={"Select Reason"}
                    selectedValue={noShowMessage}
                    onValueChange={(type) => {
                      setReason(type);
                      setNeverCheckOutMessage(type === "Other" ? "" : type);
                    }}
                  />
                </View>

                {reason === "Other" && (
                  <View>
                    <Text
                      style={{
                        marginVertical: 10,
                        fontSize: 12,
                        color: "#737373",
                        marginHorizontal: 10
                      }}
                    >
                      Reason <Text style={{ color: "red" }}>*</Text>
                    </Text>
                    <View
                      style={{
                        backgroundColor: "#f2f2f2",
                        borderRadius: 10,
                        elevation: 1,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        borderWidth: 0.5,
                        borderColor: "#e6e6e6",
                        marginHorizontal: 10,
                        paddingRight: 10,
                        marginBottom: 5,
                        justifyContent: "space-between"
                      }}
                    >
                      <TextInput
                        style={{
                          width: "90%",
                          padding: 2,
                          paddingLeft: 5,
                          fontSize: 12,
                          color: "#737373"
                        }}
                        multiline
                        onChangeText={(text) => setNeverCheckOutMessage(text)}
                        value={neverCheckOutMessage}
                        keyboardType={"default"}
                        autoCapitalize="sentences"
                        placeholderTextColor="#b3b3b3"
                        placeholder={`Pending Clock Out ${job?.jobType} Reason`}
                      />
                      <View
                        style={{
                          height: 40,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center"
                        }}
                      >
                        {neverCheckOutMessage ? (
                          <TouchableOpacity
                            onPress={() => {
                              setNeverCheckOutMessage("");
                              setReason("");
                            }}
                            style={{ marginLeft: 3 }}
                          >
                            <Ionicons
                              name="close-sharp"
                              size={22}
                              color="#808080"
                            />
                          </TouchableOpacity>
                        ) : null}
                      </View>
                    </View>
                  </View>
                )}
                <Text
                  style={{
                    marginVertical: 10,
                    fontSize: 12,
                    color: "#737373",
                    marginHorizontal: 10
                  }}
                >
                  Reviewer Notes <Text style={{ color: "red" }}>*</Text>
                </Text>

                <View
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: 10,
                    elevation: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    marginHorizontal: 10,
                    paddingRight: 10,
                    justifyContent: "space-between"
                  }}
                >
                  <TextInput
                    style={{
                      width: "90%",
                      padding: 2,
                      paddingLeft: 5,
                      fontSize: 12,
                      color: "#737373"
                    }}
                    multiline
                    onChangeText={(text) =>
                      setPendingOrNoShowFacilityDecideMessage(text)
                    }
                    value={pendingOrNoShowFacilityDecideMessage}
                    keyboardType={"default"}
                    autoCapitalize="sentences"
                    placeholderTextColor="#b3b3b3"
                    placeholder={"Message"}
                  />
                  <View
                    style={{
                      height: 40,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center"
                    }}
                  >
                    {pendingOrNoShowFacilityDecideMessage ? (
                      <TouchableOpacity
                        onPress={() =>
                          setPendingOrNoShowFacilityDecideMessage("")
                        }
                        style={{ marginLeft: 3 }}
                      >
                        <Ionicons
                          name="close-sharp"
                          size={22}
                          color="#808080"
                        />
                      </TouchableOpacity>
                    ) : null}
                  </View>
                </View>
              </View>
            )}

            <View>
              <Text
                style={{
                  marginVertical: 10,
                  fontSize: 12,
                  color: "#737373",
                  marginHorizontal: 10
                }}
              >
                Attachment <Text style={{ color: "red" }}>(optional)</Text>
              </Text>
              <TouchableOpacity
                style={{
                  backgroundColor: "#f2f2f2",
                  borderRadius: 5,
                  elevation: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderWidth: 0.5,
                  borderColor: "#e6e6e6",
                  marginHorizontal: 10,
                  paddingRight: 10,
                  justifyContent: "space-between"
                }}
                onPress={() => {
                  Platform.OS === "web" ? pickDocumentWeb() : pickDocument();
                }}
              >
                <Text
                  style={{
                    marginVertical: 10,
                    fontSize: 12,
                    color: "#8888",
                    marginHorizontal: 10
                  }}
                >
                  {noShowAttachment
                    ? "File is attached"
                    : "Click to upload your attachment"}
                </Text>
                <View
                  style={{
                    height: 40,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                  }}
                >
                  {noShowAttachment ? (
                    <TouchableOpacity
                      onPress={() => {
                        setNoShowAttachment("");
                        setNoShowAttachmentURI("");
                      }}
                      style={{ marginLeft: 3 }}
                    >
                      <Ionicons name="close-sharp" size={22} color="#808080" />
                    </TouchableOpacity>
                  ) : null}
                </View>
              </TouchableOpacity>
            </View>

            {job?.timeAdjustByNurse ? (
              <View>
                <Text
                  style={{
                    marginTop: 10,
                    fontSize: 12,
                    color: "#737373",
                    marginHorizontal: 10
                  }}
                >
                  Requested Time Adjustment
                </Text>
                <View
                  style={{
                    display: "flex",
                    // flexDirection: "row",
                    alignItems: "flex-start",
                    // justifyContent: "space-between",
                    marginTop: 5
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontSize: 10,
                        color: "#00b359",
                        fontWeight: "500",
                        marginHorizontal: 10,
                        textDecorationLine: "underline"
                      }}
                    >
                      Clock In Time -{" "}
                      {job?.checkInTime !== "" &&
                        moment(new Date(job?.checkInTime)).format("hh:mm a")}
                    </Text>
                  </View>

                  <View>
                    <Text
                      style={{
                        fontSize: 10,
                        color: "#00b359",
                        fontWeight: "500",
                        marginHorizontal: 10,
                        textDecorationLine: "underline"
                      }}
                    >
                      Clock Out Time -{" "}
                      {job?.checkOutTimeNurse !== "" &&
                        moment(new Date(job?.checkOutTimeNurse)).format(
                          "hh:mm a"
                        )}
                    </Text>
                  </View>
                </View>
              </View>
            ) : (
              <View>
                <Text
                  style={{
                    marginTop: 10,
                    fontSize: 12,
                    color: "#737373",
                    marginHorizontal: 10
                  }}
                >
                  Time Details
                </Text>
                <View
                  style={{
                    display: "flex",
                    // flexDirection: "row",
                    alignItems: "flex-start",
                    // justifyContent: "space-between",
                    marginTop: 5
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontSize: 12,
                        color: "#00b359",
                        fontWeight: "600",
                        marginHorizontal: 10,
                        textDecorationLine: "underline"
                      }}
                    >
                      Clock In Time -{" "}
                      {moment(new Date(job?.checkInTime)).format("hh:mm a")}
                    </Text>
                  </View>

                  <View>
                    <Text
                      style={{
                        fontSize: 12,
                        color: "#00b359",
                        fontWeight: "600",
                        marginHorizontal: 10,
                        textDecorationLine: "underline"
                      }}
                    >
                      Clock Out Time -{" "}
                      {moment(new Date(job?.checkOutTimeNurse)).format(
                        "hh:mm a"
                      )}
                    </Text>
                  </View>
                </View>
              </View>
            )}

            <View
              style={{
                justifyContent: "flex-end",
                alignItems: "flex-end",
                flexDirection: "row",
                marginTop: 20
              }}
            >
              {job?.pendingOrNoShowFacilityDecideMessage === "" && (
                <TouchableOpacity
                  onPress={() => {
                    loading
                      ? null
                      : Platform.OS === "web"
                      ? confirm("Are you sure want to Approve?").valueOf(
                          true
                        ) && pendingJobOrNoShowFacilityDecide(job)
                      : Alert.alert("Are you sure want to Approve?", "", [
                          {
                            text: "Yes",
                            onPress: () => pendingJobOrNoShowFacilityDecide(job)
                          },
                          {
                            text: "No",
                            style: "cancel"
                          }
                        ]);
                  }}
                  style={{
                    backgroundColor: "#00b359",
                    paddingHorizontal: 10,
                    paddingVertical: 5,
                    borderRadius: 8,
                    marginRight: 10
                  }}
                >
                  <Text
                    style={{
                      textAlign: "right",
                      fontSize: 12,
                      color: "#fff"
                    }}
                  >
                    {loading ? "Loading..." : "Approve"}
                  </Text>
                </TouchableOpacity>
              )}
              {job?.pendingOrNoShowFacilityDecideMessage === "" && (
                <TouchableOpacity
                  onPress={() => {
                    loading
                      ? null
                      : Platform.OS === "web"
                      ? confirm("Proceed with Not-Approved?").valueOf(true) &&
                        pendingJobOrNoShowFacilityDecideCancel(job)
                      : Alert.alert("Proceed with Not-Approved?", "", [
                          {
                            text: "Yes",
                            onPress: () =>
                              pendingJobOrNoShowFacilityDecideCancel(job)
                          },
                          {
                            text: "No",
                            style: "cancel"
                          }
                        ]);
                  }}
                  style={{
                    backgroundColor: "red",
                    paddingHorizontal: 10,
                    paddingVertical: 5,
                    borderRadius: 8,
                    marginRight: 10
                  }}
                >
                  <Text
                    style={{
                      textAlign: "right",
                      fontSize: 12,
                      color: "#fff"
                    }}
                  >
                    {loading ? "Loading..." : "Not Approved"}
                  </Text>
                </TouchableOpacity>
              )}
              <TouchableOpacity
                onPress={() => {
                  setPendingNeverCheckOutModelAction(false);
                  setPendingOrNoShowFacilityDecideMessage("");
                }}
                style={{
                  backgroundColor: "gray",
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 8,
                  marginRight: 10
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff"
                  }}
                >
                  Close
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>

      {/* {job?.jobFinalSelectionNurseId === null ||
      job?.jobFinalSelectionNurseId === "" ? null : (
        <TouchableOpacity
          style={{
            backgroundColor: "#00b359",
            width: 65,
            height: 65,
            borderRadius: 100,
            position: "absolute",
            bottom: 100,
            right: 35,
            justifyContent: "center",
            alignItems: "center",
          }}
          activeOpacity={0.8}
          onPress={() =>
            onAksQuestionChatRoomScreenNavigate({
              chatRoomId: "",
              nurseId: job?.jobFinalSelectionNurseId,
              facilityId: userId,
              job: job,
            })
          }
        >
          <Entypo name="message" size={35} color="white" />
        </TouchableOpacity>
      )} */}

      <Modal
        animationType="fade"
        transparent={true}
        visible={noShowViewModel}
        onRequestClose={() => setNoShowViewModel(false)}
      >
        <View
          style={{
            height: height,
            // justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              paddingVertical: 15,
              // height: 150,
              width: 300,
              justifyContent: "center",
              elevation: 5,
              paddingHorizontal: 10,
              borderRadius: 15,
              borderColor: "rgba(0, 0, 0, 0.1)",
              marginTop: 45
            }}
          >
            <View>
              <Text
                style={{
                  marginVertical: 10,
                  fontSize: 12,
                  color: "#737373",
                  marginHorizontal: 10,
                  textDecorationLine: "underline"
                }}
              >
                Missed {job?.jobType} Reason (Employee)
              </Text>
              <View
                style={{
                  backgroundColor: "#f2f2f2",
                  borderRadius: 5,
                  elevation: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderWidth: 0.5,
                  borderColor: "#e6e6e6",
                  marginHorizontal: 10,
                  paddingRight: 10,
                  justifyContent: "space-between"
                }}
              >
                <Text
                  style={{
                    marginVertical: 10,
                    fontSize: 12,
                    color: "#000",
                    marginHorizontal: 10
                  }}
                >
                  {job?.noShowReason}
                </Text>
              </View>
            </View>

            {job?.pendingOrNoShowFacilityDecideStatus ? (
              job?.timeAdjustByFacility ? (
                <View>
                  <Text
                    style={{
                      marginTop: 10,
                      fontSize: 12,
                      color: "#737373",
                      marginHorizontal: 10,
                      textDecorationLine: "underline"
                    }}
                  >
                    Approved Time
                  </Text>
                  <View
                    style={{
                      display: "flex",
                      // flexDirection: "row",
                      // alignItems: "flex-start",
                      // justifyContent: "space-between",
                      marginTop: 5
                    }}
                  >
                    <View>
                      <Text
                        style={{
                          fontSize: 12,
                          color: "#000",
                          fontWeight: "600",
                          marginHorizontal: 10,
                          textDecorationLine: "underline"
                        }}
                      >
                        Clock In Time -{" "}
                        {job?.checkInTime !== "" &&
                          moment(new Date(job?.checkInTime)).format("h:mma")}
                      </Text>
                    </View>

                    <View>
                      <Text
                        style={{
                          fontSize: 12,
                          color: "#000",
                          fontWeight: "600",
                          marginHorizontal: 10,
                          textDecorationLine: "underline"
                        }}
                      >
                        Clock Out Time -{" "}
                        {job?.checkOutTime !== "" &&
                          moment(new Date(job?.checkOutTime)).format("h:mma")}
                      </Text>
                    </View>
                  </View>
                </View>
              ) : (
                <View>
                  <Text
                    style={{
                      marginTop: 10,
                      fontSize: 12,
                      color: "#737373",
                      marginHorizontal: 10,
                      textDecorationLine: "underline"
                    }}
                  >
                    Approved Time
                  </Text>
                  <View
                    style={{
                      display: "flex",
                      // flexDirection: "row",
                      // alignItems: "flex-start",
                      // justifyContent: "space-between",
                      marginTop: 5
                    }}
                  >
                    <View>
                      <Text
                        style={{
                          fontSize: 12,
                          color: "#000",
                          fontWeight: "600",
                          marginHorizontal: 10,
                          textDecorationLine: "underline"
                        }}
                      >
                        Clock In Time -{" "}
                        {moment(new Date(job?.checkInTime)).format("h:mma")}
                      </Text>
                    </View>

                    <View>
                      <Text
                        style={{
                          fontSize: 12,
                          color: "#000",
                          fontWeight: "600",
                          marginHorizontal: 10,
                          textDecorationLine: "underline"
                        }}
                      >
                        Clock Out Time -{" "}
                        {moment(new Date(job?.checkOutTime)).format("h:mma")}
                      </Text>
                    </View>
                  </View>
                </View>
              )
            ) : (
              <View>
                <Text
                  style={{
                    marginTop: 10,
                    fontSize: 12,
                    color: "#737373",
                    marginHorizontal: 10,
                    textDecorationLine: "underline"
                  }}
                >
                  Requested Time
                </Text>
                <View
                  style={{
                    display: "flex",
                    // flexDirection: "row",
                    // alignItems: "flex-start",
                    // justifyContent: "space-between",
                    marginTop: 5
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontSize: 12,
                        color: "#000",
                        fontWeight: "600",
                        marginHorizontal: 10,
                        textDecorationLine: "underline"
                      }}
                    >
                      Clock In Time -{" "}
                      {job?.checkInTimeNurse !== "" &&
                        moment(new Date(job?.checkInTimeNurse)).format("h:mma")}
                    </Text>
                  </View>

                  <View>
                    <Text
                      style={{
                        fontSize: 12,
                        color: "#000",
                        fontWeight: "600",
                        marginHorizontal: 10,
                        textDecorationLine: "underline"
                      }}
                    >
                      Clock Out Time -{" "}
                      {job?.checkOutTimeNurse !== "" &&
                        moment(new Date(job?.checkOutTimeNurse)).format(
                          "h:mma"
                        )}
                    </Text>
                  </View>
                </View>
              </View>
            )}

            {job?.noShowReasonAttachment && (
              <View>
                <Text
                  style={{
                    marginVertical: 10,
                    fontSize: 12,
                    color: "#737373",
                    marginHorizontal: 10,
                    textDecorationLine: "underline"
                  }}
                >
                  Attachment submitted (Employee)
                </Text>
                <TouchableOpacity
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: 5,
                    elevation: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    marginHorizontal: 10,
                    paddingRight: 10,
                    justifyContent: "space-between"
                  }}
                  onPress={() =>
                    downloadAndOpenPDF(job?.noShowReasonAttachment)
                  }
                >
                  <Text
                    style={{
                      marginVertical: 10,
                      fontSize: 12,
                      color: "#8888",
                      marginHorizontal: 10
                    }}
                  >
                    Click to view the attachment
                  </Text>
                </TouchableOpacity>
              </View>
            )}

            {job?.noShowComments && (
              <View>
                <Text
                  style={{
                    marginVertical: 10,
                    fontSize: 12,
                    color: "#737373",
                    marginHorizontal: 10,
                    textDecorationLine: "underline"
                  }}
                >
                  Notes (Employee)
                </Text>
                <View
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: 5,
                    elevation: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    marginHorizontal: 10,
                    paddingRight: 10,
                    justifyContent: "space-between"
                  }}
                >
                  <Text
                    style={{
                      marginVertical: 10,
                      fontSize: 12,
                      color: "#000",
                      marginHorizontal: 10
                    }}
                  >
                    {job?.noShowComments}
                  </Text>
                </View>
              </View>
            )}

            {job?.noShowManagerReason && (
              <View>
                <Text
                  style={{
                    marginVertical: 10,
                    fontSize: 12,
                    color: "#737373",
                    marginHorizontal: 10,
                    textDecorationLine: "underline"
                  }}
                >
                  Approved {job?.jobType} Reason (Reviewer)
                </Text>
                <View
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: 5,
                    elevation: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    marginHorizontal: 10,
                    paddingRight: 10,
                    justifyContent: "space-between"
                  }}
                >
                  <Text
                    style={{
                      marginVertical: 10,
                      fontSize: 12,
                      color: "#000",
                      marginHorizontal: 10
                    }}
                  >
                    {job?.noShowManagerReason}
                  </Text>
                </View>
              </View>
            )}

            {job?.noShowManagerReasonAttachment && (
              <View>
                <Text
                  style={{
                    marginVertical: 10,
                    fontSize: 12,
                    color: "#737373",
                    marginHorizontal: 10,
                    textDecorationLine: "underline"
                  }}
                >
                  Attachment submitted (Reviewer)
                </Text>
                <TouchableOpacity
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: 5,
                    elevation: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    marginHorizontal: 10,
                    paddingRight: 10,
                    justifyContent: "space-between"
                  }}
                  onPress={() =>
                    downloadAndOpenPDF(job?.noShowManagerReasonAttachment)
                  }
                >
                  <Text
                    style={{
                      marginVertical: 10,
                      fontSize: 12,
                      color: "#8888",
                      marginHorizontal: 10
                    }}
                  >
                    Click to view the attachment
                  </Text>
                </TouchableOpacity>
              </View>
            )}

            <View
              style={{
                justifyContent: "flex-end",
                alignItems: "flex-end",
                flexDirection: "row",
                marginTop: 20
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setNoShowViewModel(false);
                }}
                style={{
                  backgroundColor: "gray",
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 8,
                  marginRight: 10
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff"
                  }}
                >
                  Close
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>

      <Modal
        animationType="fade"
        transparent={true}
        visible={pendingNeverCheckOutModel}
        onRequestClose={() => setPendingNeverCheckOutModel(false)}
      >
        <View
          style={{
            height: height,
            // justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              paddingVertical: 15,
              // height: 150,
              width: 300,
              justifyContent: "center",
              elevation: 5,
              paddingHorizontal: 10,
              borderRadius: 15,
              borderColor: "rgba(0, 0, 0, 0.1)",
              marginTop: 45
            }}
          >
            <View>
              <Text
                style={{
                  marginVertical: 10,
                  fontSize: 12,
                  color: "#737373",
                  marginHorizontal: 10
                }}
              >
                Pending Clock Out {job?.jobType} Reason (Employee)
              </Text>
              <View
                style={{
                  backgroundColor: "#f2f2f2",
                  borderRadius: 5,
                  elevation: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderWidth: 0.5,
                  borderColor: "#e6e6e6",
                  marginHorizontal: 10,
                  paddingRight: 10,
                  justifyContent: "space-between"
                }}
              >
                <Text
                  style={{
                    marginVertical: 10,
                    fontSize: 12,
                    color: "#000",
                    marginHorizontal: 10
                  }}
                >
                  {job?.neverCheckOutReason}
                </Text>
              </View>
            </View>

            {job?.pendingOrNoShowFacilityDecideStatus ? (
              <View>
                <Text
                  style={{
                    marginTop: 10,
                    fontSize: 12,
                    color: "#737373",
                    marginHorizontal: 10
                  }}
                >
                  Time Details
                </Text>
                <View
                  style={{
                    display: "flex",
                    // flexDirection: "row",
                    alignItems: "flex-start",
                    // justifyContent: "space-between",
                    marginTop: 5
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontSize: 12,
                        color: "#00b359",
                        fontWeight: "600",
                        marginHorizontal: 10,
                        textDecorationLine: "underline"
                      }}
                    >
                      Clock In Time -{" "}
                      {moment(job?.checkInTime).format("hh:mm a")}
                    </Text>
                  </View>

                  <View>
                    <Text
                      style={{
                        fontSize: 12,
                        color: "#00b359",
                        fontWeight: "600",
                        marginHorizontal: 10,
                        textDecorationLine: "underline"
                      }}
                    >
                      Clock Out Time -{" "}
                      {moment(job?.checkOutTime).format("hh:mm a")}
                    </Text>
                  </View>
                </View>
              </View>
            ) : job?.timeAdjustByNurse ? (
              <View>
                <Text
                  style={{
                    marginTop: 10,
                    fontSize: 12,
                    color: "#737373",
                    marginHorizontal: 10
                  }}
                >
                  Requested Time Adjustment
                </Text>
                <View
                  style={{
                    display: "flex",
                    // flexDirection: "row",
                    alignItems: "flex-start",
                    // justifyContent: "space-between",
                    marginTop: 5
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontSize: 10,
                        color: "#00b359",
                        fontWeight: "500",
                        marginHorizontal: 10,
                        textDecorationLine: "underline"
                      }}
                    >
                      Clock In Time -{" "}
                      {job?.checkInTime !== "" &&
                        moment(job?.checkInTime).format("hh:mm a")}
                    </Text>
                  </View>

                  <View>
                    <Text
                      style={{
                        fontSize: 10,
                        color: "#00b359",
                        fontWeight: "500",
                        marginHorizontal: 10,
                        textDecorationLine: "underline"
                      }}
                    >
                      Clock Out Time -{" "}
                      {job?.checkOutTimeNurse !== "" &&
                        moment(job?.checkOutTimeNurse).format("hh:mm a")}
                    </Text>
                  </View>
                </View>
              </View>
            ) : (
              <View>
                <Text
                  style={{
                    marginTop: 10,
                    fontSize: 12,
                    color: "#737373",
                    marginHorizontal: 10
                  }}
                >
                  Time Details
                </Text>
                <View
                  style={{
                    display: "flex",
                    // flexDirection: "row",
                    alignItems: "flex-start",
                    // justifyContent: "space-between",
                    marginTop: 5
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontSize: 12,
                        color: "#00b359",
                        fontWeight: "600",
                        marginHorizontal: 10,
                        textDecorationLine: "underline"
                      }}
                    >
                      Clock In Time -{" "}
                      {moment(job?.checkInTimeNurse).format("hh:mm a")}
                    </Text>
                  </View>

                  <View>
                    <Text
                      style={{
                        fontSize: 12,
                        color: "#00b359",
                        fontWeight: "600",
                        marginHorizontal: 10,
                        textDecorationLine: "underline"
                      }}
                    >
                      Clock Out Time -{" "}
                      {moment(job?.checkOutTimeNurse).format("hh:mm a")}
                    </Text>
                  </View>
                </View>
              </View>
            )}

            {job?.neverCheckOutReasonAttachment && (
              <View>
                <Text
                  style={{
                    marginVertical: 10,
                    fontSize: 12,
                    color: "#737373",
                    marginHorizontal: 10
                  }}
                >
                  Attachment submitted (Employee)
                </Text>
                <TouchableOpacity
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: 5,
                    elevation: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    marginHorizontal: 10,
                    paddingRight: 10,
                    justifyContent: "space-between"
                  }}
                  onPress={() =>
                    downloadAndOpenPDF(job?.neverCheckOutReasonAttachment)
                  }
                >
                  <Text
                    style={{
                      marginVertical: 10,
                      fontSize: 12,
                      color: "#8888",
                      marginHorizontal: 10
                    }}
                  >
                    Click to view the attachment
                  </Text>
                </TouchableOpacity>
              </View>
            )}

            {job?.neverCheckOutComments && (
              <View>
                <Text
                  style={{
                    marginVertical: 10,
                    fontSize: 12,
                    color: "#737373",
                    marginHorizontal: 10,
                    textDecorationLine: "underline"
                  }}
                >
                  Notes (Employee)
                </Text>
                <View
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: 5,
                    elevation: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    marginHorizontal: 10,
                    paddingRight: 10,
                    justifyContent: "space-between"
                  }}
                >
                  <Text
                    style={{
                      marginVertical: 10,
                      fontSize: 12,
                      color: "#000",
                      marginHorizontal: 10
                    }}
                  >
                    {job?.neverCheckOutComments}
                  </Text>
                </View>
              </View>
            )}

            {job?.neverCheckOutManagerReason && (
              <View>
                <Text
                  style={{
                    marginVertical: 10,
                    fontSize: 12,
                    color: "#737373",
                    marginHorizontal: 10,
                    textDecorationLine: "underline"
                  }}
                >
                  Approved {job?.jobType} Reason (Reviewer)
                </Text>
                <View
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: 5,
                    elevation: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    marginHorizontal: 10,
                    paddingRight: 10,
                    justifyContent: "space-between"
                  }}
                >
                  <Text
                    style={{
                      marginVertical: 10,
                      fontSize: 12,
                      color: "#000",
                      marginHorizontal: 10
                    }}
                  >
                    {job?.neverCheckOutManagerReason}
                  </Text>
                </View>
              </View>
            )}

            {job?.neverCheckOutManagerReasonAttachment && (
              <View>
                <Text
                  style={{
                    marginVertical: 10,
                    fontSize: 12,
                    color: "#737373",
                    marginHorizontal: 10,
                    textDecorationLine: "underline"
                  }}
                >
                  Attachment submitted (Reviewer)
                </Text>
                <TouchableOpacity
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: 5,
                    elevation: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    marginHorizontal: 10,
                    paddingRight: 10,
                    justifyContent: "space-between"
                  }}
                  onPress={() =>
                    downloadAndOpenPDF(
                      job?.neverCheckOutManagerReasonAttachment
                    )
                  }
                >
                  <Text
                    style={{
                      marginVertical: 10,
                      fontSize: 12,
                      color: "#8888",
                      marginHorizontal: 10
                    }}
                  >
                    Click to view the attachment
                  </Text>
                </TouchableOpacity>
              </View>
            )}

            <View
              style={{
                justifyContent: "flex-end",
                alignItems: "flex-end",
                flexDirection: "row",
                marginTop: 20
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setPendingNeverCheckOutModel(false);
                }}
                style={{
                  backgroundColor: "gray",
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 8,
                  marginRight: 10
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff"
                  }}
                >
                  Close
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>

      <Modal
        animationType="fade"
        transparent={true}
        visible={removeNurse}
        onRequestClose={() => setRemoveNurse(false)}
      >
        <View
          style={{
            height: height,
            // justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              paddingVertical: 15,
              // height: 150,
              width: 250,
              marginTop: 100,
              justifyContent: "center",
              elevation: 5,
              paddingHorizontal: 10,
              borderRadius: 15,
              borderColor: "rgba(0, 0, 0, 0.1)"
            }}
          >
            <View>
              <Text
                style={{
                  marginBottom: 5,
                  fontSize: 12,
                  color: "#737373",
                  marginHorizontal: 10
                }}
              >
                Reason
              </Text>

              <View
                style={{
                  backgroundColor: "#f2f2f2",
                  borderRadius: 10,
                  elevation: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderWidth: 0.5,
                  borderColor: "#e6e6e6",
                  marginHorizontal: 10,
                  paddingRight: 10,
                  justifyContent: "space-between"
                }}
              >
                <TextInput
                  style={{
                    width: "90%",
                    padding: 2,
                    paddingLeft: 5,
                    fontSize: 12,
                    color: "#737373"
                  }}
                  multiline
                  onChangeText={(text) => setRemoveNurseReason(text)}
                  value={removeNurseReason}
                  keyboardType={"default"}
                  autoCapitalize="sentences"
                  placeholderTextColor="#b3b3b3"
                  placeholder={"Reason..."}
                />
                <View
                  style={{
                    height: 40,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                  }}
                >
                  {removeNurseReason ? (
                    <TouchableOpacity
                      onPress={() => setRemoveNurseReason("")}
                      style={{ marginLeft: 3 }}
                    >
                      <Ionicons name="close-sharp" size={22} color="#808080" />
                    </TouchableOpacity>
                  ) : null}
                </View>
              </View>
            </View>
            <View
              style={{
                justifyContent: "flex-end",
                alignItems: "flex-end",
                flexDirection: "row",
                marginTop: 20
              }}
            >
              <TouchableOpacity
                onPress={() => setRemoveNurse(false)}
                style={{
                  backgroundColor: "#2775BD",
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10,
                  marginHorizontal: 10
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff"
                  }}
                >
                  Cancel
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => !loading && cancelNurse()}
                style={{
                  backgroundColor: "red",
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff"
                  }}
                >
                  {loading ? "Loading..." : "Release"}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>

      <Modal
        animationType="fade"
        transparent={true}
        visible={removeNurseBid}
        onRequestClose={() => setRemoveNurseBid(false)}
      >
        <View
          style={{
            height: height,
            // justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              paddingVertical: 15,
              // height: 150,
              width: 250,
              marginTop: 100,
              justifyContent: "center",
              elevation: 5,
              paddingHorizontal: 10,
              borderRadius: 15,
              borderColor: "rgba(0, 0, 0, 0.1)"
            }}
          >
            <View>
              <Text
                style={{
                  marginBottom: 5,
                  fontSize: 12,
                  color: "#737373",
                  marginHorizontal: 10
                }}
              >
                Reason
              </Text>

              <View
                style={{
                  backgroundColor: "#f2f2f2",
                  borderRadius: 10,
                  elevation: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderWidth: 0.5,
                  borderColor: "#e6e6e6",
                  marginHorizontal: 10,
                  paddingRight: 10,
                  justifyContent: "space-between"
                }}
              >
                <TextInput
                  style={{
                    width: "90%",
                    padding: 2,
                    paddingLeft: 5,
                    fontSize: 12,
                    color: "#737373"
                  }}
                  multiline
                  onChangeText={(text) => setRemoveNurseBidReason(text)}
                  value={removeNurseBidReason}
                  keyboardType={"default"}
                  autoCapitalize="sentences"
                  placeholderTextColor="#b3b3b3"
                  placeholder={"Reason..."}
                />
                <View
                  style={{
                    height: 40,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                  }}
                >
                  {removeNurseBidReason ? (
                    <TouchableOpacity
                      onPress={() => setRemoveNurseBidReason("")}
                      style={{ marginLeft: 3 }}
                    >
                      <Ionicons name="close-sharp" size={22} color="#808080" />
                    </TouchableOpacity>
                  ) : null}
                </View>
              </View>
            </View>
            <View
              style={{
                justifyContent: "flex-end",
                alignItems: "flex-end",
                flexDirection: "row",
                marginTop: 20
              }}
            >
              <TouchableOpacity
                onPress={() => setRemoveNurseBid(false)}
                style={{
                  backgroundColor: "#2775BD",
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10,
                  marginHorizontal: 10
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff"
                  }}
                >
                  Cancel
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => !loading && cancelNurseBid()}
                style={{
                  backgroundColor: "red",
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff"
                  }}
                >
                  {loading ? "Loading..." : "Release"}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>

      <Modal
        animationType="fade"
        transparent={true}
        visible={nurseSwap}
        onRequestClose={() => setNurseSwap(false)}
      >
        <View
          style={{
            height: height,
            // justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              paddingVertical: 15,
              height: 500,
              width: 250,
              marginTop: 100,
              elevation: 5,
              paddingHorizontal: 10,
              borderRadius: 15,
              borderColor: "rgba(0, 0, 0, 0.1)"
            }}
          >
            <View>
              <Text
                style={{
                  marginBottom: 10,
                  fontSize: 12,
                  color: "#737373",
                  marginHorizontal: 10
                }}
              >
                Swap Reason <Text style={{ color: "red" }}>*</Text>
              </Text>

              <View
                style={{
                  backgroundColor: "#f2f2f2",
                  borderRadius: 10,
                  elevation: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderWidth: 0.5,
                  borderColor: "#e6e6e6",
                  marginHorizontal: 10,
                  paddingRight: 10,
                  justifyContent: "space-between"
                }}
              >
                <TextInput
                  style={{
                    width: "90%",
                    padding: 2,
                    paddingLeft: 5,
                    fontSize: 12,
                    color: "#737373"
                  }}
                  onChangeText={(text) => setNurseSwapReason(text)}
                  value={nurseSwapReason}
                  keyboardType={"default"}
                  autoCapitalize="sentences"
                  placeholderTextColor="#b3b3b3"
                  placeholder={"Swap Reason..."}
                />
                <View
                  style={{
                    height: 40,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                  }}
                >
                  {nurseSwapReason ? (
                    <TouchableOpacity
                      onPress={() => setNurseSwapReason("")}
                      style={{ marginLeft: 3 }}
                    >
                      <Ionicons name="close-sharp" size={22} color="#808080" />
                    </TouchableOpacity>
                  ) : null}
                </View>
              </View>
              <View style={{ height: 380 }}>
                <ScrollView>
                  <View style={{ marginTop: 10 }}>
                    {!available ? (
                      <Text
                        style={[
                          {
                            fontSize: 12,
                            color: "#000",
                            textAlign: "center",
                            marginHorizontal: 20
                          }
                        ]}
                      >
                        Loading...
                      </Text>
                    ) : available?.filter((obj, index, arr) => {
                        const jobAcceptedNurses = job?.jobAcceptedNurses;
                        const isMatched = jobAcceptedNurses?.some(
                          (item) => item === obj?.id.id
                        );
                        return obj?.id.availableTime && !isMatched;
                      }).length === 0 ? (
                      <Text
                        style={[
                          {
                            fontSize: 12,
                            color: "#000",
                            textAlign: "center",
                            marginHorizontal: 20
                          }
                        ]}
                      >
                        No nurse is available.
                      </Text>
                    ) : (
                      available
                        ?.filter((obj, index, arr) => {
                          const jobAcceptedNurses = job?.jobAcceptedNurses;
                          const isMatched = jobAcceptedNurses?.some(
                            (item) => item === obj?.id.id
                          );
                          return obj?.id.availableTime && !isMatched;
                        })
                        .map((item, index) => {
                          return (
                            <UserJobCard
                              key={index}
                              element={item}
                              jobFinalSelectionNurseId={
                                jobFinalSelectionNurseId
                              }
                              setJobFinalSelectionNurseId={
                                setJobFinalSelectionNurseId
                              }
                            />
                          );
                        })
                    )}
                  </View>
                </ScrollView>
              </View>
            </View>
            <View
              style={{
                justifyContent: "flex-end",
                alignItems: "flex-end",
                flexDirection: "row",
                marginBottom: 20,
                marginTop: 10
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setJobFinalSelectionNurseId("");
                  setNurseSwapReason("");
                  setNurseSwap(false);
                }}
                style={{
                  backgroundColor: "#2775BD",
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10,
                  marginHorizontal: 10
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff"
                  }}
                >
                  Cancel
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() =>
                  !loading && swapOldNurse !== undefined && swapNurse()
                }
                style={{
                  backgroundColor: "#2775BD",
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff"
                  }}
                >
                  {loading ? "Loading..." : "Submit"}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>

      <Modal
        animationType="fade"
        transparent={true}
        visible={completeJob}
        onRequestClose={() => setCompleteJob(false)}
      >
        <View
          style={{
            height: height,
            // justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              paddingVertical: 15,
              // height: 150,
              width: 250,
              marginTop: 100,
              justifyContent: "center",
              elevation: 5,
              paddingHorizontal: 10,
              borderRadius: 15,
              borderColor: "rgba(0, 0, 0, 0.1)"
            }}
          >
            <View>
              <Text
                style={{
                  marginBottom: 5,
                  fontSize: 12,
                  color: "#737373",
                  marginHorizontal: 10
                }}
              >
                Comments
              </Text>

              <View
                style={{
                  backgroundColor: "#f2f2f2",
                  borderRadius: 10,
                  elevation: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderWidth: 0.5,
                  borderColor: "#e6e6e6",
                  marginHorizontal: 10,
                  paddingRight: 10,
                  justifyContent: "space-between"
                }}
              >
                <TextInput
                  style={{
                    width: "90%",
                    padding: 2,
                    paddingLeft: 5,
                    fontSize: 12,
                    color: "#737373"
                  }}
                  multiline
                  onChangeText={(text) => setCompleteJobComment(text)}
                  value={completeJobComment}
                  keyboardType={"default"}
                  autoCapitalize="sentences"
                  placeholderTextColor="#b3b3b3"
                  placeholder={"Comment..."}
                />
                <View
                  style={{
                    height: 40,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                  }}
                >
                  {completeJobComment ? (
                    <TouchableOpacity
                      onPress={() => setCompleteJobComment("")}
                      style={{ marginLeft: 3 }}
                    >
                      <Ionicons name="close-sharp" size={22} color="#808080" />
                    </TouchableOpacity>
                  ) : null}
                </View>
              </View>
            </View>
            <View
              style={{
                justifyContent: "flex-end",
                alignItems: "flex-end",
                flexDirection: "row",
                marginTop: 20
              }}
            >
              <TouchableOpacity
                onPress={() => setCompleteJob(false)}
                style={{
                  backgroundColor: "#2775BD",
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10,
                  marginHorizontal: 10
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff"
                  }}
                >
                  Cancel
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => !loading && completePayment(job)}
                style={{
                  backgroundColor: "#00b359",
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff"
                  }}
                >
                  {loading ? "Loading..." : "Approve"}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>

      <Modal
        animationType="fade"
        transparent={true}
        visible={showAddress}
        onRequestClose={() => setShowAddress(false)}
      >
        <AutoFillAddressModel
          setShowAddress={setShowAddress}
          setAddress={(item) =>
            setJobClockDetails({
              ...jobClockDetails,
              checkInFullAddressNurse: item?.fullAddress,
              checkInLatitudeNurse: item?.latitude,
              checkInLongitudeNurse: item?.longitude
            })
          }
        />
      </Modal>
      <Modal
        animationType="fade"
        transparent={true}
        visible={showAddressEnd}
        onRequestClose={() => setShowAddressEnd(false)}
      >
        <AutoFillAddressModel
          setShowAddress={setShowAddressEnd}
          setAddress={(item) =>
            setJobClockDetails({
              ...jobClockDetails,
              checkOutFullAddressNurse: item?.fullAddress,
              checkOutLatitudeNurse: item?.latitude,
              checkOutLongitudeNurse: item?.longitude
            })
          }
        />
      </Modal>

      <Modal
        animationType="fade"
        transparent={true}
        visible={showTimeWeb}
        onRequestClose={() => setShowTimeWeb(false)}
      >
        <View
          style={{
            height: height,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              paddingVertical: 10,
              height: 280,
              justifyContent: "center",
              elevation: 5,
              width: width / 1.5,
              paddingHorizontal: 10,
              borderRadius: 15,
              borderColor: "rgba(0, 0, 0, 0.1)"
            }}
          >
            <Text style={{ fontSize: 18, marginBottom: 0 }}>
              Select Start Time{" "}
            </Text>
            <Text style={{ fontSize: 10, marginBottom: 10 }}>
              Select Hour and Minutes
            </Text>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <TouchableOpacity
                onPress={() => {
                  setShowDropdown(!showDropdown);
                  setShowDropdownMinute(false);
                  setShowDropdownAP(false);
                }}
              >
                <Text
                  style={{
                    fontSize: 25,
                    marginBottom: 10,
                    color: showDropdown ? "#00b359" : "#000"
                  }}
                >
                  {selectedHourTime === "" ? "00" : selectedHourTime}
                </Text>
              </TouchableOpacity>
              <Text style={{ fontSize: 25, marginBottom: 10 }}> : </Text>
              <TouchableOpacity
                onPress={() => {
                  setShowDropdownMinute(!showDropdownMinute);
                  setShowDropdown(false);
                  setShowDropdownAP(false);
                }}
              >
                <Text
                  style={{
                    fontSize: 25,
                    marginBottom: 10,
                    color: showDropdownMinute ? "#00b359" : "#000"
                  }}
                >
                  {selectedMinuteTime === "" ? "00" : selectedMinuteTime}
                </Text>
              </TouchableOpacity>
              <Text style={{ fontSize: 25, marginBottom: 10 }}> </Text>
              <TouchableOpacity
                onPress={() => {
                  setShowDropdownAP(!showDropdownAP);
                  setShowDropdown(false);
                  setShowDropdownMinute(false);
                }}
              >
                <Text
                  style={{
                    fontSize: 25,
                    marginBottom: 10,
                    color: showDropdownAP ? "#00b359" : "#000"
                  }}
                >
                  {selectedAPTime === "" ? "AM" : selectedAPTime}
                </Text>
              </TouchableOpacity>
            </View>
            {showDropdown && (
              <ScrollView
                style={{
                  width: "100%",
                  maxHeight: 150,
                  overflow: "scroll",
                  borderWidth: 0.5,
                  borderRadius: 4,
                  marginBottom: 5,
                  backgroundColor: "#fff"
                }}
              >
                {hour.map((item) => {
                  return (
                    <TouchableOpacity
                      key={item}
                      onPress={() => {
                        setSelectedHourTime(item < 10 ? "0" + item : item);
                        setShowDropdown(false);
                      }}
                      style={styles.dropdownItem}
                    >
                      <Text>{item < 10 ? "0" + item : item}</Text>
                    </TouchableOpacity>
                  );
                })}
              </ScrollView>
            )}
            {showDropdownMinute && (
              <ScrollView
                style={{
                  width: "100%",
                  maxHeight: 150,
                  overflow: "scroll",
                  borderWidth: 0.5,
                  borderRadius: 4,
                  marginBottom: 5,
                  backgroundColor: "#fff"
                }}
              >
                <TextInput
                  style={{
                    width: "90%",
                    padding: 2,
                    paddingLeft: 5,
                    fontSize: 12,
                    color: "#737373"
                  }}
                  keyboardType={"decimal-pad"}
                  onChangeText={(text) => {
                    setSelectedMinuteTime(text);
                  }}
                  value={selectedMinuteTime === "00" ? "" : selectedMinuteTime}
                  placeholderTextColor="#b3b3b3"
                  placeholder={`Enter custom minutes`}
                />
                {minutes.map((item) => {
                  return (
                    <TouchableOpacity
                      key={item}
                      onPress={() => {
                        setSelectedMinuteTime(item < 10 ? "0" + item : item);
                        setShowDropdownMinute(false);
                      }}
                      style={styles.dropdownItem}
                    >
                      <Text>{item < 10 ? "0" + item : item}</Text>
                    </TouchableOpacity>
                  );
                })}
              </ScrollView>
            )}
            {showDropdownAP && (
              <ScrollView
                style={{
                  width: "100%",
                  maxHeight: 100,
                  overflow: "scroll",
                  borderWidth: 0.5,
                  borderRadius: 4,
                  marginBottom: 5,
                  backgroundColor: "#fff"
                }}
              >
                {ampm.map((item) => {
                  return (
                    <TouchableOpacity
                      key={item}
                      onPress={() => {
                        setSelectedAPTime(item);
                        setShowDropdownAP(false);
                      }}
                      style={styles.dropdownItem}
                    >
                      <Text>{item}</Text>
                    </TouchableOpacity>
                  );
                })}
              </ScrollView>
            )}
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end"
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  if (job?.jobStatus === "Missed") {
                    setNoShowViewModelAction(true);
                  } else {
                    setPendingNeverCheckOutModelAction(true);
                  }
                  setShowTimeWeb(false);
                  setShowDropdown(false);
                  setShowDropdownMinute(false);
                  setShowDropdownAP(false);
                }}
                style={{
                  backgroundColor: "#888",
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10,
                  marginHorizontal: 10
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff"
                  }}
                >
                  Close
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  let date = new Date();
                  let dateEnd = new Date();
                  date.setHours(
                    selectedAPTime === "AM" && selectedHourTime === 12
                      ? 0
                      : selectedAPTime === "PM" && selectedHourTime === 12
                      ? 12
                      : selectedHourTime !== 12 && selectedAPTime === "PM"
                      ? parseInt(selectedHourTime) + 12
                      : parseInt(selectedHourTime)
                  );
                  date.setMinutes(selectedMinuteTime);

                  dateEnd.setFullYear(date.getFullYear());
                  dateEnd.setMonth(date.getMonth());
                  dateEnd.setDate(date.getDate());
                  dateEnd.setHours(date.getHours());
                  dateEnd.setMinutes(date.getMinutes());

                  // Determine if it's AM or PM
                  let meridiem = dateEnd.getHours() >= 12 ? "PM" : "AM";

                  if (selectedAPTime === "PM" && meridiem === "AM") {
                    let endDate = new Date(jobState.startDate);
                    endDate.setDate(endDate.getDate() + 1);
                    setJobClockDetails({
                      ...jobClockDetails,
                      checkInTimeNurse: date
                    });
                  } else {
                    setJobClockDetails({
                      ...jobClockDetails,
                      checkInTimeNurse: date
                    });
                  }
                  if (job?.jobStatus === "Missed") {
                    setNoShowViewModelAction(true);
                  } else {
                    setPendingNeverCheckOutModelAction(true);
                  }
                  setShowTimeWeb(false);
                  setShowDropdown(false);
                  setShowDropdownMinute(false);
                  setShowDropdownAP(false);
                }}
                style={{
                  backgroundColor: "#00b359",
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff"
                  }}
                >
                  Select
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        animationType="fade"
        transparent={true}
        visible={showTimeEndWeb}
        onRequestClose={() => setShowTimeEndWeb(false)}
      >
        <View
          style={{
            height: height,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              paddingVertical: 10,
              height: 280,
              justifyContent: "center",
              elevation: 5,
              width: width / 1.5,
              paddingHorizontal: 10,
              borderRadius: 15,
              borderColor: "rgba(0, 0, 0, 0.1)"
            }}
          >
            <Text style={{ fontSize: 18, marginBottom: 0 }}>
              Select End Time
            </Text>
            <Text style={{ fontSize: 10, marginBottom: 10 }}>
              Select Hour and Minutes
            </Text>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <TouchableOpacity
                onPress={() => {
                  setShowDropdownEnd(!showDropdownEnd);
                  setShowDropdownMinuteEnd(false);
                  setShowDropdownAPEnd(false);
                }}
              >
                <Text
                  style={{
                    fontSize: 25,
                    marginBottom: 10,
                    color: showDropdownEnd ? "#00b359" : "#000"
                  }}
                >
                  {selectedHourTimeEnd === "" ? "00" : selectedHourTimeEnd}
                </Text>
              </TouchableOpacity>
              <Text style={{ fontSize: 25, marginBottom: 10 }}> : </Text>
              <TouchableOpacity
                onPress={() => {
                  setShowDropdownMinuteEnd(!showDropdownMinuteEnd);
                  setShowDropdownEnd(false);
                  setShowDropdownAPEnd(false);
                }}
              >
                <Text
                  style={{
                    fontSize: 25,
                    marginBottom: 10,
                    color: showDropdownMinuteEnd ? "#00b359" : "#000"
                  }}
                >
                  {selectedMinuteTimeEnd === "" ? "00" : selectedMinuteTimeEnd}
                </Text>
              </TouchableOpacity>
              <Text style={{ fontSize: 25, marginBottom: 10 }}> </Text>
              <TouchableOpacity
                onPress={() => {
                  setShowDropdownAPEnd(!showDropdownAPEnd);
                  setShowDropdownEnd(false);
                  setShowDropdownMinuteEnd(false);
                }}
              >
                <Text
                  style={{
                    fontSize: 25,
                    marginBottom: 10,
                    color: showDropdownAPEnd ? "#00b359" : "#000"
                  }}
                >
                  {selectedAPTimeEnd === "" ? "AM" : selectedAPTimeEnd}
                </Text>
              </TouchableOpacity>
            </View>

            {showDropdownEnd && (
              <ScrollView
                style={{
                  width: "100%",
                  maxHeight: 150,
                  overflow: "scroll",
                  borderWidth: 0.5,
                  borderRadius: 4,
                  marginBottom: 5,
                  backgroundColor: "#fff"
                }}
              >
                {hour.map((item) => {
                  return (
                    <TouchableOpacity
                      key={item}
                      onPress={() => {
                        setSelectedHourTimeEnd(item < 10 ? "0" + item : item);
                        setShowDropdownEnd(false);
                      }}
                      style={styles.dropdownItem}
                    >
                      <Text>{item < 10 ? "0" + item : item}</Text>
                    </TouchableOpacity>
                  );
                })}
              </ScrollView>
            )}
            {showDropdownMinuteEnd && (
              <ScrollView
                style={{
                  width: "100%",
                  maxHeight: 150,
                  overflow: "scroll",
                  borderWidth: 0.5,
                  borderRadius: 4,
                  marginBottom: 5,
                  backgroundColor: "#fff"
                }}
              >
                <TextInput
                  style={{
                    width: "90%",
                    padding: 2,
                    paddingLeft: 5,
                    fontSize: 12,
                    color: "#737373"
                  }}
                  keyboardType={"decimal-pad"}
                  onChangeText={(text) => {
                    setSelectedMinuteTimeEnd(text);
                  }}
                  value={
                    selectedMinuteTimeEnd === "00" ? "" : selectedMinuteTimeEnd
                  }
                  placeholderTextColor="#b3b3b3"
                  placeholder={`Enter custom minutes`}
                />
                {minutes.map((item) => {
                  return (
                    <TouchableOpacity
                      key={item}
                      onPress={() => {
                        setSelectedMinuteTimeEnd(item < 10 ? "0" + item : item);
                        setShowDropdownMinuteEnd(false);
                      }}
                      style={styles.dropdownItem}
                    >
                      <Text>{item < 10 ? "0" + item : item}</Text>
                    </TouchableOpacity>
                  );
                })}
              </ScrollView>
            )}

            {showDropdownAPEnd && (
              <ScrollView
                style={{
                  width: "100%",
                  maxHeight: 100,
                  overflow: "scroll",
                  borderWidth: 0.5,
                  borderRadius: 4,
                  marginBottom: 5,
                  backgroundColor: "#fff"
                }}
              >
                {ampm.map((item) => {
                  return (
                    <TouchableOpacity
                      key={item}
                      onPress={() => {
                        setSelectedAPTimeEnd(item);
                        setShowDropdownAPEnd(false);
                      }}
                      style={styles.dropdownItem}
                    >
                      <Text>{item}</Text>
                    </TouchableOpacity>
                  );
                })}
              </ScrollView>
            )}

            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end"
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  if (job?.jobStatus === "Missed") {
                    setNoShowViewModelAction(true);
                  } else {
                    setPendingNeverCheckOutModelAction(true);
                  }

                  setShowTimeEndWeb(false);
                  setShowDropdownEnd(false);
                  setShowDropdownMinuteEnd(false);
                  setShowDropdownAPEnd(false);
                }}
                style={{
                  backgroundColor: "#888",
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10,
                  marginHorizontal: 10
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff"
                  }}
                >
                  Close
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  let date = new Date();
                  date.setHours(
                    selectedHourTimeEnd === 12 && selectedAPTimeEnd === "AM"
                      ? 0
                      : selectedAPTimeEnd === "PM" && selectedHourTimeEnd === 12
                      ? 12
                      : selectedHourTimeEnd !== 12 && selectedAPTimeEnd === "PM"
                      ? parseInt(selectedHourTimeEnd) + 12
                      : parseInt(selectedHourTimeEnd)
                  );
                  date.setMinutes(selectedMinuteTimeEnd);
                  setJobClockDetails({
                    ...jobClockDetails,
                    checkOutTimeNurse: date
                  });
                  if (job?.jobStatus === "Missed") {
                    setNoShowViewModelAction(true);
                  } else {
                    setPendingNeverCheckOutModelAction(true);
                  }
                  setShowTimeEndWeb(false);
                  setShowDropdownEnd(false);
                  setShowDropdownMinuteEnd(false);
                  setShowDropdownAPEnd(false);
                }}
                style={{
                  backgroundColor: "#00b359",
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff"
                  }}
                >
                  Select
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        animationType="fade"
        transparent={true}
        visible={viewPDF}
        onRequestClose={() => setViewPDF(false)}
      >
        {/* <Pdf
          trustAllCerts={false}
          source={{ uri: viewPDFURI, cache: true }}
          style={{
            height: 300,
            width: 200
          }}
          onLoadComplete={(numberOfPages, filePath) => {
            console.log(`number of pages: ${numberOfPages}`);
          }}
        /> */}
        <TouchableOpacity
          onPress={() => {
            setViewPDF(false);
          }}
          style={{
            backgroundColor: "#888",
            paddingHorizontal: 10,
            paddingVertical: 5,
            borderRadius: 10,
            marginHorizontal: 10
          }}
        >
          <Text
            style={{
              textAlign: "right",
              fontSize: 12,
              color: "#fff"
            }}
          >
            Close
          </Text>
        </TouchableOpacity>
      </Modal>
    </View>
  );
};

export default JobDetailsScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff"
  },
  input: {
    color: "#000",
    paddingLeft: 15,
    fontWeight: "bold",
    height: 40,
    fontSize: 15,
    marginVertical: 10,
    borderRadius: 5
  },
  homeBtn: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#00b359",
    width: "40%",
    height: 35,
    borderRadius: 5,
    marginVertical: 15,
    marginHorizontal: 5,
    alignSelf: "center"
  },

  dayHeaderText: {
    fontWeight: "bold"
  },
  dayContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: 30,
    height: 30,
    borderRadius: 15,
    marginTop: 6
  },
  pastDateContainer: {
    backgroundColor: "#CCCCCC"
  },
  futureDateText: {
    color: "black"
  },
  pastDateText: {
    color: "white"
  },
  inputContainer: {
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 4
  },
  dropdownItem: {
    padding: 8
  }
});
