import React, { createContext, useContext, useState } from "react";

const ChatContext = createContext();

const ChatProvider = ({ children }) => {
  const [selectedChatRoom, setSelectedChatRoom] = useState();
  const [chatNotification, setChatNotification] = useState([]);
  const [chats, setChats] = useState(undefined);
  const [fetchAgain, setFetchAgain] = useState(false);
  const [fetchAgainProfile, setFetchAgainProfile] = useState(false);
  const [fetchAgainNotification, setFetchAgainNotification] = useState(false);
  const [fetchAgainJobDashboard, setFetchAgainJobDashboard] = useState(false);
  const [fetchAgainJobDetails, setFetchAgainJobDetails] = useState(false);
  const [fetchAgainTemplateDetails, setFetchAgainTemplateDetails] = useState(false);
  const [fetchAgainJobList, setFetchAgainJobList] = useState(false);
  const [fetchAgainTemplateList, setFetchAgainTemplateList] = useState(false);
  const [fetchAgainTimeOffList, setFetchAgainTimeOffList] = useState(false);
  const [fetchAgainTimeOffListNurse, setFetchAgainTimeOffLisNurse] = useState(false);
  const [dashboardChatIconFetchAgain, setDashboardChatIconFetchAgain] = useState(false);
  const [dashboardNotificationIconFetchAgain, setDashboardNotificationIconFetchAgain] = useState(false);

  return (
    <ChatContext.Provider
      value={{
        selectedChatRoom,
        setSelectedChatRoom,
        chatNotification,
        setChatNotification,
        chats,
        setChats,
        fetchAgain,
        setFetchAgain,
        fetchAgainProfile,
        setFetchAgainProfile,
        fetchAgainNotification,
        setFetchAgainNotification,
        fetchAgainJobDashboard,
        setFetchAgainJobDashboard,
        fetchAgainJobDetails,
        setFetchAgainJobDetails,
        fetchAgainTemplateDetails,
        setFetchAgainTemplateDetails,
        fetchAgainJobList,
        setFetchAgainJobList,
        fetchAgainTemplateList,
        setFetchAgainTemplateList,
        fetchAgainTimeOffList,
        setFetchAgainTimeOffList,
        fetchAgainTimeOffListNurse,
        setFetchAgainTimeOffLisNurse,
        dashboardChatIconFetchAgain,
        setDashboardChatIconFetchAgain,
        dashboardNotificationIconFetchAgain,
        setDashboardNotificationIconFetchAgain,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const ChatState = () => {
  return useContext(ChatContext);
};

export default ChatProvider;