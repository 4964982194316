import React, { useState, useEffect, useRef } from "react";
import {
  Text,
  View,
  StyleSheet,
  SafeAreaView,
  Image,
  Animated,
  Platform,
  Dimensions,
  ActivityIndicator,
} from "react-native";
import Message from "./Message";
import MessageInput from "./MessageInput";
import Ionicons from "react-native-vector-icons/Ionicons";
import { getNurseUniqueIdDetails } from "../../api_url/nurseTable";
import { getFacilityUniqueIdDetails } from "../../api_url/facilityTable";
import { useAuth } from "../../context/AuthContext";
import { createChatRoom, createMessage, getChatRoomMessage, getChatRoomMessageCheck, updateLastMessage } from "../../api_url/chatHistoryTable";
import { ChatState } from "../../context/ChatContext";
import { sendChatPushNotification } from "../../utils/notification";
import { sendNotification } from "../../utils/webNotification";
import * as Haptics from "expo-haptics";
import { socket } from "../../api_url/socket";

const ChatRoomScreen = (props) => {

  const { authToken, userId, userUniqueId } = useAuth();
  const { selectedChatRoom, setSelectedChatRoom,
    chats, setChats, fetchAgain, setFetchAgain,
    dashboardChatIconFetchAgain,
    setDashboardChatIconFetchAgain } = ChatState();

  const [socketConnected, setSocketConnected] = useState(false);
  const [typing, setTyping] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  const [loading, setLoading] = useState(true);

  const [nurseData, setNurseData] = useState(undefined);
  const [facilityData, setFacilityData] = useState(undefined);

  const [messages, setMessages] = useState([]);
  const [messageReplyTo, setMessageReplyTo] = useState("");

  const [chatRoomId, setChatRoomId] = useState(undefined);
  const [nurseId, setNurseId] = useState(undefined);
  const [chatRoom, setChatRoom] = useState(undefined);

  const [message, setMessage] = useState("");
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const [image, setImage] = useState("");
  const [progress, setProgress] = useState(0);
  const [recording, setRecording] = useState(null);
  const [record, setRecord] = useState(false);
  const [soundURI, setSoundURI] = useState("");
  const [nextPage, setNextPage] = useState(1);
  const [perviousPage, setPerviousPage] = useState(null);
  const [indicator, setIndicator] = useState(false);

  const { height } = Dimensions.get("window")

  // useEffect(() => {
  //   if (facilityData) {
  //     socket?.emit("setup", facilityData);
  //     socket?.on("connected", () => setSocketConnected(true));
  //   }
  // }, [facilityData])

  useEffect(() => {
    socket?.on("typing", (id) => {
      // console.log("typing", id, userUniqueId)
      setIsTyping(id !== userUniqueId ? true : false)
    });
    socket?.on("stop typing", (id) => {
      // console.log("stop typing", id, userUniqueId)
      setIsTyping(id !== userUniqueId && false)
    });
    socket?.on("newMessageInsertedEvent", (data) => {
      if (Number(data?.chathistorytableID) === Number(chatRoomId)) {
        setFetchAgain(!fetchAgain);
        getMessageOnly(1, 20);
      }
    });
  }, [socket, chatRoomId])

  const getMessageOnly = async (page, pageSize) => {
    await getChatRoomMessage(authToken, chatRoomId, page, pageSize).then(res => {
      setMessages(res?.chatRoom?.MessageItem);
      setNextPage(res?.chatRoom?.nextPage)
      setPerviousPage(res?.chatRoom?.previousPage)
    })
  }
  const getMessageScrollOnly = async (page, pageSize) => {
    // console.log("first")
    await getChatRoomMessage(authToken, chatRoomId, page, pageSize).then(res => {

      setMessages(prevMessages => [...prevMessages, ...res?.chatRoom?.MessageItem]);
      setNextPage(res?.nextPage)
      setPerviousPage(res?.previousPage)
      setIndicator(false)

    })
  }

  // useEffect(() => {
  //   socket?.on("message received", (newMessageReceived) => {
  //     // console.log("selectedChatRoom?.id", selectedChatRoom?.id)
  //     if (selectedChatRoom?.id !== undefined && selectedChatRoom?.id !== newMessageReceived?.chathistorytableID) {
  //       console.log("Notification", selectedChatRoom?.id, newMessageReceived?.chathistorytableID)
  //       //Notification
  //     } else {
  //       setMessages(prevMessages => [newMessageReceived, ...prevMessages]);
  //       setFetchAgain(!fetchAgain);
  //     }
  //   });
  // })

  const getChatRoomMes = async (chatRoomId) => {
    try {
      await getChatRoomMessage(authToken, chatRoomId, 1, 20).then(async res => {
        setNurseData(res?.nurseData);
        setFacilityData(res?.facilityData);
        // socket?.emit("setup", res?.facilityData);
        // socket?.on("connected", () => setSocketConnected(true));
        const nonVisited = [];

        for (const item of res?.chatRoom?.MessageItem) {
          if (item?.sender !== userUniqueId && item?.status === "Sent") {
            nonVisited.push(item?.id);
          }
        }
        if (nonVisited.length > 0) {
          // console.log(nonVisited.map(i => i))
          await updateLastMessage(
            authToken,
            {
              facilityTableID: userUniqueId,
              nurseTableID: "",
              organizationTableId: "",
              ids: nonVisited.map(i => i)
            }).then(async res => {
              setFetchAgain(!fetchAgain);
              setDashboardChatIconFetchAgain(!dashboardChatIconFetchAgain);
              await getChatRoomMessage(authToken, chatRoomId, 1, 20).then(res => {
                setMessages(res?.chatRoom?.MessageItem);
                setNextPage(res?.nextPage)
                setPerviousPage(res?.previousPage)
              })
            })
        } else {
          await getChatRoomMessage(authToken, chatRoomId, 1, 20).then(res => {
            setMessages(res?.chatRoom?.MessageItem);
            setNextPage(res?.nextPage)
            setPerviousPage(res?.previousPage)
          })
        }
      })
      // socket?.emit("join chat", chatRoomId)
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getChatRoomMesCheck = async (nurseId, facilityId) => {
    try {
      await getChatRoomMessageCheck(authToken, nurseId, facilityId, 1, 20).then(async res => {
        if (res) {
          setNurseData(res?.nurseData);
          setFacilityData(res?.facilityData);
          // socket?.emit("setup", res?.facilityData);
          // socket?.on("connected", () => setSocketConnected(true));
          if (res?.chatRoom) {
            setChatRoomId(res?.chatRoom?.id)
            const nonVisited = [];
            for (const item of res?.chatRoom?.MessageItem) {
              if (item?.sender !== userUniqueId && item?.status === "Sent") {
                nonVisited.push(item?.id);
              }
            }
            if (nonVisited.length > 0) {
              console.log(nonVisited.map(i => i))
              await updateLastMessage(
                authToken,
                {
                  facilityTableID: userUniqueId,
                  nurseTableID: "",
                  organizationTableId: "",
                  ids: nonVisited.map(i => i)
                }).then(async res => {
                  setFetchAgain(!fetchAgain);
                  setDashboardChatIconFetchAgain(!dashboardChatIconFetchAgain);
                  await getChatRoomMessageCheck(authToken, nurseId, facilityId, 1, 20).then(res => {
                    setMessages(res?.chatRoom?.MessageItem);
                    setNextPage(res?.nextPage)
                    setPerviousPage(res?.previousPage)
                  })
                })
            } else {
              await getChatRoomMessageCheck(authToken, nurseId, facilityId, 1, 20).then(res => {
                setMessages(res?.chatRoom?.MessageItem);
                setNextPage(res?.nextPage)
                setPerviousPage(res?.previousPage)
              })
            }
          } else {
            setChatRoomId(undefined)
          }
        }
      })
      // socket?.emit("join chat", chatRoomId)
      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  //Nurse Detail
  const getNurseDetail = async (id) => {
    try {
      await getNurseUniqueIdDetails(authToken, id).then(res => {
        setNurseData(res[0]);
      })
    } catch (error) {
      console.log(error);
    }
  };

  // Facility Detail
  const getFacilityDetail = async (id) => {
    try {
      await getFacilityUniqueIdDetails(authToken, id).then(res => {
        setFacilityData(res[0]);
        // socket?.emit("setup", res[0]);
        // socket?.on("connected", () => setSocketConnected(true));
        setLoading(false);
      })
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      props?.route?.params?.data?.chatRoomId
    ) {
      getChatRoomMes(props?.route?.params?.data?.chatRoomId);
      setChatRoomId(props?.route?.params?.data?.chatRoomId);
      setNurseId(props?.route?.params?.data?.nurseId);
    } else {
      getChatRoomMesCheck(props?.route?.params?.data?.nurseId, props?.route?.params?.data?.facilityId)
      getNurseDetail(props?.route?.params?.data?.nurseId);
      getFacilityDetail(props?.route?.params?.data?.facilityId);
      setNurseId(props?.route?.params?.data?.nurseId);
    }
  }, [props]);

  const colorFacilityBackground = "#f2f2f2";

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      headerStyle: {
        backgroundColor: "#22c35d",
        elevation: 0,
        borderBottomWidth: 0,
        shadowColor: "transparent",
        shadowOpacity: 0,
      },
      headerTitle: () => <Text></Text>,
      headerRight: () => <View></View>,
      headerLeft: () => (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Ionicons
            onPress={() => {
              props?.navigation.goBack()
            }}
            name="arrow-back"
            color="#fff"
            size={22}
            style={{ paddingHorizontal: 10 }}
          />
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              borderRadius: 100,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#fff",
              width: 38,
              height: 38,
              overflow: "hidden",
            }}
          >
            {nurseData?.profileImage ? (
              <Image
                source={{
                  uri: nurseData?.profileImage
                }}
                style={{
                  width: 38,
                  height: 38,
                  borderRadius: 10,
                }}

              />
            ) : (
              <Image
                source={require("../../../assets/images/icon.jpg")}
                style={{ width: 60, height: 60 }}
              />
            )}
          </View>
          <Text
            style={{
              fontSize: 16,
              textAlign: "center",
              fontWeight: "bold",
              color: "#fff",
              marginLeft: 5,
            }}
          >
            {nurseData?.firstName} {nurseData?.lastName}
          </Text>
        </View>
      ),
    });
  });

  const scrollViewRef = useRef(null);
  const flatListRef = useRef(null);

  //Web View
  useEffect(() => {
    if (messages?.length !== 0 && Platform.OS === "web") {
      setTimeout(() => {
        // Messages changed. Scrolling to end...  
        const scrollContainer = scrollViewRef.current;
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }, 2000);
    }
  }, [messages]);

  useEffect(() => {
    if (messages?.length !== 0 && Platform.OS === "web") {
      setTimeout(() => {
        //  Messages changed. Scrolling to end with animation... 
        // Assuming you're using a div for scrolling
        const scrollContainer = scrollViewRef.current;
        scrollContainer.scrollTo({
          top: scrollContainer.scrollHeight,
          behavior: 'smooth',
        });
      }, 2000);
    }
  }, [messages]);

  //Mobile View
  const scrollY = useRef(new Animated.Value(0)).current;

  // const onScroll = Animated.event(
  //   [{ nativeEvent: { contentOffset: { y: scrollY } } }],
  //   { useNativeDriver: true } // Use native driver for better performance
  // ); 

  const onScroll = async (event) => {
    const offsetY = event.nativeEvent.contentOffset.y;
    const contentHeight = event.nativeEvent.contentSize.height;
    const containerHeight = event.nativeEvent.layoutMeasurement.height;

    // console.log(offsetY + containerHeight, contentHeight);

    if (Math.round(offsetY + containerHeight) === Math.round(contentHeight)) {
      // console.log('User scrolled to the top');
      setIndicator(true)
      if (nextPage !== null) {
        // console.log(nextPage)
        await getMessageScrollOnly(nextPage, 20)
      }
    }
    //  else if (offsetY < 0) {
    //   console.log('User scrolled up');
    //   console.log(offsetY);
    // } else {
    //   console.log('User scrolled down');
    //   console.log(offsetY);
    // }
  };

  const sendMessageToUser = async () => {
    if (chatRoomId === undefined) {
      // Alert.alert("New chat room");
      const res = await createChatRoom({
        authToken,
        senderPublicKey: "",
        senderSecretKey: "",
        receiverPublicKey: "",
        receiverSecretKey: "",
        chatHistoryTableFacilityTableId: userUniqueId,
        chatHistoryTableNurseTableId: nurseId,
        chatHistoryTableOrganizationTableId: "0",
        text: message
      })
      // socket?.emit("new message", res?.message)
      socket?.emit("chatRoomInserted", res?.chatRoom)
      if (res) {
        setSelectedChatRoom(res);
        // setMessages(prevMessages => [...prevMessages, res?.message]);
        setMessage("");
        setFetchAgain(!fetchAgain);
        getChatRoomMes(res.chatRoom?.id);
        if (nurseData?.mobileId) {
          if (Platform.OS !== "web") {
            sendChatPushNotification({
              expoPushToken: nurseData?.mobileId,
              name: facilityData?.firstName + "-" + facilityData?.lastName,
              typeMessage: message,
              facilityId: res?.facilityTableID,
              nurseId: res?.nurseTableID,
            });
          } else {
            sendNotification(authToken, {
              expoPushToken: nurseData?.mobileId,
              title: facilityData?.firstName + "-" + facilityData?.lastName,
              body: message,
              data: { facilityId: res?.facilityTableID, nurseId: res?.nurseTableID, screen: "ChatRoomScreen" },
            })
          }
        }
        socket?.emit("newMessageInserted", res?.message?.chathistorytableID)
      }
      if (Platform.OS !== "web") {
        Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
      }
    } else {
      // Alert.alert("Old chat room");
      const res = await createMessage({
        id: chatRoomId,
        authToken,
        nurseTableID: "",
        facilityTableID: userUniqueId,
        organizationTableId: "",
        text: message
      })
      let newMsg = {
        id: res?.id,
        sender: res?.sender,
        receiver: res?.receiver,
        status: res?.status,
        text: res?.text,
        image: res?.image,
        audio: res?.audio,
        chathistorytableID: res?.chathistorytableID,
        nurseTableID: res?.nurseTableID,
        facilityTableID: res?.facilityTableID,
        organizationTableId: res?.organizationTableId,
        nurseDeleted: res?.nurseDeleted,
        facilityDeleted: res?.facilityDeleted,
        organizationDeleted: res?.organizationDeleted,
      }
      // socket?.emit("new message", res)
      if (res) {
        socket?.emit("chatRoomInserted", res?.chatRoom)
        // setMessages(prevMessages => [...prevMessages, newMsg]);
        // socket.emit("stop typing", chatRoomId);
        socket?.emit("newMessageInserted", {
          chathistorytableID: res?.chathistorytableID
        })
        setMessage("");
        setFetchAgain(!fetchAgain);
        if (nurseData?.mobileId) {
          if (Platform.OS !== "web") {
            sendChatPushNotification({
              expoPushToken: nurseData?.mobileId,
              name: facilityData?.firstName + "-" + facilityData?.lastName,
              typeMessage: message,
              facilityId: res?.facilityTableID,
              nurseId: res?.nurseTableID,
            });
          } else {
            sendNotification(authToken, {
              expoPushToken: nurseData?.mobileId,
              title: facilityData?.firstName + "-" + facilityData?.lastName,
              body: message,
              data: { facilityId: res?.facilityTableID, nurseId: res?.nurseTableID, screen: "ChatRoomScreen" },
            })
          }
        }
      }
      if (Platform.OS !== "web") {
        Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
      }
    }
  };

  const sendMessage = async () => {
    sendMessageToUser();
  };

  const onPlusClicked = () => {
    console.warn("Enter the text to send");
  };

  const onPress = () => {
    // if (image) {
    //   sendImage();
    // } else if (soundURI) {
    //   sendAudio();
    // } else if (message) {
    sendMessage();
    // } else {
    // onPlusClicked();
    // }
  };

  const onChangeText = (e) => {
    setMessage(e)
    if (!typing) {
      setTyping(true);
      // socket.emit("typing", chatRoomId, userUniqueId);
    }
    let lastTyping = new Date().getTime();
    var timeLength = 3000;
    setTimeout(() => {
      var timeNow = new Date().getTime();
      var timeDiff = timeNow - lastTyping;

      if (timeDiff >= timeLength && typing) {
        // socket.emit("stop typing", chatRoomId, userUniqueId);
        setTyping(false);
      }
    }, timeLength);

  }

  const handleScroll = async (e) => {
    const scrollTop = e.target.scrollTop;
    const scrollHeight = e.target.scrollHeight;
    const clientHeight = e.target.clientHeight;
    // console.log(scrollTop, scrollHeight, clientHeight)
    // Check if user has scrolled to the top
    if (scrollTop === 0) {
      // Load more messages
      if (nextPage !== null) {
        // console.log(nextPage)
        await getMessageScrollOnly(nextPage, 20)
      }
    }
  };

  return (
    <>
      {
        Platform.OS === "web" ?
          <div style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}>
            <div
              style={{
                overflowY: "auto",
                maxHeight: height - 125,
                transition: "scroll 1s ease-in-out",
              }}
              ref={scrollViewRef}
              onScroll={handleScroll}
            >
              <View>
                <Text style={styles.endToEndText}>
                  Messages are end-to-end encrypted
                </Text>
                {messages.sort((a, b) => new Date(a?.createdAt) - new Date(b?.createdAt)).map((item) => (
                  <Message
                    key={item?.id}
                    message={item}
                    userID={userUniqueId}
                    setAsMessageReply={() => setMessageReplyTo(item)}
                    facilityData={facilityData}
                    nurseData={nurseData}
                  />
                ))}
              </View>
            </div>
            <MessageInput
              // messageReplyTo={messageReplyTo}
              removeMessageReplyTo={() => setMessageReplyTo(null)}
              message={message}
              setMessage={setMessage}
              isEmojiPickerOpen={isEmojiPickerOpen}
              image={image}
              setImage={setImage}
              progress={progress}
              recording={recording}
              record={record}
              soundURI={soundURI}
              sendMessageToUser={sendMessageToUser}
              sendMessage={sendMessage}
              onPlusClicked={onPlusClicked}
              onPress={onPress}
              onChangeText={onChangeText}
            />
          </div>
          :
          <SafeAreaView
            style={{
              flex: 1,
              backgroundColor: colorFacilityBackground,
            }}
          >
            {!loading &&
              <View
                style={{
                  flex: 1,
                  backgroundColor: colorFacilityBackground,
                }}>

                <Animated.FlatList
                  ref={flatListRef}
                  data={messages}
                  ListFooterComponent={() => (
                    <View>
                      <Text style={styles.endToEndText}>
                        Messages are end-to-end encrypted
                      </Text>
                      {indicator && <ActivityIndicator size="large" />}
                    </View>
                  )}
                  keyExtractor={(item, index) => index.toString()}
                  renderItem={({ item }) => (
                    <Message
                      message={item}
                      userID={userUniqueId}
                      setAsMessageReply={() => setMessageReplyTo(item)}
                      facilityData={facilityData}
                      nurseData={nurseData}
                    />
                  )}
                  onScroll={onScroll}
                  scrollEventThrottle={32} // Adjust the throttle value as needed
                  inverted
                />
                {
                  isTyping && <Text>typing</Text>
                }
                <MessageInput
                  // messageReplyTo={messageReplyTo}
                  removeMessageReplyTo={() => setMessageReplyTo(null)}
                  message={message}
                  setMessage={setMessage}
                  isEmojiPickerOpen={isEmojiPickerOpen}
                  image={image}
                  setImage={setImage}
                  progress={progress}
                  recording={recording}
                  record={record}
                  soundURI={soundURI}
                  sendMessageToUser={sendMessageToUser}
                  sendMessage={sendMessage}
                  onPlusClicked={onPlusClicked}
                  onPress={onPress}
                  onChangeText={onChangeText}
                />
              </View>}
          </SafeAreaView>
      }
    </>
  );
};
export default ChatRoomScreen;
const styles = StyleSheet.create({
  endToEndText: {
    fontSize: 10,
    textAlign: "center",
    marginHorizontal: 50,
    marginTop: 5,
    padding: 10,
    backgroundColor: "#cccc00",
    borderRadius: 10,
  },
});
