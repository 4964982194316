import {
  View,
  StyleSheet,
  Text,
  Alert,
  Platform,
  TouchableOpacity,
  Linking,
  Pressable
} from "react-native";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import * as Notifications from "expo-notifications";
import { Ionicons } from "@expo/vector-icons";
import { Image } from "react-native";
import { GlobalStyles } from "../../utils/GlobalStyles";
import Constants from "../../../app.json";
import { getFacilityDetails, updateDetails } from "../../api_url/facilityTable";
import { useAuth } from "../../context/AuthContext";
import ShiftScreen from "./ShiftScreen";
import VisitScreen from "./VisitScreen";
import { socket } from "../../api_url/socket";
import { getReleaseVersion } from "../../api_url/release";
import { ChatState } from "../../context/ChatContext";
import { registerForPushNotificationsAsync } from "../../function/functionReturn";
import {
  getFacilityChatRooms,
  getLastMessage
} from "../../api_url/chatHistoryTable";
import { getFacilityNotification } from "../../api_url/facilityNotification";
import OrganizationJobView from "../OrganizationJob/OrganizationJobView";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true
  })
});

const HomeScreen = (props) => {
  const { authToken, userId, userUniqueId } = useAuth();
  const {
    fetchAgainProfile,
    setFetchAgainProfile,
    dashboardChatIconFetchAgain,
    dashboardNotificationIconFetchAgain
  } = ChatState();

  const responseListener = useRef();
  const [dashboardView, setDashboardView] = useState("Private");
  const [token, setToken] = useState("");
  const [user, setUser] = useState(undefined);
  const [loading, setLoading] = useState(undefined);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState("");

  const [notificationData, setNotificationData] = useState(undefined);
  const [chatNotificationData, setChatNotificationData] = useState(undefined);
  const [releaseVersionNum, setReleaseVersionNum] = useState(undefined);
  const [jobType, setJobType] = useState("shift");

  const currentIOSVersion = Constants.expo.version;

  const getReleaseDetail = async (authToken) => {
    try {
      const data = await getReleaseVersion(authToken);
      setReleaseVersionNum(data[0]?.nursdFlowIOS);
    } catch (error) {
      console.log(error);
    }
  };

  const onJobDetailNavigate = (d) => {
    props.navigation.navigate("JobDetailsScreen", {
      data: d
    });
  };

  const onChatRoomNavigate = (d) => {
    props?.navigation?.navigate("ChatRoomScreen", {
      data: d
    });
  };

  const onNavigate = (d) => {
    props?.navigation?.navigate(d);
  };

  const checkUser = async (authToken, userId) => {
    try {
      await getFacilityDetails(authToken, userId).then((res) => {
        if (res === undefined) {
          setUser(null);
          setLoadingScreen(false);
        } else {
          setUser(res);
          setLoadingScreen(false);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (authToken && userId) {
      checkUser(authToken, userId);
      getReleaseDetail(authToken);
      if (Platform.OS !== "web") {
        registerForPushNotificationsAsync().then((token) => {
          setToken(token?.token);
          setNotificationStatus(token?.res);
        });
      }
      if (Platform.OS === "web") {
        setLoading(false);
      }
    }
  }, [authToken, userId, fetchAgainProfile]);

  useEffect(() => {
    if (authToken && userId) {
      getNotificationAll(authToken, userId);
    }
  }, [authToken, userId, dashboardNotificationIconFetchAgain]);

  useEffect(() => {
    if (authToken && userId && userUniqueId) {
      getChatRoomsFacility(authToken, userUniqueId);
    }
  }, [authToken, userId, dashboardChatIconFetchAgain]);

  useEffect(() => {
    socket?.on("tableNotificationInsertEvent", (data) => {
      if (data?.table === "FacilityNotificationTable" && data?.id == userId) {
        // console.log("tableNotificationInsertEvent", data);
        getNotificationAll(authToken, userId);
      }
    });
  }, [socket]);

  useEffect(() => {
    socket?.on("chatRoomInsertedEvent", (data) => {
      if (data?.chatHistoryTableFacilityTableId === userUniqueId) {
        // console.log("chatRoomInsertedEvent", data);
        getChatRoomsFacility(authToken, userUniqueId);
      }
    });
  }, [socket, userUniqueId]);

  useEffect(() => {
    if (user !== undefined && token !== "") {
      if (user?.mobileId === "") {
        if (Platform.OS === "web") {
          setLoading(false);
        } else {
          setLoading(true);
        }
      } else if (user?.mobileId !== token) {
        // console.log(user?.mobileId, token);
        if (Platform.OS === "web") {
          setLoading(false);
        } else {
          setLoading(true);
        }
      } else if (user?.mobileId === token) {
        // console.log(user?.mobileId, token);
        setLoading(false);
      }
    }
  }, [user, token]);

  const getChatRoomsFacility = async (authToken, userUniqueId) => {
    let chatNotificationArray = [];
    try {
      const chatRoomData = await getFacilityChatRooms(authToken, userUniqueId);
      if (chatRoomData) {
        for (const item of chatRoomData) {
          await getLastMessage(
            authToken,
            item?.chatHistoryTableLastMessageItemId
          ).then((res) => {
            chatNotificationArray.push(res);
          });
        }
      }
      // console.log(chatNotificationArray.filter(i =>
      //   i?.sender !== userUniqueId
      //   && i?.receiver === "").length);
      setChatNotificationData(
        chatNotificationArray.filter(
          (i) => i?.sender !== userUniqueId && i?.receiver === ""
        ).length
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getNotificationAll = async (authToken, userId) => {
    try {
      const data = await getFacilityNotification(authToken, userId);
      setNotificationData(data.filter((i) => !i?.visited).length);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    responseListener.current =
      Notifications.addNotificationResponseReceivedListener((response) => {
        if (response.notification.request.content.data) {
          const screen = response.notification.request.content.data?.screen;
          if (screen === "JobDetailScreen") {
            const id = response.notification.request.content.data;
            onJobDetailNavigate({ id: id?.jobId });
          } else if (screen === "ChatRoomScreen") {
            const id = response.notification.request.content.data;
            onChatRoomNavigate({
              nurseId: id?.nurseId,
              facilityId: userUniqueId
            });
          } else if (screen === "RequestTimeOffScreen") {
            const id = response.notification.request.content.data;
            onRequestJobNavigate({
              userId: id?.userId,
              item: id?.item
            });
          } else if (screen === "TimeOffScreenNurse") {
            const id = response.notification.request.content.data;
            onTimeOffScreenNurseNavigate({
              userId: id?.userId,
              item: id?.item
            });
          } else {
            onNavigate(screen?.toString());
          }
        }
      });

    return () => {
      // Notifications.removeNotificationSubscription(
      //   notificationListener.current
      // );
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, []);

  const updateNotification = async () => {
    if (notificationStatus === "denied" && token === "") {
      Alert.alert(
        "Permission Denied",
        "Please enable notifications from your device settings and restart the app.",
        [
          {
            text: "Cancel",
            style: "cancel",
            onPress: () => setLoading(false)
          },
          {
            text: "Open Settings",
            onPress: () => {
              Linking.openSettings();
            }
          }
        ]
      );
    } else {
      setLoadingBtn(true);
      try {
        try {
          await Notifications.requestPermissionsAsync();
        } catch (error) {
          console.log(error);
        }
        const res = await updateDetails(authToken, userId, { mobileId: token });
        if (res.mobileId === token) {
          setLoading(false);
          setLoadingBtn(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      headerStyle: {
        backgroundColor: "#FFF",
        borderBottomWidth: 0
      },
      headerTitleAlign: "center",
      headerLeft: () => (
        <Image
          source={require("../../../assets/login/NURSD-Flow.png")}
          style={{
            marginLeft: 10,
            width: 75,
            height: 25,
            marginBottom: 0
          }}
        />
      ),
      headerRight: () => (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <TouchableOpacity
            onPress={() => {
              props.navigation.navigate("ChatScreen");
            }}
          >
            {chatNotificationData > 0 && (
              <View
                style={{
                  backgroundColor: "#00cc00",
                  borderRadius: 100,
                  width: 15,
                  height: 15,
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  zIndex: 1,
                  right: 7,
                  bottom: 15
                }}
              >
                <Text
                  style={{
                    color: "#fff",
                    fontSize: 10,
                    fontWeight: "700"
                  }}
                >
                  {chatNotificationData}
                </Text>
              </View>
            )}
            <Ionicons
              name="chatbubble-outline"
              color={"#333333"}
              size={24}
              style={{ marginRight: 10 }}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => props.navigation.navigate("NotificationScreen")}
          >
            {notificationData > 0 && (
              <View
                style={{
                  backgroundColor: "#00cc00",
                  borderRadius: 100,
                  width: 10,
                  height: 10,
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  zIndex: 1,
                  right: 18,
                  bottom: 15
                }}
              />
            )}
            <Ionicons
              name="notifications-outline"
              color={"#333333"}
              size={24}
              style={{ marginRight: 10 }}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: 15,
              borderWidth: 0.6,
              borderRadius: 30,
              borderColor: "#00cc00",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#fff",
              width: 30,
              height: 30,
              overflow: "hidden"
            }}
            onPress={() => props.navigation.navigate("ProfileScreen")}
          >
            {user?.profileImage ? (
              <Image
                source={{
                  uri: user?.profileImage
                }}
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: 30
                }}
              />
            ) : (
              <Image
                source={require("../../../assets/login/upload-profile.png")}
                style={{ width: 30, height: 30 }}
              />
            )}
          </TouchableOpacity>
        </View>
      ),
      headerTitle: (props) => (
        <View style={GlobalStyles.headerTitleView}>
          <Text
            style={{
              fontSize: 20,
              fontWeight: "bold",
              textAlign: "center"
            }}
          >
            Home
          </Text>
        </View>
      )
    });
  });

  let appJobType = user?.additionalFields[0]?.appJobType || "Both";

  return (
    <View style={styles.container}>
      {loadingScreen ? (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Text>Loading...</Text>
        </View>
      ) : loading === undefined ? (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Text>Loading...</Text>
        </View>
      ) : loading ? (
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Text style={{ fontSize: 15 }}>
            Sync Notifications to receive updates
          </Text>

          <Pressable
            style={{
              backgroundColor: "#00cc00",
              borderRadius: 5,
              height: 30,
              width: 100,
              marginTop: 10,
              justifyContent: "center"
            }}
            onPress={() => updateNotification()}
          >
            <Text
              style={{
                textAlign: "center",
                fontSize: 12,
                fontSize: 15,
                color: "#fff"
              }}
            >
              {loadingBtn ? "Loading..." : "Update now"}
            </Text>
          </Pressable>
        </View>
      ) : (
        <View style={{ flex: 1 }}>
          {Platform.OS !== "web" &&
            releaseVersionNum !== undefined &&
            releaseVersionNum !== currentIOSVersion && (
              <View
                style={{
                  marginTop: 10,
                  marginHorizontal: 20,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Text
                  style={{ fontWeight: "700", fontSize: 18, color: "#00b359" }}
                >
                  New version available{" "}
                </Text>
                <Pressable
                  style={{
                    backgroundColor: "#00b359",
                    borderRadius: 5,
                    height: 25,
                    width: 100,
                    marginTop: 10,
                    justifyContent: "center"
                  }}
                  onPress={() => {
                    Platform.OS === "android"
                      ? Linking.openURL(
                          "https://play.google.com/store/apps/details?id=com.nursd.NursdFacilityManager&hl=en_US"
                        )
                      : Linking.openURL(
                          "https://apps.apple.com/in/app/nursdfacilitymanager/id6483807860"
                        );
                  }}
                >
                  <Text
                    style={{
                      textAlign: "center",
                      fontSize: 12,
                      fontSize: 15,
                      color: "#fff"
                    }}
                  >
                    Update now
                  </Text>
                </Pressable>
              </View>
            )}
          {/* <TouchableOpacity
              onPress={() => {
                socket?.emit("tableNotificationInsert",
                  {
                    table: "NurseNotificationTable",
                    id: userId
                  })
              }}>
              <Text>Post</Text>
            </TouchableOpacity> */}
          <View
            style={{
              justifyContent: "flex-end",
              display: "flex",
              flexDirection: "row"
            }}
          >
            <MaterialIcons
              onPress={() =>
                dashboardView === "Location Hub"
                  ? setDashboardView("Private")
                  : setDashboardView("Location Hub")
              }
              name={
                dashboardView === "Location Hub"
                  ? "switch-left"
                  : "switch-right"
              }
              size={24}
              color="black"
              style={{ marginRight: 20 }}
            />
          </View>
          {dashboardView === "Private" ? (
            <View style={styles.container}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginTop: 10
                }}
              >
                {appJobType === "Shift" ||
                  (appJobType === "Both" && (
                    <TouchableOpacity
                      style={{
                        backgroundColor: jobType === "shift" ? "#000" : "#fff",
                        width: "40%",
                        alignItems: "center",
                        paddingVertical: 5,
                        borderRadius: 5,
                        borderWidth: 0.5
                      }}
                      activeOpacity={0.5}
                      onPress={() => setJobType("shift")}
                    >
                      <Text
                        style={{ color: jobType === "shift" ? "#fff" : "#000" }}
                      >
                        Shift Jobs{" "}
                      </Text>
                    </TouchableOpacity>
                  ))}
                {appJobType === "Visit" ||
                  (appJobType === "Both" && (
                    <TouchableOpacity
                      style={{
                        backgroundColor: jobType === "visit" ? "#000" : "#fff",
                        width: "40%",
                        alignItems: "center",
                        paddingVertical: 5,
                        borderRadius: 5,
                        borderWidth: 0.5
                      }}
                      activeOpacity={0.5}
                      onPress={() => setJobType("visit")}
                    >
                      <Text
                        style={{ color: jobType === "visit" ? "#fff" : "#000" }}
                      >
                        Visit Jobs{" "}
                      </Text>
                    </TouchableOpacity>
                  ))}
              </View>
              {appJobType === "Both" &&
                (jobType === "shift" ? (
                  <ShiftScreen props={props} />
                ) : (
                  <VisitScreen props={props} />
                ))}
              {appJobType === "Shift" && <ShiftScreen props={props} />}
              {appJobType === "Visit" && <VisitScreen props={props} />}
            </View>
          ) : (
            <OrganizationJobView props={props} />
          )}
        </View>
      )}
    </View>
  );
};

export default HomeScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff"
  }
});
