import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  Dimensions,
  TouchableOpacity,
  Modal,
  Image,
  KeyboardAvoidingView,
  TextInput,
  Alert,
} from "react-native";
import { useEffect, useState } from "react";
import { Platform } from "react-native";
import moment from "moment";
import { Linking } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { sendNewPushNotification } from "../../utils/notification";
import { getReferenceDetails, updateReference } from "../../api_url/reference";
import { getNurseDetails } from "../../api_url/nurseTable";
import { useAuth } from "../../context/AuthContext";
import { postNurseNotification } from "../../api_url/nurseNotification";
import { socket } from "../../api_url/socket";

const ReferenceScreen = (props) => {
  const { width, height } = Dimensions.get("window");

  const { authToken, userId, userUniqueId } = useAuth();
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const id = props !== undefined && props?.route?.params?.data?.id;

  const [userDocument, setUserDocument] = useState(undefined);
  const [noShowModel, setNoShowModel] = useState(false);
  const [noShowMessage, setNoShowMessage] = useState("");
  const [referenceView, setReferenceView] = useState({
    fullName: "",
    relationship: "",
    position: "",
    reference_date_complete: "",
    email: "",
    contact_number: "",
    verified: false,
    reviewerComments: false,
    referenceFile: "",
    reference_with_your_work_org: "",
    document_status: "",
  });

  const downloadAndOpenPDF = async (name) => {
    Linking.openURL(name);
  };

  useEffect(() => {
    setReferenceView({
      ...referenceView,
      fullName: userDocument?.fullName,
      relationship: userDocument?.relationship,
      position: userDocument?.position,
      reference_date_complete: moment(
        new Date(userDocument?.reference_date_complete)
      ),
      email: userDocument?.email,
      contact_number: userDocument?.contact_number,
      verified: userDocument?.verified,
      reviewerComments: userDocument?.reviewerComments,
      referenceFile: userDocument?.referenceFile,
      reference_with_your_work_org: userDocument?.reference_with_your_work_org,
      document_status: userDocument?.document_status,
    });
  }, [userDocument]);

  const updateData = async (value, status, message) => {
    if (noShowMessage === "") {
      Alert.alert("Fill the verification note");
    } else {
      try {
        const res = await updateReference(
          authToken,
          userDocument?.id,
          {
            document_status: status,
            verified: value,
            reviewerComments: message
          }
        )
        const data = await getNurseDetails(authToken, res.nurseTableID);
        setNoShowMessage("");
        setNoShowModel(false);
        await postNurseNotification(
          authToken,
          res.nurseTableID,
          "",
          "Reference Verification Status",
          "Your Reference is" + ` ${status}`,
          "MyReferenceScreen",
          {},
          false,
          false,
          "green",
          ""
        );
        socket?.emit("tableNotificationInsert",
          {
            table: "NurseNotificationTable",
            id: res.nurseTableID
          })
        if (data?.mobileId) {
          sendNewPushNotification({
            expoPushToken: data?.mobileId,
            job: "Reference Verification Status",
            typeMessage: "Your Reference is" + ` ${status}`,
            screen: "MyReferenceScreen",
          });
        }
        setNoShowMessage("");
        setNoShowModel(false);
        check(id);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const check = async (id) => {
    setLoading(true);
    try {
      const data = await getReferenceDetails(authToken, id);
      setUserDocument(data);
      setLoading(false);
    } catch (error) {
      setUserDocument(null);
    }
  };

  useEffect(() => {
    check(id);
  }, [id]);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={styles.container}
    >
      {loading ? (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Text>Loading...</Text>
        </View>
      ) : (
        <ScrollView showsVerticalScrollIndicator={false} bounces={false}>
          <View style={styles.container}>
            <View
              style={{
                justifyContent: "center",
                marginHorizontal: 20,
                marginTop: 5,
              }}
            >
              <View
                style={{
                  marginVertical: 5,
                  justifyContent: "center",
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  borderBottomWidth: 1.5,
                  borderBottomColor: "#f2f2f2",
                }}
              >
                <Text
                  style={{ color: "#1a1a1a", fontWeight: "500", fontSize: 15 }}
                >
                  Reference Name
                </Text>
                <Text style={{ color: "#595959", fontSize: 12, marginTop: 5 }}>
                  {referenceView?.fullName}
                </Text>
              </View>

              <View
                style={{
                  marginVertical: 5,
                  justifyContent: "center",
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  borderBottomWidth: 1.5,
                  borderBottomColor: "#f2f2f2",
                }}
              >
                <Text
                  style={{ color: "#1a1a1a", fontWeight: "500", fontSize: 15 }}
                >
                  Reference Relationship
                </Text>
                <Text style={{ color: "#595959", fontSize: 12, marginTop: 5 }}>
                  {referenceView?.relationship}
                </Text>
              </View>

              <View
                style={{
                  marginVertical: 5,
                  justifyContent: "center",
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  borderBottomWidth: 1.5,
                  borderBottomColor: "#f2f2f2",
                }}
              >
                <Text
                  style={{ color: "#1a1a1a", fontWeight: "500", fontSize: 15 }}
                >
                  Reference Email
                </Text>
                <Text style={{ color: "#595959", fontSize: 12, marginTop: 5 }}>
                  {referenceView?.email}
                </Text>
              </View>

              <View
                style={{
                  marginVertical: 5,
                  justifyContent: "center",
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  borderBottomWidth: 1.5,
                  borderBottomColor: "#f2f2f2",
                }}
              >
                <Text
                  style={{ color: "#1a1a1a", fontWeight: "500", fontSize: 15 }}
                >
                  Reference Contact Number
                </Text>
                <Text style={{ color: "#595959", fontSize: 12, marginTop: 5 }}>
                  {referenceView?.contact_number}
                </Text>
              </View>

              {referenceView.referenceFile && (
                <View
                  style={{
                    marginVertical: 5,
                    justifyContent: "center",
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Text
                      style={{
                        color: "#1a1a1a",
                        fontWeight: "500",
                        fontSize: 15,
                      }}
                    >
                      Reference File
                    </Text>
                  </View>

                  <TouchableOpacity
                    style={{
                      backgroundColor: "#f2f2f2",
                      borderRadius: 5,
                      elevation: 1,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 10,
                      justifyContent: "space-between",
                    }}
                    onPress={() =>
                      downloadAndOpenPDF(referenceView.referenceFile)
                    }
                  >
                    <Text
                      style={{
                        marginVertical: 10,
                        fontSize: 12,
                        color: "#8888",
                        marginHorizontal: 10,
                      }}
                    >
                      View the file
                    </Text>
                  </TouchableOpacity>
                </View>
              )}

              {referenceView?.reviewerComments && (
                <View
                  style={{
                    marginVertical: 5,
                    justifyContent: "center",
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    borderBottomWidth: 1.5,
                    borderBottomColor: "#f2f2f2",
                  }}
                >
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontWeight: "500",
                      fontSize: 15,
                    }}
                  >
                    Notes
                  </Text>
                  <Text
                    style={{ color: "#595959", fontSize: 12, marginTop: 5 }}
                  >
                    {referenceView?.reviewerComments}
                  </Text>
                </View>
              )}

              {referenceView?.document_status === "waiting" ? (
                <TouchableOpacity
                  activeOpacity={0.5}
                  style={{
                    backgroundColor: "#2775BD",
                    borderRadius: 5,
                    padding: 10,
                    marginHorizontal: 10,
                    marginTop: 5,
                  }}
                  onPress={() => setNoShowModel(true)}
                >
                  <Text style={{ color: "white", textAlign: "center" }}>
                    Verify
                  </Text>
                </TouchableOpacity>
              ) : (
                <View
                  activeOpacity={0.5}
                  style={{
                    backgroundColor:
                      referenceView?.document_status === "Approved"
                        ? "#00b359"
                        : referenceView?.document_status === "Not Approved"
                          ? "red"
                          : "gray",
                    borderRadius: 5,
                    marginHorizontal: 10,
                    padding: 10,
                  }}
                >
                  <Text style={{ color: "white", textAlign: "center" }}>
                    {referenceView?.document_status}
                  </Text>
                </View>
              )}
            </View>

            <Modal
              animationType="fade"
              transparent={true}
              visible={noShowModel}
              onRequestClose={() => setNoShowModel(false)}
            >
              <View
                style={{
                  height: height,
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
              >
                <View
                  style={{
                    backgroundColor: "white",
                    paddingVertical: 15,
                    marginTop: 100,
                    width: 300,
                    justifyContent: "center",
                    elevation: 5,
                    paddingHorizontal: 10,
                    borderRadius: 15,
                    borderColor: "rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <View>
                    <Text
                      style={{
                        marginBottom: 5,
                        fontSize: 12,
                        color: "#737373",
                        marginHorizontal: 10,
                      }}
                    >
                      Verification Note <Text style={{ color: "red" }}>*</Text>
                    </Text>

                    <View
                      style={{
                        backgroundColor: "#f2f2f2",
                        borderRadius: 10,
                        elevation: 1,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        borderWidth: 0.5,
                        borderColor: "#e6e6e6",
                        marginHorizontal: 10,
                        paddingRight: 10,
                        justifyContent: "space-between",
                      }}
                    >
                      <TextInput
                        style={{
                          width: "90%",
                          padding: 2,
                          paddingLeft: 5,
                          fontSize: 12,
                          color: "#737373",
                        }}
                        multiline
                        onChangeText={(text) => setNoShowMessage(text)}
                        value={noShowMessage}
                        keyboardType={"default"}
                        autoCapitalize="sentences"
                        placeholderTextColor="#b3b3b3"
                        placeholder={"Verification Message"}
                      />
                      <View
                        style={{
                          height: 40,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        {noShowMessage ? (
                          <TouchableOpacity
                            onPress={() => setNoShowMessage("")}
                            style={{ marginLeft: 3 }}
                          >
                            <Ionicons
                              name="close-sharp"
                              size={22}
                              color="#808080"
                            />
                          </TouchableOpacity>
                        ) : null}
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      flexDirection: "row",
                      marginTop: 20,
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        setNoShowModel(false);
                        setNoShowMessage("");
                      }}
                      style={{
                        backgroundColor: "#2775BD",
                        paddingHorizontal: 10,
                        paddingVertical: 5,
                        borderRadius: 10,
                        marginHorizontal: 10,
                      }}
                    >
                      <Text
                        style={{
                          textAlign: "right",
                          fontSize: 12,
                          color: "#fff",
                        }}
                      >
                        Cancel
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() =>
                        updateData(false, "Not Approved", noShowMessage)
                      }
                      style={{
                        backgroundColor: "red",
                        paddingHorizontal: 10,
                        paddingVertical: 5,
                        borderRadius: 8,
                        marginRight: 10,
                      }}
                    >
                      <Text
                        style={{
                          textAlign: "right",
                          fontSize: 12,
                          color: "#fff",
                        }}
                      >
                        Not Approved
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() =>
                        updateData(true, "Approved", noShowMessage)
                      }
                      style={{
                        backgroundColor: "#00b359",
                        paddingHorizontal: 10,
                        paddingVertical: 5,
                        borderRadius: 10,
                      }}
                    >
                      <Text
                        style={{
                          textAlign: "right",
                          fontSize: 12,
                          color: "#fff",
                        }}
                      >
                        Approve
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </Modal>
          </View>
        </ScrollView>
      )}
    </KeyboardAvoidingView>
  );
};

export default ReferenceScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    justifyContent: "center",
  },
});
