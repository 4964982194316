import { createContext, useState, useContext, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Platform } from "react-native";

const AuthContext = createContext({});

export const AuthContextProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userUniqueId, setUserUniqueId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      console.log("Calling fetch data")
      if (Platform.OS === "web") {
        const storedToken = localStorage.getItem("authToken");
        const storeUserId = localStorage.getItem("userId");
        const storeUserUniqueId = localStorage.getItem("userUniqueId");
        if (storedToken) {
          setAuthToken(storedToken);
          setUserId(storeUserId);
          setUserUniqueId(storeUserUniqueId);
        }
      } else {
        const storedToken = await AsyncStorage.getItem("authToken");
        const storeUserId = await AsyncStorage.getItem("userId");
        const storeUserUniqueId = await AsyncStorage.getItem("userUniqueId");
        if (storedToken) {
          setAuthToken(storedToken);
          setUserId(storeUserId);
          setUserUniqueId(storeUserUniqueId);
        }
      }
    }
    fetchData()
  }, []);

  const updateAuthToken = async (newToken, userId, userUniqueId) => {
    // console.log("updateAuthToken", newToken, userId, userUniqueId)
    if (Platform.OS === "web") {
      localStorage.setItem("authToken", newToken);
      localStorage.setItem("userId", userId);
      localStorage.setItem("userUniqueId", userUniqueId);
      setAuthToken(newToken);
      setUserId(userId);
      setUserUniqueId(userUniqueId);
    }
    else {
      await AsyncStorage.setItem("authToken", newToken);
      await AsyncStorage.setItem("userId", userId);
      await AsyncStorage.setItem("userUniqueId", userUniqueId);
      setAuthToken(newToken);
      setUserId(userId);
      setUserUniqueId(userUniqueId);
    }
  };

  const removeAuthToken = async () => {
    if (Platform.OS === "web") {
      localStorage.removeItem("authToken");
      localStorage.removeItem("userId");
      localStorage.removeItem("userUniqueId");
      setAuthToken(null);
      setUserId(null);
      setUserUniqueId(null);
    } else {
      await AsyncStorage.removeItem("authToken");
      await AsyncStorage.removeItem("userId");
      await AsyncStorage.removeItem("userUniqueId");
      setAuthToken(null);
      setUserId(null);
      setUserUniqueId(null);
    }
  };

  return (
    <AuthContext.Provider
      value={{ authToken, userId, userUniqueId, updateAuthToken, removeAuthToken }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
