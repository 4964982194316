import { getNurseDetails } from "../../api_url/nurseTable";
import { useState, useEffect } from "react";
import { Text } from "react-native";
import { TouchableOpacity } from "react-native";
import { View } from "react-native";
import { Image } from "react-native";

const NurseData = ({ id, authToken, onUserDetailNavigate, color, fontSize, small, userUniqueId }) => {
  const [nurse, setNurse] = useState(undefined);

  const getNurseData = async (id) => {
    try {
      const nurseData = await getNurseDetails(authToken, id)
      setNurse(nurseData);
    } catch (error) {
      console.error("Error fetching nurse data:", error);
    }
  };

  useEffect(() => {
    const nurseId = id;
    getNurseData(nurseId);
  }, []);

  return (
    <View>
      {
        nurse === undefined ?
          <Text>Loading...</Text>
          :
          <TouchableOpacity
            style={{
              flexDirection: "row",
              marginLeft: 3,
              marginBottom: 5
            }}
            onPress={() => onUserDetailNavigate({
              id: nurse?.uniqueId,
              facilityId: userUniqueId,
            })}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flex: 1,
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderWidth: 1,
                  borderRadius: 10,
                  borderColor: "#2775BD",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  width: small ? 30 : 40,
                  height: small ? 30 : 40,
                  overflow: "hidden",
                }}
              >
                {nurse?.profileImage !== "" ? (
                  <Image
                    source={{
                      uri: nurse?.profileImage
                    }}
                    style={{
                      width: small ? 45 : 65,
                      height: small ? 45 : 65,
                      borderRadius: 10,
                    }}

                  />
                ) : (
                  <Image
                    source={require("../../../assets/images/icon.jpg")}
                    style={{ width: small ? 45 : 65, height: small ? 45 : 65, }}
                  />
                )}
              </View>
              <View
                style={{
                  display: "flex",
                  flex: 1,
                  marginLeft: 5
                }}
              >
                <Text
                  style={{
                    color: color ? color : "#2775BD",
                    fontSize: fontSize ? fontSize : 14,
                    fontWeight: "700"
                  }}>{nurse?.firstName + " " + nurse?.lastName}
                </Text>
                <Text style={{ color: color ? color : "#000", fontWeight: "700", fontSize: 12 }}>
                  {nurse?.primaryLicenseType}
                </Text>
              </View>
            </View>
          </TouchableOpacity>
      }
    </View>

  );
};

export default NurseData;
