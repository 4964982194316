import { View, Text, TouchableOpacity, Platform } from "react-native";
import React, { useState, useEffect } from "react";
import TimeOffCard from "./Card/TimeOffCard";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { getFacilityAllTimeOff } from "../../../api_url/timeoffFacility";
import { useAuth } from "../../../context/AuthContext";
import { ChatState } from "../../../context/ChatContext";

const TimeOffScreen = (props) => {

  const { authToken, userId, userUniqueId } = useAuth();
  const { fetchAgainTimeOffList, setFetchAgainTimeOffList } = ChatState();

  const [timeOff, setTimeOff] = useState(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userId !== undefined) {
      getFacilityTimeOff(userId);
    }
  }, [userId, fetchAgainTimeOffList]);

  //Get Jobs List in Selected Nurse
  const getFacilityTimeOff = async (id) => {
    const itemArr = await getFacilityAllTimeOff(authToken, id)

    setTimeOff(itemArr?.sort((a, b) => new Date(b.startDate) - new Date(a.startDate)));
    setLoading(false);
  };

  const onEditJobNavigate = (d) => {
    props?.navigation.navigate("AddTimeOffScreen", {
      data: d,
    });
  };

  const onAddJobNavigate = () => {
    props?.navigation.navigate("AddTimeOffScreen");
  };

  const onRequestJobNavigate = () => {
    props?.navigation.navigate("ManagePTO");
  };

  const [dateSort, setDateSort] = useState(null);

  if (dateSort) {
    timeOff?.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
  } else {
    timeOff?.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
  }

  return (
    <View style={{ flex: 1, backgroundColor: "#fff" }}>
      {loading ? (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Text>Loading...</Text>
        </View>
      ) : (
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: Platform.OS === "ios" ? 30 : 50,
          }}
        >
          <View
            style={{
              marginHorizontal: 20,
              marginTop: 10,
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <TouchableOpacity
              style={{
                borderWidth: 0.5,
                borderColor: "#e6e6e6",
                backgroundColor: "#f2f2f2",
                borderRadius: 10,
                elevation: 1,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingHorizontal: 15,
                paddingVertical: 5,
                marginRight: 10,
              }}
              onPress={() => onRequestJobNavigate()}
            >
              <Text
                style={[
                  {
                    fontSize: 12,
                    color: "#000",
                  },
                ]}
              >
                Request for Nurse
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                borderWidth: 0.5,
                borderColor: "#e6e6e6",
                backgroundColor: "#f2f2f2",
                borderRadius: 10,
                elevation: 1,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingHorizontal: 15,
                paddingVertical: 5,
              }}
              onPress={() => onAddJobNavigate()}
            >
              <Text
                style={[
                  {
                    fontSize: 12,
                    color: "#000",
                  },
                ]}
              >
                Add Time Off
              </Text>
            </TouchableOpacity>
            {timeOff?.length !== 0 && <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                marginLeft: 5,
              }}
              onPress={() => setDateSort(!dateSort)}
              activeOpacity={0.5}
            >
              <Text>Date Sort</Text>

              <MaterialCommunityIcons name="sort" size={22} color="#595959" />
            </TouchableOpacity>}
          </View>
          {timeOff?.length !== 0 ? (
            timeOff?.map((element, index) => {
              return (
                <TimeOffCard
                  key={element?.id}
                  element={element}
                  onEditJobNavigate={onEditJobNavigate}
                />
              );
            })
          ) : (
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <Text>Empty List</Text>
            </View>
          )}
        </View>
      )}
    </View>
  );
};

export default TimeOffScreen;
