import { API_URL } from "./config";

export async function getOpenJobs(authToken, userId, org) {
  if (!authToken) {
    return;
  }

  const res = await fetch(
    `${API_URL}/nurse-dashboard/open/list/${org}/${userId}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json"
      }
    }
  );
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getCompletedJobs(authToken, userId) {
  if (!authToken) {
    return;
  }

  const res = await fetch(
    `${API_URL}/nurse-dashboard/completed/list/${userId}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json"
      }
    }
  );
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getUpComingJobs(authToken, userId) {
  if (!authToken) {
    return;
  }
  // console.log(`${API_URL}/nurse-dashboard/upcoming/data/list/${userId}`)
  const res = await fetch(
    `${API_URL}/nurse-dashboard/upcoming/data/list/${userId}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json"
      }
    }
  );
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getMyPayJobs(authToken, userId, type) {
  if (!authToken) {
    return;
  }
  // console.log(`${API_URL}/nurse-dashboard/upcoming/data/list/${userId}`)
  const res = await fetch(`${API_URL}/nurse-dashboard/${type}/${userId}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json"
    }
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getAcceptJobDetail(authToken, id) {
  if (!authToken) {
    return;
  }
  // console.log(`${API_URL}/nurse-dashboard/accept/job/${id}`)
  const res = await fetch(`${API_URL}/nurse-dashboard/accept/job/${id}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json"
    }
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}
