import {
  View,
  Text,
  TouchableOpacity,
  Image,
  Dimensions,
  Animated,
  Easing
} from "react-native";
import React, { useState, useEffect, useRef } from "react";
import { Ionicons } from "@expo/vector-icons";
import moment from "moment";
import { getNurseUniqueIdDetails } from "../../../api_url/nurseTable";
import { getLastMessage } from "../../../api_url/chatHistoryTable";
import { getFacilityUniqueIdDetails } from "../../../api_url/facilityTable";
import { ChatState } from "../../../context/ChatContext";
// import { API_URL } from "../../../api_url/config";
// import io from "socket.io-client";

const { width, height } = Dimensions.get("window");
const SCREEN_WIDTH = width < height ? width : height;

const ChatCardNurse = ({ userId, userUniqueId, authToken, element, onChatRoomNavigate }) => {

  const { setSelectedChatRoom } = ChatState();

  const [lastMessage, setLastMessage] = useState(undefined);
  const [nurseData, setNurseData] = useState(undefined);
  // const [facilityData, setFacilityData] = useState(undefined);

  useEffect(() => {
    if (element?.chatHistoryTableNurseTableId) {
      getNurseDetail(element?.chatHistoryTableNurseTableId)
      getLastMessageDetail(element?.chatHistoryTableLastMessageItemId)
      // getFacilityDetail(element?.chatHistoryTableFacilityTableId)
    }
  }, [element])

  const getLastMessageDetail = async (id) => {
    try {
      await getLastMessage(authToken, id).then(res => {
        setLastMessage(res);
      })
    } catch (error) {
      console.log(error);
    }
  };

  const getNurseDetail = async (id) => {
    try {
      await getNurseUniqueIdDetails(authToken, id).then(res => {
        setNurseData(res[0]);
      })
    } catch (error) {
      console.log(error);
    }
  };

  // var socket, selectedChatCompare;
  // socket = io(API_URL);

  // useEffect(() => {
  //   if (facilityData) {
  //     socket?.emit("setup", facilityData);
  //     socket?.on("connected", () => console.log("connected", true));
  //   }
  // }, [facilityData])

  // useEffect(() => {
  //   socket?.on("message received", (newMessageReceived) => {
  //     if (element?.id !== newMessageReceived?.chathistorytableID) {
  //       console.log("Notification", element?.id, newMessageReceived?.chathistorytableID)
  //       //Notification
  //     } else {
  //       setLastMessage(newMessageReceived);
  //     }
  //   });
  // })

  // Facility Detail
  // const getFacilityDetail = async (id) => {
  //   try {
  //     await getFacilityUniqueIdDetails(authToken, id).then(res => {
  //       setFacilityData(res[0]);
  //     })
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  let currentDate = moment().format("MM-DD-YYYY");

  function isValidDateFormat(dateString) {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    return regex.test(dateString);
  }

  const opacity = useRef(new Animated.Value(0)).current;

  const startAnimation = () => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(opacity, {
          toValue: 1,
          duration: 1000,
          easing: Easing.ease,
          useNativeDriver: true,
        }),
        Animated.timing(opacity, {
          toValue: 0.4,
          duration: 1000,
          easing: Easing.ease,
          useNativeDriver: true,
        }),
      ]),
    ).start();
  };

  useEffect(() => {
    if (lastMessage?.receiver === "" && lastMessage?.sender !== userUniqueId) {
      startAnimation();
    }
  }, [lastMessage]); // Run the animation

  return (
    <View
      style={{
        marginBottom: element.id === 100 ? 90 : 0,
      }}
    >
      <TouchableOpacity
        activeOpacity={0.5}
        onPress={() => {
          setSelectedChatRoom(element);
          onChatRoomNavigate({
            chatRoomId: element?.id,
            nurseId: element?.chatHistoryTableNurseTableId,
            facilityId: element?.chatHistoryTableFacilityTableId
          })
        }}
        style={{
          display: "flex",
          flexDirection: "row",
          width: SCREEN_WIDTH,
          justifyContent: "center",
          alignItems: "center",
          padding: 10,
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            borderWidth: 1,
            borderRadius: 10,
            borderColor: "#2775BD",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            width: 45,
            height: 45,
            overflow: "hidden",
          }}
        >
          {nurseData?.profileImage ? (
            <Image
              source={{
                uri: nurseData?.profileImage
              }}
              style={{
                width: 43,
                height: 43,
                borderRadius: 10,
              }}

            />
          ) : (
            <Image
              source={require("../../../../assets/images/icon.jpg")}
              style={{ width: 90, height: 90 }}
            />
          )}
        </View>
        <View
          style={{
            marginLeft: 10,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Text>
              {nurseData?.firstName} {nurseData?.lastName}
            </Text>
            {/* <Text>{element?.id}</Text> */}
            <Text style={{ color: "#8d8d8d", fontWeight: "600", fontSize: 12 }}>
              {moment(lastMessage?.createdAt).format("MM-DD-YYYY") === currentDate
                ? moment(lastMessage?.createdAt).toNow() === "in a few seconds"
                  ? "few seconds ago"
                  : moment(lastMessage?.createdAt).calendar()
                : isValidDateFormat(moment(lastMessage?.createdAt).calendar())
                  ? moment(lastMessage?.createdAt).calendar() +
                  " " +
                  moment(lastMessage?.createdAt).format("hh:mm a")
                  : moment(lastMessage?.createdAt).calendar()}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 5,
            }}
          >
            <Text
              numberOfLines={1}
              style={{ color: "gray", paddingRight: 10, flex: 1 }}
            >
              {lastMessage?.text}
            </Text>
            {lastMessage?.sender === userUniqueId ?
              (lastMessage?.status === "Sent" ? (
                <Ionicons
                  name={"checkmark"}
                  size={16}
                  color="gray"
                  style={{ marginHorizontal: 5 }}
                />
              ) : (
                <Ionicons
                  name="checkmark-done"
                  size={16}
                  color="blue"
                  style={{ marginHorizontal: 5 }}
                />
              )) :
              lastMessage?.receiver === userUniqueId
                ?
                null :
                <Animated.View
                  style={{
                    backgroundColor: '#00b359',
                    borderRadius: 100,
                    width: 12,
                    height: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    opacity,
                  }}
                />
            }
          </View>
        </View>
      </TouchableOpacity>
      <View
        style={{
          borderWidth: 1,
          borderStyle: "dashed",
          borderColor: "lightgray",
          marginHorizontal: 5,
        }}
      />
    </View>
  );
};

export default ChatCardNurse;
