import React, { useEffect, useState } from "react";
import { Alert, Text, View, TouchableOpacity, Platform } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import AppFacilityContainer from "./facilityNavigationAPI";
import { useAuth } from "../context/AuthContext";
import { getFacilityDetails } from "../api_url/facilityTable";
import { socket } from "../api_url/socket";

function AppView() {
  const { authToken, userId, removeAuthToken, userUniqueId } = useAuth();

  const [user, setUser] = useState(undefined);
  const [loadingScreen, setLoadingScreen] = useState(true);

  useEffect(() => {
    setUser(undefined);
    if (authToken && userId) {
      getFacilityDetails(authToken, userId)
        .then((res) => {
          setUser(res);
          setLoadingScreen(false);
        })
        .catch((e) => {
          if (e) {
            setUser(null);
            setLoadingScreen(false)
          }
        });
    } else {
      setLoadingScreen(false)
    }
  }, [authToken, removeAuthToken, userId]);

  useEffect(() => {
    socket?.on("activateDeactivateEvent", (data) => {
      // console.log(data?.id);
      if (data?.table === "FacilityTable" &&
        data?.id == userId) {
        getFacilityDetails(authToken, userId)
          .then((res) => {
            setUser(res);
          })
          .catch((e) => {
            if (e) {
              setUser(null);
            }
          });
      }
    });
  }, [socket]);

  return (
    <SafeAreaProvider>
      <View style={{ flex: 1 }}>
        {loadingScreen ? (
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              backgroundColor: "#fff",
              alignItems: "center",
            }}
          >
            <Text>Loading...</Text>
          </View>
        ) : user !== undefined && user !== null ? (
          (user?.facilityLoginControl ?
            <AppFacilityContainer />
            :
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                backgroundColor: "#fff",
                alignItems: "center",
              }}
            >
              <Text style={{ color: "red" }}>
                Your access is restricted
              </Text>
              <Text
                style={{
                  color: "#2775BD",
                  fontWeight: "500",
                  marginTop: 5,
                }}
              >
                Please contact your admin
              </Text>

              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: "#00b359",
                  paddingHorizontal: 20,
                  paddingVertical: 7,
                  borderRadius: 5,
                  marginTop: 10,
                }}
                activeOpacity={0.5}
                onPress={() =>
                  Platform.OS === "web" ?
                    confirm(
                      "Are you sure want to Logout?"
                    ).valueOf(true) && removeAuthToken()
                    : Alert.alert("Are you sure want to Logout?", "", [
                      {
                        text: "Yes",
                        onPress: () => {
                          removeAuthToken()
                        },
                      },
                      {
                        text: "No",
                        style: "cancel",
                      },
                    ])
                }
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 15,
                    color: "#fff",
                  }}
                >
                  Log Out
                </Text>
              </TouchableOpacity>
            </View>)
        ) : (
          null
        )}
      </View>
    </SafeAreaProvider>
  );
}

export default AppView;