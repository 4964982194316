import React, { useState } from "react";
import { KeyboardAvoidingView, Platform, Alert } from "react-native";
import { View, Text, StyleSheet, Dimensions } from "react-native";
import AuthButton from "../../../customComponents/Button/AuthButton";
import CustomInput from "../../../customComponents/Input/CustomInput";
import TitleText from "../../../customComponents/Text/TitleText";
import { forgetPasswordChange } from "../../../api_url/authFacility";

const ChangePassword = ({ setContainerScreen, forgotPasswordEmail }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [state, setState] = useState({
    code: "",
    codeFocus: false,
    password: "",
    passwordFocus: false,
    conPassword: "",
    conPasswordFocus: false,
  });

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/;
    return passwordRegex.test(password);
  };

  const changePassword = async (code, newPassword) => {
    if (
      code === "" ||
      newPassword === ""
    ) {
      Alert.alert("Enter the fields");
    } else {
      setLoading(true);
      if (loading) {
        return;
      }
      try {
        await forgetPasswordChange({
          email: forgotPasswordEmail,
          emailToken: code,
          password: newPassword
        })
          .then(async res => {
            // console.log("res", res)
            Alert.alert("Success", "Password changed.")
            setContainerScreen("");
            setError(undefined)
          }).catch(e => {
            Alert.alert("Warning", e?.message)
            setError(e?.message)
          });
      } catch (error) {
        console.log("error confirm :", error);
      }
      setLoading(false);
    }
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : ""}
      style={styles.container}
    >
      <View style={{ alignItems: "center" }}>
        <TitleText text={"Reset Password"} />
        <Text>{forgotPasswordEmail}</Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10,
            marginHorizontal: 20,
          }}
        >
          <CustomInput
            value={state.code}
            label={"Code"}
            placeholder="Enter Code"
            keyboardType={"default"}
            onChangeText={(text) => setState({ ...state, code: text })}
            clearValue={() => setState({ ...state, code: "" })}
            viewStyle={{
              height: 40,
              backgroundColor: "#f2f2f2",
              borderRadius: 10,
              elevation: 1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              borderWidth: 0.5,
              borderColor: "#e6e6e6",
              paddingRight: 10,
              justifyContent: "space-between",
              marginTop: 0,
            }}
            textInputStyle={{
              height: 40,
              borderRadius: 10,
              paddingLeft: 10,
              paddingRight: 5,
              fontSize: 12,
            }}
            iconStyle={{
              height: 40,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          />
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10,
            marginHorizontal: 20,
          }}
        >
          <CustomInput
            value={state.password}
            label="New Password"
            placeholder="Enter New Password"
            keyboardType={"default"}
            onChangeText={(text) => setState({ ...state, password: text })}
            clearValue={() => setState({ ...state, password: "" })}
            viewStyle={{
              height: 40,
              backgroundColor: "#f2f2f2",
              borderRadius: 10,
              elevation: 1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              borderWidth: 0.5,
              borderColor: "#e6e6e6",
              paddingRight: 10,
              justifyContent: "space-between",
              marginTop: 0,
            }}
            textInputStyle={{
              height: 40,
              borderRadius: 10,
              paddingLeft: 10,
              paddingRight: 5,
              fontSize: 12,
            }}
            iconStyle={{
              height: 40,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          />
        </View>
        <Text
          style={{
            fontSize: 8,
            textAlign: "center",
            marginHorizontal: 20,
            color: "red",
          }}
        >
          {state.password.length !== 0 &&
            !validatePassword(state?.password) &&
            "At least 8 characters with uppercase,lowercase letter,one numeric and one special characters"}
        </Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10,
            marginHorizontal: 20,
          }}
        >
          <CustomInput
            value={state.conPassword}
            label="Confirm Password"
            placeholder="Enter Confirm Password"
            keyboardType={"default"}
            onChangeText={(text) => setState({ ...state, conPassword: text })}
            clearValue={() => setState({ ...state, conPassword: "" })}
            viewStyle={{
              height: 40,
              backgroundColor: "#f2f2f2",
              borderRadius: 10,
              elevation: 1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              borderWidth: 0.5,
              borderColor: "#e6e6e6",
              paddingRight: 10,
              justifyContent: "space-between",
              marginTop: 0,
            }}
            textInputStyle={{
              height: 40,
              borderRadius: 10,
              paddingLeft: 10,
              paddingRight: 5,
              fontSize: 12,
            }}
            iconStyle={{
              height: 40,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          />
        </View>
        <Text
          style={{
            fontSize: 8,
            textAlign: "center",
            marginHorizontal: 20,
            color: "red",
          }}
        >
          {state.conPassword.length !== 0 &&
            state.conPassword !== state.password &&
            "Password and confirm password not matched"}
        </Text>
        <Text
          style={{
            fontSize: 8,
            textAlign: "center",
            marginHorizontal: 20,
            color: "red",
          }}
        >
          {error === "Incorrect username or password."
            ? "Currant password is wrong"
            : error}
        </Text>
      </View>

      <View style={{ alignItems: "center", marginTop: 10 }}>
        <AuthButton
          name={loading ? "Loading..." : "Submit"}
          disabled={
            state.code.length !== 0 &&
              state.password.length !== 0 &&
              validatePassword(state?.password) &&
              state.conPassword.length !== 0 &&
              state.conPassword === state.password
              ? false
              : true
          }
          onPress={() =>
            loading ? null : changePassword(state?.code, state?.conPassword)
          }
        />
      </View>
      <Text
        style={{
          fontSize: 12,
          textAlign: "center",
          marginTop: 15,
        }}
        onPress={() => setContainerScreen("ForgotPassword")}
      >
        Back to Forgot Password
      </Text>
    </KeyboardAvoidingView>
  );
};

export default ChangePassword;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: "#fff",
  },
});
