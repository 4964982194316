import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Alert,
  Platform,
} from "react-native";
import Ionicons from "react-native-vector-icons/Ionicons";

import moment from "moment";

const colorHospital = "#00b359";

const TimeOffCard = ({ element, onEditJobNavigate }) => {
  let currentDate = moment()

  function DateFormat(dateString) {
    const d = moment(dateString).format("MMM-DD")
    return d;
  }

  return (
    <View
      key={element?.id}
      style={{
        display: "flex",
        marginHorizontal: 15,
        marginTop: 10,
        padding: 8,
        backgroundColor: element?.status === "Approved" ? "#e6ffee" : "#ffcccc",
        borderRadius: 10,
        elevation: 1,
        shadowColor: colorHospital,
        shadowOpacity: 0.2,
        shadowRadius: 3.5,
        shadowOffset: {
          width: 0,
          height: 2,
        },
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <View
          style={{
            flex: 1,
          }}
        >
          <Text
            style={{
              fontSize: 12,
              marginTop: 5,
              color: "#1a1a1a",
              fontWeight: "600",
              marginLeft: 5,
            }}
          >
            {element?.timeOffReason}
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 5,
          }}
        >
          {DateFormat(element?.startDate) === DateFormat(element?.endDate) ? (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Ionicons name={"calendar"} color={"#666"} size={15} />
              <Text
                style={{
                  fontWeight: "bold",
                  marginVertical: 5,
                  fontSize: 10,
                  marginLeft: 3,
                  color: "#595959",
                }}
              >
                {DateFormat(element?.startDate)}
              </Text>
            </View>
          ) : (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Ionicons name={"calendar"} color={"#666"} size={15} />
              <Text
                style={{
                  fontWeight: "bold",
                  marginVertical: 5,
                  fontSize: 10,
                  marginLeft: 3,
                  color: "#595959",
                }}
              >
                {DateFormat(element?.startDate)} -
              </Text>
              <Text
                style={{
                  fontWeight: "bold",
                  marginVertical: 5,
                  fontSize: 10,
                  marginLeft: 3,
                  color: "#595959",
                }}
              >
                {DateFormat(element?.endDate)}
              </Text>
            </View>
          )}
        </View>
      </View>

      <View>
        <Text
          style={{
            fontSize: 12,
            marginTop: 5,
            color: "#888",
            fontWeight: "600",
            marginLeft: 5,
          }}
        >
          {element?.comments}
        </Text>
      </View>

      {/* {currentDate.isBefore(moment(element?.startDate)) && (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <TouchableOpacity
            onPress={() =>
              Platform.OS === "web"
                ? confirm(
                  "This will be permanently deleted, Continue?"
                ).valueOf(true) && console.log()
                : Alert.alert(
                  "This will be permanently deleted, Continue?",
                  "",
                  [
                    {
                      text: "Yes",
                      onPress: () => console.log(),
                    },
                    {
                      text: "No",
                      style: "cancel",
                    },
                  ]
                )
            }
            style={{
              backgroundColor: "red",
              paddingVertical: 3,
              paddingHorizontal: 8,
              borderRadius: 5,
            }}
          >
            <Text style={{ fontSize: 12, color: "#fff" }}>Cancel</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => onEditJobNavigate(element)}
            style={{
              backgroundColor: "#ccc",
              paddingVertical: 3,
              paddingHorizontal: 8,
              marginLeft: 10,
              borderRadius: 5,
            }}
          >
            <Text style={{ fontSize: 12 }}>Update</Text>
          </TouchableOpacity>
        </View>
      )} */}
    </View>
  );
};

export default TimeOffCard;
