import AWS from "aws-sdk"
import { Alert, Platform } from "react-native";
import { getAccessKey } from "../../api_url/accesskey";

// export const uploadFile = async (name, body, message) => {
//   // console.log(fileName, body);
//   try {

//     const params = {
//       Bucket: "nursd",
//       Key: name,
//       Body: body,
//     }


//     var promise = s3.upload(params).promise()

//     promise.then(
//       function (data) {
//         if (Platform.OS === "web") {
//           alert(message);
//         } else {
//           Alert.alert(message);
//         }
//       },
//       function (err) {
//         return console.log("err", err);
//       }
//     );
//   } catch (error) {
//     console.log("error", error);
//   }
// };

export const uploadFile = async (authToken, name, body, message) => {
  
  const getID = async () => {
    try {
      const resp = await getAccessKey(authToken, "s3", 1);
      return resp;
    } catch (error) {
      return null;
    }
  }

  const res = await getID();

  if (res) {

    AWS.config.update({
      credentials: {
        accessKeyId: res?.AWS_S3_ACCESS_KEY_ID,
        secretAccessKey: res?.AWS_S3_SECRET_ACCESS_KEY
      },
      region: res?.AWS_S3_BUCKET_REGION,
    });

    const s3 = new AWS.S3();

    try {
      const params = {
        Bucket: res?.AWS_S3_BUCKET_NAME,
        Key: name,
        Body: body,
      };

      // Wrap s3.upload call in a Promise
      return new Promise((resolve, reject) => {
        s3.upload(params, function (err, data) {
          if (err) {
            console.log("Error uploading file:", err);
            // reject(err); // Reject the promise with the error
          } else {
            // console.log("File uploaded successfully:", data);
            if (message) {
              if (Platform.OS === "web") {
                alert(message);
              } else {
                Alert.alert(message);
              }
            }
            resolve(data); // Resolve the promise with the uploaded data
          }
        });
      });
    } catch (error) {
      console.log("Error:", error);
      // throw error; // Rethrow the error if caught
    }
  }
};
