import { View, Text, TouchableOpacity, Image, Dimensions } from "react-native";
import React from "react";

const { width, height } = Dimensions.get("window");
const SCREEN_WIDTH = width < height ? width : height;

const UserCardPTO = ({
  element,
  onUserDetailNavigate,
  onChatRoomNavigate,
  onRequestJobNavigate,
  onViewTimeOffNavigate,
  userId,
  userUniqueId
}) => {
  return (
    <TouchableOpacity
      activeOpacity={0.5}
      onPress={() =>
        onUserDetailNavigate({
          id: element?.uniqueId,
          facilityId: userUniqueId
        })
      }
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: 10
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            borderWidth: 1,
            borderRadius: 10,
            borderColor: "#2775BD",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            width: 45,
            height: 45,
            overflow: "hidden"
          }}
        >
          {element?.profileImage ? (
            <Image
              source={{
                uri: element?.profileImage
              }}
              style={{
                width: 43,
                height: 43,
                borderRadius: 10
              }}
            />
          ) : (
            <Image
              source={require("../../../../assets/images/icon.jpg")}
              style={{ width: 80, height: 80 }}
            />
          )}
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            flex: 1
          }}
        >
          <View
            style={{
              marginLeft: 10,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            <View>
              <Text>
                {element?.firstName} {element?.lastName}
              </Text>
              <Text
                style={{ color: "#8d8d8d", fontWeight: "600", fontSize: 12 }}
              >
                {element?.primaryLicenseType}
              </Text>
            </View>
            {/* <TouchableOpacity
              onPress={() =>
                onChatRoomNavigate({
                   chatRoomId: undefined,
                  chatRoom: undefined,
                  nurseId: element?.uniqueId,
                  facilityId: userUniqueId,
                })
              }
              style={{
                backgroundColor: "#ddd",
                width: 80,
                height: 25,
                borderRadius: 5,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ color: "#000", fontWeight: "600", fontSize: 12 }}>
                Message
              </Text>
            </TouchableOpacity> */}
          </View>
          <View style={{ flexDirection: "row" }}>
            <TouchableOpacity
              onPress={() =>
                onViewTimeOffNavigate({
                  userId: element?.id,
                  mobileId: element?.mobileId
                })
              }
              style={{
                backgroundColor: "#ddd",
                width: 100,
                height: 25,
                borderRadius: 5,
                justifyContent: "center",
                alignItems: "center",
                marginRight: 5
              }}
            >
              <Text style={{ color: "#000", fontWeight: "600", fontSize: 12 }}>
                View Time Off
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() =>
                onRequestJobNavigate({
                  item: undefined,
                  userId: element?.id,
                  uniqueId: element?.uniqueId,
                })
              }
              style={{
                backgroundColor: "#00b359",
                width: 100,
                height: 25,
                borderRadius: 5,
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Text style={{ color: "#fff", fontWeight: "600", fontSize: 12 }}>
                Add Time Off
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>

      <View
        style={{
          borderWidth: 1,
          borderStyle: "dashed",
          borderColor: "lightgray",
          marginHorizontal: 5
        }}
      />
    </TouchableOpacity>
  );
};

export default UserCardPTO;
