import React, { useEffect, useRef, useState } from 'react'
import {
  StyleSheet,
  Platform,
  Text,
  TouchableOpacity,
  View,
  SafeAreaView,
  Dimensions
} from 'react-native';

import { GooglePlacesAutocomplete } from "react-native-google-places-autocomplete";

const GOOGLE_PLACES_API_KEY = "AIzaSyASXZSrsvL25WiY7nZc2M4WJhkDMj-jkvs"
const mapApiJs = 'https://maps.googleapis.com/maps/api/js';
const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';

// load google map api js

function loadAsyncScript(src) {
  return new Promise(resolve => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src
    })
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  })
}

const extractAddress = (place) => {
  const address = {
    street_number: "",
    route: "",
    locality: "",
    administrative_area_level_3: "",
    administrative_area_level_2: "",
    administrative_area_level_1: "",
    country: "",
    postal_code: "",
    postal_code_suffix: "",
    latitude: "",
    longitude: "",
    plain() {
      const street_number = this.street_number ? this.street_number + ", " : "";
      const route = this.route ? this.route + ", " : "";
      const locality = this.locality ? this.locality + ", " : "";
      const administrative_area_level_3 = this.administrative_area_level_3 ? this.administrative_area_level_3 + ", " : "";
      const administrative_area_level_2 = this.administrative_area_level_2 ? this.administrative_area_level_2 + ", " : "";
      const administrative_area_level_1 = this.administrative_area_level_1 ? this.administrative_area_level_1 + ", " : "";
      const country = this.country ? this.country + ", " : "";
      const postal_code = this.postal_code ? this.postal_code + ", " : "";
      const postal_code_suffix = this.postal_code_suffix ? this.postal_code_suffix + ", " : "";
      return street_number +
        route +
        locality +
        administrative_area_level_3 +
        administrative_area_level_2 +
        administrative_area_level_1 +
        country +
        postal_code +
        postal_code_suffix;
    }
  }

  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  place.address_components.forEach(component => {
    const types = component.types;
    const value = component.long_name;

    if (types.includes("street_number")) {
      address.street_number = value;
    }
    if (types.includes("route")) {
      address.route = value;
    }
    if (types.includes("locality")) {
      address.locality = value;
    }
    if (types.includes("administrative_area_level_3")) {
      address.administrative_area_level_3 = value;
    }
    if (types.includes("administrative_area_level_2")) {
      address.administrative_area_level_2 = value;
    }
    if (types.includes("administrative_area_level_1")) {
      address.administrative_area_level_1 = value;
    }
    if (types.includes("country")) {
      address.country = value;
    }
    if (types.includes("postal_code")) {
      address.postal_code = value;
    }
    if (types.includes("postal_code_suffix")) {
      address.postal_code_suffix = value;
    }

  });

  address.latitude = place?.geometry?.viewport?.Zh?.lo
  address.longitude = place?.geometry?.viewport?.Jh?.lo

  return address;
}

function AutoFillAddress({ setShowAddress, jobState, setJobState }) {

  const searchInput = useRef(null);
  const [address, setAddress] = useState({});

  const { width, height } = Dimensions.get("window");
  // init gmap script
  const initMapScript = () => {
    // if script already loaded
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${GOOGLE_PLACES_API_KEY}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  }

  // do something on address change
  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    // setAddress(extractAddress(place));
    setJobState({
      ...jobState,
      fullAddress: extractAddress(place)?.street_number + ", " +
        extractAddress(place)?.route + ", " +
        extractAddress(place)?.locality + ", " +
        extractAddress(place)?.administrative_area_level_3 + ", " +
        extractAddress(place)?.administrative_area_level_2 + ", " +
        extractAddress(place)?.administrative_area_level_1 + ", " +
        extractAddress(place)?.country + ", " +
        extractAddress(place)?.postal_code + ", " +
        extractAddress(place)?.postal_code_suffix,
      latitude: extractAddress(place)?.latitude,
      longitude: extractAddress(place)?.longitude,
    });
    setShowAddress(false);
    // console.log({
    //   fullAddress: extractAddress(place)?.street_number + ", " +
    //     extractAddress(place)?.route + ", " +
    //     extractAddress(place)?.locality + ", " +
    //     extractAddress(place)?.administrative_area_level_3 + ", " +
    //     extractAddress(place)?.administrative_area_level_2 + ", " +
    //     extractAddress(place)?.administrative_area_level_1 + ", " +
    //     extractAddress(place)?.country + ", " +
    //     extractAddress(place)?.postal_code + ", " +
    //     extractAddress(place)?.postal_code_suffix,
    //   latitude: extractAddress(place)?.latitude,
    //   longitude: extractAddress(place)?.longitude,
    // })
  }

  // init autocomplete
  const initAutocomplete = () => {
    if (!searchInput.current) return;

    const autocomplete = new window.google.maps.places.Autocomplete(searchInput.current);
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () => onChangeAddress(autocomplete));
  }

  const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
    const url = `${geocodeJson}?key=${GOOGLE_PLACES_API_KEY}&latlng=${lat},${lng}`;
    searchInput.current.value = "Getting your location...";
    fetch(url)
      .then(response => response.json())
      .then(location => {
        const place = location.results[0];
        const _address = extractAddress(place);
        // setAddress(_address);
        searchInput.current.value = _address.plain();
      })
  }

  const findMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        // reverseGeocode(position.coords)
        reverseGeocode(position.coords)
      })
    }
  }

  // load map script after mounted
  useEffect(() => {
    if (Platform.OS === "web") {
      initMapScript().then(() => initAutocomplete())
    }
  }, []);

  return (
    <>
      {
        Platform.OS === "web" ?
          <div
            style={{
              display: "flex",
              height: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
              backgroundColor: "#fff",
              paddingTop: 50,
              boxSizing: "border-box"
            }}>
            <div>
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <TouchableOpacity
                  onPress={() => setShowAddress(false)}
                  style={{
                    backgroundColor: "#00b359",
                    paddingHorizontal: 10,
                    paddingVertical: 5,
                    borderRadius: 10,
                    marginBottom: 10,
                    marginRight: 5
                  }}
                >
                  <Text
                    style={{
                      textAlign: "right",
                      fontSize: 14,
                      color: "#fff",
                    }}
                  >
                    Close
                  </Text>
                </TouchableOpacity>
              </View>
              <div
                style={{
                  position: "relative"
                }}>
                <input
                  ref={searchInput}
                  type="text"
                  placeholder="Search your address here..."
                  style={{
                    boxSizing: "border-box",
                    display: "block",
                    width: width,
                    height: 50,
                    borderRadius: 5,
                    fontSize: 16,
                    outline: "none",
                    background: "#fff"
                  }} />
                {/* <button onClick={findMyLocation}>GpsFixed</button> */}
              </div>
            </div>
          </div >
          :
          <SafeAreaView style={styles.container}>
            <View
              style={{
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <TouchableOpacity
                onPress={() => setShowAddress(false)}
                style={{
                  backgroundColor: "#00b359",
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10,
                  marginBottom: 10,
                  marginRight: 5
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff",
                  }}
                >
                  Close
                </Text>
              </TouchableOpacity>
            </View>
            <GooglePlacesAutocomplete
              placeholder="Search your address here..."
              styles={{ color: "#000" }}
              query={{
                key: GOOGLE_PLACES_API_KEY,
                language: "en", // language of the results
              }}
              minLength={2}
              autoFocus={false}
              returnKeyType={"search"}
              listViewDisplayed="auto"
              fetchDetails
              onPress={(data, details = null) => {
                if (details && details.address_components) {
                  setJobState({
                    ...jobState,
                    fullAddress: details.address_components
                      .map((value) => value.long_name)
                      .join(","),
                    latitude: details.geometry.location.lat,
                    longitude: details.geometry.location.lng,
                  });
                  setShowAddress(false);
                }
              }}
              onFail={(error) => console.error(error)}
              requestUrl={{
                url: "https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api",
                useOnPlatform: "web",
              }} // this in only required for use on the web. See https://git.io/JflFv more for details.
              nearbyPlacesAPI="GooglePlacesSearch"
              debounce={200}
            />
          </SafeAreaView>
      }

    </>
  )
}

export default AutoFillAddress

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    paddingTop: 10,
    backgroundColor: "#f1f1f1",
  },
});
