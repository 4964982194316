import { View, Text } from 'react-native'
import React from 'react'
import { useState, useEffect } from 'react'
import { TouchableOpacity } from 'react-native'
import moment from 'moment'
import { timeDifferentCard } from '../../utils/function'
import { ScrollView } from 'react-native'
import NurseData from './NurseData'
import { Alert } from 'react-native'
import { Platform } from 'react-native'
import { useAuth } from '../../context/AuthContext'

const OnDayCalenderView = (props) => {

  const { authToken, userId, userUniqueId } = useAuth();

  // console.log(props?.route?.params?.data)

  const date = props?.route?.params?.data?.date;
  const data = props?.route?.params?.data?.data;

  const [dayArray, setDayArray] = useState([])
  const [dayArrayJob, setDayArrayJobs] = useState([])

  useEffect(() => {
    if (data) {
      const nurseIdJobsMap = [];
      const normalJobs = [];

      data.forEach((item) => {
        if (item.shiftTitle !== undefined) {
          if (item.jobFinalSelectionNurseId) {
            // Nurse Id with jobs
            const nurseId = item.jobFinalSelectionNurseId;
            const nurseData = nurseIdJobsMap.find((data) => data.nurseId === nurseId);

            if (nurseData) {
              // Nurse Id already exists, add job data
              nurseData.jobData.push(item);
            } else {
              // New Nurse Id, create entry
              nurseIdJobsMap.push({
                nurseId: nurseId,
                jobData: [item],
                timeOffData: []
              });
            }
          } else {
            // Normal jobs
            normalJobs.push(item);
          }
        } else {
          if (item.nurseTableID) {
            // Time Off Nurse Id
            const nurseId = item.nurseTableID;
            const nurseData = nurseIdJobsMap.find((data) => data.nurseId === nurseId);

            if (nurseData) {
              // Nurse Id already exists, add time off data
              nurseData.timeOffData.push(item);
            } else {
              // New Nurse Id, create entry
              nurseIdJobsMap.push({
                nurseId: nurseId,
                jobData: [],
                timeOffData: [item]
              });
            }
          }
        }
      });

      // console.log("Nurse Ids with Jobs", nurseIdJobsMap);
      setDayArray(nurseIdJobsMap)
      // console.log("Normal Jobs", normalJobs);
      setDayArrayJobs(normalJobs)
    }
  }, [data]);

  const onUserDetailNavigate = (d) => {
    props.navigation.navigate("UserDetailsScreen", {
      data: d,
    });
  };

  const onJobDetailNavigate = (d) => {
    props?.navigation.navigate("JobDetailsScreen", {
      data: d,
    });
  };

  const onRequestTimeOffScreenNavigate = (d) => {
    props?.navigation.navigate("RequestTimeOffScreen", {
      data: d,
    });
  };

  const currentDate = moment();
  const webNavigate = (status, d, date) => {
    if (status === "Pending") {
      onRequestTimeOffScreenNavigate(d)
    } else {
      if (moment(date).isAfter(currentDate)) {
        if (Platform.OS !== "web") {
          Alert.alert("Do you want edit?", "", [
            {
              text: "No",
              onPress: () => console.log("Cancel Pressed"),
              style: "cancel",
            },
            {
              text: "Yes",
              onPress: () => {
                onRequestTimeOffScreenNavigate(d)
              },
            },
          ]);
        } else {
          if (confirm("Do you want edit?")) {
            onRequestTimeOffScreenNavigate(d)
          }
        }
      }

    }
  }

  return (
    <ScrollView
      style={{ backgroundColor: "#fff" }}
      showsVerticalScrollIndicator={false}>
      <View
        style={{ marginHorizontal: 5 }}>
        <Text
          style={{
            textAlign: "center",
            color: "#000",
            fontWeight: "700",
            fontSize: 15,
            marginVertical: 5,
            textDecorationStyle: "solid",
            textDecorationLine: "underline"
          }}>
          {moment(date).format("YYYY MMM-DD")}
        </Text>
        {
          (dayArray?.map((jobItem, index) => {
            return (
              <View
                key={index}
                style={{ marginVertical: 5 }}>
                <NurseData
                  id={jobItem?.nurseId}
                  authToken={authToken}
                  onUserDetailNavigate={onUserDetailNavigate}
                  userUniqueId={userUniqueId} />
                {
                  jobItem?.jobData?.map((jobDataItem, index) => {
                    return (
                      <TouchableOpacity
                        key={index}
                        onPress={() =>
                          onJobDetailNavigate({
                            id: jobDataItem?.id,
                          })
                        }
                        style={{
                          // backgroundColor: jobDataItem?.jobStatus === "Unfulfilled" ? "#888"
                          //   : jobDataItem?.jobType === "Visit" ? "#00b359" : "#2775BD",
                          backgroundColor: jobDataItem?.jobStatus === "Unfulfilled" ? "#888" :
                            (jobDataItem?.jobStatus === "Open" || jobDataItem?.jobStatus === "Pending Assignment") ? "#ffff00" :
                              (jobDataItem?.jobStatus === "Completed") ? "#00b359" :
                                (jobDataItem?.jobStatus === "Nurse Assigned" || jobDataItem?.jobStatus === "In-Progress"
                                  || jobDataItem?.jobStatus === "Pending Approval") ? "#2775BD" :
                                  (jobDataItem?.jobStatus === "Missed" || jobDataItem?.jobStatus === "Pending Clock Out") ? "red" :
                                    "#888",
                          padding: 5,
                          margin: 3,
                          borderRadius: 3
                        }}>
                        <View style={{ flexDirection: "row" }}>
                          <Text style={{ color: "#fff", fontSize: 11 }}>{moment(jobDataItem?.startTime).format("hh:mma")} - </Text>
                          <Text style={{ color: "#fff", fontSize: 11 }}>{moment(jobDataItem?.endTime).format("hh:mma")} - </Text>
                          <Text style={{ color: "#fff", fontSize: 11 }}>{timeDifferentCard(jobDataItem?.startTime, jobDataItem?.endTime)}</Text>
                        </View>
                        <Text style={{ color: "#fff", fontSize: 11, fontWeight: "600", marginTop: 3 }}>
                          Status : {jobDataItem?.jobStatus}</Text>
                        <Text style={{ color: "#fff", fontSize: 12, fontWeight: "600", marginTop: 3 }}>{jobDataItem?.shiftTitle}</Text>
                      </TouchableOpacity>
                    )
                  })
                }
                {
                  jobItem?.timeOffData?.map((timeOffItem, index) => {
                    return (
                      <TouchableOpacity
                        key={index}
                        onPress={() =>
                          webNavigate(timeOffItem?.status, {
                            userId: timeOffItem?.nurseTableID,
                            timeOffId: timeOffItem?.id,
                          }, timeOffItem?.startDate)
                        }
                        style={{
                          backgroundColor: timeOffItem?.status === "Pending" ? "#888" : "red",
                          padding: 5,
                          borderRadius: 3,
                          marginVertical: 2
                        }}>
                        {timeOffItem?.startTime === "" && timeOffItem?.endTime === "" ?
                          <View style={{ flexDirection: "row" }}>
                            <Text style={{ color: "#fff", fontSize: 11 }}>Full Day</Text>
                          </View>
                          :
                          <View style={{ flexDirection: "row" }}>
                            <Text style={{ color: "#fff", fontSize: 11 }}>{moment(timeOffItem?.startTime).format("hh:mma")} - </Text>
                            <Text style={{ color: "#fff", fontSize: 11 }}>{moment(timeOffItem?.endTime).format("hh:mma")} - </Text>
                            <Text style={{ color: "#fff", fontSize: 11 }}>{timeDifferentCard(timeOffItem?.startTime, timeOffItem?.endTime)}</Text>
                          </View>}
                        <Text style={{ color: "#fff", fontSize: 12, fontWeight: "600", marginTop: 3 }}>
                          {/* {timeOffItem?.timeOffReason} */}
                          TimeOff-{timeOffItem?.status}
                        </Text>
                      </TouchableOpacity>
                    )
                  })
                }
              </View>
            )
          }))
        }
        {dayArrayJob?.length !== 0 &&
          <Text
            style={{
              textAlign: "center",
              color: "#000",
              fontWeight: "700",
              fontSize: 15,
              marginVertical: 5,
              textDecorationStyle: "solid",
              textDecorationLine: "underline"
            }}>
            UnAssigned Jobs
          </Text>}
        {
          (dayArrayJob?.map((jobItem, index) => {
            return (
              <View key={index}>
                <TouchableOpacity
                  key={index}
                  onPress={() =>
                    onJobDetailNavigate({
                      id: jobItem?.id,
                    })
                  }
                  style={{
                    backgroundColor: jobItem?.jobStatus === "Unfulfilled" ? "#888" :
                      (jobItem?.jobStatus === "Open" || jobItem?.jobStatus === "Pending Assignment") ? "#ffff00" :
                        (jobItem?.jobStatus === "Completed") ? "#00b359" :
                          (jobItem?.jobStatus === "Nurse Assigned" || jobItem?.jobStatus === "In-Progress"
                            || jobItem?.jobStatus === "Pending Approval") ? "#2775BD" :
                            (jobItem?.jobStatus === "Missed" || jobItem?.jobStatus === "Pending Clock Out") ? "red" :
                              "#888",
                    padding: 5,
                    margin: 3,
                    borderRadius: 3
                  }}>
                  <View style={{ flexDirection: "row" }}>
                    <Text style={{ color: "#000", fontSize: 11 }}>{moment(jobItem?.startTime).format("hh:mma")} - </Text>
                    <Text style={{ color: "#000", fontSize: 11 }}>{moment(jobItem?.endTime).format("hh:mma")} - </Text>
                    <Text style={{ color: "#000", fontSize: 11 }}>{timeDifferentCard(jobItem?.startTime, jobItem?.endTime)}</Text>
                  </View>
                  <Text style={{ color: "#000", fontSize: 11, fontWeight: "600", marginTop: 3 }}>
                    Status : {jobItem?.jobStatus}</Text>
                  <Text style={{ color: "#000", fontSize: 12, fontWeight: "600", marginTop: 3 }}>{jobItem?.shiftTitle}</Text>
                </TouchableOpacity>
              </View>
            )
          }))
        }
      </View>
    </ScrollView>
  )
}

export default OnDayCalenderView