import { Fontisto } from '@expo/vector-icons';
import React, { useRef } from 'react';
import { useEffect } from 'react';
import { TouchableOpacity, Animated, Easing } from 'react-native';

const AlertButton = ({ onPress, refreshingView }) => {
  const rotationValue = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (refreshingView) {
      rotateButton();
    }
  }, [refreshingView]);

  const rotateButton = () => {
    Animated.loop(
      Animated.timing(rotationValue, {
        toValue: 1,
        duration: 1500, // Adjust the duration as needed
        easing: Easing.linear,
        useNativeDriver: true,
      })
    ).start();
  };

  const rotation = rotationValue.interpolate({
    inputRange: [0, 0.25, 0.5, 0.75, 1],
    outputRange: ['0deg', '30deg', '0deg', '-30deg', '0deg'], // Adjust the angle as needed
  });

  return (
    <TouchableOpacity
      style={{
        position: "absolute",
        width: 60,
        height: 60,
        borderRadius: 50,
        bottom: 50,
        right: 50,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#00b359",
        elevation: 5,
        shadowColor: "gray",
        shadowOpacity: 0.2,
        shadowRadius: 1.5,
        shadowOffset: {
          width: 1,
          height: 1,
        },
      }}
      activeOpacity={0.5}
      onPress={() => onPress()}>
      <Animated.View style={{ transform: [{ rotate: rotation }] }}>
        <Fontisto name="spinner-refresh" size={35} color="white" />
      </Animated.View>
    </TouchableOpacity>
  );
};


export default AlertButton;
