import {
  View,
  Text,
  TouchableOpacity,
  Image,
  Dimensions,
  Alert,
  Platform
} from "react-native";
import React from "react";

const { width, height } = Dimensions.get("window");
const SCREEN_WIDTH = width < height ? width : height;

const UserJobCard = ({
  element,
  jobFinalSelectionNurseId,
  setWeekNurseDirectAssignmentAvailabilityData,
  setJobFinalSelectionNurseId,
  buttonType
}) => {
  const handleChange = (value) => {
    setWeekNurseDirectAssignmentAvailabilityData([]);
    setJobFinalSelectionNurseId(value);
  };
  return (
    <TouchableOpacity
      activeOpacity={0.5}
      onPress={() =>
        element?.nurseAppAccessControl
          ? Platform.OS === "web"
            ? alert(`${element?.name} account is currently Suspended. `)
            : Alert.alert(`${element?.name} account is currently Suspended. `)
          : element?.available
          ? jobFinalSelectionNurseId === element?.uniqueId
            ? buttonType === "Repeat"
              ? Platform.OS === "web"
                ? alert("Uncheck the Post Job and Try")
                : Alert.alert("Uncheck the Post Job and Try")
              : handleChange("")
            : buttonType === "Repeat"
            ? Platform.OS === "web"
              ? alert("Uncheck the Post Job and Try")
              : Alert.alert("Uncheck the Post Job and Try")
            : handleChange(element?.uniqueId)
          : Platform.OS === "web"
          ? alert("Nurse is not available")
          : Alert.alert("Nurse is not available")
      }
      style={{
        marginHorizontal: 20
      }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: 10,
          marginHorizontal: 5,
          backgroundColor: element?.nurseAppAccessControl
            ? "#ffcccc"
            : jobFinalSelectionNurseId === element?.uniqueId
            ? "#e6ffe6"
            : element?.available
            ? "#fff"
            : "#ffcccc"
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            borderWidth: 1,
            borderRadius: 10,
            borderColor: "#2775BD",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            width: 45,
            height: 45,
            overflow: "hidden"
          }}
        >
          {element?.profile ? (
            <Image
              source={{
                uri: element?.profile
              }}
              style={{
                width: 43,
                height: 43,
                borderRadius: 10
              }}
            />
          ) : (
            <Image
              source={require("../../../../assets/images/icon.jpg")}
              style={{ width: 80, height: 80 }}
            />
          )}
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            flex: 1
          }}
        >
          <View
            style={{
              marginLeft: 10,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            <Text>{element?.name}</Text>
            <Text style={{ color: "#8d8d8d", fontWeight: "600", fontSize: 12 }}>
              {element?.license}
            </Text>
          </View>
          {/* <View
            style={{
              marginLeft: 10,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontSize: 12,
                color: element?.available ? "green" : "red",
                fontWeight:
                  jobFinalSelectionNurseId === element?.uniqueId ? "700" : "400",
              }}
            >
              {jobFinalSelectionNurseId === element?.uniqueId
                ? "Selected"
                : element?.available
                ? "Available"
                : "Not Available"}
            </Text>
          </View> */}
          <View
            style={{
              marginLeft: 10,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            <Text
              style={{
                fontSize: 12,
                color: element?.nurseAppAccessControl ? "red" : "#000",
                fontWeight: element?.nurseAppAccessControl ? "700" : "400"
              }}
            >
              {element?.nurseAppAccessControl ? "Under Suspend" : ""}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          borderWidth: 1,
          borderStyle: "dashed",
          borderColor: "lightgray",
          marginHorizontal: 5
        }}
      />
    </TouchableOpacity>
  );
};

export default UserJobCard;
