import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet, Pressable, Image } from "react-native";
import { useWindowDimensions } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { useActionSheet } from "@expo/react-native-action-sheet";
import moment from "moment";

const Message = (props) => {
  const {
    setAsMessageReply,
    message,
    facilityData,
    nurseData,
    userID,
  } = props;

  // const [message, setMessage] = useState(propMessage);
  const [decryptedContent, setDecryptedContent] = useState("");
  const [repliedTo, setRepliedTo] = useState(undefined);
  const [user, setUser] = useState(undefined);
  const [isMe, setIsMe] = useState(null);
  const [soundURI, setSoundURI] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);

  const { width } = useWindowDimensions();
  const { showActionSheetWithOptions } = useActionSheet();

  useEffect(() => {
    if (!message?.text) {
      return;
    }
    decryptMessage();
  }, [message]);

  const decryptMessage = async () => {
    setDecryptedContent(message?.text);
  };

  let currentDate = moment().format("MM-DD-YYYY");

  function isValidDateFormat(dateString) {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    return regex.test(dateString);
  }

  return (
    <View>
      {decryptedContent !== "" && (
        <Pressable
          style={[
            styles.container,
            userID === message?.facilityTableID
              ? styles.rightContainer
              : styles.leftContainer,
            { width: soundURI ? "75%" : "auto" },
          ]}
        >
          {/* {repliedTo && <MessageReply message={repliedTo} />} */}

          <View
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {userID !== message?.facilityTableID ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderWidth: 1,
                  borderRadius: 100,
                  borderColor: "#2775BD",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  width: 45,
                  height: 45,
                  overflow: "hidden",
                  marginRight: 10,
                }}
              >
                {nurseData?.profileImage ? (
                  <Image
                    source={{
                      uri: nurseData?.profileImage
                    }}
                    style={{
                      width: 43,
                      height: 43,
                      borderRadius: 100,
                    }}

                  />
                ) : (
                  <Image
                    source={require("../../../assets/images/icon.jpg")}
                    style={{ width: 60, height: 60 }}
                  />
                )}
              </View>
            ) : (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderWidth: 1,
                  borderRadius: 100,
                  borderColor: "#2775BD",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  width: 45,
                  height: 45,
                  overflow: "hidden",
                  marginRight: 10,
                }}
              >
                {facilityData?.profileImage ? (
                  <Image
                    source={{
                      uri: facilityData?.profileImage
                    }}
                    style={{
                      width: 43,
                      height: 43,
                      borderRadius: 100,
                    }}

                  />
                ) : (
                  <Image
                    source={require("../../../assets/images/icon.jpg")}
                    style={{ width: 60, height: 60 }}
                  />
                )}
              </View>
            )}
            <View
              style={{
                flex: 1,
                flexDirection: "column",
              }}
            >
              <View style={styles.row}>
                <Text
                  style={{
                    color: "black",
                    fontSize: 10,
                    marginRight: 0,
                    fontWeight: "700",
                  }}
                >
                  {userID === message?.facilityTableID
                    ? "You"
                    : nurseData?.firstName}
                </Text>
                <Text
                  style={{
                    color: "gray",
                    fontSize: 8,
                    marginRight: 0,
                    fontWeight: "600",
                    marginLeft: decryptedContent.length > 10 ? 2 : 5,
                  }}
                >
                  {moment(message?.createdAt).format("MM-DD-YYYY") === currentDate
                    ? moment(message?.createdAt).toNow() === "in a few seconds"
                      ? "few seconds ago"
                      : moment(message?.createdAt).calendar()
                    : isValidDateFormat(moment(message?.createdAt).calendar())
                      ? moment(message?.createdAt).calendar() +
                      " " +
                      moment(message?.createdAt).format("hh:mm a")
                      : moment(message?.createdAt).calendar()}
                  {/* {message?.receiver === null
                    ? "null"
                    : message?.receiver === undefined
                    ? "undefined"
                    : message?.receiver}{" "} */}
                </Text>

                {message?.sender === userID &&
                  (message?.status === "Sent" ? (
                    <Ionicons
                      name={"checkmark"}
                      size={12}
                      color="gray"
                      style={{ marginHorizontal: 5 }}
                    />
                  ) : (
                    <Ionicons
                      name="checkmark-done"
                      size={12}
                      color="green"
                      style={{ marginHorizontal: 5 }}
                    />
                  ))}
              </View>
              {/* {message?.image && (
              <View style={{ marginBottom: message?.text ? 10 : 0 }}>
                <S3Image
                  imgKey={message?.image}
                  style={{ width: width * 0.65, aspectRatio: 4 / 3 }}
                  resizeMode="contain"
                />
              </View>
            )} */}

              {/* {soundURI && <AudioPlayer soundURI={soundURI} />} */}

              <Text
                style={{
                  // color: userID === message?.facilityTableID ? "black" : "black",
                  fontSize: 12,
                }}
              >
                {decryptedContent}
              </Text>
            </View>
          </View>
        </Pressable>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 10,
    marginHorizontal: 10,
    marginTop: 5,
    borderRadius: 10,
    // maxWidth: "90%",
    backgroundColor: "#fff",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  messageReply: {
    backgroundColor: "gray",
    padding: 5,
    borderRadius: 5,
  },
  leftContainer: {
    // backgroundColor: "#fff",
    marginLeft: 10,
    // marginRight: "auto",
  },
  rightContainer: {
    // backgroundColor: "#bef4d2",
    // marginLeft: "auto",
    marginRight: 10,
    // alignItems: "flex-end",
  },
});

export default Message;
