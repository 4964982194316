import { API_URL } from "./config";

export const createChatRoom = async ({
  authToken,
  senderPublicKey,
  senderSecretKey,
  receiverPublicKey,
  receiverSecretKey,
  chatHistoryTableFacilityTableId,
  chatHistoryTableNurseTableId,
  chatHistoryTableOrganizationTableId,
  text }) => {
  if (!authToken) {
    return;
  }
  // console.log(JSON.stringify({ email, password }));
  // console.log(`${API_URL}/auth-nurse/login-and-authenticate`)

  const res = await fetch(`${API_URL}/chatroom`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "application/json", // "application/json" should be lowercase
    },
    body: JSON.stringify({
      senderPublicKey,
      senderSecretKey,
      receiverPublicKey,
      receiverSecretKey,
      chatHistoryTableFacilityTableId,
      chatHistoryTableNurseTableId,
      chatHistoryTableOrganizationTableId,
      text
    }),
  });

  if (res.status === 400) {
    throw new Error("Network error");
  } else {
    return res.json();
  }
};

export const createMessage = async ({
  id,
  authToken,
  nurseTableID,
  facilityTableID,
  organizationTableId,
  text }) => {
  if (!authToken) {
    return;
  }
  // console.log(JSON.stringify({ email, password }));
  // console.log(`${API_URL}/auth-nurse/login-and-authenticate`)

  const res = await fetch(`${API_URL}/chatroom/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "application/json", // "application/json" should be lowercase
    },
    body: JSON.stringify({
      nurseTableID,
      facilityTableID,
      organizationTableId,
      text
    }),
  });

  if (res.status === 400) {
    throw new Error("Network error");
  } else {
    return res.json();
  }
};

export const messageUpdate = async ({
  authToken,
  nurseTableID,
  facilityTableID,
  organizationTableId,
}) => {
  if (!authToken) {
    return;
  }
  // console.log(JSON.stringify({ email, password }));
  // console.log(`${API_URL}/auth-nurse/login-and-authenticate`)

  const res = await fetch(`${API_URL}/chatroom/message/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "application/json", // "application/json" should be lowercase
    },
    body: JSON.stringify({
      nurseTableID,
      facilityTableID,
      organizationTableId,
    }),
  });

  if (res.status === 400) {
    throw new Error("Network error");
  } else {
    return res.json();
  }
};

export async function getChatRoomMessage(authToken, id, page, pageSize) {
  if (!authToken) {
    return;
  }

  const queryParams = new URLSearchParams({
    page: String(page),
    pageSize: String(pageSize)
  });

  const url = `${API_URL}/chatroom/${id}?${queryParams.toString()}`;

  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 400) {
    throw new Error("Network error");
  }
  return await res.json();
}

export async function getChatRoomMessageCheck(authToken, nurseId, facilityId, page, pageSize) {
  if (!authToken) {
    return;
  }

  const queryParams = new URLSearchParams({
    page: String(page),
    pageSize: String(pageSize)
  });

  const url = `${API_URL}/chatroom/check/chatroom/${nurseId}/${facilityId}?${queryParams.toString()}`;

  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 400) {
    throw new Error("Network error");
  }
  return await res.json();
}

export async function getNurseChatRooms(
  authToken,
  chatHistoryTableNurseTableId,
) {
  if (!authToken) {
    return;
  }

  // const queryParams = new URLSearchParams({
  //   chatHistoryTableFacilityTableId,
  //   chatHistoryTableNurseTableId,
  //   chatHistoryTableOrganizationTableId,
  // });

  const url = `${API_URL}/chatroom/nurse/${chatHistoryTableNurseTableId}`;

  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });

  if (res.status === 500) {
    throw new Error("Internal Server Error");
  }

  return await res.json();
}

export async function getFacilityChatRooms(
  authToken,
  chatHistoryTableFacilityTableId,
) {

  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/chatroom/facility/${chatHistoryTableFacilityTableId}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });

  if (res.status === 500) {
    throw new Error("Internal Server Error");
  }

  return await res.json();
}

export async function getOrganizationChatRooms(
  authToken,
  chatHistoryTableOrganizationTableId
) {
  if (!authToken) {
    return;
  }

  const url = `${API_URL}/chatroom/organization/${chatHistoryTableOrganizationTableId}`;

  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });

  if (res.status === 500) {
    throw new Error("Internal Server Error");
  }

  return await res.json();
}

export async function getLastMessage(
  authToken,
  id
) {
  if (!authToken) {
    return;
  }

  const url = `${API_URL}/chatroom/last_message/${id}`;

  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });

  if (res.status === 500) {
    throw new Error("Internal Server Error");
  }

  return await res.json();
}

export const updateLastMessage = async (
  authToken, data
) => {

  if (!authToken) {
    return;
  }
  const res = await fetch(`${API_URL}/chatroom/message/update`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "application/json", // "application/json" should be lowercase
    },
    body: JSON.stringify(data),
  });

  if (res.status === 400) {
    throw new Error("Network error");
  } else {
    return res.json();
  }
};