import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Alert,
  Platform,
} from "react-native";
import React, { useState } from "react";
import { MaterialIcons } from "@expo/vector-icons";
import { useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import { updateDetails } from "../../api_url/facilityTable";

const MoreScreen = (props) => {

  const { authToken, userId, userUniqueId, removeAuthToken } = useAuth();

  const List = [
    {
      name: "Profile",
      route: "ProfileScreen",
    },
    {
      name: "Time Card",
      route: "MyPaymentsScreen",
    },
    {
      name: "Time Off",
      route: "TimeOffScreen",
    },
    {
      name: "Settings",
      route: "SettingScreen",
    },
    {
      name: "Contact",
      route: "ContactScreen",
    },
    {
      name: "Terms & Conditions",
      route: "TermsAndConditionsScreen",
    },
    {
      name: "Privacy Policy",
      route: "PrivacyPolicyScreen",
    },
  ];

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      // Start the timeout when the component mounts
      const timeout = setTimeout(() => {
        if (Platform.OS === "web") {
          alert("something went wrong. Try again later.");
        } else {
          Alert.alert("something went wrong. Try again later.");
        }
        setLoading(false);
      }, 5000); // 30 seconds
      // Clear the timeout when the component unmounts or the dependency changes
      return () => clearTimeout(timeout);
    }
  }, [loading]); // Empty dependency array ensures the effect runs only once

  const upDateNotificationNurseId = async () => {
    setLoading(true);
    const res = await updateDetails(
      authToken,
      userId,
      { mobileId: "" }
    )

    if (res?.mobileId === "") {
      removeAuthToken();
      setLoading(false);
    }
  };

  const logOut = async () => {
    upDateNotificationNurseId();
  };

  return (
    <View style={styles.container}>
      {List.map((item, index) => {
        return (
          <View
            key={index}
            style={{
              height: 40,
              marginVertical: 5,
              justifyContent: "center",
              paddingHorizontal: 20,
              borderBottomWidth: 1,
              borderBottomColor: "#ccc",
            }}
          >
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
              }}
              activeOpacity={0.5}
              onPress={() => props?.navigation?.navigate(item.route)}
            >
              <Text style={{ color: "#595959", fontSize: 12 }}>
                {item.name}
              </Text>
              <MaterialIcons
                name="keyboard-arrow-right"
                size={24}
                color="#808080"
              />
            </TouchableOpacity>
          </View>
        );
      })}
      <View
        style={{
          height: 40,
          marginVertical: 5,
          justifyContent: "center",
          paddingHorizontal: 20,
          borderBottomWidth: 1,
          borderBottomColor: "#ccc",
        }}
      >
        <TouchableOpacity
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
          onPress={() =>
            userId !== undefined && Platform.OS === "web"
              ? confirm("Are you sure want to Logout?").valueOf(true) &&
              logOut()
              : Alert.alert("Are you sure want to Logout?", "", [
                {
                  text: "Yes",
                  onPress: () => logOut(),
                },
                {
                  text: "No",
                  style: "cancel",
                },
              ])
          }
          activeOpacity={0.5}
        >
          <Text style={{ color: "#595959", fontSize: 12 }}>
            {loading ? "Loading..." : "Log Out"}
          </Text>
          <MaterialIcons
            name="keyboard-arrow-right"
            size={24}
            color="#808080"
          />
        </TouchableOpacity>
      </View>

    </View>
  );
};

export default MoreScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
