import {
  View,
  StyleSheet,
  Text,
  ScrollView,
  Platform,
  TouchableOpacity,
} from "react-native";
import React, { useState, useEffect } from "react";
import TemplateCard from "./Card/TemplateCard";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { ActivityIndicator } from "react-native";
import { useAuth } from "../../context/AuthContext";
import { deleteJobTemplate, getTemplateType } from "../../api_url/jobTemplate";
import { ChatState } from "../../context/ChatContext";

const ManageTemplateVisit = ({ props }) => {

  const { authToken, userId, userUniqueId } = useAuth();
  const { fetchAgainTemplateList, setFetchAgainTemplateList } = ChatState();

  const [data, setData] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingBottom, setLoadingBottom] = useState(false);
  const [page, setPage] = useState(0);
  const [dateSort, setDateSort] = useState(null);

  useEffect(() => {
    // if (userId !== undefined && Platform.OS !== "web") {
    if (userId !== undefined) {
      getFacilityJobTotal(userId);
    }
  }, [userId, fetchAgainTemplateList]);

  // useEffect(() => {
  //   if (userId !== undefined) {
  //     getFacilityJob(userId, page);
  //   }
  // }, [userId, page]);

  const getFacilityJobTotal = async (id) => {
    try {
      await getTemplateType(authToken, id, "Visit").then(res => {
        setData(res);
        setLoading(false);
      }).catch(e => { console.log(e) })
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Get Jobs List in Particular Facility
  // const getFacilityJob = async (id) => {
  //   try {
  //     if (Platform.OS === "web") {
  //       const itemA = await DataStore.query(FacilityTable, (item) =>
  //         item.id.eq(id)
  //       );
  //       const itemArr = await DataStore.query(
  //         JobTemplate,
  //         (item) =>
  //           item.and((c) => [
  //             c.location_id.eq(itemA[0].location_id),
  //             c.jobType.eq("Visit"),
  //             c.organization.eq(itemA[0].organization),
  //           ]),
  //         {
  //           sort: (s) => s.createdAt(SortDirection.DESCENDING)
  //         }
  //       );
  //       setData(itemArr);
  //     } else {
  //       const itemA = await DataStore.query(FacilityTable, (item) =>
  //         item.id.eq(id)
  //       );
  //       const itemArr = await DataStore.query(
  //         JobTemplate,
  //         (item) =>
  //           item.and((c) => [
  //             c.location_id.eq(itemA[0].location_id),
  //             c.jobType.eq("Visit"),
  //             c.organization.eq(itemA[0].organization),
  //           ]),
  //         {
  //           sort: (s) => s.createdAt(SortDirection.DESCENDING),
  //           page: page,
  //           limit: 10,
  //         }
  //       );
  //       const updatedData = [...data, ...itemArr];
  //       setData(updatedData);
  //     }
  //     setLoading(false);
  //     setLoadingBottom(false);
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  const onJobDetailNavigate = (d) => {
    props.navigation.navigate("TemplateDetailsScreen", {
      data: d,
    });
  };

  const deleteJobAction = async (id) => {
    await deleteJobTemplate(authToken, id)
      .then(
        res => {
          getFacilityJobTotal(userId);
        }
      ).catch(e => console.log(e))
  };

  if (dateSort) {
    data?.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
  } else {
    data?.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
  }

  const handleScrollEnd = () => {
    if (dataLength !== data?.length) {
      setLoadingBottom(true);
      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <View style={styles.container}>
      {loading ? (
        <View
          style={{ justifyContent: "center", alignItems: "center", flex: 1 }}
        >
          <Text>Loading...</Text>
        </View>
      ) : (
        <ScrollView
          showsVerticalScrollIndicator={false}
        // onScrollEndDrag={Platform.OS !== "web" && handleScrollEnd}
        >
          {data?.length === 0 ? (
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                marginTop: 10
              }}
            >
              <Text>No Template</Text>
            </View>
          ) : (
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: Platform.OS === "ios" ? 30 : 50,
              }}
            >
              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginHorizontal: 20,
                  marginTop: 5,
                }}
                onPress={() => setDateSort(!dateSort)}
                activeOpacity={0.5}
              >
                <Text>Date Sort</Text>

                <MaterialCommunityIcons name="sort" size={22} color="#595959" />
              </TouchableOpacity>
              {data
                ?.filter(
                  (element, index, self) =>
                    self.findIndex((e) => e.id === element.id) === index
                )
                ?.map((element, index) => {
                  return (
                    <TemplateCard
                      key={index}
                      authToken={authToken}
                      element={element}
                      onJobDetailNavigate={onJobDetailNavigate}
                      userId={userId}
                      deleteJobAction={deleteJobAction}
                    />
                  );
                })}
              {loadingBottom && (
                <ActivityIndicator
                  style={{ marginTop: 10 }}
                  size={30}
                  color={"#888"}
                />
              )}
            </View>
          )}
        </ScrollView>
      )}
    </View>
  );
};

export default ManageTemplateVisit;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
