import React, { useEffect, useRef, useState } from "react";
import { View, TouchableOpacity, Text, Animated } from "react-native";
import { Agenda, Calendar } from "react-native-calendars";
import CalendarCard from "../Home/Card/CalendarCard";
import moment from "moment";
import { AntDesign } from "@expo/vector-icons";
import { PanResponder } from "react-native";
import { convertDate } from "../../utils/function";
import AlertButton from "../../customComponents/AlertButton";
import { getAgendaDataFacility, getCalenderDataFacility } from "../../api_url/facilityTable";
import { useAuth } from "../../context/AuthContext";

const ManagerCalendarScreen = ({ props }) => {

  const { authToken, userId, userUniqueId } = useAuth();

  const onHomeScreenNavigate = () => {
    props?.navigation.navigate("ManagePage");
  };

  const animation = useRef(new Animated.Value(0)).current;
  const [refreshScreen, setRefreshScreen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingAgenda, setLoadingAgenda] = useState(true);

  const [markedDates, setMarkedDates] = useState(undefined);
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [newItems, setNewItem] = useState(undefined);

  const onJobDetailNavigate = (d) => {
    props?.navigation.navigate("JobDetailsScreen", {
      data: d,
    });
  };

  const renderItem = (element) => {
    return (
      <CalendarCard
        key={element?.id}
        element={element}
        onJobDetailNavigate={onJobDetailNavigate}
        userId={userId}
      />
    );
  };

  const colorHospital = "#00b359";

  const getAgendaData = async (id, date) => {
    setLoadingAgenda(true);
    await getAgendaDataFacility(authToken, id, date).then(
      res => {
        setNewItem(res);
        setLoadingAgenda(false);
      }
    ).catch(e => console.log("AgendaData", e))
  };

  useEffect(() => {
    getAgendaData(userId, selectedDate);
  }, [userId, selectedDate]);

  const getCalender = async (id) => {
    await getCalenderDataFacility(authToken, id).then(
      res => {
        setMarkedDates(res);
        setLoading(false);
        setRefreshScreen(false);
      }
    ).catch(e => console.log(e))
  };

  useEffect(() => {
    if (userId !== undefined) {
      getCalender(userId);
    }
  }, [userId]);

  const handleRefresh = () => {
    getCalender(userId);
  };

  const onPress = () => {
    getCalender(userId);
  }

  useEffect(() => {
    if (refreshScreen) {
      Animated.loop(
        Animated.sequence([
          Animated.timing(animation, {
            toValue: 1,
            duration: 500,
            useNativeDriver: true,
          }),
          Animated.timing(animation, {
            toValue: 0,
            duration: 500,
            useNativeDriver: true,
          }),
        ])
      ).start();
    }
  }, [refreshScreen, animation]);

  const translateY = animation.interpolate({
    inputRange: [0, 1],
    outputRange: [5, 10],
  });

  const panResponder = PanResponder.create({
    onMoveShouldSetPanResponder: (evt, gestureState) => {
      // Check if the user is pulling down (you can adjust the threshold as needed)
      return gestureState.dy > 50;
    },
    onPanResponderRelease: () => {
      if (refreshScreen) {
        // User has released the touch, trigger refresh
        handleRefresh();
      }
    },
  });

  return (
    <View
      style={{
        flex: 1,
      }}
    >
      {loading ? (
        <View
          style={{ justifyContent: "center", alignItems: "center", flex: 1 }}
        >
          <Text>Loading...</Text>
        </View>
      ) : (
        markedDates !== undefined && (
          <View
            style={{
              flex: 1,
            }}
          >
            {refreshScreen && (
              <View
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  paddingVertical: 20,
                  backgroundColor: "#fff",
                }}
              >
                <Animated.Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    marginTop: -10,
                    backgroundColor: "#fff",
                    transform: [{ translateY: translateY }],
                  }}
                >
                  Pull down{" "}
                  <AntDesign name="arrowdown" size={10} color="black" />
                </Animated.Text>
              </View>
            )}

            <View {...panResponder.panHandlers} style={{ flex: 1 }}>
              {/* <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingHorizontal: 20,
                  backgroundColor: "#fff",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginHorizontal: 20,
                  }}
                >
                  <Entypo name="dot-single" size={24} color={"#2775BD"} />
                  <Text
                    style={{
                      fontWeight: "500",
                      fontSize: 12,
                      color: "#2775BD",
                    }}
                  >
                    Shift
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginHorizontal: 20,
                  }}
                >
                  <Entypo name="dot-single" size={24} color={"green"} />
                  <Text
                    style={{ fontWeight: "500", fontSize: 12, color: "green" }}
                  >
                    Visit
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginHorizontal: 20,
                  }}
                >
                  <Entypo name="dot-single" size={24} color={"red"} />
                  <Text
                    style={{ fontWeight: "500", fontSize: 12, color: "red" }}
                  >
                    Time Off
                  </Text>
                </View>
              </View> */}
              <Calendar
                onDayPress={(day) => {
                  const inputDate = new Date(day.dateString).toString();
                  console.log(inputDate);
                  console.log(convertDate(inputDate));
                  setSelectedDate(day.dateString);
                }}
                markingType={"period"}
                markedDates={markedDates}
                theme={{ arrowColor: "#000", arrowWidth: 20, arrowHeight: 20 }}
              />
              <Text
                style={{
                  textAlign: "center",
                  marginTop: 10,
                  color: "#000",
                  fontWeight: "500",
                }}
              >
                {moment(selectedDate).format("MMMM")}
              </Text>
              {loadingAgenda ? (
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <Text>Loading...</Text>
                </View>
              ) : (
                <View style={{ flex: 1 }}>
                  <Agenda
                    // loadItemsForMonth={loadItems}
                    showClosingKnob={true}
                    showOnlySelectedDayItems={true}
                    items={newItems}
                    theme={{
                      dotColor: "#00b359",
                      selectedDayBackgroundColor: "#00b359",
                      selectedDayTextColor: "#e6f0ff",
                      selectedDotColor: "#e6f0ff",
                      "stylesheet.agenda.main": {
                        header: {
                          height: 0,
                          width: 0,
                        },
                        knobContainer: {
                          height: 0,
                          width: 0,
                        },
                        knob: {
                          height: 0,
                          width: 0,
                        },
                        weekdays: {
                          height: 0,
                          width: 0,
                        },
                        weekday: {
                          height: 0,
                          width: 0,
                        },
                        reservations: {
                          flex: 1,
                          marginTop: 5,
                        },
                      },
                    }}
                    renderEmptyData={() => (
                      <View
                        style={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          // backgroundColor: "#fff",
                        }}
                      >
                        <Text>No work is scheduled on this day</Text>
                        <TouchableOpacity
                          style={{
                            backgroundColor: colorHospital,
                            padding: 6,
                            width: "40%",
                            borderRadius: 5,
                            elevation: 5,
                            shadowColor: "gray",
                            shadowOpacity: 0.2,
                            shadowRadius: 1.5,
                            shadowOffset: {
                              width: 1,
                              height: 1,
                            },
                            marginVertical: 5,
                          }}
                          onPress={() => onHomeScreenNavigate()}
                        >
                          <Text
                            style={{
                              fontWeight: "500",
                              textAlign: "center",
                              color: "white",
                            }}
                          >
                            Add New Job
                          </Text>
                        </TouchableOpacity>
                      </View>
                    )}
                    selected={selectedDate}
                    renderItem={renderItem}
                  />
                </View>
              )}
            </View>
          </View>
        )
      )}
      {refreshScreen && <AlertButton onPress={onPress} refreshingView={refreshScreen} />}
    </View>
  );
};

export default ManagerCalendarScreen;
