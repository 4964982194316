import { API_URL } from "./config";

export async function getJobDetails(authToken, id) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/job/${id}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json"
    }
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching job");
  }
  return await res.json();
}

export async function getJobFacilityDashboard(authToken, id, type) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/job/facility_dashboard/${id}/${type}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json"
    }
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching job");
  }
  return await res.json();
}

export async function getJobFacilityLocationDashboard(authToken, id, type) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/job/location_dashboard/${id}/${type}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json"
    }
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching job");
  }
  return await res.json();
}

export async function getJobFacilityLocationDashboardChart(
  authToken,
  id,
  type,
  startDate
) {
  if (!authToken) {
    return;
  }

  const queryParams = new URLSearchParams({
    id: String(id),
    type: String(type),
    startDate: String(startDate)
  });

  const url = `${API_URL}/job/location_dashboard/chart?${queryParams.toString()}`;

  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json"
    }
  });

  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching job");
  }

  return await res.json();
}

export async function getJobStatusList(
  authToken,
  id,
  type,
  status,
  page,
  pageSize
) {
  if (!authToken) {
    return;
  }

  const queryParams = new URLSearchParams({
    page: String(page),
    pageSize: String(pageSize)
  });

  const url = `${API_URL}/job/job_status/${id}/${type}/${status}?${queryParams.toString()}`;

  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json"
    }
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching job");
  }
  return await res.json();
}

export async function getJobStatusListLocation(
  authToken,
  location,
  org,
  type,
  status,
  page,
  pageSize
) {
  if (!authToken) {
    return;
  }

  const queryParams = new URLSearchParams({
    page: String(page),
    pageSize: String(pageSize)
  });

  const url = `${API_URL}/job/job_location/${location}/${org}/${type}/${status}?${queryParams.toString()}`;

  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json"
    }
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching job");
  }
  return await res.json();
}

export async function getJobStatusListLocationApp(
  authToken,
  location,
  org,
  type,
  status,
  page,
  pageSize
) {
  if (!authToken) {
    return;
  }

  const queryParams = new URLSearchParams({
    page: String(page),
    pageSize: String(pageSize)
  });

  const url = `${API_URL}/job/mobile/job_location/${location}/${org}/${type}/${status}?${queryParams.toString()}`;

  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json"
    }
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching job");
  }
  return await res.json();
}

export async function getJobTypeList(authToken, id, type) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/job/job_type/${id}/${type}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json"
    }
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching job");
  }
  return await res.json();
}

export const postJobTable = async (
  authToken,
  licenseType,
  shiftTitle,
  specialty,
  certification,
  yearOfExperience,
  expiration,
  startDate,
  endDate,
  startTime,
  endTime,
  jobBreak,
  customerName,
  customerId,
  unit,
  floor,
  fullAddress,
  latitude,
  longitude,
  baseRate,
  enableBid,
  notes,
  jobType,
  jobStatus,
  jobFinalSelectionNurseId,
  jobBitFinalAmount,
  jobAcceptedNurses,
  jobRequestedNurses,
  autoAcceptedEnable,
  checkInTime,
  checkInFullAddress,
  checkInLatitude,
  checkInLongitude,
  checkOutTime,
  checkOutFullAddress,
  checkOutLatitude,
  checkOutLongitude,
  checkOutMessage,
  jobShiftTimingType,
  maxBidAmount,
  jobDuration,
  jobTiming,
  jobDays,
  EMRorEHRExperience,
  noShowReason,
  noShowReasonAttachment,
  noShowComments,
  neverCheckOutReason,
  neverCheckOutReasonAttachment,
  neverCheckOutComments,
  pendingOrNoShowFacilityDecideMessage,
  pendingOrNoShowFacilityDecideStatus,
  specialtyRequired,
  experienceRequired,
  emrehrRequired,
  certificationRequired,
  customerVisibility,
  worked_hours,
  total_payment,
  nurseCancelNotes,
  nurseSwapNotes,
  jobPostingTableFacilityTableId,
  baseRateVisibility,
  manager_review_comments,
  approved_manager,
  closed_by,
  timeAdjustByNurse,
  timeAdjustByFacility,
  checkInTimeNurse,
  checkInFullAddressNurse,
  checkInLatitudeNurse,
  checkInLongitudeNurse,
  checkOutTimeNurse,
  checkOutFullAddressNurse,
  checkOutLatitudeNurse,
  checkOutLongitudeNurse,
  startDateTimeStamp,
  noShowManagerReason,
  noShowManagerReasonAttachment,
  neverCheckOutManagerReason,
  neverCheckOutManagerReasonAttachment,
  organization,
  location_id
) => {
  if (!authToken) {
    return;
  }
  // console.log(JSON.stringify({
  //   licenseType,
  //   shiftTitle,
  //   specialty,
  //   certification,
  //   yearOfExperience,
  //   expiration,
  //   startDate,
  //   endDate,
  //   startTime,
  //   endTime,
  //   jobBreak,
  //   customerName,
  //   customerId,
  //   unit,
  //   floor,
  //   fullAddress,
  //   latitude,
  //   longitude,
  //   baseRate,
  //   enableBid,
  //   notes,
  //   jobType,
  //   jobStatus,
  //   jobFinalSelectionNurseId,
  //   jobBitFinalAmount,
  //   jobAcceptedNurses,
  //   autoAcceptedEnable,
  //   checkInTime,
  //   checkInFullAddress,
  //   checkInLatitude,
  //   checkInLongitude,
  //   checkOutTime,
  //   checkOutFullAddress,
  //   checkOutLatitude,
  //   checkOutLongitude,
  //   checkOutMessage,
  //   jobShiftTimingType,
  //   maxBidAmount,
  //   jobDuration,
  //   jobTiming,
  //   jobDays,
  //   EMRorEHRExperience,
  //   noShowReason,
  //   noShowReasonAttachment,
  //   noShowComments,
  //   neverCheckOutReason,
  //   neverCheckOutReasonAttachment,
  //   neverCheckOutComments,
  //   pendingOrNoShowFacilityDecideMessage,
  //   pendingOrNoShowFacilityDecideStatus,
  //   specialtyRequired,
  //   experienceRequired,
  //   emrehrRequired,
  //   certificationRequired,
  //   customerVisibility,
  //   worked_hours,
  //   total_payment,
  //   nurseCancelNotes,
  //   nurseSwapNotes,
  //   jobPostingTableFacilityTableId,
  //   baseRateVisibility,
  //   manager_review_comments,
  //   approved_manager,
  //   closed_by,
  //   timeAdjustByNurse,
  //   timeAdjustByFacility,
  //   checkInTimeNurse,
  //   checkInFullAddressNurse,
  //   checkInLatitudeNurse,
  //   checkInLongitudeNurse,
  //   checkOutTimeNurse,
  //   checkOutFullAddressNurse,
  //   checkOutLatitudeNurse,
  //   checkOutLongitudeNurse,
  //   startDateTimeStamp,
  //   noShowManagerReason,
  //   noShowManagerReasonAttachment,
  //   neverCheckOutManagerReason,
  //   neverCheckOutManagerReasonAttachment,
  //   organization,
  //   location_id,
  // }))
  const res = await fetch(`${API_URL}/job`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "Application/json"
    },
    body: JSON.stringify({
      licenseType,
      shiftTitle,
      specialty,
      certification,
      yearOfExperience,
      expiration,
      startDate,
      endDate,
      startTime,
      endTime,
      jobBreak,
      customerName,
      customerId,
      unit,
      floor,
      fullAddress,
      latitude,
      longitude,
      baseRate,
      enableBid,
      notes,
      jobType,
      jobStatus,
      jobFinalSelectionNurseId,
      jobBitFinalAmount,
      jobAcceptedNurses,
      jobRequestedNurses,
      autoAcceptedEnable,
      checkInTime,
      checkInFullAddress,
      checkInLatitude,
      checkInLongitude,
      checkOutTime,
      checkOutFullAddress,
      checkOutLatitude,
      checkOutLongitude,
      checkOutMessage,
      jobShiftTimingType,
      maxBidAmount,
      jobDuration,
      jobTiming,
      jobDays,
      EMRorEHRExperience,
      noShowReason,
      noShowReasonAttachment,
      noShowComments,
      neverCheckOutReason,
      neverCheckOutReasonAttachment,
      neverCheckOutComments,
      pendingOrNoShowFacilityDecideMessage,
      pendingOrNoShowFacilityDecideStatus,
      specialtyRequired,
      experienceRequired,
      emrehrRequired,
      certificationRequired,
      customerVisibility,
      worked_hours,
      total_payment,
      nurseCancelNotes,
      nurseSwapNotes,
      jobPostingTableFacilityTableId,
      baseRateVisibility,
      manager_review_comments,
      approved_manager,
      closed_by,
      timeAdjustByNurse,
      timeAdjustByFacility,
      checkInTimeNurse,
      checkInFullAddressNurse,
      checkInLatitudeNurse,
      checkInLongitudeNurse,
      checkOutTimeNurse,
      checkOutFullAddressNurse,
      checkOutLatitudeNurse,
      checkOutLongitudeNurse,
      startDateTimeStamp,
      noShowManagerReason,
      noShowManagerReasonAttachment,
      neverCheckOutManagerReason,
      neverCheckOutManagerReasonAttachment,
      organization,
      location_id
    })
  });
  if (res.status === 400) {
    throw new Error("Error during job posting.");
  } else {
    return res.json();
  }
};

export async function updateJobTable(authToken, id, data) {
  if (!authToken) {
    return;
  }

  // console.log(JSON.stringify(data))

  const res = await fetch(`${API_URL}/job/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "application/json" // "application/json" should be lowercase
    },
    body: JSON.stringify(data) // Convert data to JSON string
  });

  if (res.status === 400) {
    throw new Error("Failed to update the user. Try again!");
  }

  return await res.json();
}

export const deleteJob = async (authToken, id) => {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/job/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "application/json"
    }
  });

  console.log("Response status:", res.status);

  if (res.status === 200) {
    // Handle successful non-JSON response
    const textResult = await res.text();
    console.log("Non-JSON response:", textResult);
    return textResult; // You may choose to return or handle textResult as needed
  } else if (res.status === 400) {
    throw new Error("Network error");
  } else {
    try {
      // Assuming JSON response for other status codes
      const jsonResult = await res.json();
      console.log("JSON response:", jsonResult);
      return jsonResult;
    } catch (error) {
      console.error("Error parsing JSON response:", error);
      throw error;
    }
  }
};
