import React, { useState, useEffect } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import Ionicons from "react-native-vector-icons/Ionicons";
import { convertDate, timeDifferentCard } from "../../../../utils/function";
import { Entypo, MaterialCommunityIcons } from "@expo/vector-icons";
import moment from "moment";
import { getFacilityDetails } from "../../../../api_url/facilityTable";
import { ChatState } from "../../../../context/ChatContext";

const colorHospital = "#00b359";

const TimeOffCardNurse = ({ element, authToken, onEditJobNavigate }) => {
  let currentDate = moment()
  // console.log(element)

  const { fetchAgainTimeOffListNurse } = ChatState();
  // console.log(moment(new Date(element?.startDate)).format("MM-DD-YYYY"))
  const [name, setName] = useState("")

  //Facility Detail
  const getFacilityName = async (id) => {
    try {
      const facilityData = await getFacilityDetails(authToken, id)
      setName(facilityData?.firstName + " " + facilityData?.lastName)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (element?.status === "Approved") {
      getFacilityName(element?.approvedManager)
    } else if (element?.status === "Not-Approved") {
      getFacilityName(element?.approvedManager)
    } else if (element?.status === "Cancelled") {
      getFacilityName(element?.approvedManager)
    }
  }, [fetchAgainTimeOffListNurse])

  let date = moment(element?.startDate)
  let time = moment(element?.startTime).format("hh:mm a")

  const combinedDateTime = moment(`${date} ${time}`, "MM-DD-YYYY hh:mm a");

  return (
    <View
      key={element?.id}
      style={{
        display: "flex",
        marginHorizontal: 15,
        marginTop: 10,
        padding: 8,
        backgroundColor: element?.status === "Pending" ? "#f2f2f2"
          : element?.status === "Approved" ? "#e6ffee"
            : element?.status === "Not-Approved" ? "#ffcccc"
              : "#ccc",
        borderRadius: 10,
        elevation: 1,
        shadowColor: colorHospital,
        shadowOpacity: 0.2,
        shadowRadius: 3.5,
        shadowOffset: {
          width: 0,
          height: 2,
        },
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              fontWeight: "bold",
              marginVertical: 5,
              fontSize: 10,
              marginLeft: 2,
              color: "#595959",
              textDecorationLine: "underline"
            }}
          >
            {element?.status}
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 5,
          }}
        >
          {(element?.startDate) === (element?.endDate) ? (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Ionicons name={"calendar"} color={"#666"} size={15} />
              <Text
                style={{
                  fontWeight: "bold",
                  marginVertical: 5,
                  fontSize: 10,
                  marginLeft: 3,
                  color: "#595959",
                }}
              >
                {moment(element?.startDate).format("MMM-DD")}
              </Text>
            </View>
          ) : (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Ionicons name={"calendar"} color={"#666"} size={15} />
              <Text
                style={{
                  fontWeight: "bold",
                  marginVertical: 5,
                  fontSize: 10,
                  marginLeft: 3,
                  color: "#595959",
                }}
              >
                {moment(element?.startDate).format("MMM-DD")} -
              </Text>
              <Text
                style={{
                  fontWeight: "bold",
                  marginVertical: 5,
                  fontSize: 10,
                  marginLeft: 3,
                  color: "#595959",
                }}
              >
                {moment(element?.endDate).format("MMM-DD")}
              </Text>
            </View>
          )}
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MaterialCommunityIcons
            name={"clock-outline"}
            color={"#595959"}
            size={15}
            style={{
              marginLeft: 5,
            }}
          />

          {element?.startTime === "" && element?.endTime === "" ?
            <Text
              style={{
                fontWeight: "bold",
                marginVertical: 5,
                fontSize: 10,
                marginLeft: 2,
                color: "#595959",
              }}
            >
              Full Day
            </Text>
            :
            <Text
              style={{
                fontWeight: "bold",
                marginVertical: 5,
                fontSize: 10,
                marginLeft: 2,
                color: "#595959",
              }}
            >
              {moment(new Date(element?.startTime)).format("h:mma")}-
              {moment(new Date(element?.endTime)).format("h:mma")}
              <Entypo name="dot-single" size={13} />
              {timeDifferentCard(element?.startTime, element?.endTime)}
            </Text>
          }

        </View>
      </View>

      <Text
        style={{
          fontSize: 12,
          marginVertical: 5,
          color: "#1a1a1a",
          fontWeight: "600",
        }}
      >
        Reason : {element?.timeOffReason}
      </Text>

      {element?.comments &&
        <Text
          style={{
            fontSize: 10,
            marginVertical: 5,
            color: "#888",
            fontWeight: "600",
          }}
        >
          Comments: {element?.comments}
        </Text>}

      {name &&
        <Text
          style={{
            fontSize: 10,
            marginVertical: 5,
            color: "#888",
            fontWeight: "600",
          }}
        >
          {element?.status} By : {name}
        </Text>
      }
      {element?.nurseCancelNotes &&
        <Text
          style={{
            fontSize: 10,
            marginVertical: 5,
            color: "#888",
            fontWeight: "600",
          }}
        >
          Cancellation Notes: {element?.nurseCancelNotes}
        </Text>}
      <Text
        style={{
          fontSize: 10,
          marginVertical: 5,
          color: "#888",
          fontWeight: "600",
        }}
      >
        Last Updated : {moment(element?._lastChangedAt).format("MM-DD-YYYY h:mm a")}
      </Text>

      {currentDate.isBefore(moment(element?.startDate)) &&
        element?.status === "Pending" ? (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <TouchableOpacity
            onPress={() =>
              onEditJobNavigate({
                item: element.id,
                userId: element?.nurseTableID,
              })
            }
            style={{
              backgroundColor: "green",
              paddingVertical: 3,
              paddingHorizontal: 8,
              marginLeft: 10,
              borderRadius: 5,
            }}
          >
            <Text style={{ fontSize: 12, color: "#fff" }}>Action</Text>
          </TouchableOpacity>
        </View>
      ) :
        null}
      {
        (moment(element?.startDate).isAfter(currentDate)
          && element?.status === "Approved" &&
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <TouchableOpacity
              onPress={() =>
                onEditJobNavigate({
                  item: element.id,
                  userId: element?.nurseTableID,
                })
              }
              style={{
                backgroundColor: "green",
                paddingVertical: 3,
                paddingHorizontal: 8,
                marginLeft: 10,
                borderRadius: 5,
              }}
            >
              <Text style={{ fontSize: 12, color: "#fff" }}>Action</Text>
            </TouchableOpacity>
          </View>)
      }
      {
        (element?.startDate === convertDate(currentDate)
          && (combinedDateTime.isAfter(moment(), "MM-DD-YYYY hh:mm a"))
          && element?.status === "Approved" &&
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <TouchableOpacity
              onPress={() =>
                onEditJobNavigate({
                  item: element.id,
                  userId: element?.nurseTableID,
                })
              }
              style={{
                backgroundColor: "green",
                paddingVertical: 3,
                paddingHorizontal: 8,
                marginLeft: 10,
                borderRadius: 5,
              }}
            >
              <Text style={{ fontSize: 12, color: "#fff" }}>Action</Text>
            </TouchableOpacity>
          </View>)
      }
    </View>
  );
};

export default TimeOffCardNurse;
