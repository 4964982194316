import { View, StyleSheet, Text, TouchableOpacity } from "react-native";
import React, { useState } from "react";
import ManageShift from "./Shift";
import ManageVisit from "./Visit";
import { useAuth } from "../../../context/AuthContext";
import { getFacilityDetails } from "../../../api_url/facilityTable";
import { useEffect } from "react";

const MyPayments = (props) => {
  const [type, setType] = useState("shift");

  const { authToken, userId, userUniqueId } = useAuth();
  const [user, setUser] = useState(undefined);
  const [loadingScreen, setLoadingScreen] = useState(false);

  const checkUser = async (authToken, userId) => {
    try {
      await getFacilityDetails(authToken, userId)
        .then((res) => {
          if (res === undefined) {
            setUser(null);
            setLoadingScreen(false);
          } else {
            setUser(res);
            setLoadingScreen(false);
          }
        })
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (authToken && userId) {
      checkUser(authToken, userId);
    }
  }, [authToken, userId]);

  let appJobType = user?.additionalFields[0]?.appJobType || "Both"

  return (
    <View style={styles.container}>
      <View style={{ flex: 1 }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-evenly",
            marginTop: 10,
          }}
        >
          {appJobType === "Shift" || appJobType === "Both" &&
            <TouchableOpacity
              style={{
                backgroundColor: type === "shift" ? "#000" : "#fff",
                width: "40%",
                alignItems: "center",
                paddingVertical: 5,
                borderRadius: 5,
                borderWidth: 0.5,
              }}
              activeOpacity={0.5}
              onPress={() => setType("shift")}
            >
              <Text style={{ color: type === "shift" ? "#fff" : "#000" }}>
                Shift{" "}
              </Text>
            </TouchableOpacity>}
          {appJobType === "Visit" || appJobType === "Both" &&
            <TouchableOpacity
              style={{
                backgroundColor: type === "visit" ? "#000" : "#fff",
                width: "40%",
                alignItems: "center",
                paddingVertical: 5,
                borderRadius: 5,
                borderWidth: 0.5,
              }}
              activeOpacity={0.5}
              onPress={() => setType("visit")}
            >
              <Text style={{ color: type === "visit" ? "#fff" : "#000" }}>
                Visit{" "}
              </Text>
            </TouchableOpacity>}
        </View>
        {appJobType === "Both" &&
          (type === "shift" ? (
            <ManageShift props={props} />
          ) : (
            <ManageVisit props={props} />
          ))}
        {appJobType === "Shift" &&
          <ManageShift props={props} />}
        {appJobType === "Visit" &&
          <ManageVisit props={props} />}
      </View>
    </View>
  );
};

export default MyPayments;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
