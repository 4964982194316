import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { Calendar } from "react-native-calendars";
import { getCalenderDataNurse } from "../../api_url/nurseTable";
import { useAuth } from "../../context/AuthContext";

const CalendarView = (props) => {

  const { authToken } = useAuth();

  const [userId, setUserId] = useState(props?.userID);
  const [markedDates, setMarkedDates] = useState(undefined);

  useEffect(() => {
    if (userId !== undefined) {
      getCalender(userId)
    }
  }, [userId]);

  const getCalender = async (id) => {
    await getCalenderDataNurse(authToken, id).then(
      res => {
        // console.log(res)
        setMarkedDates(res);
      }
    ).catch(e => console.log(e))
  };

  const onJobDetailNavigate = (d) => {
    props?.navigation.navigate("JobDetailsScreen", {
      data: d,
    });
  };

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: "#c",
      }}
    >
      {markedDates !== undefined && (
        <View
          style={{
            flex: 1,
          }}
        >
          <Calendar
            markingType={"period"}
            markedDates={markedDates}
            theme={{ arrowColor: "#000", arrowWidth: 20, arrowHeight: 20 }}
          />

        </View>
      )}
    </View>
  );
};

export default CalendarView;
