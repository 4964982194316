import { View, Text, ActivityIndicator, Platform } from "react-native";
import React from "react";
import WebView from "react-native-webview";
import { StyleSheet } from "react-native";

const TermsAndConditions = () => {
  return (
    <View style={styles.container}>
      {Platform.OS === "web" ?
        <iframe
          title="My Website"
          src="https://www.nursdinc.com/terms"
          style={{ width: '100%', height: '100vh', border: 'none' }}
        /> : <WebView
          source={{
            uri: "https://www.nursdinc.com/terms",
          }}
          style={styles.webView}
        />}
    </View>
  );
};

export default TermsAndConditions;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
