import { View, Text, Dimensions } from 'react-native'
import React from 'react'
import {
  LineChart,
  BarChart,
  PieChart,
  ProgressChart,
  ContributionGraph,
  StackedBarChart
} from "react-native-chart-kit";

const Chart = ({ chartData, dataProgressChart, dataPieChart, totalCount }) => {

  const chartConfig = {
    backgroundGradientFrom: "#1E2923",
    backgroundGradientFromOpacity: 0,
    backgroundGradientTo: "#08130D",
    backgroundGradientToOpacity: 0.5,
    color: (opacity = 1) => `rgba(26, 255, 146, ${opacity})`,
    strokeWidth: 2, // optional, default 3
    barPercentage: 0.5,
    useShadowColorFromDataset: false // optional
  };

  return (
    <View style={{ paddingBottom: 50 }}>
      <View>
        <LineChart
          data={chartData}
          width={Dimensions.get("window").width}
          height={220}
          formatYLabel={(e) => Math.round(e)}
          yAxisInterval={1} // optional, defaults to 1
          chartConfig={{
            backgroundColor: "#fff",
            backgroundGradientFrom: "#fff",
            backgroundGradientTo: "#fff",
            decimalPlaces: 2, // optional, defaults to 2dp
            color: (opacity = 1) => `rgba(0, 102, 51, ${opacity})`,
            labelColor: (opacity = 1) => `rgba(0, 102, 51, ${opacity})`,
            style: {
              borderRadius: 16
            },
            propsForDots: {
              r: "6",
              strokeWidth: "2",
              stroke: "#00b359"
            }
          }}
          bezier
          style={{
            margin: 8,
          }}
        />
        {/* <Text style={{
          textAlign: "center",
          marginTop: 10,
          textDecorationLine: "underline"
        }}>
          Total Job = {totalCount}
        </Text> */}
        <ProgressChart
          data={dataProgressChart}
          width={Dimensions.get("window").width}
          height={220}
          strokeWidth={16}
          radius={32}
          chartConfig={{
            backgroundColor: "#fff",
            backgroundGradientFrom: "#fff",
            backgroundGradientTo: "#fff",
            decimalPlaces: 2, // optional, defaults to 2dp
            color: (opacity = 1) => `rgba(0, 102, 51, ${opacity})`,
            labelColor: (opacity = 1) => `rgba(0, 102, 51, ${opacity})`,
            propsForDots: {
              r: "6",
              strokeWidth: "2",
              stroke: "#000",
            },
            fillShadowGradient: "rgba(0, 107, 54, 0.1)", // Shadow color 
          }}
          hideLegend={false}
          withCustomBarColorFromData
          // style={{
          //   margin: 10
          // }}
        // center={[10, 50]}
        // absolute
        />
        {/* <Text style={{
          textAlign: "center",
          marginTop: 10,
          textDecorationLine: "underline"
        }}>
          Total Job = {totalCount}
        </Text> */}
        <PieChart
          data={dataPieChart}
          width={Dimensions.get("window").width} // from react-native=
          height={220}
          chartConfig={chartConfig}
          accessor={"count"}
          backgroundColor={"transparent"}
          paddingLeft={"15"}
        // center={[10, 50]}
        // absolute
        />
      </View>
    </View>
  )
}

export default Chart