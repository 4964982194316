import React, { useState } from "react";
import { KeyboardAvoidingView, Platform, Alert } from "react-native";
import { View, Text, StyleSheet } from "react-native";
import AuthButton from "../../../customComponents/Button/AuthButton";
import CustomInput from "../../../customComponents/Input/CustomInput";
import { updateDetails, getFacilityDetails } from "../../../api_url/facilityTable";
import { useAuth } from "../../../context/AuthContext";

const ChangePassword = (props) => {
  const { authToken, userId, userUniqueId } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [state, setState] = useState({
    currentPassword: "",
    currentPasswordFocus: false,
    password: "",
    passwordFocus: false,
    conPassword: "",
    conPasswordFocus: false,
  });

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/;
    return passwordRegex.test(password);
  };

  const changePassword = async (currentPassword, newPassword) => {
    setLoading(true);
    await getFacilityDetails(authToken, userId).then(
      async res => {
        if (res?.password === currentPassword) {
          try {
            await updateDetails(
              authToken, userId,
              {
                password: newPassword
              }
            )
            if (Platform.OS === "web") {
              alert("Success\nPassword Changed");
            } else {
              Alert.alert("Success", "Password Changed");
            }
            setLoading(false);
            setError("");
            setState({
              currentPassword: "",
              currentPasswordFocus: false,
              password: "",
              passwordFocus: false,
              conPassword: "",
              conPasswordFocus: false,
            });
            props.navigation.goBack()
          } catch (error) {
            setLoading(false);
            setError(error.message);
            console.log("error", error);
          }
        } else {
          setLoading(false);
          if (Platform.OS === "web") {
            alert("Current password wrong");
          } else {
            Alert.alert("Current password wrong");
          }
        }
      }
    )
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={styles.container}
    >
      <View style={{ alignItems: "center" }}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10,
            marginHorizontal: 20,
          }}
        >
          <CustomInput
            value={state.currentPassword}
            label={"Current Password"}
            placeholder="Enter Current Password"
            keyboardType={"default"}
            onChangeText={(text) =>
              setState({ ...state, currentPassword: text })
            }
            clearValue={() => setState({ ...state, currentPassword: "" })}
            viewStyle={{
              height: 40,
              backgroundColor: "#f2f2f2",
              borderRadius: 10,
              elevation: 1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              borderWidth: 0.5,
              borderColor: "#e6e6e6",
              paddingRight: 10,
              justifyContent: "space-between",
              marginTop: 0,
            }}
            textInputStyle={{
              height: 40,
              borderRadius: 10,
              paddingLeft: 10,
              paddingRight: 5,
              fontSize: 12,
            }}
            iconStyle={{
              height: 40,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          />
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10,
            marginHorizontal: 20,
          }}
        >
          <CustomInput
            value={state.password}
            label="New Password"
            placeholder="Enter New Password"
            keyboardType={"default"}
            onChangeText={(text) => setState({ ...state, password: text })}
            clearValue={() => setState({ ...state, password: "" })}
            viewStyle={{
              height: 40,
              backgroundColor: "#f2f2f2",
              borderRadius: 10,
              elevation: 1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              borderWidth: 0.5,
              borderColor: "#e6e6e6",
              paddingRight: 10,
              justifyContent: "space-between",
              marginTop: 0,
            }}
            textInputStyle={{
              height: 40,
              borderRadius: 10,
              paddingLeft: 10,
              paddingRight: 5,
              fontSize: 12,
            }}
            iconStyle={{
              height: 40,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          />
        </View>
        <Text
          style={{
            fontSize: 8,
            textAlign: "center",
            marginHorizontal: 20,
            color: "red",
          }}
        >
          {state.password.length !== 0 &&
            !validatePassword(state?.password) &&
            "At least 8 characters with uppercase,lowercase letter,one numeric and one special characters"}
        </Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10,
            marginHorizontal: 20,
          }}
        >
          <CustomInput
            value={state.conPassword}
            label="Confirm Password"
            placeholder="Enter Confirm Password"
            keyboardType={"default"}
            onChangeText={(text) => setState({ ...state, conPassword: text })}
            clearValue={() => setState({ ...state, conPassword: "" })}
            viewStyle={{
              height: 40,
              backgroundColor: "#f2f2f2",
              borderRadius: 10,
              elevation: 1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              borderWidth: 0.5,
              borderColor: "#e6e6e6",
              paddingRight: 10,
              justifyContent: "space-between",
              marginTop: 0,
            }}
            textInputStyle={{
              height: 40,
              borderRadius: 10,
              paddingLeft: 10,
              paddingRight: 5,
              fontSize: 12,
            }}
            iconStyle={{
              height: 40,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          />
        </View>
        <Text
          style={{
            fontSize: 8,
            textAlign: "center",
            marginHorizontal: 20,
            color: "red",
          }}
        >
          {state.conPassword.length !== 0 &&
            state.conPassword !== state.password &&
            "Password and confirm password not matched"}
        </Text>
        <Text
          style={{
            fontSize: 8,
            textAlign: "center",
            marginHorizontal: 20,
            color: "red",
          }}
        >
          {error === "Incorrect username or password."
            ? "Currant password is wrong"
            : error}
        </Text>
      </View>

      <View style={{ alignItems: "center", marginTop: 10 }}>
        <AuthButton
          name={loading ? "Loading..." : "Submit"}
          disabled={
            state.currentPassword.length !== 0 &&
              state.password.length !== 0 &&
              validatePassword(state?.password) &&
              state.conPassword.length !== 0 &&
              state.conPassword === state.password
              ? false
              : true
          }
          onPress={() =>
            loading
              ? null
              : changePassword(state?.currentPassword, state?.conPassword)
          }
        />
      </View>
    </KeyboardAvoidingView>
  );
};

export default ChangePassword;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
