import {
  View,
  Text,
  StyleSheet,
  Modal,
  TouchableOpacity,
  Dimensions,
  TextInput,
  KeyboardAvoidingView,
  ScrollView,
  Alert,
} from "react-native";
import React from "react";
import { useState } from "react";
import CalendarPicker from "react-native-calendar-picker";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import { Platform } from "react-native";
import AuthButton from "../../../customComponents/Button/AuthButton";
import { useEffect } from "react";
import moment from "moment";
import { DateString, GetDateMergedTime } from "../../../utils/function";
import { getFacilityDetails } from "../../../api_url/facilityTable";
import { useAuth } from "../../../context/AuthContext";
import { postFacilityTimeOff, updateFacilityTimeOff } from "../../../api_url/timeoffFacility";
import { ChatState } from "../../../context/ChatContext";

const TimeOff = (props) => {
  const { width, height } = Dimensions.get("window");

  const { authToken, userId, userUniqueId } = useAuth();
  const { fetchAgainTimeOffList, setFetchAgainTimeOffList } = ChatState();

  const [user, setUser] = useState(undefined);

  const [timeOffData, setTimeOffData] = useState(
    props.route.params !== undefined ? props.route.params.data : undefined
  );

  const [timeOff, setTimeOff] = useState({
    timeOffReason: "",
    startDate: new Date(),
    startTime: new Date(),
    endDate: new Date(),
    endTime: new Date(),
    alternateContact: "",
    phoneNum: "",
    comments: "",
  });

  const [showCalendar, setShowCalendar] = useState(false);
  const [showCalendarEnd, setShowCalendarEnd] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const [showTimeEnd, setShowTimeEnd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showTimeIos, setShowTimeIos] = useState(false);
  const [showTimeEndIos, setShowTimeEndIos] = useState(false);

  useEffect(() => {
    if (timeOffData !== undefined) {
      setTimeOff({
        ...timeOff,
        timeOffReason: timeOffData?.timeOffReason,
        startDate: new Date(timeOffData?.startDate),
        startTime: new Date(timeOffData?.startDate),
        endDate: new Date(timeOffData?.endDate),
        endTime: new Date(timeOffData?.endDate),
        alternateContact: timeOffData?.alternateContactNumber,
        phoneNum: timeOffData?.phoneNumber,
        comments: timeOffData?.comments,
      });
    }
  }, [timeOffData]);

  const checkUser = async (id) => {
    try {
      const facility = await getFacilityDetails(authToken, id)
      setUser(facility);
    } catch (error) {
      setUser(undefined);
    }
  };

  useEffect(() => {
    if (userId === null) {
      return;
    }
    checkUser(userId);
  }, [userId]);

  useEffect(() => {
    if (user !== undefined) {
      setTimeOff({ ...timeOff, phoneNum: user?.phoneNumber });
    }
  }, [user]);

  const onChangeStartTime = (event, selectedDate) => {
    setTimeOff({ ...timeOff, startTime: selectedDate });
    setShowTime(false);
  };
  const onChangeEndTime = (event, selectedDate) => {
    setTimeOff({ ...timeOff, endTime: selectedDate });
    setShowTimeEnd(false);
  };

  const postTimeOff = async () => {
    setLoading(true);
    if (
      timeOff.timeOffReason === "" ||
      // timeOff.alternateContact === "" ||
      timeOff.phoneNum === ""
    ) {
      if (Platform.OS === "web") {
        alert("Fill all required fields first");
      } else {
        Alert.alert("Fill all required fields first");
      }
      setLoading(false);
    } else {
      try {
        await postFacilityTimeOff(
          authToken,
          userId,
          timeOff.timeOffReason,
          moment(timeOff.startDate).format("YYYY-MM-DD"),
          GetDateMergedTime(timeOff.startDate.toString(), timeOff.startTime.toString()).toString(),
          moment(timeOff.endDate).format("YYYY-MM-DD"),
          GetDateMergedTime(timeOff.endDate.toString(), timeOff.endTime.toString()).toString(),
          timeOff.alternateContact,
          timeOff.phoneNum,
          user?.organization,
          user?.location_id,
          timeOff.comments,
          "Approved",
        ).then(
          res => {
            setFetchAgainTimeOffList(!fetchAgainTimeOffList)
            setLoading(false);
            if (Platform.OS === "web") {
              alert("Success\nTime Off successfully added to your calendar.");
            } else {
              Alert.alert(
                "Success",
                "Time Off successfully added to your calendar."
              );
            }
            props?.navigation.goBack();
          }
        ).catch(
          e => {
            console.log(e)
            setLoading(false);
          }
        )
      } catch (error) {
        console.log("data save error:", error);
        setLoading(false);
      }
    }
  };

  const editTimeOff = async () => {
    setLoading(true);
    try {

      await updateFacilityTimeOff(
        authToken,
        timeOffData?.id,
        timeOff.timeOffReason,
        moment(timeOff.startDate).format("YYYY-MM-DD"),
        GetDateMergedTime(timeOff.startDate.toString(), timeOff.startTime.toString()).toString(),
        moment(timeOff.startDate).format("YYYY-MM-DD"),
        GetDateMergedTime(timeOff.endDate.toString(), timeOff.endTime.toString()).toString(),
        timeOff.alternateContact,
        timeOff.phoneNum,
        user?.organization,
        user?.location_id,
        timeOff.comments,
        timeOffData?.status
      ).then(
        res => {
          setFetchAgainTimeOffList(!fetchAgainTimeOffList)
          setLoading(false);
          if (Platform.OS === "web") {
            alert("Successfully Edited");
          } else {
            Alert.alert("Successfully Edited");
          }
          props?.navigation.goBack();
        }
      ).catch(e => {
        console.log("data save error:", error);
        setLoading(false);
      })
    } catch (error) {
      console.log("data save error:", error);
      setLoading(false);
    }
  };

  const currentDate = moment();

  const isPastDate = (date) => {
    return date.isBefore(currentDate, "day");
  };

  const disabledDates = (date) => {
    return isPastDate(date);
  };

  const isStartPastDate = (date) => {
    return date.isBefore(moment(timeOff.startDate), "day");
  };

  const disabledStartDates = (date) => {
    return isStartPastDate(date);
  };

  const disabledDatesTextStyle = { color: "#CCCCCC" };
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={styles.container}
    >
      <ScrollView scrollEnabled={true} showsVerticalScrollIndicator={false}>
        <View style={{ marginTop: 10 }}>
          <Text
            style={{
              marginBottom: 5,
              fontSize: 12,
              color: "#737373",
              marginHorizontal: 20,
            }}
          >
            Time Off Reason <Text style={{ color: "red" }}>*</Text>
          </Text>

          <View
            style={{
              backgroundColor: "#f2f2f2",
              borderRadius: 10,
              elevation: 1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              borderWidth: 0.5,
              borderColor: "#e6e6e6",
              marginHorizontal: 20,
              paddingRight: 10,
              justifyContent: "space-between",
            }}
          >
            <TextInput
              style={{
                width: "90%",
                padding: 2,
                paddingLeft: 5,
                fontSize: 12,
                color: "#737373",
              }}
              multiline
              onChangeText={(text) =>
                setTimeOff({ ...timeOff, timeOffReason: text })
              }
              value={timeOff.timeOffReason}
              keyboardType={"default"}
              autoCapitalize="sentences"
              placeholderTextColor="#b3b3b3"
              placeholder={"Time Off Reason"}
            />
            <View
              style={{
                height: 40,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {timeOff.timeOffReason ? (
                <TouchableOpacity
                  onPress={() => setTimeOff({ ...timeOff, timeOffReason: "" })}
                  style={{ marginLeft: 3 }}
                >
                  <Ionicons name="close-sharp" size={22} color="#808080" />
                </TouchableOpacity>
              ) : null}
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 10,
            marginHorizontal: 20,
          }}
        >
          <View
            style={{
              flex: 1,
            }}
          >
            <Text
              style={{
                fontSize: 12,
                color: "#737373",
              }}
            >
              Start Date <Text style={{ color: "red" }}>*</Text>
            </Text>
            <TouchableOpacity
              onPress={() => setShowCalendar(true)}
              style={{
                borderWidth: 1,
                borderColor: "#e6e6e6",
                backgroundColor: "#f2f2f2",
                borderRadius: 10,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: 10,
                marginTop: 10,
                marginRight: 5,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 12,
                  color: "#737373",
                }}
              >
                {DateString(timeOff.startDate)}
              </Text>
              <AntDesign name="calendar" size={20} color="#808080" />
            </TouchableOpacity>
          </View>
        </View>

        {/* <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 10,
            marginHorizontal: 20,
          }}
        >
          <View
            style={{
              flex: 1,
            }}
          >
            <Text
              style={{
                fontSize: 12,
                color: "#737373",
              }}
            >
              End Date <Text style={{ color: "red" }}>*</Text>
            </Text>
            <TouchableOpacity
              onPress={() => setShowCalendarEnd(true)}
              style={{
                borderWidth: 1,
                borderColor: "#e6e6e6",
                backgroundColor: "#f2f2f2",
                borderRadius: 10,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: 10,
                marginTop: 10,
                marginRight: 5,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 12,
                  color: "#737373",
                }}
              >
                {DateString(timeOff.endDate)}
              </Text>
              <AntDesign name="calendar" size={20} color="#808080" />
            </TouchableOpacity>
          </View>
        </View> */}

        <View
          style={{
            marginTop: 10,
            marginHorizontal: 20,
          }}
        >
          <Text
            style={{
              fontSize: 12,
              color: "#737373",
            }}
          >
            Alternate Contact (Optional){" "}
          </Text>
          <View
            style={{
              height: 45,
              backgroundColor: "#f2f2f2",
              borderRadius: 10,
              elevation: 1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              borderWidth: 0.5,
              borderColor: "#e6e6e6",
              paddingRight: 10,
              justifyContent: "space-between",
              marginTop: 10,
            }}
          >
            <Text
              style={{
                marginLeft: 5,
                paddingHorizontal: 5,
                borderRightWidth: 1,
                borderColor: "#d9d9d9",
                color: "#737373",
              }}
            >
              +1
            </Text>
            <TextInput
              onChangeText={(text) =>
                setTimeOff({ ...timeOff, alternateContact: text })
              }
              value={timeOff.alternateContact}
              keyboardType={"numbers-and-punctuation"}
              autoCapitalize="none"
              placeholderTextColor="#b3b3b3"
              placeholder={"Enter Alternate Contact"}
              style={{
                flex: 1,
                height: 45,
                borderRadius: 10,
                paddingLeft: 10,
                paddingRight: 5,
                fontSize: 12,
              }}
            />
            <View
              style={{
                height: 40,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {timeOff.alternateContact ? (
                <TouchableOpacity
                  onPress={() =>
                    setTimeOff({ ...timeOff, alternateContact: "" })
                  }
                >
                  <Ionicons name="close-sharp" size={22} color="#808080" />
                </TouchableOpacity>
              ) : null}
            </View>
          </View>
        </View>

        <View
          style={{
            marginTop: 10,
            marginHorizontal: 20,
          }}
        >
          <Text
            style={{
              fontSize: 12,
              color: "#737373",
            }}
          >
            Phone Number
          </Text>
          <View
            style={{
              height: 45,
              backgroundColor: "#f2f2f2",
              borderRadius: 10,
              elevation: 1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              borderWidth: 0.5,
              borderColor: "#e6e6e6",
              paddingRight: 10,
              justifyContent: "space-between",
              marginTop: 10,
            }}
          >
            <Text
              style={{
                marginLeft: 5,
                paddingHorizontal: 5,
                borderRightWidth: 1,
                borderColor: "#d9d9d9",
                color: "#737373",
              }}
            >
              +1
            </Text>
            <TextInput
              onChangeText={(text) =>
                setTimeOff({ ...timeOff, phoneNum: text })
              }
              value={timeOff.phoneNum}
              keyboardType={"decimal-pad"}
              editable={false}
              autoCapitalize="none"
              placeholderTextColor="#b3b3b3"
              placeholder={"Enter Phone Number"}
              style={{
                flex: 1,
                height: 45,
                borderRadius: 10,
                paddingLeft: 10,
                paddingRight: 5,
                fontSize: 12,
              }}
            />
          </View>
        </View>

        <View>
          <Text
            style={{
              marginTop: 10,
              fontSize: 12,
              color: "#737373",
              marginHorizontal: 20,
            }}
          >
            Comments
          </Text>

          <View
            style={{
              backgroundColor: "#f2f2f2",
              borderRadius: 10,
              elevation: 1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              borderWidth: 0.5,
              borderColor: "#e6e6e6",
              marginHorizontal: 20,
              paddingRight: 10,
              justifyContent: "space-between",
              marginTop: 10,
            }}
          >
            <TextInput
              style={{
                width: "90%",
                padding: 2,
                paddingLeft: 5,
                fontSize: 12,
                color: "#737373",
              }}
              multiline
              onChangeText={(text) =>
                setTimeOff({ ...timeOff, comments: text })
              }
              value={timeOff.comments}
              keyboardType={"default"}
              autoCapitalize="sentences"
              placeholderTextColor="#b3b3b3"
              placeholder={"Comments..."}
            // returnKeyType="next"
            />
            <View
              style={{
                height: 40,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {timeOff.comments ? (
                <TouchableOpacity
                  onPress={() => setTimeOff({ ...timeOff, comments: "" })}
                  style={{ marginLeft: 3 }}
                >
                  <Ionicons name="close-sharp" size={22} color="#808080" />
                </TouchableOpacity>
              ) : null}
            </View>
          </View>
        </View>

        <View
          style={{
            marginTop: 10,
            marginBottom: 100,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AuthButton
            name={loading ? "Loading..." : "Submit"}
            onPress={() =>
              loading
                ? null
                : timeOffData !== undefined
                  ? editTimeOff()
                  : postTimeOff()
            }
          />
        </View>

        <Modal
          animationType="fade"
          transparent={true}
          visible={showCalendar}
          onRequestClose={() => setShowCalendar(false)}
        >
          <View
            style={{
              height: height,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <View
              style={{
                backgroundColor: "white",
                paddingVertical: 20,
                justifyContent: "center",
                elevation: 5,
                borderRadius: 15,
                borderColor: "rgba(0, 0, 0, 0.1)",
              }}
            >
              <CalendarPicker
                onDateChange={(date) =>
                  setTimeOff({
                    ...timeOff,
                    startDate: date,
                    endDate: date
                  })
                }
                selectedDayColor="#00b359"
                selectedDayTextColor="#FFFFFF"
                initialDate={new Date()}
                disabledDates={disabledDates}
                disabledDatesTextStyle={disabledDatesTextStyle}
              />
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <TouchableOpacity
                  onPress={() => setShowCalendar(false)}
                  style={{
                    backgroundColor: "#00b359",
                    marginHorizontal: 20,
                    paddingHorizontal: 10,
                    paddingVertical: 5,
                    borderRadius: 10,
                  }}
                >
                  <Text
                    style={{
                      textAlign: "right",
                      fontSize: 12,
                      color: "#fff",
                    }}
                  >
                    Select
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
        <Modal
          animationType="fade"
          transparent={true}
          visible={showCalendarEnd}
          onRequestClose={() => setShowCalendarEnd(false)}
        >
          <View
            style={{
              height: height,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <View
              style={{
                backgroundColor: "white",
                paddingVertical: 20,
                justifyContent: "center",
                elevation: 5,
                borderRadius: 15,
                borderColor: "rgba(0, 0, 0, 0.1)",
              }}
            >
              <CalendarPicker
                onDateChange={(date) =>
                  setTimeOff({ ...timeOff, endDate: date })
                }
                selectedDayColor="#00b359"
                selectedDayTextColor="#FFFFFF"
                initialDate={new Date()}
                disabledDates={disabledStartDates}
                disabledDatesTextStyle={disabledDatesTextStyle}
              />
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <TouchableOpacity
                  onPress={() => setShowCalendarEnd(false)}
                  style={{
                    backgroundColor: "#00b359",
                    marginHorizontal: 20,
                    paddingHorizontal: 10,
                    paddingVertical: 5,
                    borderRadius: 10,
                  }}
                >
                  <Text
                    style={{
                      textAlign: "right",
                      fontSize: 12,
                      color: "#fff",
                    }}
                  >
                    Select
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default TimeOff;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    justifyContent: "center",
  },
});
