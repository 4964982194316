export const durationCount = ["4 Hours", "8 Hours", "10 Hours", "12 Hours"];
export const timingCount = ["Morning", "Afternoon", "Evening", "Night"];
export const experienceLevel = ["0 - 1 year", "1 - 2 years", "2+ years"];
export const optionsEMR = [
  "ABELMed",
  "AdvancedMD",
  "AllegianceMD",
  "Amazing Charts",
  "AthenaHealth",
  "Axxess",
  "CampDoc",
  "Care360",
  "Centricity EMR",
  "Cerner",
  "Clinicient INSIGHT",
  "Dr. Cloud EHR",
  "EpicCare",
  "iClinic Systems",
  "InSync EMR",
  "InteGreat EHR",
  "Kareo Clinical",
  "MatrixCare",
  "MDVision PM EMR",
  "MicroMD",
  "Nextech EMR",
  "NextGen Healthcare",
  "Office Ally EHR 24/7",
  "OpenEMR",
  "Optum Physician",
  "PointClickCare",
  "Practice Fusion",
  "Praxis",
  "PrognoCIS",
  "ReLi Med Solutions",
  "TherapyNotes",
  "TouchWorks EHR",
  "Yardi",
];
export const options = [
  "ACLS",
  "APHN-BC",
  "AWHONN",
  "BLS",
  "CCRN",
  "CEN",
  "CMSRN",
  "CNOR",
  "CPI",
  "CRRN",
  "OCN",
  "PALS",
  "NRP",
  "NIHSS",
  "RN-BC",
  "RNC",
  "STABLE",
  "TNCC",
];
//HMK
export const option = ["CNA", "HHA", "RN", "LPN"];
export const specialty = [
  "Administration",
  "Advanced Practice",
  "Allergy",
  "Ambulatory",
  "Anesthesia",
  "Blood Management",
  "Cardiac Care",
  "Cardiac Cath Lab",
  "Case Management",
  "Clinical Informatics",
  "Critical Care",
  "Dermatology",
  "Development Disability",
  "Diabetes",
  "Dialysis",
  "Education / Instruction",
  "Emergency",
  "ENT",
  "Fertility",
  "Gastroenterology",
  "Genetics",
  "Geriatrics",
  "Hematology",
  "HIV/AIDS",
  "Home Health",
  "Hospice",
  "Infection Control",
  "Infectious Disease",
  "Infusion",
  "Medical / Surgical",
  "Mother Baby",
  "Neonatal Critical Care",
  "Neurology",
  "Nursery",
  "Obstetric / Labor & Delivery",
  "Occupational Health",
  "Oncology",
  "Operating Room / Surgical",
  "Ophthalmology",
  "Orthopedics",
  "PACU / Surgical Recovery Room",
  "Pain Management",
  "Palliative Care",
  "PCU / Stepdown",
  "Pediatric Critical Care",
  "Pediatrics",
  "Plastic Surgery",
  "Primary Care",
  "Psychiatry",
  "Public Health",
  "Pulmonary Care",
  "Quality Assurance",
  "Radiology",
  "Rehabilitation",
  "Renal",
  "Reproductive",
  "Research",
  "Rheumatology",
  "Substance Abuse",
  "Telemetry",
  "Toxicology",
  "Transplant",
  "Transport",
  "Trauma",
  "Urology",
  "Vascular",
  "Women's Health",
  "Wound",
];
export const optionBreak = [
  "No Break",
  "15 Minutes",
  "30 Minutes",
  "45 Minutes",
  "1 Hour",
];

export const buttonTypeArr = ["Repeat", "Save Template", "Publish Job"];
export const dayCount = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
