import React, { useState } from "react";
import { View, TouchableOpacity, Text, Image } from "react-native";
import ManagerCalendarScreen from "./ManagerCalendarScreen";
import NewCalenderScreen from "./NewCalenderScreen";
import { GlobalStyles } from "../../utils/GlobalStyles";
import FontAwesome5 from "@expo/vector-icons/FontAwesome5";

const CalendarScreen = (props) => {
  const [typeScreen, setTypeScreen] = useState("Full Schedule");

  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      headerStyle: {
        backgroundColor: "#FFF",
        borderBottomWidth: 0
      },
      headerTitleAlign: "center",
      headerLeft: () => (
        <TouchableOpacity onPress={() => props?.navigation.navigate("Home")}>
          <Image
            source={require("../../../assets/login/NURSD-Flow.png")}
            style={{
              marginLeft: 0,
              width: 82,
              marginBottom: 0,
              height: 32
            }}
          />
        </TouchableOpacity>
      ),
      headerRight: () => (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <FontAwesome5
            name="user-clock"
            size={22}
            color="#333333"
            onPress={() => props?.navigation.navigate("TimeOffScreen")}
          />
           <FontAwesome5
            name="clipboard-list"
            size={23}
            color="#333333"
            style={{ marginRight: 15, marginLeft: 10 }}
            onPress={() => props?.navigation.navigate("AddJobScreen")}
          />
          {/* <TouchableOpacity
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#00b359",
              paddingVertical: 5,
              paddingHorizontal: 8,
              borderRadius: 5,
              marginHorizontal: 10
            }}
            activeOpacity={0.5}
            onPress={() =>
              typeScreen === "My Events"
                ? props?.navigation.navigate("TimeOffScreen")
                : props?.navigation.navigate("AddJobScreen")
            }
          >
            <Text style={{ fontWeight: "500", fontSize: 12, color: "#fff" }}>
              {typeScreen === "My Events" ? "Time Off" : "Create"}
            </Text>
          </TouchableOpacity> */}
        </View>
      ),
      headerTitle: (props) => (
        <View style={GlobalStyles.headerTitleView}>
          <Text
            style={{
              fontSize: 20,
              fontWeight: "bold",
              textAlign: "center"
            }}
          >
            {typeScreen}
          </Text>
        </View>
      )
    });
  });

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: "#fff"
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          paddingHorizontal: 10,
          backgroundColor: "#fff",
          marginVertical: 5
        }}
      >
        <TouchableOpacity
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#00b359",
            paddingVertical: 5,
            paddingHorizontal: 5,
            borderRadius: 5
          }}
          activeOpacity={0.5}
          onPress={() =>
            setTypeScreen(
              typeScreen === "My Events" ? "Full Schedule" : "My Events"
            )
          }
        >
          <Text style={{ fontWeight: "500", fontSize: 12, color: "#fff" }}>
            {typeScreen === "My Events" ? "Full Schedule" : "My Events"}
          </Text>
        </TouchableOpacity>
      </View>
      {typeScreen === "My Events" ? (
        <ManagerCalendarScreen props={props} />
      ) : (
        <NewCalenderScreen props={props} />
      )}
    </View>
  );
};

export default CalendarScreen;
