import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  Dimensions,
  TouchableOpacity,
  Modal,
  Image,
  KeyboardAvoidingView,
  TextInput,
  Alert,
} from "react-native";
import { useEffect, useState } from "react";
import { Platform } from "react-native";
import moment from "moment";
import { Linking } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { sendNewPushNotification } from "../../utils/notification";
import { getDocumentDetails, updateDocument } from "../../api_url/documents";
import { useAuth } from "../../context/AuthContext";
import { getNurseDetails } from "../../api_url/nurseTable";
import { postNurseNotification } from "../../api_url/nurseNotification";
import { socket } from "../../api_url/socket";

const DocumentScreen = (props) => {
  const { width, height } = Dimensions.get("window");

  const { authToken, userId, userUniqueId } = useAuth();
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const id = props !== undefined && props?.route?.params?.data?.id;

  const [userDocument, setUserDocument] = useState(undefined);

  const [noShowModel, setNoShowModel] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [noShowMessage, setNoShowMessage] = useState("");
  const [uploadDocView, setUploadDocView] = useState({
    type: "",
    documentName: "",
    issuingBody: "",
    expiration_date: "",
    frontImage: "",
    backImage: "",
    multipleImages: [],
    verified: false,
    file: "",
    verification_note: "",
    document_status: "",
  });

  const downloadAndOpenPDF = async (name) => {
    Linking.openURL(name);
  };

  const currentDate = moment();

  useEffect(() => {
    setUploadDocView({
      ...uploadDocView,
      type: userDocument?.type,
      documentName: userDocument?.documentName,
      issuingBody: userDocument?.issuingBody,
      expiration_date: moment(new Date(userDocument?.expiration_date)),
      frontImage: userDocument?.frontImage,
      backImage: userDocument?.backImage,
      multipleImages:
        userDocument?.multipleImages,
      verified: userDocument?.verified,
      file: userDocument?.file,
      verification_note: userDocument?.verification_note,
      document_status: userDocument?.document_status,
    });

    const expirationDate = moment(userDocument?.expiration_date);

    if (userDocument?.type === "Assessment") {
      const monthsDifference = currentDate.diff(expirationDate, "months");
      setIsExpired(monthsDifference >= 36);
    } else if (userDocument?.type === "Health Document") {
      const monthsDifference = currentDate.diff(expirationDate, "months");
      setIsExpired(monthsDifference >= 12);
    } else if (userDocument?.type === "Misc Documents") {
      const monthsDifference = currentDate.diff(expirationDate, "months");
      setIsExpired(monthsDifference >= 12);
    } else {
      setIsExpired(expirationDate <= currentDate);
    }
  }, [userDocument]);

  const updateData = async (status, message) => {
    if (noShowMessage === "") {
      Alert.alert("Fill the verification note");
    } else {
      try {
        const res = await updateDocument(
          authToken,
          userDocument?.id,
          {
            document_status: status,
            verified: true,
            reviewerComments: message
          }
        )
        const data = await getNurseDetails(authToken, res.nurseTableID);
        setNoShowMessage("");
        setNoShowModel(false);
        await postNurseNotification(
          authToken,
          res.nurseTableID,
          "",
          "Document Verification Status",
          "Your Document is" + ` ${status}`,
          "MyDocumentsScreen",
          {},
          false,
          false,
          "green",
          ""
        );
        socket?.emit("tableNotificationInsert",
          {
            table: "NurseNotificationTable",
            id: res.nurseTableID
          })
        if (data?.mobileId) {
          sendNewPushNotification({
            expoPushToken: data?.mobileId,
            job: "Document Verification Status",
            typeMessage: "Your Document is" + ` ${status}`,
            screen: "MyDocumentsScreen",
          });
        }
        setNoShowMessage("");
        setNoShowModel(false);
        check(id);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const check = async (id) => {
    setLoading(true);
    try {
      const data = await getDocumentDetails(authToken, id)
      setUserDocument(data);
      setLoading(false);
    } catch (error) {
      setUserDocument(null);
    }
  };

  useEffect(() => {
    check(id);
  }, [id]);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={styles.container}
    >
      {loading ? (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Text>Loading...</Text>
        </View>
      ) : (
        <ScrollView showsVerticalScrollIndicator={false} bounces={false}>
          <View style={styles.container}>
            <View
              style={{
                justifyContent: "center",
                marginHorizontal: 20,
                marginTop: 5,
              }}
            >
              <View
                style={{
                  marginVertical: 5,
                  justifyContent: "center",
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  borderBottomWidth: 1.5,
                  borderBottomColor: "#f2f2f2",
                }}
              >
                <Text
                  style={{ color: "#1a1a1a", fontWeight: "500", fontSize: 15 }}
                >
                  Document Type
                </Text>
                <Text style={{ color: "#595959", fontSize: 12, marginTop: 5 }}>
                  {uploadDocView?.type}
                </Text>
              </View>

              <View
                style={{
                  marginVertical: 5,
                  justifyContent: "center",
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  borderBottomWidth: 1.5,
                  borderBottomColor: "#f2f2f2",
                }}
              >
                <Text
                  style={{ color: "#1a1a1a", fontWeight: "500", fontSize: 15 }}
                >
                  Document Name
                </Text>
                <Text style={{ color: "#595959", fontSize: 12, marginTop: 5 }}>
                  {uploadDocView?.documentName}
                </Text>
              </View>

              <View
                style={{
                  marginVertical: 5,
                  justifyContent: "center",
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  borderBottomWidth: 1.5,
                  borderBottomColor: "#f2f2f2",
                }}
              >
                <Text
                  style={{ color: "#1a1a1a", fontWeight: "500", fontSize: 15 }}
                >
                  {uploadDocView?.type === "Assessment" ||
                    uploadDocView?.type === "Health Document"
                    ? "Date Completed"
                    : uploadDocView?.type === "Misc Documents"
                      ? "Submission Date"
                      : "Expiration Date"}
                </Text>
                <Text style={{ color: "#595959", fontSize: 12, marginTop: 5 }}>
                  {moment(new Date(uploadDocView.expiration_date)).format(
                    "MMMM D, YYYY"
                  )}
                </Text>
              </View>

              <View
                style={{
                  marginVertical: 5,
                  justifyContent: "center",
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  borderBottomWidth: 1.5,
                  borderBottomColor: "#f2f2f2",
                }}
              >
                <Text
                  style={{ color: "#1a1a1a", fontWeight: "500", fontSize: 15 }}
                >
                  Valid Until
                </Text>
                <Text style={{ color: "#595959", fontSize: 12, marginTop: 5 }}>
                  {uploadDocView.type === "Assessment"
                    ? moment(new Date(uploadDocView.expiration_date))
                      .add(36, "months")
                      .format("MMMM D, YYYY")
                    : uploadDocView.type === "Health Document"
                      ? moment(new Date(uploadDocView.expiration_date))
                        .add(12, "months")
                        .format("MMMM D, YYYY")
                      : uploadDocView.type === "Misc Documents"
                        ? moment(new Date(uploadDocView.expiration_date))
                          .add(12, "months")
                          .format("MMMM D, YYYY")
                        : moment(new Date(uploadDocView.expiration_date)).format(
                          "MMMM D, YYYY"
                        )}
                </Text>
              </View>

              {uploadDocView?.multipleImages?.length > 0 && (
                <View
                  style={{
                    marginVertical: 5,
                    justifyContent: "center",
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    borderBottomWidth: 1.5,
                    borderBottomColor: "#f2f2f2",
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Text
                      style={{
                        color: "#1a1a1a",
                        fontWeight: "500",
                        fontSize: 15,
                      }}
                    >
                      Document Images
                    </Text>
                  </View>

                  <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                    {uploadDocView?.multipleImages.map(
                      (item, index) => {
                        return (
                          <View
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              borderWidth: 2,
                              marginTop: 5,
                              borderRadius: 10,
                              borderColor: "#2775BD",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "#fff",
                              width: width / 3,
                              overflow: "hidden",
                              margin: 2,
                            }}
                          >
                            <Image
                              source={{
                                uri: item
                              }}
                              style={{
                                width: 100,
                                height: 100,
                              }}
                            />
                          </View>
                        );
                      }
                    )}
                  </View>
                </View>
              )}

              <View
                style={{
                  marginVertical: 5,
                  justifyContent: "center",
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  borderBottomWidth: 1.5,
                  borderBottomColor: "#f2f2f2",
                }}
              >
                <Text
                  style={{
                    color: "#1a1a1a",
                    fontWeight: "500",
                    fontSize: 15,
                  }}
                >
                  {uploadDocView?.type === "Misc Documents"
                    ? "Submission Notes"
                    : "Issuing Body"}
                </Text>
                <Text style={{ color: "#595959", fontSize: 12, marginTop: 5 }}>
                  {uploadDocView?.issuingBody}
                </Text>
              </View>

              {uploadDocView.frontImage && (
                <View
                  style={{
                    marginVertical: 5,
                    justifyContent: "center",
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    borderBottomWidth: 1.5,
                    borderBottomColor: "#f2f2f2",
                  }}
                >
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontWeight: "500",
                      fontSize: 15,
                    }}
                  >
                    Document Front Side
                  </Text>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      borderWidth: 2,
                      marginTop: 5,
                      borderRadius: 10,
                      borderColor: "#2775BD",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#fff",
                      width: width / 2.5,
                      overflow: "hidden",
                    }}
                  >
                    {uploadDocView?.frontImage !== "" && (
                      <View>
                        <Image
                          source={{
                            uri: uploadDocView?.frontImage
                          }}
                          style={{
                            width: 100,
                            height: 100,
                          }}
                        />
                      </View>
                    )}
                  </View>
                </View>
              )}

              {uploadDocView.backImage && (
                <View
                  style={{
                    marginVertical: 5,
                    justifyContent: "center",
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    borderBottomWidth: 1.5,
                    borderBottomColor: "#f2f2f2",
                  }}
                >
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontWeight: "500",
                      fontSize: 15,
                    }}
                  >
                    Document Back Side
                  </Text>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      borderWidth: 2,
                      marginTop: 5,
                      borderRadius: 10,
                      borderColor: "#2775BD",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#fff",
                      width: width / 2.5,
                      overflow: "hidden",
                    }}
                  >
                    {uploadDocView?.backImage !== "" && (
                      <View>
                        <Image
                          source={{
                            uri: uploadDocView?.backImage
                          }}
                          style={{
                            width: 100,
                            height: 100,
                          }}
                        />
                      </View>
                    )}
                  </View>
                </View>
              )}

              {uploadDocView.file && (
                <View
                  style={{
                    marginVertical: 5,
                    justifyContent: "center",
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Text
                      style={{
                        color: "#1a1a1a",
                        fontWeight: "500",
                        fontSize: 15,
                      }}
                    >
                      Document File
                    </Text>
                  </View>

                  <TouchableOpacity
                    style={{
                      backgroundColor: "#f2f2f2",
                      borderRadius: 5,
                      elevation: 1,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 10,
                      justifyContent: "space-between",
                    }}
                    onPress={() => downloadAndOpenPDF(uploadDocView.file)}
                  >
                    <Text
                      style={{
                        marginVertical: 10,
                        fontSize: 12,
                        color: "#8888",
                        marginHorizontal: 10,
                      }}
                    >
                      View the file
                    </Text>
                  </TouchableOpacity>
                </View>
              )}

              {uploadDocView?.verification_note && (
                <View
                  style={{
                    marginVertical: 5,
                    justifyContent: "center",
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    borderBottomWidth: 1.5,
                    borderBottomColor: "#f2f2f2",
                  }}
                >
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontWeight: "500",
                      fontSize: 15,
                    }}
                  >
                    Notes
                  </Text>
                  <Text
                    style={{ color: "#595959", fontSize: 12, marginTop: 5 }}
                  >
                    {uploadDocView?.verification_note}
                  </Text>
                </View>
              )}

              {isExpired ? (
                <View
                  activeOpacity={0.5}
                  style={{
                    backgroundColor: "red",
                    borderRadius: 5,
                    padding: 10,
                    marginHorizontal: 10,
                  }}
                >
                  <Text style={{ color: "white", textAlign: "center" }}>
                    Expired
                  </Text>
                </View>
              ) : !uploadDocView.verified ? (
                <TouchableOpacity
                  activeOpacity={0.5}
                  style={{
                    backgroundColor: "#2775BD",
                    borderRadius: 5,
                    padding: 10,
                    marginHorizontal: 10,
                    marginTop: 5,
                  }}
                  onPress={() => setNoShowModel(true)}
                >
                  <Text style={{ color: "white", textAlign: "center" }}>
                    Verify
                  </Text>
                </TouchableOpacity>
              ) : (
                <View
                  activeOpacity={0.5}
                  style={{
                    backgroundColor:
                      uploadDocView?.document_status === "Approved" ? "#00b359" : "red",
                    borderRadius: 5,
                    marginHorizontal: 10,
                    padding: 10,
                  }}
                >
                  <Text style={{ color: "white", textAlign: "center" }}>
                    {uploadDocView?.document_status}
                  </Text>
                </View>
              )}
            </View>

            <Modal
              animationType="fade"
              transparent={true}
              visible={noShowModel}
              onRequestClose={() => setNoShowModel(false)}
            >
              <View
                style={{
                  height: height,
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
              >
                <View
                  style={{
                    backgroundColor: "white",
                    paddingVertical: 15,
                    marginTop: 100,
                    width: 300,
                    justifyContent: "center",
                    elevation: 5,
                    paddingHorizontal: 10,
                    borderRadius: 15,
                    borderColor: "rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <View>
                    <Text
                      style={{
                        marginBottom: 5,
                        fontSize: 12,
                        color: "#737373",
                        marginHorizontal: 10,
                      }}
                    >
                      Verification Note <Text style={{ color: "red" }}>*</Text>
                    </Text>

                    <View
                      style={{
                        backgroundColor: "#f2f2f2",
                        borderRadius: 10,
                        elevation: 1,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        borderWidth: 0.5,
                        borderColor: "#e6e6e6",
                        marginHorizontal: 10,
                        paddingRight: 10,
                        justifyContent: "space-between",
                      }}
                    >
                      <TextInput
                        style={{
                          width: "90%",
                          padding: 2,
                          paddingLeft: 5,
                          fontSize: 12,
                          color: "#737373",
                        }}
                        multiline
                        onChangeText={(text) => setNoShowMessage(text)}
                        value={noShowMessage}
                        keyboardType={"default"}
                        autoCapitalize="sentences"
                        placeholderTextColor="#b3b3b3"
                        placeholder={"Verification Message"}
                      />
                      <View
                        style={{
                          height: 40,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        {noShowMessage ? (
                          <TouchableOpacity
                            onPress={() => setNoShowMessage("")}
                            style={{ marginLeft: 3 }}
                          >
                            <Ionicons
                              name="close-sharp"
                              size={22}
                              color="#808080"
                            />
                          </TouchableOpacity>
                        ) : null}
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      flexDirection: "row",
                      marginTop: 20,
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        setNoShowModel(false);
                        setNoShowMessage("");
                      }}
                      style={{
                        backgroundColor: "#2775BD",
                        paddingHorizontal: 10,
                        paddingVertical: 5,
                        borderRadius: 10,
                        marginHorizontal: 10,
                      }}
                    >
                      <Text
                        style={{
                          textAlign: "right",
                          fontSize: 12,
                          color: "#fff",
                        }}
                      >
                        Cancel
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => updateData("Not Approved", noShowMessage)}
                      style={{
                        backgroundColor: "red",
                        paddingHorizontal: 10,
                        paddingVertical: 5,
                        borderRadius: 8,
                        marginRight: 10,
                      }}
                    >
                      <Text
                        style={{
                          textAlign: "right",
                          fontSize: 12,
                          color: "#fff",
                        }}
                      >
                        Not Approved
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => updateData("Approved", noShowMessage)}
                      style={{
                        backgroundColor: "#00b359",
                        paddingHorizontal: 10,
                        paddingVertical: 5,
                        borderRadius: 10,
                      }}
                    >
                      <Text
                        style={{
                          textAlign: "right",
                          fontSize: 12,
                          color: "#fff",
                        }}
                      >
                        Approve
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </Modal>
          </View>
        </ScrollView>
      )}
    </KeyboardAvoidingView>
  );
};

export default DocumentScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    justifyContent: "center",
  },
});
