import { View, StyleSheet, Text, ScrollView, Platform } from "react-native";
import React, { useState, useEffect } from "react";
import UserCardPTO from "./Card/UserCardPTO";
import { useAuth } from "../../context/AuthContext";
import { getAllNurseList } from "../../api_url/nurseTable";

const ManagePTO = (props) => {
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const { authToken, userId, userUniqueId, removeAuthToken } = useAuth();

  const [facilityData, setFacilityData] = useState(undefined);

  const onUserDetailNavigate = (d) => {
    props.navigation.navigate("UserDetailsScreen", {
      data: d,
    });
  };

  useEffect(() => {
    if (userId !== undefined) {
      getNurseList(userId);
    }
  }, [userId]);

  const getNurseList = async (id) => {
    try {
      const nurseList = await getAllNurseList(authToken, id);
      setData(nurseList.sort((a, b) =>
        a?.firstName
          ?.toLowerCase()
          ?.localeCompare(b?.firstName?.toLowerCase())
      ));
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onChatRoomNavigate = (d) => {
    props?.navigation.navigate("ChatRoomScreen", {
      data: d,
    });
  };

  const onRequestJobNavigate = (d) => {
    props?.navigation.navigate("RequestTimeOffScreen", {
      data: d,
    });
  };

  const onViewTimeOffNavigate = (d) => {
    props?.navigation.navigate("TimeOffScreenNurse", {
      data: d,
    });
  };

  return (
    <View style={styles.container}>
      {loading ? (
        <View
          style={{ justifyContent: "center", alignItems: "center", flex: 1 }}
        >
          <Text>Loading...</Text>
        </View>
      ) : (
        <ScrollView showsVerticalScrollIndicator={false}>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: Platform.OS === "ios" ? 30 : 50,
            }}
          >
            {data?.map((element, index) => {
              return (
                <UserCardPTO
                  key={element?.id}
                  element={element}
                  onUserDetailNavigate={onUserDetailNavigate}
                  onChatRoomNavigate={onChatRoomNavigate}
                  onRequestJobNavigate={onRequestJobNavigate}
                  onViewTimeOffNavigate={onViewTimeOffNavigate}
                  userId={userId}
                  userUniqueId={userUniqueId}
                />
              );
            })}
          </View>
        </ScrollView>
      )}
    </View>
  );
};

export default ManagePTO;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
