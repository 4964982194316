import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  BackHandler,
  Alert,
  Platform,
  KeyboardAvoidingView,
} from "react-native";
import AuthButton from "../../../customComponents/Button/AuthButton";
import CustomInput from "../../../customComponents/Input/CustomInput";
import TitleText from "../../../customComponents/Text/TitleText";
import { getConfirmCode } from "../../../api_url/authFacility";

const ForgotPassword = ({ setContainerScreen, setForgotPasswordEmail }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [state, setState] = useState({
    email: "",
    emailFocus: false,
  });
  const { width, height } = Dimensions.get("window");
  const SCREEN_WIDTH = width < height ? width : height;

  const onChange = (text) => {
    setState({ ...state, email: text });
    setForgotPasswordEmail(text);
  };

  useEffect(() => {
    if (Platform.OS !== "web") {
      const backAction = () => {
        Alert.alert("Hold on!", "Are you sure you want to go back?", [
          {
            text: "Cancel",
            onPress: () => null,
            style: "cancel",
          },
          { text: "YES", onPress: () => setContainerScreen("") },
        ]);
        return true;
      };

      const backHandler = BackHandler.addEventListener(
        "hardwareBackPress",
        backAction
      );

      return () => backHandler.remove();
    }
  }, []);

  const onSubmit = async (email) => {
    if (state.email === "") {
      Alert.alert("Enter the fields");
    } else {
      setLoading(true);
      if (loading) {
        return;
      }
      try {
        await getConfirmCode({ email: email })
          .then(async res => {
            // console.log("res", res)
            Alert.alert("Success", res?.success)
            setContainerScreen("ChangePassword");
            setError(undefined)
          }).catch(e => {
            console.log(e?.message);
            Alert.alert("Warning", res?.message)
            setError(e?.message)
          });
      } catch (error) {
        console.log("error confirm :", error);
      }
      setLoading(false);
    }
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : ""}
      style={styles.container}
    >
      <View style={{ alignItems: "center" }}>
        <TitleText text={"Forgot Password"} />
        <Text
          style={{
            fontSize: 13,
            textAlign: "center",
            marginTop: 20,
            marginBottom: 20,
            width: width / 1.3,
          }}
        >
          Please enter your register email address , we will send OTP.
        </Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10,
            marginHorizontal: 20,
          }}
        >
          <CustomInput
            value={state.email}
            placeholder="Email"
            keyboardType={"email-address"}
            onChangeText={(text) => {
              onChange(text);
            }}
            clearValue={() => {
              setState({ ...state, email: "" });
              setForgotPasswordEmail("");
            }}
            viewStyle={{
              height: 40,
              backgroundColor: "#f2f2f2",
              borderRadius: 10,
              elevation: 1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              borderWidth: 0.5,
              borderColor: "#e6e6e6",
              paddingRight: 10,
              justifyContent: "space-between",
              marginTop: 0,
            }}
            textInputStyle={{
              height: 40,
              borderRadius: 10,
              paddingLeft: 10,
              paddingRight: 5,
              fontSize: 12,
            }}
            iconStyle={{
              height: 40,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          />
        </View>
      </View>

      {error !== "" && (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              color: "red",
              textAlign: "center",
              marginVertical: 10,
            }}
          >
            {error}
          </Text>
        </View>
      )}

      <View style={{ alignItems: "center", marginTop: 10 }}>
        <AuthButton
          name={loading ? "Loading..." : "Send OTP"}
          onPress={() => (loading ? null : onSubmit(state.email))}
          color={"#00b359"}
        />
      </View>
      <Text
        style={{
          fontSize: 12,
          textAlign: "center",
          marginTop: 15,
        }}
        onPress={() => setContainerScreen("")}
      >
        Back to Login
      </Text>
    </KeyboardAvoidingView>
  );
};

export default ForgotPassword;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    justifyContent: "center",
  },
});
