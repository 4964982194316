import React, { useRef } from "react";
import { TextInput, View } from "react-native";

const OtpInput = ({ otpCode, setOtpCode }) => {
  const inputRefs = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  const handleChange = (index, text) => {
    const newOtpCode = otpCode + text;
    setOtpCode(newOtpCode);

    if (index === 5) {
      return;
    }
    inputRefs.current[index + 1].current.focus();
  };

  const handleKeyPress = (event, index) => {
    if (event.nativeEvent.key === "Backspace" && index > 0) {
      event.preventDefault();
      setOtpCode(otpCode.slice(0, -1));
      inputRefs.current[index - 1].current.focus();
    }
  };

  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 10,
      }}
    >
      {[...Array(6)].map((_, index) => (
        <TextInput
          key={index}
          ref={inputRefs.current[index]}
          keyboardType="numeric"
          style={{
            width: 40,
            height: 40,
            marginHorizontal: 5,
            textAlign: "center",
            borderWidth: 0.5,
            borderColor: "#b3b3b3",
            backgroundColor: "#e6e6e6",
            borderRadius: 10,
            elevation: 3,
          }}
          maxLength={1}
          onChangeText={(text) => handleChange(index, text)}
          onKeyPress={(event) => handleKeyPress(event, index)}
          value={otpCode.charAt(index) || ""}
        />
      ))}
    </View>
  );
};

export default OtpInput;
